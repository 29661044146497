import React from 'react';

const ComponentLayout = ({ label, msg, required, children }) => {
  return (
    <div className="app-form-item">
      {label && (
        <div className="app-form-label" required={required}>
          {label}
        </div>
      )}
      <div className="app-form-input-wrap">{children}</div>
      {msg && <div className="app-form-msg">{msg}</div>}
    </div>
  );
};

export default ComponentLayout;
