import React, { useContext, useState, useMemo, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
//
import { Card, Tooltip, Space, Button, Row, Col, Table, message, Tag, Steps } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import Input from '../../components/Input';
import Select from '../../components/Select';
import DatePicker from '../../components/DatePicker';
//
import useListOfCidDateTypeBySPV from '../../hooks/useListOfCidDateTypeBySPV';
import useListOfCidStatusBySPV from '../../hooks/useListOfCidStatusBySPV';
import useListOfCidLevel from '../../hooks/useListOfCidLevel';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { SettingContext } from '../../contexts/settingsProvider';
import { AppSyncQueryTextFormat, SetKeyToArray, DateFormat } from '../../utils/format';
import { GetAppSyncRspsErrorMessage } from '../../utils/parse';
import { IsValidAppSyncRsps } from '../../utils/valid';
import { QueryOmContractLevelList } from '../../utils/dataHelpers';

const DefaultCidListFilter = {
  dateType: 1,
  startDate: moment().startOf('M'),
  endDate: moment().endOf('M'),
};

const CidStatistics = ({ grafanaUrl, objectID }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { dark } = useContext(SettingContext);
  const theme = dark ? 'dark' : 'light';
  const token = user.token?.replace?.(/^Bearer /, '');
  const [uncloseAmount, setUncloseAmount] = useState({ check: 0, process: 0 });
  const uncloseTotal = useMemo(() => {
    return uncloseAmount.check + uncloseAmount.process;
  }, [uncloseAmount]);
  //
  const { data: listOfCidDateType } = useListOfCidDateTypeBySPV();
  const { data: listOfCidStatus } = useListOfCidStatusBySPV();
  const { data: listOfDefaultCidLevel } = useListOfCidLevel();
  const [listOfCidLevel, setListOfCidLevel] = useState([]);
  //
  const [filterData, setFilterData] = useState({ ...DefaultCidListFilter });
  const [cidFormList, setCidFormList] = useState([]);
  const isMaintainMode = useMemo(() => {
    return !user.operations.includes('BOOSTERINFO_investor-view');
  }, [user]);

  const CidFormTableColumn = [
    {
      title: t('boosterStation.cidStatistics.importance'),
      dataIndex: 'levelName',
      key: 'levelName',
      width: 120,
      showSorterTooltip: false,
      sorter: (a, b) => a.level - b.level,
      render: (value, record) => (
        <Tag
          color={
            record.levelSeq === 1
              ? 'red'
              : record.levelSeq === 2
              ? 'volcano'
              : record.levelSeq === 3
              ? 'gold'
              : 'green'
          }
        >
          {value}
        </Tag>
      ),
    },
    {
      title: t('boosterStation.cidStatistics.cidNumber'),
      dataIndex: 'cidFormID',
      key: 'cidFormID',
      width: 120,
      showSorterTooltip: false,
      sorter: (a, b) => a.cidFormID.localeCompare(b.cidFormID),
      render: (value) => (
        <Link to={`/cids/${value}`} target="_blank">
          {value}
        </Link>
      ),
    },
    {
      title: t('boosterStation.cidStatistics.createDate'),
      dataIndex: 'createDate',
      key: 'createDate',
      width: 120,
      showSorterTooltip: false,
      sorter: (a, b) => a.createDate.localeCompare(b.createDate),
    },
    {
      title: t('boosterStation.cidStatistics.occurDate'),
      dataIndex: 'occurDate',
      key: 'occurDate',
      width: 120,
      showSorterTooltip: false,
      sorter: (a, b) => a.occurDate.localeCompare(b.occurDate),
    },
    {
      title: t('boosterStation.cidStatistics.maintainStartDate'),
      dataIndex: 'maintainStartDate',
      key: 'maintainStartDate',
      width: 120,
      showSorterTooltip: false,
      sorter: (a, b) => a.maintainStartDate.localeCompare(b.maintainStartDate),
    },
    {
      title: t('boosterStation.cidStatistics.maintainEndDate'),
      dataIndex: 'maintainEndDate',
      key: 'maintainEndDate',
      width: 120,
      showSorterTooltip: false,
      sorter: (a, b) => a.maintainEndDate.localeCompare(b.maintainEndDate),
    },
    {
      title: t('boosterStation.cidStatistics.relatedSite'),
      dataIndex: 'ownerObjectID',
      key: 'ownerObjectID',
      width: 200,
      showSorterTooltip: false,
      sorter: (a, b) => a.ownerObjectID.localeCompare(b.ownerObjectID),
      render: (value, record) => {
        if (Array.isArray(record.ownerObjectNames)) {
          return record.ownerObjectNames.map((m, mIdx) => <div key={mIdx}>{m}</div>);
        } else {
          return '';
        }
      },
    },
    {
      title: t('boosterStation.cidStatistics.issue'),
      dataIndex: 'issue',
      key: 'issue',
      showSorterTooltip: false,
      sorter: (a, b) => a.issue.localeCompare(b.issue),
    },
    {
      title: t('boosterStation.cidStatistics.cidStatus'),
      dataIndex: 'statusName',
      key: 'statusName',
      width: 120,
      showSorterTooltip: false,
      sorter: (a, b) => a.status - b.status,
    },
    {
      title: t('boosterStation.cidStatistics.inverterNumber'),
      dataIndex: 'invSeq',
      key: 'invSeq',
      showSorterTooltip: false,
      sorter: (a, b) => a.issue.localeCompare(b.issue),
    },
  ];

  // -------------------------------------------------

  // 載入：即時未結案CID - 處理流程
  useEffect(() => {
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery($ownerObjectID: String, $maintainView: Boolean) {
              om_getCidUncloseAmountSummary(ownerObjectID: $ownerObjectID, maintainView: $maintainView) {
                step
                amount
              }
            }
            `
          ),
          variables: { ownerObjectID: objectID, maintainView: isMaintainMode },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(
            t('boosterStation.cidStatistics.queryUnclosedCIDFormCountFailed', {
              error: GetAppSyncRspsErrorMessage(res),
            })
          );
          return;
        }

        const resData = res.data.data.om_getCidUncloseAmountSummary;
        if (Array.isArray(resData) && resData.length > 0) {
          const result = { check: 0, process: 0 };
          for (let i = 0; i < resData.length; i++) {
            if (resData[i].step === 'check') result.check = resData[i].amount || 0;
            if (resData[i].step === 'process') result.process = resData[i].amount || 0;
          }
          setUncloseAmount(result);
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('boosterStation.cidStatistics.queryUnclosedCIDFormCountError'));
          console.error('load CID unclose amount summary error: ', err);
        }
      });
  }, [user, objectID, isMaintainMode, t]);

  // 載入：CID 列表
  const LoadCidFormList = useCallback(
    (conditions) => {
      axios
        .post(
          appConfigs.appSyncURL,
          {
            query: AppSyncQueryTextFormat(
              `query OmQuery(
                $cidFormID: String
                $dateType: String
                $startDate: String
                $endDate: String
                $ownerObjectID: String
                $level: String
                $issue: String
                $status: String
                $timezone: String
                $maintainView: Boolean
              ) {
                om_getCidFormListBySPV(
                  cidFormID: $cidFormID,
                  dateType: $dateType,
                  startDate: $startDate,
                  endDate: $endDate,
                  ownerObjectID: $ownerObjectID,
                  level: $level,
                  issue: $issue,
                  status: $status,
                  timezone: $timezone
                  maintainView: $maintainView
                ) {
                  level
                  levelName
                  levelSeq
                  cidFormID
                  createDate
                  occurDate
                  maintainStartDate
                  maintainEndDate
                  ownerObjectID
                  ownerObjectNames
                  issue
                  statusName
                  status
                  invSeq
                }
              }`
            ),
            variables: {
              cidFormID: conditions.cidFormID,
              dateType: `${conditions.dateType}`,
              startDate: DateFormat(conditions.startDate),
              endDate: DateFormat(conditions.endDate),
              ownerObjectID: objectID,
              level: conditions.level,
              issue: conditions.issue,
              status: conditions.status,
              timezone: appConfigs.timezone,
              maintainView: isMaintainMode,
            },
          },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (!IsValidAppSyncRsps(res)) {
            message.warn(
              t('boosterStation.cidStatistics.queryCIDFormFailed', { error: GetAppSyncRspsErrorMessage(res) })
            );
            return;
          }

          setCidFormList((res.data.data.om_getCidFormListBySPV || []).map(SetKeyToArray));
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            message.error(t('boosterStation.cidStatistics.queryCIDFormError'));
            console.error('load CID list error: ', err);
          }
        });
    },
    [user, objectID, isMaintainMode, t]
  );
  useEffect(() => {
    LoadCidFormList({ ...DefaultCidListFilter });
  }, [LoadCidFormList]);

  // 載入：CID列表篩選條件 - 重要性
  useEffect(() => {
    async function fetchData() {
      if (!objectID) return;

      const getOmContractLevelList = await QueryOmContractLevelList(user, [], [objectID]);
      if (getOmContractLevelList.length > 0) {
        setListOfCidLevel(getOmContractLevelList.sort((a, b) => a.seq - b.seq));
      } else {
        setListOfCidLevel(listOfDefaultCidLevel.sort((a, b) => a.seq - b.seq));
      }
    }
    fetchData();
  }, [user, objectID, listOfDefaultCidLevel]);

  // -------------------------------------------------

  const handleFilterDataChange = (name) => (e) => {
    let modifyValue = null;
    switch (name) {
      case 'cidFormID':
      case 'issue':
        modifyValue = e?.target?.value;
        break;
      default:
        modifyValue = e;
        break;
    }

    setFilterData((prev) => ({ ...prev, [name]: modifyValue }));
  };
  const handleFilterDataReset = () => {
    setFilterData({ ...DefaultCidListFilter });
  };
  const handleSearch = () => {
    LoadCidFormList(filterData);
  };

  // -------------------------------------------------
  return (
    <div className="app-page-content">
      <Card
        title={
          <Space>
            <div>{t('boosterStation.cidStatistics.realtimeUnclosedCID', { count: uncloseTotal })}</div>
            <Tooltip placement="right" title={t('boosterStation.cidStatistics.unclosedCIDTooltip')}>
              <InfoCircleOutlined />
            </Tooltip>
          </Space>
        }
      >
        <Row gutter={[8, 8]} justify="center">
          <Col xs={24} sm={24} md={12} lg={8}>
            <div
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                marginBottom: '20px',
                textAlign: 'center',
                width: '100%',
              }}
            >
              {t('boosterStation.cidStatistics.processFlow')}
            </div>
            <Steps
              progressDot
              current={1}
              items={[
                {
                  title: (
                    <Row gutter={[10, 0]} align="bottom">
                      <Col>{t('boosterStation.cidStatistics.problemClarification')}</Col>
                      <Col style={{ fontSize: '30px', lineHeight: '30px' }}>{uncloseAmount.check}</Col>
                      <Col>
                        <Tooltip
                          placement="right"
                          title={t('boosterStation.cidStatistics.problemClarificationTooltip')}
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Col>
                    </Row>
                  ),
                  description: '',
                },
                {
                  title: (
                    <Row gutter={[10, 0]} align="bottom">
                      <Col>{t('boosterStation.cidStatistics.taskAssignment')}</Col>
                      <Col style={{ fontSize: '30px', lineHeight: '30px' }}>{uncloseAmount.process}</Col>
                      <Col>
                        <Tooltip
                          placement="right"
                          title={t('boosterStation.cidStatistics.taskAssignmentTooltip')}
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Col>
                    </Row>
                  ),
                  description: '',
                },
              ]}
            />
          </Col>
        </Row>
      </Card>

      <div style={{ margin: '16px -16px 16px -16px' }}>
        <iframe
          id="frame"
          width={'100%'}
          height={900}
          title={t('boosterStation.cidStatistics.cidStatisticsTitle')}
          style={{ border: 0 }}
          src={`${grafanaUrl}&var-spv=ALL&var-period=ALL&var-plant=${objectID}&auth_token=${token}&theme=${theme}`}
        ></iframe>
      </div>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card
            title={t('boosterStation.cidStatistics.cidList')}
            extra={
              <Row gutter={[8, 8]} justify="end">
                <Col xs={12} sm={3} md={3} lg={2}>
                  <Input
                    placeholder={t('boosterStation.cidStatistics.number')}
                    value={filterData.cidFormID}
                    onChange={handleFilterDataChange('cidFormID')}
                  />
                </Col>
                <Col xs={12} sm={3} md={3} lg={3}>
                  <Select
                    placeholder={t('boosterStation.cidStatistics.dateType')}
                    options={listOfCidDateType}
                    style={{ width: '100%' }}
                    value={filterData.dateType}
                    onChange={handleFilterDataChange('dateType')}
                  />
                </Col>
                <Col xs={12} sm={3} md={3} lg={3}>
                  <DatePicker
                    placeholder={t('boosterStation.cidStatistics.startDate')}
                    value={filterData.startDate}
                    onChange={handleFilterDataChange('startDate')}
                    style={{ width: '100%' }}
                  />
                </Col>
                <Col xs={12} sm={3} md={3} lg={3}>
                  <DatePicker
                    placeholder={t('boosterStation.cidStatistics.endDate')}
                    value={filterData.endDate}
                    onChange={handleFilterDataChange('endDate')}
                    style={{ width: '100%' }}
                  />
                </Col>
                <Col xs={12} sm={3} md={3} lg={2}>
                  <Select
                    placeholder={t('boosterStation.cidStatistics.importance')}
                    options={listOfCidLevel}
                    style={{ width: '100%' }}
                    value={filterData.level}
                    onChange={handleFilterDataChange('level')}
                  />
                </Col>
                <Col xs={12} sm={3} md={3} lg={2}>
                  <Input
                    placeholder={t('boosterStation.cidStatistics.issue')}
                    style={{ width: '100%' }}
                    value={filterData.issue}
                    onChange={handleFilterDataChange('issue')}
                  />
                </Col>
                <Col xs={12} sm={3} md={3} lg={2}>
                  <Select
                    placeholder={t('boosterStation.cidStatistics.status')}
                    options={listOfCidStatus}
                    style={{ width: '100%' }}
                    value={filterData.status}
                    onChange={handleFilterDataChange('status')}
                  />
                </Col>
                <Col xs={12} sm={3} md={3} lg={2}>
                  <Button onClick={handleFilterDataReset} style={{ width: '100%' }}>
                    {t('boosterStation.cidStatistics.reset')}
                  </Button>
                </Col>
                <Col xs={12} sm={3} md={3} lg={2}>
                  <Button type="primary" onClick={handleSearch} style={{ width: '100%' }}>
                    {t('boosterStation.cidStatistics.search')}
                  </Button>
                </Col>
              </Row>
            }
          >
            <Table
              columns={CidFormTableColumn}
              dataSource={cidFormList}
              footer={false}
              scroll={{ x: 1500 }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CidStatistics;
