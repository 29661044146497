import React from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';

/*
bc {
  location: {
    pathname: '/alerts/2'
  },
  match {
    params: {id: '2'},
    pathname: '/alerts/2',
    route: {
      path: '/alerts/:id',
      breadcrumbName: 'AlertID: '
    }
  }
}
*/
const RfdmeBreadcrumb = ({ routes }) => {
  const { t } = useTranslation();
  const breadcrumbs = useBreadcrumbs(routes);
  const breadcrumbItems = breadcrumbs.map((bc) => {
    const currentPathname = bc.location.pathname;
    const pathname = bc.match.pathname;
    const params = bc.match.params;

    let breadcrumbName = '';
    if (bc.match.route) {
      breadcrumbName = bc.match.route.breadcrumbName;
    }

    let displayName = breadcrumbName;
    if (Object.keys(params).length > 0) {
      displayName = displayName + params.id;
    }
    //客製路由：當路由在spv列表、alert統計、cid統計分析報告、om統計分析報告時加上父路由"儀錶板"
    if (
      pathname === '/spvlist' ||
      pathname === '/alertstatistics' ||
      pathname === '/cidamountanalysis' ||
      pathname === '/omamountanalysis'
    ) {
      return (
        <React.Fragment key={'dashboard' + pathname}>
          <Breadcrumb.Item key="dashboard">{t('breadcrumb.dashboard')}</Breadcrumb.Item>
          {pathname === '/spvlist' && currentPathname !== pathname ? (
            <Breadcrumb.Item key={pathname}>
              <Link to={pathname}>{displayName}</Link>
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item key={pathname}>{displayName}</Breadcrumb.Item>
          )}
        </React.Fragment>
      );
    }
    //客製路由：當路由是在"報表" 父路由下時，加上 "報表" 的父層
    if (
      pathname === '/nerbyobjectcompare' ||
      pathname === '/tpcrecordsheet' ||
      pathname === '/tpcweeklysheet'
    ) {
      return (
        <React.Fragment key={'dashboard' + pathname}>
          <Breadcrumb.Item key="dashboard">{t('breadcrumb.report')}</Breadcrumb.Item>
          <Breadcrumb.Item key={pathname}>{displayName}</Breadcrumb.Item>
        </React.Fragment>
      );
    }
    //客製路由：當路由在"CID管理"時加上父路由"事件管理"
    if (pathname === '/cids') {
      if (currentPathname === pathname) {
        return (
          <React.Fragment key={'event' + pathname}>
            <Breadcrumb.Item key="event">{t('breadcrumb.eventManagement')}</Breadcrumb.Item>
            <Breadcrumb.Item key={pathname}>{displayName}</Breadcrumb.Item>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={'event' + pathname}>
            <Breadcrumb.Item key="event">{t('breadcrumb.eventManagement')}</Breadcrumb.Item>
            {displayName && (
              <Breadcrumb.Item key={pathname}>
                <Link to={pathname}>{displayName}</Link>
              </Breadcrumb.Item>
            )}
          </React.Fragment>
        );
      }
    }

    //客製路由：當路由在OM代收與待開單、派工時程規劃、OM計劃管理、派工記錄管理、OM記錄管理、OM晨會報表時加上父路由"事件管理/OM管理"
    if (
      pathname === '/omtodo' ||
      pathname === '/omcalendar' ||
      pathname === '/oms' ||
      pathname === '/omrecord' ||
      pathname === '/omworks' ||
      pathname === '/ommorning'
    ) {
      if (currentPathname === pathname) {
        return (
          <React.Fragment key={'event' + pathname}>
            <Breadcrumb.Item key="event">{t('breadcrumb.eventManagement')}</Breadcrumb.Item>
            <Breadcrumb.Item key="eventom">{t('breadcrumb.omManagement')}</Breadcrumb.Item>
            <Breadcrumb.Item key={pathname}>{displayName}</Breadcrumb.Item>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={'event' + pathname}>
            <Breadcrumb.Item key="event">{t('breadcrumb.eventManagement')}</Breadcrumb.Item>
            <Breadcrumb.Item key="eventom">{t('breadcrumb.omManagement')}</Breadcrumb.Item>
            <Breadcrumb.Item key={pathname}>
              <Link to={pathname}>{displayName}</Link>
            </Breadcrumb.Item>
          </React.Fragment>
        );
      }
    }

    //客製路由：當路由在CID選單項目管理、OM選單項目管理時加上父路由"系統設定/選單管理"
    if (pathname === '/cidconfig' || pathname === '/omconfig') {
      if (currentPathname === pathname) {
        return (
          <React.Fragment key={'system' + pathname}>
            <Breadcrumb.Item key="system">{t('breadcrumb.systemSettings')}</Breadcrumb.Item>
            <Breadcrumb.Item key="systemmenu">{t('breadcrumb.menuManagement')}</Breadcrumb.Item>
            <Breadcrumb.Item key={pathname}>{displayName}</Breadcrumb.Item>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={'system' + pathname}>
            <Breadcrumb.Item key="system">{t('breadcrumb.systemSettings')}</Breadcrumb.Item>
            <Breadcrumb.Item key="systemmenu">{t('breadcrumb.menuManagement')}</Breadcrumb.Item>
            <Breadcrumb.Item key={pathname}>
              <Link to={pathname}>{displayName}</Link>
            </Breadcrumb.Item>
          </React.Fragment>
        );
      }
    }

    if (currentPathname === pathname) {
      return <Breadcrumb.Item key={pathname}>{displayName}</Breadcrumb.Item>;
    } else {
      return (
        <Breadcrumb.Item key={pathname}>
          <Link to={pathname}>{displayName}</Link>
        </Breadcrumb.Item>
      );
    }
  });

  return <Breadcrumb>{breadcrumbItems}</Breadcrumb>;
};

export default RfdmeBreadcrumb;
