import moment from 'moment';
import appConfigs from '../../configs';
import { JsonStringToFormObject, FormObjectToJsonString } from '../../utils/format';

const PAGE_SIZE = 20;
const MOMENT_COLUMN_NAMES = ['omStartDate', 'cidStartDate'];
export const INITIAL_FILTER_DATA = { omStartDate: moment().add(-2, 'months') };

export const GetDataOfFilterFromSession = () => {
  const filterDataStringInSession = window.sessionStorage.getItem('om-record-list-filterData');
  return JsonStringToFormObject(filterDataStringInSession, MOMENT_COLUMN_NAMES);
};

export const SetDefaultDataOfFilterToSession = () => {
  const filterDataStringInSession = window.sessionStorage.getItem('om-record-list-filterData');
  if (!filterDataStringInSession) {
    window.sessionStorage.setItem(
      'om-record-list-filterData',
      FormObjectToJsonString(INITIAL_FILTER_DATA, MOMENT_COLUMN_NAMES)
    );
  }
};

export const SetDataOfFilterToSession = (value) => {
  window.sessionStorage.setItem(
    'om-record-list-filterData',
    FormObjectToJsonString(value, MOMENT_COLUMN_NAMES)
  );
};

export const GenerateQueryConditions = (currentPage) => {
  const conditions = GetDataOfFilterFromSession();

  // 針對查詢條件的特殊性再處理

  // 案場
  if (conditions.omObject) {
    conditions.objectIDs = [conditions.omObject];
    delete conditions.omObject;
  }

  // 派工日期
  if (moment.isMoment(conditions.omStartDate)) {
    conditions.omDateType = 1;
    conditions.omStartTime = conditions.omStartDate.format('YYYY-MM-DD 00:00:00');
    conditions.omEndTime = '2999-12-31 23:59:59';
    delete conditions.omStartDate;
  }

  // 重要性
  if (conditions.cidLevel) {
    conditions.cidLevelIDs = [conditions.cidLevel];
    delete conditions.cidLevel;
  }

  // 開單日期
  if (moment.isMoment(conditions.cidStartDate)) {
    conditions.cidDateType = 2;
    conditions.cidStartDate = conditions.cidStartDate.format('YYYY-MM-DD');
    conditions.cidEndDate = '2999-12-31';
  }

  if (currentPage) {
    conditions.page = currentPage;
    conditions.pageSize = PAGE_SIZE;
  }

  conditions.timezone = appConfigs.timezone;

  return conditions;
};
