import moment from 'moment';

export const IsValidAppSyncRsps = (res) => {
  return res && res.status === 200 && res.data && res.data.data && !res.data.errors;
};

export const IsValidApiGatewayRsps = (res) => {
  return res && res.status === 200 && res.data && res.data.status === true;
};

export const IsNullOrUndefined = (val) => {
  if (val === null) return true;
  if (val === undefined) return true;
  return false;
};

export const IsNumber = (value) => {
  if ([true, false, null, undefined].includes(value)) return false;
  if (Array.isArray(value)) return false;
  return !isNaN(value);
};

/**
 * 判斷信箱是否符合格式
 * @param {string} value 信箱
 */
export const IsEmail = (value) => {
  if (IsNullOrUndefined(value)) return false;
  if (value === '') return false;

  const re = /^\w+((-\w+)|(\.\w+)|(\+\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
  return re.test(value);
};

/**
 * 判斷電話號碼是否符合格式
 * @param {string} value 號碼
 */
export const IsPhoneNumber = (value) => {
  if (IsNullOrUndefined(value)) return false;
  if (value === '') return false;

  const re = /^\+?\(?([0-9]{2})\)?([0-9])*([0-9]|-)*([0-9])+$/;
  return re.test(value);
};

const MSG_EMPTY = '欄位未填寫';
const MSG_NOT_NUMBER = '欄位需輸入數字';
const MSG_DATE_FORMAT = '日期格式不正確';
// const MSG_DATE_AFTER_NOW = '日期不可超過今日';
// const MSG_DATETIME_BEFORE_NOW = '時間不可早於現在';
export const ValidFormData = (data, checkSchema) => {
  const result = { status: true, msg: '', data: null };

  if (data) {
    result.data = { ...data };
    if (checkSchema) {
      if (Array.isArray(checkSchema)) {
        let c = null;
        let value = null;
        for (let i = 0; i < checkSchema.length; i++) {
          c = checkSchema[i];
          value = result.data[c.name];

          switch (c.type) {
            case 'input':
              if (c.required === true) {
                if (IsNullOrUndefined(value) || value === '') {
                  result.status = false;
                  result.data[c.name] = null;
                  result.data[c.name + '_msg'] = MSG_EMPTY;
                }
              }
              break;
            case 'select':
            case 'select-multi':
            case 'radio':
            case 'checkboxgroup':
              if (c.required === true) {
                if (IsNullOrUndefined(value) || value === '' || (Array.isArray(value) && value.length < 1)) {
                  result.status = false;
                  result.data[c.name] = c.type === 'select-multi' ? [] : null;
                  result.data[c.name + '_msg'] = MSG_EMPTY;
                }
              }
              break;
            case 'number':
              if (c.required === true) {
                if (IsNullOrUndefined(value) || value === '') {
                  result.status = false;
                  result.data[c.name] = null;
                  result.data[c.name + '_msg'] = MSG_EMPTY;
                }
              }

              if (value && !IsNumber(value)) {
                result.status = false;
                result.data[c.name] = null;
                result.data[c.name + '_msg'] = MSG_NOT_NUMBER;
              }
              break;
            case 'date':
            case 'datetime':
              if (c.required === true) {
                if (IsNullOrUndefined(value)) {
                  result.status = false;
                  result.data[c.name] = null;
                  result.data[c.name + '_msg'] = MSG_EMPTY;
                }
              }

              if (!IsNullOrUndefined(value)) {
                const m = moment.isMoment(value) ? value : moment(value);
                if (!m.isValid()) {
                  result.status = false;
                  result.data[c.name] = null;
                  result.data[c.name + '_msg'] = MSG_DATE_FORMAT;
                }
              }
              break;
            default:
              break;
          }
        }
      } else {
        result.status = false;
        result.msg = '傳入的驗證綱要格式不正確';
      }
    }
  } else {
    result.data = {};
  }

  return result;
};
