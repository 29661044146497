import { Input } from 'antd';
import ComponentLayout from '../../layout/componentLayout';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

const RfdmeTextArea = ({ required, label, msg, ...props }) => {
  const { t } = useTranslation();
  return (
    <ComponentLayout label={label} required={required} msg={msg}>
      <TextArea style={{ width: '100%' }} placeholder={label && `${t('pleaseEnter')}${label}`} {...props} />
    </ComponentLayout>
  );
};

export default RfdmeTextArea;
