import { useEffect, useContext, useState, useMemo } from 'react';
import axios from 'axios';
import { message, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { SettingContext } from '../../contexts/settingsProvider';
import { IsValidAppSyncRsps } from '../../utils/valid';
import { AppSyncQueryTextFormat } from '../../utils/format';
import { GetAppSyncRspsErrorMessage } from '../../utils/parse';

const ReportTPCWeeklySheet = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { dark } = useContext(SettingContext);
  const [grafanaUrl, setGrafanUrl] = useState('');
  
  const token = useMemo(() => {
    return user.token?.replace?.(/^Bearer /, '');
  }, [user]);
  
  const theme = useMemo(() => {
    return dark ? 'dark' : 'light';
  }, [dark]);

  useEffect(() => {
    axios
      .post(
        appConfigs.sharedAppSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery($system: String, $kind: String) {
              shared_getGrafanaEmbeddingList(system: $system, kind: $kind) {
                code
                id
                url
              }
            }
            `
          ),
          variables: {
            system: appConfigs.systemID,
            kind: 'reporttpcweeklysheet',
          },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('reportTPCWeeklySheet.failedToGetGrafanaEmbeddingInfo', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        const data = res.data.data.shared_getGrafanaEmbeddingList || [];
        if (!Array.isArray(data) || data.length === 0) {
          message.warn(t('reportTPCWeeklySheet.failedToGetGrafanaUrl'));
          return;
        }

        let url1 = data.find((f) => f.code === 'report-tpc-weekly-sheet');
        if (url1) url1 = url1.url;
        setGrafanUrl(url1);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error('load grafanaUrlApi error', err);
          message.error(t('reportTPCWeeklySheet.getGrafanaEmbeddingInfoError'));
        }
      });
  }, [user, t]);

  return (
    <>
      <Typography.Title level={3}>{t('reportTPCWeeklySheet.title')}</Typography.Title>
      {grafanaUrl ? (
        <div style={{ width: '100%', height: '1000px', overflow: 'hidden' }}>
          <iframe
            id="frame"
            width={'100%'}
            title={t('reportTPCWeeklySheet.title')}
            style={{ border: 0, height: '100%' }}
            src={`${grafanaUrl}&auth_token=${token}&theme=${theme}`}
          ></iframe>
        </div>
      ) : (
        <Spin />
      )}
    </>
  );
};

export default ReportTPCWeeklySheet;
