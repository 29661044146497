import React, { useState, useMemo, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import bn from 'bignumber.js';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { ArcherContainer, ArcherElement } from 'react-archer';
import { Col, Row, Button, Table, Spin, message, Descriptions, Card, Badge, Tooltip, Empty } from 'antd';
import { ReloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { IsValidAppSyncRsps } from '../../utils/valid';
import { AppSyncQueryTextFormat, SetKeyToArray, NumberFormat } from '../../utils/format';
import { GetAppSyncRspsErrorMessage } from '../../utils/parse';
import pattern from '../../images/pattern.svg';

const bgColor = '#E9F5F9';
const energyLineColor = 'rgba(0, 0, 0, 0.3)';
const alertColor = '#FF4D4F';
const alertYellowColor = '#FFD700';
const BlueSpan = styled.span`
  color: #1ea4d0;
`;
const GenerateEnergyText = styled.div`
  font-size: 24px;
  font-weight: 500;
`;
const RedDotDiv = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 100px;
  background: #fa541c;
`;
const GreenDotDiv = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 100px;
  background: #52c41a;
`;
const Share = styled.div`
  display: flex;
  width: ${(props) => props.width - props.marginRight + 'px'};
  margin-right: ${(props) => props.marginRight + 'px'};
  margin-bottom: ${(props) => props.marginBottom + 'px'};
  background-color: ${(props) => props['background-color'] || bgColor};
`;
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const String = styled(Share)`
  writing-mode: vertical-rl;
  justify-content: center;
  align-items: center;
`;
const StringText = styled.div`
  margin-right: ${(props) => props.marginRight + 'px'};
  text-align: center;
`;
const Element = styled(Share)`
  flex-direction: column;
  justify-content: space-between;
`;
const Port = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  /*
  height: 30px;
  border: 1px solid gray;
  */
`;
const Label = styled.div`
  flex: 1;
  min-height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TF = styled(Element)`
  min-height: 30px;
  margin-bottom: 0px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
`;
const TFBlock = styled.div`
  flex: 1;
`;
const VContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;
const Inv = styled(Element)`
  height: 140px;
`;
const TFValue = styled.div`
  position: relative;
  left: 50%;
  padding-left: 2px;
  text-align: left;
  font-size: 12px;
  color: red;
`;

//
const baseProps = {
  marginRight: 5,
  marginBottom: 30,
};

//
const GetObjectID = (urlPath) => {
  const objectId = (urlPath || '').replace('/pvs/', '');
  return objectId || null;
};

//
const Info = ({ dataOfObject, basicLoading, setBasicLoading }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const [recordOfObjectCid, setRecordOfObjectCid] = useState([]);
  const [cidLoading, setCidLoading] = useState(false);
  const [pvStringLoading, setPvStringLoading] = useState(false);
  const [dataOfPvStringSort, setOfPvStringSort] = useState();
  const [dataOfPvString, setOfPvString] = useState();
  const [dataOfPvAlertSignal, setOfPvAlertSignal] = useState();
  const [dataOfString, setDataOfString] = useState();
  const [dataOfDcb1, setDataOfDcb1] = useState();
  const [dataOfInv, setDataOfInv] = useState();
  const [dataOfAcb, setDataOfAcb] = useState();
  const [dataOfMp, setDataOfMp] = useState();
  const [dataOfPm, setDataOfPm] = useState();
  let stringUnitWidth = 25;
  const [unitWidth, setUnitWidth] = useState(stringUnitWidth + baseProps.marginRight);

  const showCidContent = user.operations.includes('OBJECTINFO_show-cid-content');
  const showGenerationData = user.operations.includes('OBJECTINFO_show-generation-data');
  const showPvstringDiagram = user.operations.includes('OBJECTINFO_show-pvstring-diagram');

  const loadCidFormList = useCallback(() => {
    const objectId = GetObjectID(location.pathname);
    if (!objectId) return;
    if (!showCidContent) return;

    setCidLoading(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery($objectID: String!, $timezone: String!) { 
              om_getObjectCidContent(objectID: $objectID, timezone: $timezone) {
                cidFormID
                startDate
                expired
                days
                issue
                avgLossEnergy
                lossEnergy
              }
            }
           `
          ),
          variables: {
            objectID: objectId,
            timezone: appConfigs.timezone,
          },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('object.info.failedToGetPendingCidList', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        const tmp = res.data.data.om_getObjectCidContent;
        setRecordOfObjectCid(tmp.map(SetKeyToArray));
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error('load object cid content error', err);
          message.error(t('object.info.getPendingCidListError'));
        }
      })
      .then(() => {
        setCidLoading(false);
      });
  }, [user, location.pathname, showCidContent, t]);

  const loadPvAlertSignal = useCallback(() => {
    const objectId = GetObjectID(location.pathname);
    if (!objectId) return;

    setPvStringLoading(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery($objectID: String!) { 
              om_getObjectPvAlertSignal(objectID: $objectID) {
                alertOwnerID
                alertName
                alertStartTime
                alertCheckTime
                alertID
                alertType
                alertComponent
                lossEnergy
                alertRedColor
                mpptNumber
                cnt
              }
            }
           `
          ),
          variables: {
            objectID: objectId,
          },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('object.info.failedToGetPvAlertSignalData', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        let exeAlertOwnerID = '';
        let exeAlertName = '';
        let exeAlertStartTime = '';
        const tmp = res.data.data.om_getObjectPvAlertSignal;
        if (tmp.length > 0) {
          let splitLossEnergy = false;
          let filterAlterData = [];
          const setAlertDatas = tmp.map((row) => {
            if (
              (exeAlertOwnerID === '' && exeAlertName === '' && exeAlertStartTime === '') ||
              exeAlertOwnerID !== row.alertOwnerID ||
              exeAlertName !== row.alertName ||
              exeAlertStartTime !== row.alertStartTime
            ) {
              exeAlertOwnerID = row.alertOwnerID;
              exeAlertName = row.alertName;
              exeAlertStartTime = row.alertStartTime;
              splitLossEnergy = false;

              filterAlterData = tmp.filter((alert) => {
                return (
                  alert.alertOwnerID === exeAlertOwnerID &&
                  alert.alertName === exeAlertName &&
                  alert.alertStartTime === exeAlertStartTime
                );
              });

              if (filterAlterData.length > 1 && row.alertComponent === 'mppt') {
                splitLossEnergy = true;
              }
            } else if (
              exeAlertOwnerID === row.alertOwnerID &&
              exeAlertName === row.alertName &&
              exeAlertStartTime === row.alertStartTime
            ) {
              if (filterAlterData.length > 1 && row.alertComponent === 'mppt') {
                splitLossEnergy = true;
              }
            }

            if (splitLossEnergy) {
              const mpptNumbers = filterAlterData.map((alert) => alert.mpptNumber);

              const filterStringData = dataOfPvStringSort.filter((data) => {
                return data.clientDeviceID === exeAlertOwnerID && mpptNumbers.includes(data.invInNO);
              });

              const sumModuleCount = filterStringData.reduce((sum, data) => sum + data.stringModuleCount, 0);
              const filterModuleCountByMppt = filterStringData.filter(
                (data) => data.invInNO === row.mpptNumber
              );
              const sumModuleCountByMppt = filterModuleCountByMppt.reduce(
                (sum, data) => sum + data.stringModuleCount,
                0
              );
              const ratio = sumModuleCountByMppt / sumModuleCount;
              const newLossEnergy = row.lossEnergy * ratio;

              return {
                ...row,
                lossEnergy: newLossEnergy,
              };
            } else {
              return row;
            }
          });

          setOfPvAlertSignal(setAlertDatas);
        } else {
          setOfPvAlertSignal(tmp);
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error('load object pv alert signal data error', err);
          message.error(t('object.info.getPvAlertSignalDataError'));
        }
      })
      .then(() => {
        setCidLoading(false);
      });
  }, [user, location.pathname, dataOfPvStringSort, t]);

  const loadPvStringData = useCallback(() => {
    const objectId = GetObjectID(location.pathname);
    if (!objectId) return;

    setPvStringLoading(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery($objectID: String!) { 
              om_getObjectPvString(objectID: $objectID) {
                objectID
                roofNO stringModuleCount
                dcBox1InNO dcBox1EqNO dcBox1OutNO
                dcBox2InNO dcBox2EqNO dcBox2OutNO
                invInNO invEqNO invOutNO
                acBoxInNO acBoxEqNO acBoxOutNO
                mpInNO mpEqNO mpOutNO
                pmInNO pmEqNO pmOutNO
                clientDeviceID
              }
            }
           `
          ),
          variables: {
            objectID: objectId,
          },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('object.info.failedToGetPvStringData', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        const tmp = res.data.data.om_getObjectPvString;
        const sortResult = tmp.sort((a, b) => {
          if (a.dcBox1EqNO < b.dcBox1EqNO) {
            return -1;
          } else if (a.dcBox1EqNO > b.dcBox1EqNO) {
            return 1;
          }

          if (a.dcBox1InNO < b.dcBox1InNO) {
            return -1;
          } else if (a.dcBox1InNO > b.dcBox1InNO) {
            return 1;
          }

          return 0;
        });

        setOfPvStringSort(sortResult);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error('load object pvstring data error', err);
          message.error(t('object.info.getPvStringDataError'));
        }
      })
      .then(() => {
        setPvStringLoading(false);
      });
  }, [user, location.pathname, t]);

  const loadCreateObject = useCallback(() => {
    let maxInvIn = 0;
    let maxInvEQ = 0;
    const string = dataOfPvStringSort.map((row) => {
      const { roofNO, stringModuleCount, invEqNO, invInNO, clientDeviceID } = row;
      maxInvIn = Math.max(maxInvIn, invInNO);
      maxInvEQ = Math.max(maxInvEQ, invEqNO);

      if (dataOfPvAlertSignal && dataOfPvAlertSignal.length > 0) {
        // 篩選出符合 clientDeviceID 和 row.dcBox1InNO 的資料
        const filterAlterData = dataOfPvAlertSignal.filter((signal) => {
          return signal.alertOwnerID === clientDeviceID && signal.mpptNumber === row.dcBox1OutNO;
        });

        return {
          in: row.dcBox1InNO,
          dcb1: { id: row.dcBox1EqNO, in: row.dcBox1OutNO },
          roof: roofNO,
          count: stringModuleCount,
          abnormal: filterAlterData.length > 0 ? true : false,
          abnormalColor: filterAlterData.length > 0 ? filterAlterData[0].alertRedColor : null,
        };
      } else {
        return {
          in: row.dcBox1InNO,
          dcb1: { id: row.dcBox1EqNO, in: row.dcBox1OutNO },
          roof: roofNO,
          count: stringModuleCount,
        };
      }
    });
    setDataOfString(string);

    if (maxInvIn === 1) {
      setUnitWidth(65 + baseProps.marginRight);
    } else if (maxInvEQ > 10) {
      setUnitWidth(30 + baseProps.marginRight);
    }

    const dbc1 = dataOfPvStringSort.reduce((acc, cur) => {
      if (!acc[cur.dcBox1EqNO]) {
        acc[cur.dcBox1EqNO] = { id: cur.dcBox1EqNO, in: [], out: [], count: 0 };
        if (!acc.order) {
          acc.order = [];
        }
        acc.order.push(cur.dcBox1EqNO);
      }

      if (!acc[cur.dcBox1EqNO].in.find((elm) => elm === cur.dcBox1OutNO)) {
        acc[cur.dcBox1EqNO].in.push(cur.dcBox1OutNO);

        if (dataOfPvAlertSignal && dataOfPvAlertSignal.length > 0) {
          // 篩選出符合 clientDeviceID 和 row.dcBox1InNO 的資料
          const filterAlterData = dataOfPvAlertSignal.filter((signal) => {
            return signal.alertOwnerID === cur.clientDeviceID && signal.mpptNumber === cur.dcBox1OutNO;
          });

          acc[cur.dcBox1EqNO].out.push({
            port: cur.dcBox1OutNO,
            inv: { id: cur.invEqNO, in: cur.invInNO },
            outlier: filterAlterData.length > 0 ? true : false,
            outlierColor: filterAlterData.length > 0 ? filterAlterData[0].alertRedColor : null,
          });
        } else {
          acc[cur.dcBox1EqNO].out.push({
            port: cur.dcBox1OutNO,
            inv: { id: cur.invEqNO, in: cur.invInNO },
          });
        }
      }
      acc[cur.dcBox1EqNO].count += 1;
      return acc;
    }, {});
    setDataOfDcb1(dbc1);

    const inv = dataOfPvStringSort.reduce((acc, cur) => {
      if (!acc[cur.invEqNO]) {
        if (dataOfPvAlertSignal && dataOfPvAlertSignal.length > 0) {
          // 篩選出符合 clientDeviceID 的資料
          const filterAlterData = dataOfPvAlertSignal.filter((signal) => {
            return signal.alertOwnerID === cur.clientDeviceID && signal.mpptNumber === null;
          });

          const validLossEnergyData = filterAlterData
            .filter((signal) => signal.lossEnergy !== null)
            .sort((a, b) => new Date(a.alertStartTime) - new Date(b.alertStartTime));

          const earliestLossEnergy =
            validLossEnergyData.length > 0 ? validLossEnergyData[0].lossEnergy : null;

          acc[cur.invEqNO] = {
            id: cur.invEqNO,
            clientDeviceID: cur.clientDeviceID,
            in: [],
            out: [],
            invAbnormal: filterAlterData.length > 0 ? true : false,
            invAbnormalColor: filterAlterData.length > 0 ? filterAlterData[0].alertRedColor : null,
            lossEnergyByInv: earliestLossEnergy,
          };
        } else {
          acc[cur.invEqNO] = { id: cur.invEqNO, in: [], out: [] };
        }

        if (!acc.order) {
          acc.order = [];
        }
        acc.order.push(cur.invEqNO);
      }

      if (!acc[cur.invEqNO].in.find((elm) => elm.id === cur.invInNO)) {
        if (dataOfPvAlertSignal && dataOfPvAlertSignal.length > 0) {
          // 篩選出符合 clientDeviceID 和 row.dcBox1InNO 的資料
          const filterAlterData = dataOfPvAlertSignal.filter((signal) => {
            return signal.alertOwnerID === cur.clientDeviceID && signal.mpptNumber === cur.dcBox1OutNO;
          });

          acc[cur.invEqNO].in.push({
            id: cur.invInNO,
            clientDeviceID: cur.clientDeviceID,
            outlier: filterAlterData.length > 0 ? true : false,
            outlierColor: filterAlterData.length > 0 ? filterAlterData[0].alertRedColor : null,
            lossEnergyByMppt: filterAlterData.reduce((sum, data) => sum + data.lossEnergy, 0),
          });
        } else {
          acc[cur.invEqNO].in.push({ id: cur.invInNO });
        }

        acc[cur.invEqNO].out.push({ port: 1, acb: { id: cur.acBoxEqNO, in: cur.acBoxInNO } });
      }
      return acc;
    }, {});
    setDataOfInv(inv);

    const acb = dataOfPvStringSort.reduce((acc, cur) => {
      if (!acc[cur.acBoxEqNO]) {
        acc[cur.acBoxEqNO] = {
          id: cur.acBoxEqNO,
          in: [],
          out: [{ port: 1, mp: { id: cur.mpEqNO, in: cur.mpInNO } }],
        };
        if (!acc.order) {
          acc.order = [];
        }
        acc.order.push(cur.acBoxEqNO);
      }

      if (!acc[cur.acBoxEqNO].in.find((elm) => elm === cur.acBoxInNO)) {
        acc[cur.acBoxEqNO].in.push(cur.acBoxInNO);
      }
      return acc;
    }, {});
    setDataOfAcb(acb);

    const mp = dataOfPvStringSort.reduce((acc, cur) => {
      if (!acc[cur.mpEqNO]) {
        acc[cur.mpEqNO] = {
          id: cur.mpEqNO,
          in: [],
          out: [{ port: 1, pm: { id: cur.pmEqNO, in: cur.pmInNO } }],
        };
        if (!acc.order) {
          acc.order = [];
        }
        acc.order.push(cur.mpEqNO);
      }

      if (!acc[cur.mpEqNO].in.find((elm) => elm === cur.mpInNO)) {
        acc[cur.mpEqNO].in.push(cur.mpInNO);
      }
      return acc;
    }, {});
    setDataOfMp(mp);

    const pm = dataOfPvStringSort.reduce((acc, cur) => {
      if (!acc[cur.pmEqNO]) {
        acc[cur.pmEqNO] = { id: cur.pmEqNO, in: [] };
        if (!acc.order) {
          acc.order = [];
        }
        acc.order.push(cur.pmEqNO);
      }

      if (!acc[cur.pmEqNO].in.find((elm) => elm === cur.pmOutNO)) {
        acc[cur.pmEqNO].in.push(cur.pmOutNO);
      }
      return acc;
    }, {});
    setDataOfPm(pm);

    setOfPvString(dataOfPvStringSort);
    setPvStringLoading(false);
  }, [dataOfPvStringSort, dataOfPvAlertSignal]);

  useEffect(() => {
    loadCidFormList();
    if (showPvstringDiagram) {
      loadPvStringData();
    }
  }, [loadCidFormList, showPvstringDiagram, loadPvStringData]);

  useEffect(() => {
    if (dataOfPvStringSort && showPvstringDiagram) {
      loadPvAlertSignal();
    }
  }, [dataOfPvStringSort, showPvstringDiagram, loadPvAlertSignal]);

  useEffect(() => {
    if (dataOfPvStringSort && dataOfPvAlertSignal && showPvstringDiagram) {
      loadCreateObject();
    }
  }, [dataOfPvStringSort, dataOfPvAlertSignal, showPvstringDiagram, loadCreateObject]);

  const handleCidListReload = () => {
    loadCidFormList();
  };

  //
  const columns = useMemo(() => {
    return [
      {
        title: t('object.info.cidNumber'),
        dataIndex: 'cidFormID',
        key: 'cidFormID',
        render: (value) => (
          <Link to={`/cids/${value}`} target="_blank">
            {value}
          </Link>
        ),
      },
      {
        title: t('object.info.occurDate'),
        dataIndex: 'startDate',
        key: 'startDate',
      },
      {
        title: t('object.info.relatedSite'),
        dataIndex: 'objectName',
        key: 'objectName',
        render: (value) => dataOfObject.objectName,
      },
      {
        title: t('object.info.avgDailyPowerLoss'),
        dataIndex: 'avgLossEnergy',
        key: 'avgLossEnergy',
        render: (value, record) => (
          <Row gutter={[4, 0]} align="middle" justify="space-between" style={{ padding: '0px 5px' }}>
            <Col>{record.expired ? <GreenDotDiv /> : <RedDotDiv />}</Col>
            {value && <Col>{bn(value).toFixed(2)}</Col>}
            {!value && <Col> - </Col>}
          </Row>
        ),
      },
      {
        title: t('object.info.cumulativePowerLoss'),
        dataIndex: 'lossEnergy',
        key: 'lossEnergy',
        render: (value, record) => (
          <Row gutter={[4, 0]} align="middle" justify="space-between" style={{ padding: '0px 5px' }}>
            <Col>{record.expired ? <GreenDotDiv /> : <RedDotDiv />}</Col>
            {value && <Col>{bn(value).toFixed(2)}</Col>}
            {!value && <Col> - </Col>}
          </Row>
        ),
      },
      {
        title: t('object.info.days'),
        dataIndex: 'days',
        key: 'days',
      },
      {
        title: t('object.info.issue'),
        dataIndex: 'issue',
        key: 'issue',
      },
    ];
  }, [dataOfObject.objectName, t]);

  //
  const createString = (props) => (
    <div key={`STR_${props.dcb1.id}_${props.in}`} className="app-archer-item">
      <StringText {...props}>{props.roof}</StringText>
      <StringText {...props}>{props.count}</StringText>
      <ArcherElement
        id={`STR_${props.dcb1.id}_${props.in}`}
        relations={[
          {
            targetId: `DCB1_${props.dcb1.id}_IN_${props.dcb1.in}`,
            targetAnchor: 'top',
            sourceAnchor: 'bottom',
            className: props.abnormal ? (props.abnormalColor ? 'alert' : 'yellow') : '',
            style: {
              strokeColor: props.abnormal
                ? props.abnormalColor
                  ? alertColor
                  : alertYellowColor
                : energyLineColor,
              lineStyle: 'curve',
            },
          },
        ]}
      >
        <String
          className={`app-archer-box STR ${
            props.abnormal ? (props.abnormalColor ? 'alert' : 'alert-yellow') : ''
          }`}
          {...props}
          background-color={props.abnormal ? (props.abnormalColor ? alertColor : alertYellowColor) : bgColor}
        >{`STR_${props.dcb1.id}_${props.in}`}</String>
      </ArcherElement>
    </div>
  );

  const createDCB1 = (props) => (
    <div key={`DCB1_${props.id}`}>
      <Element {...props} className="app-archer-box">
        <Container>
          {props.in.map((id, i) => (
            <ArcherElement key={i} id={`DCB1_${props.id}_IN_${id}`}>
              <Port>{/*`DCB1_${props.id}_IN_${id}`*/}</Port>
            </ArcherElement>
          ))}
        </Container>
        <Label>{`DCB1_${props.id}`}</Label>
        <Container>
          {props.out.map((item, i) => (
            <ArcherElement
              key={i}
              id={`DCB1_${props.id}_OUT_${item.port}`}
              relations={[
                {
                  targetId: `INV_TF_${item.inv.id}_IN_${item.inv.in}`,
                  targetAnchor: 'top',
                  sourceAnchor: 'bottom',
                  className: item.outlier ? (props.outlierColor ? 'alert' : 'yellow') : '',
                  style: {
                    strokeColor: item.outlier
                      ? props.outlierColor
                        ? alertColor
                        : alertYellowColor
                      : energyLineColor,
                    lineStyle: 'curve',
                  },
                },
              ]}
            >
              <Port>{/*`DCB1_${props.id}_OUT_${item.port}`*/}</Port>
            </ArcherElement>
          ))}
        </Container>
      </Element>
    </div>
  );

  const createInvInTF = (props) => (
    <div key={`INV_TF_${props.id}`}>
      <TF {...props} className="app-archer-box TF">
        <Container>
          {props.in.map((item, i) => (
            <TFBlock key={i}>
              <ArcherElement
                id={`INV_TF_${props.id}_IN_${item.id}`}
                relations={[
                  {
                    targetId: `INV_${props.id}_IN_${item.id}`,
                    targetAnchor: 'top',
                    sourceAnchor: 'bottom',
                    className: item.outlier ? (props.outlierColor ? 'alert' : 'yellow') : '',
                    style: {
                      strokeColor: item.outlier
                        ? props.outlierColor
                          ? alertColor
                          : alertYellowColor
                        : energyLineColor,
                    },
                  },
                ]}
              >
                <div />
              </ArcherElement>
              <TFValue>
                {item.lossEnergyByMppt && item.lossEnergyByMppt > 0
                  ? item.lossEnergyByMppt.toFixed(2) + ' kWh'
                  : ''}
              </TFValue>
            </TFBlock>
          ))}
        </Container>
      </TF>
    </div>
  );

  const createINV = (props) => (
    <div key={`INV_${props.id}`}>
      <Inv {...props} className="app-archer-box">
        <Container>
          {props.in.map((item, i) => (
            <Port key={i}>
              <svg
                height="16"
                width="16"
                className={item.outlier ? 'alert' : ''}
                onClick={() =>
                  handleCircleClick({
                    type: 'mppt',
                    invId: props.id,
                    clientDeviceID: props.clientDeviceID,
                    mpptId: item.id,
                  })
                }
              >
                <ArcherElement
                  id={`INV_${props.id}_IN_${item.id}`}
                  relations={[
                    {
                      targetId: `INV_${props.id}_MIDDLE`,
                      targetAnchor: 'top',
                      sourceAnchor: 'bottom',
                      className: props.invAbnormal ? 'alert-no-color' : '',
                      style: {
                        strokeColor: energyLineColor,
                      },
                    },
                  ]}
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="4"
                    stroke={
                      item.outlier ? (props.outlierColor ? alertColor : alertYellowColor) : 'rgba(0,0,0,0.8)'
                    }
                    className={item.outlier ? (props.outlierColor ? 'alert' : 'yellow') : ''}
                    strokeWidth="2"
                    fill="none"
                  />
                </ArcherElement>
              </svg>
            </Port>
          ))}
        </Container>
        <VContainer>
          <Container>
            <TFBlock>
              <TFValue style={{ paddingTop: '8px' }}>
                {props.lossEnergyByInv && props.lossEnergyByInv > 0
                  ? props.lossEnergyByInv.toFixed(2) + 'kWh'
                  : ''}
              </TFValue>
            </TFBlock>
            <Label></Label>
          </Container>
          <Container>
            <Port>
              <svg
                height="16"
                width="16"
                className={props.invAbnormal ? 'alert' : ''}
                onClick={() =>
                  handleCircleClick({
                    type: 'inv',
                    invId: props.id,
                    clientDeviceID: props.clientDeviceID,
                    mpptId: null,
                  })
                }
              >
                <ArcherElement
                  id={`INV_${props.id}_MIDDLE`}
                  relations={[
                    {
                      targetId: `INV_${props.id}_OUT`,
                      targetAnchor: 'top',
                      sourceAnchor: 'bottom',
                      className: props.invAbnormal ? 'alert-no-color' : '',
                      style: {
                        strokeColor: energyLineColor,
                      },
                    },
                  ]}
                >
                  <circle cx="8" cy="8" r="4" stroke="rgba(0,0,0,0.8)" strokeWidth="2" fill="none" />
                </ArcherElement>
              </svg>
            </Port>
            <Label
              style={{
                backgroundColor: props.invAbnormal
                  ? props.invAbnormalColor
                    ? alertColor
                    : alertYellowColor
                  : 'transparent',
                color: props.invAbnormal ? (props.invAbnormalColor ? '#fff' : '#000') : '',
              }}
              className={props.invAbnormal ? 'alert-lable' : ''}
            >{`INV_${props.id}`}</Label>
          </Container>
        </VContainer>
        <VContainer>
          <Container>
            {props.in.map((_, i) =>
              i === 0 ? (
                <Port key={i}>
                  <svg height="16" width="16">
                    <ArcherElement
                      id={`INV_${props.id}_OUT`}
                      relations={[
                        {
                          targetId: `ACB_${props.out[i].acb.id}_IN_${props.out[i].acb.in}`,
                          targetAnchor: 'top',
                          sourceAnchor: 'bottom',
                          className: props.invAbnormal ? 'alert-no-color' : '',
                          style: {
                            strokeColor: energyLineColor,
                            lineStyle: 'curve',
                          },
                        },
                      ]}
                    >
                      <circle cx="8" cy="8" r="4" stroke="rgba(0,0,0,0.8)" strokeWidth="2" fill="none" />
                    </ArcherElement>
                  </svg>
                </Port>
              ) : (
                <Port key={i}>{/*`INV_${props.id}_OUT_${item.port}`*/}</Port>
              )
            )}
            {props.in.length === 1 ? <Port /> : null}
          </Container>
        </VContainer>
      </Inv>
    </div>
  );

  const createACB = (props) => (
    <div key={`ACB_${props.id}`}>
      <Element {...props} className="app-archer-box">
        <Container>
          {props.in.map((id, i) => (
            <ArcherElement key={i} id={`ACB_${props.id}_IN_${id}`}>
              <Port>{/*`ACB_${props.id}_IN_${id}`*/}</Port>
            </ArcherElement>
          ))}
        </Container>
        <Label>{`ACB_${props.id}`}</Label>
        <Container>
          {props.out.map((item, i) => (
            <ArcherElement
              key={i}
              id={`ACB_${props.id}_OUT_${item.port}`}
              relations={[
                {
                  targetId: `MP_${item.mp.id}_IN_${item.mp.in}`,
                  targetAnchor: 'top',
                  sourceAnchor: 'bottom',
                  className: props.abnormal ? 'alert' : '',
                  style: {
                    strokeColor: props.abnormal ? alertColor : energyLineColor,
                    lineStyle: 'curve',
                  },
                },
              ]}
            >
              <Port>{/*`ACB_${props.id}_OUT_${item.port}`*/}</Port>
            </ArcherElement>
          ))}
        </Container>
      </Element>
    </div>
  );

  const createMP = (props) => (
    <div key={`MP_${props.id}`}>
      <Element {...props} className="app-archer-box">
        <Container>
          {props.in.map((id, i) => (
            <ArcherElement key={i} id={`MP_${props.id}_IN_${id}`}>
              <Port>{/*`MP_${props.id}_IN_${id}`*/}</Port>
            </ArcherElement>
          ))}
        </Container>
        <Label>{`MP_${props.id}`}</Label>
        <Container>
          {props.out.map((item, i) => (
            <ArcherElement
              key={i}
              id={`MP_${props.id}_OUT_${item.port}`}
              relations={[
                {
                  targetId: `PM_${item.pm.id}_IN_${item.pm.in}`,
                  targetAnchor: 'top',
                  sourceAnchor: 'bottom',
                  className: props.abnormal ? 'alert' : '',
                  style: {
                    strokeColor: props.abnormal ? alertColor : energyLineColor,
                    lineStyle: 'curve',
                  },
                },
              ]}
            >
              <Port>{/*MP_${props.id}_OUT_${item.port}`*/}</Port>
            </ArcherElement>
          ))}
        </Container>
      </Element>
    </div>
  );

  const createPMTF = (props) => (
    <div key={`PMTF_${props.id}`}>
      <TF {...props}>
        <Container>
          {props.in.map((id, i) => (
            <TFBlock key={i}>
              {/* <TFValue>{props.abnormal ? percentFormat(props.advance.toFixed(4)) : ''}</TFValue> */}
            </TFBlock>
          ))}
        </Container>
      </TF>
    </div>
  );

  const createPM = (props) => (
    <div key={`PM_${props.id}`}>
      <Element {...props} className="app-archer-box PM">
        <Container>
          {props.in.map((id, i) => (
            <ArcherElement key={i} id={`PM_${props.id}_IN_${id}`}>
              <Port>{/*`PM_${props.id}_IN_${id}`*/}</Port>
            </ArcherElement>
          ))}
        </Container>
        <Label>{`PM_${props.id}`}</Label>
        <Port>
          <svg height="16" width="16">
            <circle
              cx="8"
              cy="8"
              r="4"
              stroke={props.outlier ? alertColor : 'rgba(0,0,0,0.8)'}
              className={props.outlier ? 'alert' : ''}
              strokeWidth="2"
              fill="none"
            />
          </svg>
        </Port>
      </Element>
    </div>
  );

  const handleCircleClick = (data) => {
    const objectId = GetObjectID(location.pathname);
    if (!objectId) return;

    const filterAlterData = dataOfPvAlertSignal.filter((signal) => {
      return (
        signal.alertOwnerID === data.clientDeviceID &&
        signal.alertComponent === data.type &&
        signal.mpptNumber === (data.type === 'mppt' ? data.mpptId : null)
      );
    });

    if (filterAlterData && filterAlterData.length > 0) {
      let urlParams = {
        objectID: objectId,
        invID: data.invId,
        alertIDs: [...new Set(filterAlterData.map((signal) => signal.alertID))],
      };

      window.open(`/alerts?${new URLSearchParams(urlParams).toString()}`, '_blank');
    }
  };

  return (
    <div className="app-page-content">
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <Spin spinning={basicLoading}>
            <Card>
              <Descriptions size="small" column={{ xxl: 4, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}>
                <Descriptions.Item label={t('object.info.projectNumber')}>
                  <BlueSpan>{dataOfObject.projectID}</BlueSpan>
                </Descriptions.Item>
                <Descriptions.Item label={t('object.info.spv')}>
                  <BlueSpan>{dataOfObject.spvName}</BlueSpan>
                </Descriptions.Item>
                <Descriptions.Item label={t('object.info.siteLocation')}>
                  <BlueSpan>{dataOfObject.region}</BlueSpan>
                </Descriptions.Item>
                <Descriptions.Item label={t('object.info.firstYearGuaranteedPower')}>
                  <BlueSpan>{dataOfObject.annualPower}</BlueSpan>
                </Descriptions.Item>
                <Descriptions.Item label={t('object.info.electricianLicensePeriod')}>
                  <BlueSpan>{dataOfObject.electricianLicenseSemester}</BlueSpan>
                </Descriptions.Item>
                <Descriptions.Item label={t('object.info.preparatoryPermitPeriod')}>
                  <BlueSpan>{dataOfObject.preparatoryPermitSemester}</BlueSpan>
                </Descriptions.Item>
                <Descriptions.Item label={t('object.info.constructionPermitPeriod')}>
                  <BlueSpan>{dataOfObject.constructionPermitSemester}</BlueSpan>
                </Descriptions.Item>
                <Descriptions.Item label={t('object.info.powerDecayRate')}>
                  <BlueSpan>
                    {bn(dataOfObject.decayRate || 0)
                      .times(100)
                      .toFixed(2)}
                    %
                  </BlueSpan>
                </Descriptions.Item>
                <Descriptions.Item label={t('object.info.meterStartDate')}>
                  <BlueSpan>{dataOfObject.meterBeginDate}</BlueSpan>
                </Descriptions.Item>
                <Descriptions.Item label={t('object.info.guaranteeStartDate')}>
                  <BlueSpan>{dataOfObject.guaranteeBeginDate}</BlueSpan>
                </Descriptions.Item>
                <Descriptions.Item label={t('object.info.siteCapacity')}>
                  <BlueSpan>{NumberFormat(dataOfObject.capacity)}</BlueSpan>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Spin>
        </Col>
        <Col span={24}>
          {showGenerationData && (
            <Spin spinning={basicLoading}>
              <Card>
                <Row gutter={[8, 16]}>
                  <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <p>
                      {t('object.info.annualMonitoringAccumulatedPower')}
                      <div className="app-hint">
                        <Tooltip
                          title={
                            <div>
                              {t('object.info.statisticsDateRange')}：
                              {dataOfObject.qryGuaranteeStartDate + ' ' + t('object.info.to') + ' ' + dataOfObject.currentEndDate}
                            </div>
                          }
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                    </p>
                    <GenerateEnergyText>{NumberFormat(dataOfObject.totalEnergyThisYear)}</GenerateEnergyText>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <p>
                      {t('object.info.annualGuaranteedPowerGeneration')}
                      <div className="app-hint">
                        <Tooltip
                          title={
                            <div>
                              {t('object.info.statisticsDateRange')}：
                              {dataOfObject.qryGuaranteeStartDate + ' ' + t('object.info.to') + ' ' + dataOfObject.qryGuaranteeEndDate}
                            </div>
                          }
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                    </p>
                    <GenerateEnergyText>{NumberFormat(dataOfObject.annualPowerThisYear)}</GenerateEnergyText>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <p>
                      {t('object.info.guaranteedPowerAchievementRate')}
                      <div className="app-hint">
                        <Tooltip
                          title={
                            <div>
                              {t('object.info.statisticsDateRange')}：
                              {dataOfObject.qryGuaranteeStartDate + ' ' + t('object.info.to') + ' ' + dataOfObject.qryGuaranteeEndDate}
                            </div>
                          }
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                    </p>

                    <GenerateEnergyText>
                      {dataOfObject.guaranteeAchieve
                        ? (dataOfObject.guaranteeAchieve * 100).toFixed(2)
                        : NumberFormat(dataOfObject.guaranteeAchieve)}
                    </GenerateEnergyText>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <p>
                      {t('object.info.gridConnectedAccumulatedPower')}
                      <div className="app-hint">
                        <Tooltip
                          title={
                            <div>
                              {t('object.info.statisticsDateRange')}：
                              {dataOfObject.meterBeginDate + ' ' + t('object.info.to') + ' ' + dataOfObject.currentEndDate}
                            </div>
                          }
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                    </p>
                    <GenerateEnergyText>{NumberFormat(dataOfObject.totalEnergy)}</GenerateEnergyText>
                  </Col>
                </Row>
              </Card>
            </Spin>
          )}
        </Col>
        {showCidContent && (
          <Col span={24}>
            <Card
              title={
                <>
                  {t('object.info.pendingCidList')} <Badge count={recordOfObjectCid.length} className="app-badge-lg" />
                </>
              }
              extra={<Button type="text" icon={<ReloadOutlined />} onClick={handleCidListReload} />}
            >
              <Spin spinning={cidLoading}>
                <Table
                  size="small"
                  pagination={false}
                  columns={columns}
                  dataSource={recordOfObjectCid}
                  scroll={{ x: 1400 }}
                />
              </Spin>
            </Card>
          </Col>
        )}
        {showPvstringDiagram && (
          <Col span={24}>
            <Card title={t('object.info.equipmentHierarchyDiagram')}>
              <Spin spinning={pvStringLoading}>
                <div className="app-archer" style={{ backgroundImage: `url(${pattern})` }}>
                  <ArcherContainer endMarker={false} lineStyle={'angle'}>
                    {dataOfPvString && dataOfPvString.length > 0 ? (
                      <div style={{ padding: '4px 0px' }}>
                        <div>{t('object.info.roofNumberAndStringCount')}</div>
                        <div className="app-archer-row">
                          {dataOfString.map((row) =>
                            createString({ ...row, ...baseProps, width: unitWidth })
                          )}
                        </div>
                        <div className="app-archer-row">
                          {dataOfDcb1.order.map((key) =>
                            createDCB1({
                              ...dataOfDcb1[key],
                              width: dataOfDcb1[key].count * unitWidth,
                              ...baseProps,
                            })
                          )}
                        </div>
                        <div className="app-archer-row">
                          {dataOfInv.order.map((key) =>
                            createInvInTF({
                              ...dataOfInv[key],
                              ...baseProps,
                              width: (dataOfString.length * unitWidth) / dataOfInv.order.length,
                            })
                          )}
                        </div>
                        <div className="app-archer-row">
                          {dataOfInv.order.map((key) =>
                            createINV({
                              ...dataOfInv[key],
                              ...baseProps,
                              width: (dataOfString.length * unitWidth) / dataOfInv.order.length,
                            })
                          )}
                        </div>
                        <div className="app-archer-row">
                          {dataOfAcb.order.map((key) =>
                            createACB({
                              ...dataOfAcb[key],
                              ...baseProps,
                              width: (dataOfString.length * unitWidth) / dataOfAcb.order.length,
                            })
                          )}
                        </div>
                        <div className="app-archer-row">
                          {dataOfMp.order.map((key) =>
                            createMP({
                              ...dataOfMp[key],
                              ...baseProps,
                              width: (dataOfString.length * unitWidth) / dataOfMp.order.length,
                            })
                          )}
                        </div>
                        <div className="app-archer-row">
                          {dataOfPm.order.map((key) =>
                            createPMTF({
                              ...dataOfPm[key],
                              ...baseProps,
                              width: (dataOfString.length * unitWidth) / dataOfPm.order.length,
                            })
                          )}
                        </div>
                        <div className="app-archer-row">
                          {dataOfPm.order.map((key) =>
                            createPM({
                              ...dataOfPm[key],
                              ...baseProps,
                              width: (dataOfString.length * unitWidth) / dataOfPm.order.length,
                            })
                          )}
                        </div>
                      </div>
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                  </ArcherContainer>
                </div>
              </Spin>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Info;
