import appConfigs from '../../configs';
import { JsonStringToFormObject, FormObjectToJsonString } from '../../utils/format';

export const MOMENT_COLUMN_NAMES = ['startDate', 'endDate'];

export const INITIAL_FILTER_DATA = {
  timezone: appConfigs.timezone,
};

export const GetDataOfFilterFromSession = () => {
  const filterDataStringInSession = window.sessionStorage.getItem('om-list-filterData');

  return JsonStringToFormObject(filterDataStringInSession, MOMENT_COLUMN_NAMES);
};

export const SetDefaultDataOfFilterToSession = () => {
  window.sessionStorage.setItem(
    'om-list-filterData',
    FormObjectToJsonString(INITIAL_FILTER_DATA, MOMENT_COLUMN_NAMES)
  );
};

export const SetDataOfFilterToSession = (value) => {
  window.sessionStorage.setItem('om-list-filterData', FormObjectToJsonString(value, MOMENT_COLUMN_NAMES));
};
