import React, { useCallback, useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Button, Spin, PageHeader, Row, Col, Collapse, message, Divider } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
//
import RfdmeInput from '../../components/Input';
import RfdmeDatePicker from '../../components/DatePicker';
import RfdmeSelect from '../../components/Select';
import OmRecordCard from './card';
//
import useListOfCidLevel from '../../hooks/useListOfCidLevel';
import useListOfOmExtraPv from '../../hooks/useListOfOmExtraPv';
import useListOfOmWorkHistoryStatus from '../../hooks/useListOfOmWorkHistoryStatus';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { AppSyncQueryTextFormat, SetKeyToArray } from '../../utils/format';
import { IsValidAppSyncRsps } from '../../utils/valid';
import { GetAppSyncRspsErrorMessage } from '../../utils/parse';
import { QueryOmContractLevelList } from '../../utils/dataHelpers';
import {
  SetDefaultDataOfFilterToSession,
  SetDataOfFilterToSession,
  GetDataOfFilterFromSession,
  GenerateQueryConditions,
  INITIAL_FILTER_DATA,
} from './filterAction';

//
const { Panel } = Collapse;

//主要元件
const OmRecord = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [dataOfFilter, setDataOfFilter] = useState({ ...INITIAL_FILTER_DATA });
  const [currentPage, setCurrentPage] = useState(1);
  const [amountOfRecord, setAmountOfRecord] = useState(0);
  const [recordOfOmForm, setRecordOfOmForm] = useState([]);
  const [loading, setLoading] = useState(false);
  //
  const { data: listOfDefaultCidLevel } = useListOfCidLevel();
  const { data: listOfPv } = useListOfOmExtraPv();
  const { data: listOfOmWorkHistoryStatus } = useListOfOmWorkHistoryStatus();
  const [listOfCidLevel, setListOfCidLevel] = useState([]);

  useEffect(() => {
    SetDefaultDataOfFilterToSession();
    setDataOfFilter(GetDataOfFilterFromSession());
  }, []);

  // 隨案場選擇來變更重要性
  useEffect(() => {
    async function fetchData() {
      let items = [];
      const queryObjectID = !dataOfFilter?.omObject ? [] : [dataOfFilter.omObject];

      const getOmContractLevelList = await QueryOmContractLevelList(user, [], queryObjectID);

      if (queryObjectID.length > 0) {
        if (getOmContractLevelList.length > 0) {
          items = [...getOmContractLevelList.sort((a, b) => a.key - b.key)];
        } else {
          items = [...listOfDefaultCidLevel];
        }
      } else {
        items = [...getOmContractLevelList.sort((a, b) => a.key - b.key)];
        listOfDefaultCidLevel.forEach((defaultItem) => {
          const exists = getOmContractLevelList.some((item) => item.label === defaultItem.label);

          if (!exists) {
            // 如果不存在，則將 defaultItem 加入到 getOmContractLevelList
            items.push(defaultItem);
          }
        });
      }

      items = items.map((m) => ({
        label: m.label,
        value: m.label,
      }));

      setListOfCidLevel(items.map(SetKeyToArray));
      setDataOfFilter((currentData) => {
        return { ...currentData, cidLevelNames: [] };
      });
    }
    fetchData();
  }, [user, listOfDefaultCidLevel, dataOfFilter.omObject]);

  const loadData = useCallback(() => {
    const queryConditions = GenerateQueryConditions(currentPage);
    setLoading(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `
            query OmQuery (
              $omFormID: String,
              $omDateType: Int,
              $omStartTime: String,
              $omEndTime: String,
              $objectIDs: [String],
              $cidLevelNames: [String],
              $cidDateType: Int,
              $cidStartDate: String,
              $cidEndDate: String,
              $omWorkHistoryStatus: Boolean,
              $timezone: String!,
              $page: Int!,
              $pageSize: Int!
            ) {
              om_getOmFormList (
                omFormID: $omFormID,
                omDateType: $omDateType,
                omStartTime: $omStartTime,
                omEndTime: $omEndTime,
                objectIDs: $objectIDs,
                cidLevelNames: $cidLevelNames,
                cidDateType: $cidDateType,
                cidStartDate: $cidStartDate,
                cidEndDate: $cidEndDate,
                omWorkHistoryStatus: $omWorkHistoryStatus,
                timezone: $timezone,
                page: $page,
                pageSize: $pageSize
              ) {
                total
                list {
                  omObjectName
                  omStartTime
                  omFormID
                  omWorkContent
                  omStatus
                  omStatusName
                  omProcessingItem
                  omRecommend
                }
              }
            }            
            `
          ),
          variables: queryConditions,
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('omRecord.failedToQueryList', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        const resData = res.data.data.om_getOmFormList;
        setAmountOfRecord(resData.total || 0);
        setRecordOfOmForm((prev) =>
          currentPage === 1 ? resData.list.map(SetKeyToArray) : [...prev, ...resData.list].map(SetKeyToArray)
        );
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omRecord.queryListError'));
          console.error('load om form list error: ', err);
        }
      })
      .then(() => {
        setLoading(false);
      });

    //
  }, [user, currentPage, t]);
  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleCurrentPageIncrease = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleQuery = () => {
    setAmountOfRecord(0);
    setRecordOfOmForm([]);
    if (currentPage === 1) {
      loadData();
    } else {
      setCurrentPage(1);
    }
  };

  const handleQueryReset = () => {
    setDataOfFilter({ ...INITIAL_FILTER_DATA });
    SetDataOfFilterToSession({ ...INITIAL_FILTER_DATA });
    handleQuery();
  };

  const handleDataOfFilterChange = (name) => (e) => {
    const tmp = { ...dataOfFilter };
    tmp[name] = e && e.target ? e.target.value : e;
    setDataOfFilter(tmp);
    SetDataOfFilterToSession(tmp);
  };

  return (
    <>
      {/* page header */}
      <PageHeader className="app-page-header" title={t('omRecord.title')} />

      {/* 搜尋面板 */}
      <div className="app-page-searchpanel">
        <Collapse defaultActiveKey={['1']}>
          <Panel header={t('omRecord.filterConditions')} key="1">
            <Row gutter={[8, 8]}>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeInput
                  placeholder={t('omRecord.omNumber')}
                  value={dataOfFilter.omFormID}
                  onChange={handleDataOfFilterChange('omFormID')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  placeholder={t('omRecord.importance')}
                  options={listOfCidLevel}
                  value={dataOfFilter.cidLevelNames}
                  onChange={handleDataOfFilterChange('cidLevelNames')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  showSearch
                  placeholder={t('omRecord.site')}
                  options={listOfPv}
                  value={dataOfFilter.omObject}
                  onChange={handleDataOfFilterChange('omObject')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeDatePicker
                  placeholder={t('omRecord.createDate')}
                  value={dataOfFilter.cidStartDate}
                  onChange={handleDataOfFilterChange('cidStartDate')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeDatePicker
                  placeholder={t('omRecord.dispatchDate')}
                  value={dataOfFilter.omStartDate}
                  onChange={handleDataOfFilterChange('omStartDate')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  placeholder={t('omRecord.workHistoryStatus')}
                  options={listOfOmWorkHistoryStatus}
                  value={dataOfFilter.omWorkHistoryStatus}
                  onChange={handleDataOfFilterChange('omWorkHistoryStatus')}
                />
              </Col>
              <Col xs={12} sm={12} md={6} lg={{ span: 3, offset: 6 }} xl={{ span: 3, offset: 6 }}>
                <Button block loading={loading} onClick={handleQueryReset}>
                  {t('omRecord.reset')}
                </Button>
              </Col>
              <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                <Button type="primary" block loading={loading} onClick={handleQuery}>
                  {t('omRecord.search')}
                </Button>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </div>

      {/* 列表 */}
      <div className="app-page-content">
        <InfiniteScroll
          style={{ overflow: 'visible' }}
          dataLength={recordOfOmForm.length}
          next={handleCurrentPageIncrease}
          hasMore={amountOfRecord > recordOfOmForm.length}
          loader={
            <div style={{ textAlign: 'center' }}>
              <Spin
                spinning={loading}
                tip={t('omRecord.searching')}
                style={{ minHeight: 100, paddingTop: '10px' }}
              />
            </div>
          }
          endMessage={<Divider plain>{t('omRecord.noMoreFiles')}</Divider>}
          scrollableTarget="scrollableDiv"
        >
          <Row gutter={[16, 16]}>
            {recordOfOmForm.map((item, itemIdx) => (
              <OmRecordCard data={item} key={itemIdx} />
            ))}
          </Row>
        </InfiniteScroll>
      </div>
    </>
  );
};

export default OmRecord;
