import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import appConfigs from '../configs';
import AuthContext from '../contexts/authProvider';

const useListOfCidRootCause = (enabled = true) => {
  const { user } = useContext(AuthContext);

  return useQuery({
    queryKey: ['query-cid-rootCause-list'],
    queryFn: () => {
      return axios
        .post(
          appConfigs.appSyncURL,
          {
            query: `query OmQuery { om_getCidRootCauseList { key value seq level parent } }`,
            variables: null,
          },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (res.status === 200 && res.data.data) {
            return res.data.data.om_getCidRootCauseList.map((m) => ({
              label: m.value,
              value: m.key,
              seq: m.seq,
              level: m.level,
              parent: m.parent,
            }));
          }
          return [];
        });
    },
    staleTime: 1000 * 60 * 60 * 1, // 1 hours
    cacheTime: 1000 * 60 * 60 * 2, // 2 hours
    enabled: enabled,
  });
};

export default useListOfCidRootCause;
