import { CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Collapse,
  Descriptions,
  PageHeader,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  Tooltip,
  message,
} from 'antd';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import RfdmeDatePicker from '../../components/DatePicker';
import RfdmeDisplay from '../../components/Display';
import RfdmeModal from '../../components/Modal';
import OmWorkEditor from '../../components/OmWorkEditor';
import RfdmeSelect from '../../components/Select';
import RfdmeTextArea from '../../components/TextArea';
import RfdmeUpload from '../../components/Upload';
import RfdmeInputNumber from '../../components/InputNumber';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import useListOfOmAction from '../../hooks/useListOfOmAction';
import useListOfOmExecutionResult from '../../hooks/useListOfOmExecutionResult';
import useListOfOmExpectPvStatus from '../../hooks/useListOfOmExpectPvStatus';
import useListOfOmExtraPv from '../../hooks/useListOfOmExtraPv';
import useListOfOmObject from '../../hooks/useListOfOmObject';
import useListOfOmSource from '../../hooks/useListOfOmSource';
import useListOfOmTraffic from '../../hooks/useListOfOmTraffic';
import useListOfOmUser from '../../hooks/useListOfOmUser';
import useListOfOrganize from '../../hooks/useListOfOrganize';
import useConfigOfSystem from '../../hooks/useConfigOfSystem';
import { OmStatusConstant } from '../../utils/constant';
import { AppSyncQueryTextFormat, DateTimeFormat, SetKeyToArray } from '../../utils/format';
import { GetAppSyncRspsErrorMessage } from '../../utils/parse';
import {
  IsValidApiGatewayRsps,
  IsValidAppSyncRsps,
  ValidFormData,
  IsNullOrUndefined,
} from '../../utils/valid';
//
import { useTranslation } from 'react-i18next';

const HintLabel = styled.div`
  font-size: 12px;
  color: #ccc;
`;
const Label = styled.div`
  width: 100%;
  margin-top: 5px;
  margin-bottom: 3px;
  color: #353d45;
`;
//
const { Panel } = Collapse;
const GetOmFormID = (urlPath) => {
  return (urlPath || '').replace('/oms/', '');
};

const OmView = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  // get data from state
  const isNew = location.state?.isNew;
  const cidFormId = location.state?.cidFormId;
  const originOmFormId = location.state?.originOmFormId;
  const redirectUrl = location.state?.redirectUrl;
  //
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [redirectFlag, setRedirectFlag] = useState(false);
  // 'data-' variable: non-array type data that can be modified.
  const [dataOfOm, setDataOfOm] = useState({});
  const [dataOfOmOrigin, setDataOfOmOrigin] = useState({});
  const [dataOfCid, setDataOfCid] = useState({});
  const [dataOfOriginOm, setDataOfOriginOm] = useState({});
  // 'record-' variable: array type data that can be modified.
  const [recordOfOmWork, setRecordOfOmWork] = useState([]);
  //
  const { data: listOfOmSource } = useListOfOmSource();
  const { data: listOfOmExtraPv } = useListOfOmExtraPv();
  const { data: listOfOmUser } = useListOfOmUser();
  const { data: listOfOmExpectPvStatus } = useListOfOmExpectPvStatus();
  const { data: listOfOmExecutionResult } = useListOfOmExecutionResult();
  const { data: listOfOmAction } = useListOfOmAction();
  const { data: listOfOmTraffic } = useListOfOmTraffic();
  const { data: listOfOmObject } = useListOfOmObject();
  const { data: listOfOrganize } = useListOfOrganize();
  const { data: systemConfigs } = useConfigOfSystem();
  // variables for control view.
  const [omWorkModalProps, setOmWorkModalProps] = useState({ open: false, data: null });
  const [omCloseModalOpen, setOmCloseModalOpen] = useState(false);
  const [omExecutionResult, setOmExecutionResult] = useState({ value: null, msg: null });
  const [loading, setLoading] = useState(false);
  const [buttonWorking, setButtonWorking] = useState(false);
  //
  const [dataChangedOfOm, setDataChangedOfOm] = useState(false);
  const [dataChangedOfOmWork, setDataChangedOfOmWork] = useState(false);
  const [reloadOmWorkFlag, setReloadOmWorkFlag] = useState(false);
  //
  const [dataOfOmWorkFileList, setDataOfOmWorkFileList] = useState([]);
  //
  const { t } = useTranslation();

  // column of component table
  const ColumnsOfComponentTable = [
    {
      title: t('omView.componentName'),
      dataIndex: 'componentName',
      key: 'componentName',
    },
    {
      title: t('omView.amount'),
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: t('omView.unit'),
      dataIndex: 'unit',
      key: 'unit',
      align: 'center',
      width: 60,
      render: () => 'pcs',
    },
  ];
  const ColumnsOfCidLogTable = (cidAlertComponent, cidClientDeviceSeq) => {
    return [
      {
        title: t('omView.requestor'),
        dataIndex: 'askUserName',
        key: 'askUserName',
      },
      {
        title: (
          <>
            <span>{t('omView.requestContent')}</span>
            {(cidAlertComponent || cidClientDeviceSeq) && (
              <span style={{ marginLeft: '5px' }}>
                <b>
                  ({t('omView.equipmentLevel')}
                  {cidAlertComponent}；
                </b>
                <b>
                  {t('omView.invNumber')}#{cidClientDeviceSeq})
                </b>
              </span>
            )}
          </>
        ),
        dataIndex: 'askReason',
        key: 'askReason',
      },
      {
        title: t('omView.requestTime'),
        dataIndex: 'askTime',
        key: 'askTime',
      },
      {
        title: t('omView.acceptTime'),
        dataIndex: 'takeTime',
        key: 'takeTime',
      },
      {
        title: t('omView.withdrawTime'),
        dataIndex: 'withdrawTime',
        key: 'withdrawTime',
      },
      {
        title: t('omView.rejectTime'),
        dataIndex: 'rejectTime',
        key: 'rejectTime',
      },
      {
        title: t('omView.rejectReason'),
        dataIndex: 'rejectReason',
        key: 'rejectReason',
      },
    ];
  };

  // 表單標題
  const formTitle = useMemo(() => {
    let text = t('omView.viewOmForm');
    if (!dataOfOm.omFormID && originOmFormId) {
      text = t('omView.followUpOmForm', { id: originOmFormId });
    } else if (isNew) {
      text = t('omView.addOmForm');
    } else if (isEditing) {
      text = t('omView.editOmForm');
    }

    return text;
  }, [isNew, isEditing, dataOfOm.omFormID, originOmFormId, t]);
  const formProps = useMemo(() => {
    const result = {
      canEdit: false,
      showCreateButton: false,
      showSaveButton: false,
      showCloseButton: false,
      showVoidButton: false,
      showOmWorkActionButton: false,
    };
    const hasPermissionToEdit = user.operations.includes('OMVIEW_show-om-editor-button');
    const hasPermissionToOperateOmWork = user.operations.includes('OMS_save-om-work-form');

    if (hasPermissionToEdit && !dataOfOm.omStatus) {
      result.canEdit = true;
      result.showCreateButton = true;
      return result;
    }

    if (
      hasPermissionToEdit &&
      [OmStatusConstant.PROCESSING, OmStatusConstant.ISSUE_OBSERVE].includes(dataOfOm.omStatus)
    ) {
      result.canEdit = true;
      result.showSaveButton = true;
      result.showCloseButton = true;
      result.showVoidButton = true;
      result.showOmWorkActionButton = hasPermissionToOperateOmWork;
      return result;
    }

    if (
      hasPermissionToEdit &&
      [OmStatusConstant.REQUEST_PREPARATION, OmStatusConstant.PLANNED].includes(dataOfOm.omStatus)
    ) {
      result.canEdit = true;
      result.showSaveButton = true;
      result.showVoidButton = true;
      result.showOmWorkActionButton = hasPermissionToOperateOmWork;
      return result;
    }
    return result;
  }, [user, dataOfOm]);
  // 目前表單的 cidFormID
  const currentCidFormID = useMemo(() => {
    return dataOfOm.cidFormID || cidFormId || null;
  }, [cidFormId, dataOfOm.cidFormID]);
  // 考慮CID表單限制的相關案場列表
  const pvList = useMemo(() => {
    if (!Array.isArray(listOfOmExtraPv)) return [];
    if (!Array.isArray(dataOfCid.objectIDs)) return listOfOmExtraPv.filter((f) => f.state === 'Y');
    return listOfOmExtraPv.filter((f) => dataOfCid.objectIDs.includes(f.value));
  }, [listOfOmExtraPv, dataOfCid.objectIDs]);
  // 相關案場的地區資訊
  const pvRegion = useMemo(() => {
    if (!dataOfOm.object) return '--';
    if (!Array.isArray(listOfOmExtraPv)) return '--';
    const item = listOfOmExtraPv.find((f) => f.value === dataOfOm.object);
    return item ? item.region || '--' : '--';
  }, [listOfOmExtraPv, dataOfOm.object]);
  // 相關案場的案場擁有者資訊
  const pvOwner = useMemo(() => {
    if (!dataOfOm.object) return '--';
    if (!Array.isArray(listOfOmExtraPv)) return '--';
    const item = listOfOmExtraPv.find((f) => f.value === dataOfOm.object);
    return item ? item.ownerName || '--' : '--';
  }, [listOfOmExtraPv, dataOfOm.object]);
  // O&M主要處理人員列表
  const listOfOmUserMain = useMemo(() => {
    if (Array.isArray(listOfOmUser)) {
      return listOfOmUser.filter(
        (s) =>
          ((dataOfOm.userInChargeIDs || []).includes(s.value) || !!s.status) &&
          !(dataOfOm.userCooperateIDs || []).includes(s.value)
      );
    }
    return [];
  }, [listOfOmUser, dataOfOm.userInChargeIDs, dataOfOm.userCooperateIDs]);
  // O&M協同處理人員列表
  const listOfOmUserOther = useMemo(() => {
    if (Array.isArray(listOfOmUser)) {
      return listOfOmUser.filter(
        (s) =>
          ((dataOfOm.userCooperateIDs || []).includes(s.value) || !!s.status) &&
          !(dataOfOm.userInChargeIDs || []).includes(s.value)
      );
    }
    return [];
  }, [listOfOmUser, dataOfOm.userInChargeIDs, dataOfOm.userCooperateIDs]);
  // 來源項目列表
  const listOfOmSourceFilter = useMemo(() => {
    if (!Array.isArray(listOfOmSource)) return [];

    return listOfOmSource.filter((s) => dataOfOm.omSource === s.value || s.enable === true);
  }, [listOfOmSource, dataOfOm.omSource]);
  // 對象列表
  const listOfOmObjectFilter = useMemo(() => {
    if (!Array.isArray(listOfOmObject)) return [];

    return listOfOmObject.filter((s) => dataOfOm.omObjectID === s.value || s.enable === true);
  }, [listOfOmObject, dataOfOm.omObjectID]);
  // 行動列表
  const listOfOmActionFilter = useMemo(() => {
    if (!Array.isArray(listOfOmAction)) return [];

    return listOfOmAction.filter((s) => dataOfOm.omAction === s.value || s.enable === true);
  }, [listOfOmAction, dataOfOm.omAction]);
  // 交通方式列表
  const listOfOmTrafficFilter = useMemo(() => {
    if (!Array.isArray(listOfOmTraffic)) return [];

    return listOfOmTraffic.filter((s) => dataOfOm.omTraffic === s.value || s.enable === true);
  }, [listOfOmTraffic, dataOfOm.omTraffic]);

  // ========== 判斷是否自動進入編輯模式 ==========
  useEffect(() => {
    if (!location || !location.state || !formProps) return;

    if ((location.state?.isNew || location.state?.redirectUrl) && formProps.canEdit) {
      setIsEditing(true);
    }
  }, [location, formProps]);

  // ========== 載入資料 ==========
  const handleQuery = useCallback(() => {
    const omFormID = GetOmFormID(location.pathname);
    if (!user || !omFormID) return;
    if (location.state?.isNew) {
      setDataOfOm({
        startTime: moment().set({ hour: 9, minute: 0 }),
        endTime: moment().set({ hour: 18, minute: 0 }),
      });
      return;
    }

    //查詢OM單
    setLoading(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery($omFormID: String!, $timezone: String!) { 
                om_getOmFormContent(omFormID: $omFormID, timezone: $timezone) {
                  omFormID
                  omSource
                  omSourceName
                  cidFormID
                  omStatus
                  omStatusName
                  object
                  objectName
                  objectRegion
                  ownerName
                  omCrDate
                  omTraffic
                  omTrafficName
                  omExpectPvStatus
                  omExpectPvStatusName
                  omStartTime
                  omEndTime
                  omAction
                  omActionName
                  omExecutionResult
                  omExecutionResultName
                  omUserInCharge
                  omUserInChargeName
                  omUserCooperate
                  omUserCooperateName
                  omProcessingItem
                  omActionPlan
                  omObjectID
                  omObjectIDName
                  omObjectName
                  omCleanCost
                  omCleanUnitCost
                  omCleanModuleAmount
                  omWorkHistory {
                    seq 
                    startTime
                    endTime
                    workHour
                    userID
                    userName
                    workContent
                    recommend
                    logbook
                    fillInUserID
                    fillInUserName
                    ocapList { ocapID ocapName }
                    componentList { componentID componentName amount }
                    fileList { fileID fileName }    
                  }
                }
              }
            `
          ),
          variables: { omFormID: omFormID, timezone: appConfigs.timezone },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('omView.failedToQueryOmForm', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        const resData = res.data.data.om_getOmFormContent;
        //
        resData.startTime = resData.omStartTime ? moment(resData.omStartTime) : null;
        resData.endTime = resData.omEndTime ? moment(resData.omEndTime) : null;
        resData.omWorkHistory =
          Array.isArray(resData.omWorkHistory) && resData.omWorkHistory.length > 0
            ? resData.omWorkHistory.map(SetKeyToArray)
            : [{}];
        resData.userInChargeIDs = Array.isArray(resData.omUserInCharge)
          ? resData.omUserInCharge.filter((f) => f)
          : [];
        resData.omUserInChargeName = Array.isArray(resData.omUserInChargeName)
          ? resData.omUserInChargeName.join(', ')
          : null;
        resData.userCooperateIDs = Array.isArray(resData.omUserCooperate)
          ? resData.omUserCooperate.filter((f) => f)
          : [];
        resData.omUserCooperateName = Array.isArray(resData.omUserCooperateName)
          ? resData.omUserCooperateName.join(', ')
          : null;
        setDataOfOm(resData);
        setDataOfOmOrigin(resData);
        setRecordOfOmWork(resData.omWorkHistory);
        setDataOfOmWorkFileList(resData.omWorkHistory);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omView.queryOmFormError'));
          console.error('load om form error: ', err);
        }
      })
      .then(() => {
        setLoading(false);
      });
  }, [user, location, t]);
  useEffect(() => {
    handleQuery();
  }, [handleQuery]);

  // load extra CID form.
  useEffect(() => {
    if (!currentCidFormID) return;

    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery($cidFormID: String!, $timezone: String!) { 
            om_getCidFormContent(cidFormID: $cidFormID, timezone: $timezone) {
              topic
              problemCategoryName
              currentProgress
              possibleRootCause
              issue
              rootCause
              rootCauseLevel1Name
              rootCauseLevel2Name
              rootCauseLevel3Name
              solution
              solutionLevel1Name
              solutionLevel2Name
              alertComponent
              clientDeviceSeq
              clientDeviceOriginSeq
              logbook
              objectIDs
              levelName
              ocapList {
                ocapID
                ocapName
                ocapRefDate
              }
              askOmLogList {
                askTime
                askReason
                askUserName
                takeTime
                withdrawTime
                rejectTime
                rejectReason
              }               
            }
          }
        `
          ),
          variables: { cidFormID: currentCidFormID, timezone: appConfigs.timezone },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('omView.failedToQueryCidForm', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        const data = res.data.data.om_getCidFormContent;

        // 處理實際跟因與對策
        const rootCauseLevelName = [];
        if (data.rootCauseLevel1Name) rootCauseLevelName.push(data.rootCauseLevel1Name);
        if (data.rootCauseLevel2Name) rootCauseLevelName.push(data.rootCauseLevel2Name);
        if (data.rootCauseLevel3Name) rootCauseLevelName.push(data.rootCauseLevel3Name);
        const solutionLevelName = [];
        if (data.solutionLevel1Name) solutionLevelName.push(data.solutionLevel1Name);
        if (data.solutionLevel2Name) solutionLevelName.push(data.solutionLevel2Name);

        setDataOfCid({
          ...data,
          rootCauseLevelName: rootCauseLevelName.map((o) => o).join(' / '),
          solutionLevelName: solutionLevelName.map((o) => o).join(' / '),
        });
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omView.queryCidFormError'));
          console.error('load cid form error: ', err);
        }
      });
  }, [user, currentCidFormID, t]);
  useEffect(() => {
    if (
      dataOfOm.omProcessingItem ||
      !Array.isArray(dataOfCid.askOmLogList) ||
      dataOfCid.askOmLogList.length === 0
    )
      return;

    // 將 CID單的[委託維運內容] 預帶入 OM單[處理項目]
    const { askReason } = dataOfCid.askOmLogList[dataOfCid.askOmLogList.length - 1];
    setDataOfOm((prev) => ({
      ...prev,
      omProcessingItem: askReason,
    }));
  }, [dataOfCid.askOmLogList, dataOfOm.omProcessingItem]);

  // load origin OM form.
  useEffect(() => {
    if (!originOmFormId) return;

    setLoading(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery($omFormID: String!, $timezone: String!) { 
              om_getContinueOmFormContent(omFormID: $omFormID, timezone: $timezone) {
                omSource
                cidFormID
                object
                omTraffic
                omExpectPvStatus
                omAction
                omUserInCharge
                omUserCooperate
                omProcessingItem
                omActionPlan
                omWorkContent
                omRecommend                
                omComponents { componentName amount }
                omObjectID
                omObjectIDName
                omObjectName
              }
            }
          `
          ),
          variables: { omFormID: originOmFormId, timezone: appConfigs.timezone },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('omView.failedToQueryOriginOmForm', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        const resData = res.data.data.om_getContinueOmFormContent;
        //
        const om = {};
        om.omSource = resData.omSource;
        om.cidFormID = resData.cidFormID;
        om.object = resData.object;
        om.omTraffic = resData.omTraffic;
        om.omExpectPvStatus = resData.omExpectPvStatus;
        om.startTime = moment().hours(9).minutes(0).seconds(0);
        om.endTime = moment().hours(18).minutes(0).seconds(0);
        om.omAction = resData.omAction;
        om.userInChargeIDs = resData.omUserInCharge;
        om.userCooperateIDs = resData.omUserCooperate;
        om.omProcessingItem = resData.omProcessingItem;
        om.omActionPlan = resData.omActionPlan;
        om.omObjectID = resData.omObjectID;
        om.omObjectIDName = resData.omObjectIDName;
        om.omObjectName = resData.omObjectName;
        //
        const prevOm = {};
        prevOm.workContent = resData.omWorkContent;
        prevOm.recommend = resData.omRecommend;
        prevOm.components = Array.isArray(resData.omComponents)
          ? resData.omComponents.map((m) => `${m.componentName} ${m.amount}pcs`).join(', ')
          : '';

        setDataOfOm(om);
        setDataOfOriginOm(prevOm);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omView.queryOriginOmFormError'));
          console.error('load origin om form error: ', err);
        }
      })
      .then(() => {
        setLoading(false);
      });
  }, [user, originOmFormId, t]);

  // ========== 返回上頁 ==========
  useEffect(() => {
    if (!redirectFlag) return;

    if (redirectUrl) {
      navigate(redirectUrl);
    } else {
      navigate(-1);
    }
  }, [redirectUrl, redirectFlag, navigate]);

  // ========== 取消編輯 ==========
  const handleCancelEdit = (reload) => {
    setDataChangedOfOm(false);
    setDataChangedOfOmWork(false);
    setReloadOmWorkFlag(false);
    setIsEditing(false);
    if (reload) {
      handleQuery();
    }
  };

  // ========== OM 表單相關 ==========
  //
  const handleDataOfOmChange = (name) => (e) => {
    const modifyValue = e && e.target ? e.target.value : e;
    const current = { ...dataOfOm };

    if (['startTime', 'endTime'].includes(name) && modifyValue) {
      modifyValue.second(0);
    }

    if (name === 'object' && modifyValue) {
      const getObjectData = listOfOmExtraPv.find((f) => f.value === modifyValue);
      if (getObjectData && getObjectData.omGroupID) {
        const getUserIDs = listOfOmUser
          .filter((f) => f.omGroupID === getObjectData.omGroupID && f.status === true)
          .map((m) => m.value);
        if (getUserIDs) {
          if ([OmStatusConstant.PROCESSING].includes(dataOfOm.omStatus)) {
            const getRecordUserIDs = recordOfOmWork.reduce((acc, cur) => {
              return acc.concat(cur.userID);
            }, []);
            const getNewUserID = [...new Set([...getUserIDs, ...getRecordUserIDs])];
            current['userInChargeIDs'] = getNewUserID.filter(
              (f) => !(current.userCooperateIDs || []).includes(f)
            );
          } else {
            current['userInChargeIDs'] = getUserIDs;
          }
        }
      }
    }

    if (name === 'omObjectID' && modifyValue) {
      current['omObjectName'] = '';
    }

    if (name === 'omAction' && modifyValue !== 4) {
      current['omCleanCost'] = null;
      current['omCleanUnitCost'] = null;
      current['omCleanModuleAmount'] = null;
    }

    if (['omCleanCost', 'omCleanUnitCost', 'omCleanModuleAmount'].includes(name)) {
      current['omCleanModify'] = 'Y';
    }

    current[name] = modifyValue;
    delete current[`${name}_msg`];
    setDataOfOm(current);
    setDataChangedOfOm(true);
  };
  const handleDataOfOmBlur = (name) => (e) => {
    let modifyValue = e && e.target ? e.target.value : e;
    const current = { ...dataOfOm };

    if (current['omCleanModify'] !== 'Y') return;

    if (['omCleanCost', 'omCleanUnitCost', 'omCleanModuleAmount'].includes(name)) {
      modifyValue = Number(modifyValue);
    }

    if (name === 'omCleanCost') {
      current['omCleanUnitCost'] = null;
      current['omCleanModuleAmount'] = null;
    }
    if (name === 'omCleanUnitCost') {
      if (current['omCleanCost'] && !current['omCleanModuleAmount']) {
        if (modifyValue) current['omCleanModuleAmount'] = _.ceil(current['omCleanCost'] / modifyValue);
        else current['omCleanModuleAmount'] = null;
      } else if (current['omCleanModuleAmount']) {
        current['omCleanCost'] = modifyValue * current['omCleanModuleAmount'];
      } else {
        //do nothing
      }
    }
    if (name === 'omCleanModuleAmount') {
      if (current['omCleanCost'] && !current['omCleanUnitCost']) {
        if (modifyValue) current['omCleanUnitCost'] = _.ceil(current['omCleanCost'] / modifyValue);
        else current['omCleanUnitCost'] = null;
      } else if (current['omCleanUnitCost']) {
        current['omCleanCost'] = modifyValue * current['omCleanUnitCost'];
      } else {
        //do nothing
      }
    }

    current[name] = modifyValue;
    setDataOfOm(current);
  };
  const handleDataOfOmSave = (cbFn) => () => {
    // valid
    const validResult = ValidFormData(dataOfOm, [
      { name: 'object', type: 'select', required: true },
      { name: 'omObjectID', type: 'select', required: true },
      { name: 'startTime', type: 'datetime', required: false },
      { name: 'endTime', type: 'datetime', required: false },
    ]);
    if (!validResult.status) {
      setDataOfOm(validResult.data);
      return;
    }
    if (dataOfOm.startTime && dataOfOm.endTime && dataOfOm.startTime.isAfter(dataOfOm.endTime)) {
      setDataOfOm({ ...dataOfOm, endTime_msg: t('omView.omStartTimeAfterEndTime') });
      return;
    }
    if (dataOfOm.omObjectID === 3 && !dataOfOm.omObjectName) {
      setDataOfOm({ ...dataOfOm, omObjectName_msg: t('omView.omObjectNameRequired') });
      return;
    }
    if (dataOfOm.omAction === 4) {
      let omModuleCleanFieldCheck = true;
      const omModuleCleanFieldValidMsg = {};
      if (IsNullOrUndefined(dataOfOm.omCleanCost) || dataOfOm.omCleanCost === 0) {
        omModuleCleanFieldValidMsg['omCleanCost_msg'] = t('omView.omCleanCostRequired');
        omModuleCleanFieldCheck = false;
      }
      // if (IsNullOrUndefined(dataOfOm.omCleanUnitCost) || dataOfOm.omCleanUnitCost === 0) {
      //   omModuleCleanFieldValidMsg['omCleanUnitCost_msg'] = '行動選擇「模組清潔」就必須輸入「清洗單價」欄位';
      //   omModuleCleanFieldCheck = false;
      // }
      // if (IsNullOrUndefined(dataOfOm.omCleanModuleAmount) || dataOfOm.omCleanModuleAmount === 0) {
      //   omModuleCleanFieldValidMsg['omCleanModuleAmount_msg'] =
      //     '行動選擇「模組清潔」就必須輸入「清洗片數」欄位';
      //   omModuleCleanFieldCheck = false;
      // }

      if (!omModuleCleanFieldCheck) {
        setDataOfOm({ ...dataOfOm, ...omModuleCleanFieldValidMsg });
        return;
      }
    }

    // save data prepare
    const saveData = {};
    saveData.omFormID = dataOfOm.omFormID || null;
    saveData.originOmFormID = originOmFormId;
    saveData.cidFormID = dataOfOm.cidFormID || cidFormId;
    saveData.omSource = dataOfOm.omSource;
    saveData.object = dataOfOm.object;
    saveData.omTraffic = dataOfOm.omTraffic;
    saveData.omExpectPvStatus = dataOfOm.omExpectPvStatus;
    saveData.omStartTime = DateTimeFormat(dataOfOm.startTime);
    saveData.omEndTime = DateTimeFormat(dataOfOm.endTime);
    saveData.omAction = dataOfOm.omAction;
    saveData.omUserInCharge = dataOfOm.userInChargeIDs;
    saveData.omUserCooperate = dataOfOm.userCooperateIDs;
    saveData.omProcessingItem = dataOfOm.omProcessingItem;
    saveData.omObjectID = dataOfOm.omObjectID;
    saveData.omObjectName = dataOfOm.omObjectName;
    saveData.omCleanCost = dataOfOm.omCleanCost;
    saveData.omCleanUnitCost = dataOfOm.omCleanUnitCost;
    saveData.omCleanModuleAmount = dataOfOm.omCleanModuleAmount;
    saveData.timezone = appConfigs.timezone;

    setButtonWorking(true);
    axios
      .post(`${appConfigs.apiGatewayURL}/oms/om/save`, saveData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('omView.failedToSaveOmForm', { error: res?.data?.msg }));
          return;
        }

        message.success('儲存 OM 表單完成');
        if (cbFn) {
          setDataChangedOfOm(false);
          cbFn(true);
        } else {
          handleCancelEdit(true);
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omView.saveOmFormError'));
          console.error('save om form error: ', err);
        }
      })
      .then(() => {
        setButtonWorking(false);
      });
  };
  const handleOmVoid = () => {
    if (!dataOfOm.omFormID) return;

    setButtonWorking(true);
    axios
      .post(
        `${appConfigs.apiGatewayURL}/oms/om/void`,
        { omFormID: dataOfOm.omFormID },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('omView.failedToVoidOmForm', { error: res?.data?.msg }));
          return;
        }

        message.success(t('omView.voidOmFormSuccess'));
        if (redirectUrl) {
          setRedirectFlag(true);
        } else {
          handleCancelEdit(true);
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omView.voidOmFormError'));
          console.error('void om form error: ', err);
        }
      })
      .then(() => {
        setButtonWorking(false);
      });
  };
  const handleDataOfOmCancel = () => {
    let modalClose = false;
    if (dataChangedOfOm || dataChangedOfOmWork) {
      if (window.confirm(t('omView.confirmCancel'))) {
        modalClose = true;
      }
    } else {
      modalClose = true;
    }

    if (modalClose) {
      if (isNew || redirectUrl) {
        setRedirectFlag(true);
      } else {
        if (dataChangedOfOm) {
          setDataOfOm((prev) => ({ ...prev, ...dataOfOmOrigin }));
        }
        handleCancelEdit(reloadOmWorkFlag);
      }
    }
  };

  // ========== OM 結案操作相關 ==========
  //
  const handleCloseModalClose = () => {
    setOmCloseModalOpen(false);
    setOmExecutionResult({ value: null, msg: null });
  };
  const handleOmExecutionResultChange = (selectedValue) => {
    setOmExecutionResult({ value: selectedValue, msg: '' });
  };
  const handleOmClose = () => {
    if (!dataOfOm.omFormID) return;
    if (!omExecutionResult.value) {
      setOmExecutionResult({ value: null, msg: t('omView.executionResultRequired') });
      return;
    }

    setButtonWorking(true);
    axios
      .post(
        `${appConfigs.apiGatewayURL}/oms/om/close`,
        { omFormID: dataOfOm.omFormID, executionResult: omExecutionResult.value },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('omView.failedToCloseOmForm', { error: res?.data?.msg }));
          return;
        }

        message.success(t('omView.closeOmFormSuccess'));
        setOmCloseModalOpen(false);
        setOmExecutionResult({ value: null, msg: null });
        if (redirectUrl) {
          setRedirectFlag(true);
        } else {
          handleCancelEdit(true);
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omView.closeOmFormError'));
          console.error('close om form error: ', err);
        }
      })
      .then(() => {
        setButtonWorking(false);
      });
  };

  // ========== OM 處理歷程相關 ========
  const handleOmWorkModalOpen = useCallback(
    (record) => () => {
      setOmWorkModalProps({ open: true, data: record });
    },
    []
  );
  const handleOmWorkModalClose = (reload) => () => {
    setOmWorkModalProps({ open: false, data: null });
    if (reload) {
      handleQuery();
    }
  };
  const handleOmWorkDelete = useCallback(
    (seq) => () => {
      if (!seq) return;

      axios
        .post(
          `${appConfigs.apiGatewayURL}/oms/om/work/delete`,
          { omFormID: dataOfOm.omFormID, seq: seq },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (!IsValidApiGatewayRsps(res)) {
            message.warn(t('omView.failedToDeleteOmWork', { error: res?.data?.msg }));
            return;
          }

          message.success(t('omView.deleteOmWorkSuccess'));
          setReloadOmWorkFlag(true);
          handleQuery();
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            message.error(t('omView.deleteOmWorkError'));
            console.error('delete om work form error: ', err);
          }
        });
    },
    [user, dataOfOm.omFormID, handleQuery, t]
  );
  const columnsOfOmWorkTable = useMemo(() => {
    const cols = [
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: 120,
        render: (value, record) => (
          <>
            {user.operations.includes('OMS_save-om-work-form') && (
              <Space>
                <Tooltip title={t('omView.edit')}>
                  <Button icon={<EditOutlined />} onClick={handleOmWorkModalOpen(record)} />
                </Tooltip>
                <Tooltip title={t('omView.delete')}>
                  <Popconfirm
                    placement="topLeft"
                    title={t('omView.confirmDeleteOmWork')}
                    okText={t('omView.confirmDelete')}
                    cancelText={t('omView.cancel')}
                    onConfirm={handleOmWorkDelete(record.seq)}
                  >
                    <Button icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </Space>
            )}
          </>
        ),
      },
      {
        title: t('omView.workTime'),
        dataIndex: 'startTime',
        key: 'startTime',
        width: 150,
        render: (value, record) => (
          <>
            <div>{record.startTime}</div>
            <div>{record.endTime}</div>
          </>
        ),
      },
      {
        title: () => (
          <div style={{ textAlign: 'center' }}>
            <div>{t('omView.workHours')}</div>
            <div>({t('omView.minutes')})</div>
          </div>
        ),
        dataIndex: 'workHour',
        key: 'workHour',
        width: 60,
      },
      {
        title: t('omView.fillInUserName'),
        dataIndex: 'fillInUserName',
        key: 'fillInUserName',
        width: 80,
      },
      {
        title: t('omView.actualArrivalPersonnel'),
        dataIndex: 'userName',
        key: 'userName',
        width: 120,
        render: (value) => (
          <div>{Array.isArray(value) && value.map((m, mIdx) => m && <div key={mIdx}>{m}</div>)}</div>
        ),
      },
      {
        title: t('omView.workContent'),
        dataIndex: 'workContent',
        key: 'workContent',
        width: 120,
        render: (value, record) => (
          <>
            <HintLabel>{t('omView.workDescription')}</HintLabel>
            <div>{record.workContent || t('omView.none')}</div>
            <br />
            <HintLabel>{t('omView.nextSuggestion')}</HintLabel>
            <div>{record.recommend || t('omView.none')}</div>
          </>
        ),
      },
      {
        title: 'Ocap',
        dataIndex: 'ocapList',
        key: 'ocapNames',
        render: (value) => (
          <div>
            {Array.isArray(value) &&
              value.map((m, mIdx) => (
                <div key={mIdx}>
                  {m.ocapID}_{m.ocapName}
                </div>
              ))}
          </div>
        ),
      },
      {
        title: t('omView.file'),
        dataIndex: 'fileList',
        key: 'fileList',
        render: (value) => <RfdmeUpload value={value} downloadOnly />,
      },
      {
        title: 'Logbook',
        dataIndex: 'logbook',
        key: 'logbook',
      },
    ];

    if (!formProps.showOmWorkActionButton) {
      delete cols[0];
    }

    return cols;
  }, [user, formProps.showOmWorkActionButton, handleOmWorkModalOpen, handleOmWorkDelete, t]);
  const handleDataOfOmWorkFileListChange = (name, idx) => (e) => {
    const modifyValue = e && e.target ? e.target.value : e;
    const updatedList = dataOfOmWorkFileList.map((item, index) => {
      if (index === idx) {
        const originOmWork = recordOfOmWork.find((f) => f.seq === item.seq);
        const isEditFileList = !(JSON.stringify(originOmWork['fileList']) === JSON.stringify(modifyValue));
        return { ...item, [name]: modifyValue, editFileList: isEditFileList };
      }
      return item;
    });

    console.log(updatedList);
    setDataOfOmWorkFileList(updatedList);
  };
  const handleDataOfOmWorkFileSave = (seq) => () => {
    // 是使用者來儲存，需檢核是否為相關處理人員
    if (user.operations.includes('OMS_save-om-work-file-by-user')) {
      // 依 listOfOmUser 查詢出，user 的 id 資料
      const omUser = listOfOmUser.find((f) => f.label === user.userName);
      if (
        omUser &&
        !(
          (dataOfOm.userInChargeIDs || []).includes(omUser.value) ||
          (dataOfOm.userCooperateIDs || []).includes(omUser.value)
        )
      ) {
        message.success(t('omView.cannotSaveNotRelatedPersonnel'));
        return;
      }
    }

    const saveOmWorkFile = dataOfOmWorkFileList.find((f) => f.seq === seq);

    // save data prepare
    const saveData = {};
    saveData.omFormID = dataOfOm.omFormID || null;
    saveData.seq = seq;
    saveData.files = saveOmWorkFile.fileList;
    console.log(saveData);

    setButtonWorking(true);
    axios
      .post(`${appConfigs.apiGatewayURL}/oms/om/work/updateFiles`, saveData, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('omView.failedToSaveOmWorkFile', { error: res?.data?.msg }));
          return;
        }

        message.success(t('omView.saveOmWorkFileSuccess'));
        handleQuery();
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omView.saveOmFormError'));
          console.error('save om form error: ', err);
        }
      })
      .then(() => {
        setButtonWorking(false);
      });
  };

  // =====================
  if (loading) {
    return (
      <>
        <PageHeader className="app-page-header" title={formTitle} />
        <div className="app-page-content">
          <div style={{ display: 'flex', minHeight: 300, justifyContent: 'center', alignItems: 'center' }}>
            <Spin spinning={loading} tip={t('omView.loading')} />
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      {/* page header */}
      <PageHeader
        className="app-page-header"
        title={formTitle}
        extra={
          formProps.canEdit && !isEditing
            ? [
                <Button key="1" type="primary" onClick={() => setIsEditing(true)}>
                  {t('omView.edit')}
                </Button>,
              ]
            : null
        }
      />
      {/* 列表 */}
      <div className="app-page-content">
        <section className="app-page-section">
          <Card title={t('omView.omBasicData')}>
            <Row gutter={[16, 24]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                <RfdmeDisplay label={t('omView.omFormID')}>
                  {dataOfOm.omFormID || t('omView.autoGenerated')}
                </RfdmeDisplay>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                {currentCidFormID && (
                  <RfdmeDisplay label={t('omView.sourceProject')}>
                    <Link to={`/cids/${currentCidFormID}`} target="_blank">
                      {currentCidFormID}
                    </Link>
                  </RfdmeDisplay>
                )}
                {!currentCidFormID && (
                  <>
                    {isEditing ? (
                      <RfdmeSelect
                        required
                        label={t('omView.sourceProject')}
                        options={listOfOmSourceFilter}
                        value={dataOfOm.omSource}
                        msg={dataOfOm.omSource_msg}
                        onChange={handleDataOfOmChange('omSource')}
                      />
                    ) : (
                      <RfdmeDisplay label={t('omView.sourceProject')}>{dataOfOm.omSourceName}</RfdmeDisplay>
                    )}
                  </>
                )}
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                <RfdmeDisplay label={t('omView.status')}>
                  {dataOfOm.omStatusName || t('omView.autoGenerated')}
                </RfdmeDisplay>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                <RfdmeDisplay label={t('omView.createDate')}>{dataOfOm.omCrDate}</RfdmeDisplay>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                {isEditing ? (
                  <RfdmeSelect
                    required
                    showSearch
                    label={t('omView.relatedProject')}
                    options={pvList}
                    value={dataOfOm.object}
                    msg={dataOfOm.object_msg}
                    onChange={handleDataOfOmChange('object')}
                  />
                ) : (
                  <RfdmeDisplay label={t('omView.relatedProject')}>
                    {dataOfOm.object}-{dataOfOm.objectName}
                  </RfdmeDisplay>
                )}
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                <RfdmeDisplay label={t('omView.regionInfo')}>{pvRegion}</RfdmeDisplay>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                <RfdmeDisplay label="SPV">{pvOwner}</RfdmeDisplay>
              </Col>
              <Col xs={0} md={12} lg={12} xl={6} />
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                {isEditing ? (
                  <RfdmeDatePicker
                    label={t('omView.expectedStartTime')}
                    showTime={{ format: 'HH:mm', minuteStep: 15 }}
                    format="YYYY-MM-DD HH:mm"
                    value={dataOfOm.startTime}
                    msg={dataOfOm.startTime_msg}
                    popupClassName="app-datetimepicker"
                    onSelect={handleDataOfOmChange('startTime')}
                    onChange={handleDataOfOmChange('startTime')}
                  />
                ) : (
                  <RfdmeDisplay label={t('omView.expectedStartTime')}>{dataOfOm.omStartTime}</RfdmeDisplay>
                )}
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                {isEditing ? (
                  <RfdmeDatePicker
                    label={t('omView.expectedEndTime')}
                    showTime={{ format: 'HH:mm', minuteStep: 15 }}
                    format="YYYY-MM-DD HH:mm"
                    value={dataOfOm.endTime}
                    msg={dataOfOm.endTime_msg}
                    popupClassName="app-datetimepicker"
                    onSelect={handleDataOfOmChange('endTime')}
                    onChange={handleDataOfOmChange('endTime')}
                  />
                ) : (
                  <RfdmeDisplay label={t('omView.expectedEndTime')}>{dataOfOm.omEndTime}</RfdmeDisplay>
                )}
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                {isEditing ? (
                  <RfdmeSelect
                    required
                    showSearch
                    label={t('omView.object')}
                    options={listOfOmObjectFilter}
                    value={dataOfOm.omObjectID}
                    msg={dataOfOm.omObjectID_msg}
                    onChange={handleDataOfOmChange('omObjectID')}
                  />
                ) : (
                  <RfdmeDisplay label={t('omView.object')}>{dataOfOm.omObjectIDName}</RfdmeDisplay>
                )}
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                {isEditing ? (
                  <>
                    {dataOfOm.omObjectID !== 3 ? (
                      <RfdmeTextArea
                        label={t('omView.objectName')}
                        rows={1}
                        value={dataOfOm.omObjectName}
                        onChange={handleDataOfOmChange('omObjectName')}
                      />
                    ) : (
                      <RfdmeSelect
                        required
                        showSearch
                        label={t('omView.objectName')}
                        options={listOfOrganize}
                        value={dataOfOm.omObjectName}
                        msg={dataOfOm.omObjectName_msg}
                        onChange={handleDataOfOmChange('omObjectName')}
                      />
                    )}
                  </>
                ) : (
                  <RfdmeDisplay label={t('omView.objectName')}>
                    {dataOfOm.omObjectID !== 3
                      ? dataOfOm.omObjectName
                      : (listOfOrganize?.find((item) => item.value === dataOfOm.omObjectName) || {}).label}
                  </RfdmeDisplay>
                )}
              </Col>
            </Row>
          </Card>
        </section>
        {!!currentCidFormID && (
          <section className="app-page-section">
            <Collapse>
              <Panel header={t('omView.cidInfo')} key="1">
                <Row gutter={[16, 24]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    <RfdmeDisplay label={t('omView.cidFormID')}>
                      <Link to={`/cids/${currentCidFormID}`} target="_blank">
                        {currentCidFormID}
                      </Link>
                    </RfdmeDisplay>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    <RfdmeDisplay label={t('omView.cidImportance')}>
                      {dataOfCid.levelName || '--'}
                    </RfdmeDisplay>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    <RfdmeDisplay label={t('omView.problemCategory')}>
                      {dataOfCid.problemCategoryName || '--'}
                    </RfdmeDisplay>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    <RfdmeDisplay label={t('omView.issue')}>{dataOfCid.issue || '--'}</RfdmeDisplay>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    <RfdmeDisplay label={t('omView.currentProgress')}>
                      {dataOfCid.currentProgress || '--'}
                    </RfdmeDisplay>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    {dataOfCid.rootCause && !dataOfCid.rootCauseLevelName ? (
                      <RfdmeDisplay label={t('omView.rootCause')}>{dataOfCid.rootCause}</RfdmeDisplay>
                    ) : (
                      <RfdmeDisplay label={t('omView.rootCause')}>
                        {dataOfCid.rootCauseLevelName || '--'}
                      </RfdmeDisplay>
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    {dataOfCid.solution && !dataOfCid.solutionLevelName ? (
                      <RfdmeDisplay label={t('omView.solution')}>{dataOfCid.solution}</RfdmeDisplay>
                    ) : (
                      <RfdmeDisplay label={t('omView.solution')}>
                        {dataOfCid.solutionLevelName || '--'}
                      </RfdmeDisplay>
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={6}></Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    <RfdmeDisplay label={t('omView.logbookAnalysis')}>
                      {dataOfCid.logbook || '--'}
                    </RfdmeDisplay>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    <RfdmeDisplay label="OCAP/SOP">
                      {dataOfCid.ocapList?.map((item) => {
                        return (
                          <>
                            {`[ ${item.ocapID} ] ${item.ocapName} (${item.ocapRefDate})`}
                            <br />
                          </>
                        );
                      }) || '--'}
                    </RfdmeDisplay>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Label>{t('omView.cdLog')}</Label>
                    <Table
                      style={{ maxWidth: '100%' }}
                      scroll={{ x: 800 }}
                      size="small"
                      pagination={false}
                      columns={ColumnsOfCidLogTable(
                        dataOfCid.alertComponent,
                        dataOfCid.clientDeviceOriginSeq &&
                          dataOfCid.clientDeviceSeq !== dataOfCid.clientDeviceOriginSeq
                          ? `${dataOfCid.clientDeviceSeq} [${dataOfCid.clientDeviceOriginSeq}]`
                          : dataOfCid.clientDeviceSeq
                      )}
                      dataSource={
                        Array.isArray(dataOfCid.askOmLogList) ? dataOfCid.askOmLogList.map(SetKeyToArray) : []
                      }
                    />
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </section>
        )}
        <section className="app-page-section">
          <Card title={t('omView.omAssignInfo')}>
            <Row gutter={[16, 24]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                {isEditing ? (
                  <RfdmeSelect
                    label={t('omView.mainHandler')}
                    mode="multiple"
                    options={listOfOmUserMain}
                    value={dataOfOm.userInChargeIDs || []}
                    msg={dataOfOm.userInChargeIDs_msg}
                    onChange={handleDataOfOmChange('userInChargeIDs')}
                  />
                ) : (
                  <RfdmeDisplay label={t('omView.mainHandler')}>{dataOfOm.omUserInChargeName}</RfdmeDisplay>
                )}
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                {isEditing ? (
                  <RfdmeSelect
                    label={t('omView.cooperateHandler')}
                    mode="multiple"
                    options={listOfOmUserOther}
                    value={dataOfOm.userCooperateIDs || []}
                    msg={dataOfOm.userCooperateIDs_msg}
                    onChange={handleDataOfOmChange('userCooperateIDs')}
                  />
                ) : (
                  <RfdmeDisplay label={t('omView.cooperateHandler')}>
                    {dataOfOm.omUserCooperateName}
                  </RfdmeDisplay>
                )}
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={6}>
                {isEditing ? (
                  <RfdmeSelect
                    label={t('omView.action')}
                    options={listOfOmActionFilter}
                    value={dataOfOm.omAction}
                    msg={dataOfOm.omAction_msg}
                    onChange={handleDataOfOmChange('omAction')}
                  />
                ) : (
                  <RfdmeDisplay label={t('omView.action')}>{dataOfOm.omActionName}</RfdmeDisplay>
                )}
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                {isEditing ? (
                  <RfdmeSelect
                    label={t('omView.traffic')}
                    options={listOfOmTrafficFilter}
                    value={dataOfOm.omTraffic}
                    msg={dataOfOm.omTraffic_msg}
                    onChange={handleDataOfOmChange('omTraffic')}
                  />
                ) : (
                  <RfdmeDisplay label={t('omView.traffic')}>{dataOfOm.omTrafficName}</RfdmeDisplay>
                )}
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                {isEditing ? (
                  <RfdmeSelect
                    label={t('omView.expectPvStatus')}
                    options={listOfOmExpectPvStatus}
                    value={dataOfOm.omExpectPvStatus}
                    msg={dataOfOm.omExpectPvStatus_msg}
                    onChange={handleDataOfOmChange('omExpectPvStatus')}
                  />
                ) : (
                  <RfdmeDisplay label={t('omView.expectPvStatus')}>
                    {dataOfOm.omExpectPvStatusName}
                  </RfdmeDisplay>
                )}
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                {isEditing ? (
                  <RfdmeTextArea
                    label={t('omView.processingItem')}
                    rows={5}
                    value={dataOfOm.omProcessingItem}
                    onChange={handleDataOfOmChange('omProcessingItem')}
                  />
                ) : (
                  <RfdmeDisplay label={t('omView.processingItem')}>{dataOfOm.omProcessingItem}</RfdmeDisplay>
                )}
              </Col>
              <Col xs={0} lg={6} />
              {dataOfOm.omAction === 4 && (
                <>
                  <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    {isEditing ? (
                      <RfdmeInputNumber
                        required
                        label={t('omView.cleanCost')}
                        min={0}
                        max={9999999}
                        value={dataOfOm.omCleanCost}
                        onChange={handleDataOfOmChange('omCleanCost')}
                        onBlur={handleDataOfOmBlur('omCleanCost')}
                        msg={dataOfOm.omCleanCost_msg}
                      />
                    ) : (
                      <RfdmeDisplay label={t('omView.cleanCost')}>
                        {dataOfOm.omCleanCost || dataOfOm.omCleanCost === 0
                          ? dataOfOm.omCleanCost.toLocaleString() + ' NTD'
                          : ''}
                      </RfdmeDisplay>
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    {isEditing ? (
                      <RfdmeInputNumber
                        label={t('omView.cleanUnitCost')}
                        min={0}
                        max={9999999}
                        value={dataOfOm.omCleanUnitCost}
                        onChange={handleDataOfOmChange('omCleanUnitCost')}
                        onBlur={handleDataOfOmBlur('omCleanUnitCost')}
                        msg={dataOfOm.omCleanUnitCost_msg}
                      />
                    ) : (
                      <RfdmeDisplay label={t('omView.cleanUnitCost')}>
                        {dataOfOm.omCleanUnitCost || dataOfOm.omCleanUnitCost === 0
                          ? dataOfOm.omCleanUnitCost.toLocaleString() + ' NTD'
                          : ''}
                      </RfdmeDisplay>
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    {isEditing ? (
                      <RfdmeInputNumber
                        label={t('omView.cleanModuleAmount')}
                        min={0}
                        max={9999999}
                        value={dataOfOm.omCleanModuleAmount}
                        onChange={handleDataOfOmChange('omCleanModuleAmount')}
                        onBlur={handleDataOfOmBlur('omCleanModuleAmount')}
                        msg={dataOfOm.omCleanModuleAmount_msg}
                      />
                    ) : (
                      <RfdmeDisplay label={t('omView.cleanModuleAmount')}>
                        {dataOfOm.omCleanModuleAmount || dataOfOm.omCleanModuleAmount === 0
                          ? dataOfOm.omCleanModuleAmount.toLocaleString()
                          : ''}
                      </RfdmeDisplay>
                    )}
                  </Col>
                </>
              )}
            </Row>
            {originOmFormId && (
              <>
                <br />
                <div className="app-description">
                  <Descriptions title={t('omView.workContentReference', { id: originOmFormId })}>
                    <Descriptions.Item label={t('omView.workContent')}>
                      {dataOfOriginOm.workContent || '--'}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('omView.recommend')}>
                      {dataOfOriginOm.recommend || '--'}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('omView.components')}>
                      {dataOfOriginOm.components || '--'}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </>
            )}
          </Card>
        </section>
        {dataOfOm.omFormID && (
          <>
            {isEditing ? (
              <section className="app-page-section">
                <Card title={t('omView.workHistory')}>
                  <Row gutter={[16, 8]}>
                    {formProps.showOmWorkActionButton && (
                      <Col span={6}>
                        <Button type="primary" onClick={handleOmWorkModalOpen(null)}>
                          {t('omView.addWorkHistory')}
                        </Button>
                      </Col>
                    )}
                    <Col span={24}>
                      <div className="app-form-item">
                        <Table
                          size="small"
                          scroll={{ x: 800 }}
                          pagination={false}
                          columns={columnsOfOmWorkTable}
                          dataSource={recordOfOmWork.filter((f) => Object.keys(f).length)}
                        />
                      </div>
                    </Col>
                  </Row>
                </Card>
              </section>
            ) : (
              <>
                {Array.isArray(dataOfOmWorkFileList) &&
                  dataOfOmWorkFileList.map((work, idx) => (
                    <section className="app-page-section">
                      <Card
                        title={t('omView.workHistoryIndex', { idx: dataOfOm.omWorkHistory.length - idx })}
                      >
                        <Row gutter={[16, 24]}>
                          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                            <RfdmeDisplay label={t('omView.startTime')}>
                              {work.startTime && DateTimeFormat(work.startTime, true)}
                            </RfdmeDisplay>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                            <RfdmeDisplay label={t('omView.endTime')}>
                              {work.endTime && DateTimeFormat(work.endTime, true)}
                            </RfdmeDisplay>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                            <RfdmeDisplay label={t('omView.workHour')}>{work.workHour}</RfdmeDisplay>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={6}></Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                            <RfdmeDisplay label={t('omView.actualAttendees')}>
                              {Array.isArray(work.userName) && work.userName.join(', ')}
                            </RfdmeDisplay>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                            <RfdmeDisplay label="填寫人員">
                              {work.fillInUserName && work.fillInUserName}
                            </RfdmeDisplay>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}></Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <RfdmeDisplay label={t('omView.workContent')}>
                              {work.workContent || '無'}
                            </RfdmeDisplay>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <RfdmeDisplay label={t('omView.recommend')}>
                              {work.recommend || '無'}
                            </RfdmeDisplay>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <RfdmeDisplay label="OCAP/SOP">
                              {work.ocapList?.map((m, mIdx) => (
                                <div key={mIdx}>
                                  {m.ocapID}_{m.ocapName}
                                </div>
                              ))}
                            </RfdmeDisplay>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <RfdmeDisplay label="LogBooks">{work.logbook}</RfdmeDisplay>
                          </Col>
                          <Col span={24}>
                            <Table
                              title={() => t('omView.components')}
                              size="small"
                              pagination={false}
                              columns={ColumnsOfComponentTable}
                              dataSource={work.componentList}
                            />
                          </Col>
                          {user.operations.includes('OMS_save-om-work-file-by-user') ||
                          user.operations.includes('OMS_save-om-work-file-by-manager') ? (
                            <>
                              <Col span={8}>
                                <RfdmeUpload
                                  label={t('omView.file')}
                                  maxCount={
                                    (((systemConfigs || {}).fileUpload || {}).omWorkHistory || {}).number
                                  }
                                  maxSize={
                                    (((systemConfigs || {}).fileUpload || {}).omWorkHistory || {}).capacity
                                  }
                                  value={work.fileList}
                                  onChange={handleDataOfOmWorkFileListChange('fileList', idx)}
                                />
                              </Col>
                              {work.editFileList && (
                                <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                                  <Button
                                    type="primary"
                                    loading={buttonWorking}
                                    onClick={handleDataOfOmWorkFileSave(work.seq)}
                                    block
                                  >
                                    {t('omView.save')}
                                  </Button>
                                </Col>
                              )}
                            </>
                          ) : (
                            <Col span={24}>
                              <div style={{ marginTop: '30px' }}>{t('omView.file')}</div>
                              <RfdmeUpload downloadOnly value={work.fileList} />
                            </Col>
                          )}
                        </Row>
                      </Card>
                    </section>
                  ))}
              </>
            )}
          </>
        )}

        {isEditing && (
          <div className="app-form-footer">
            <Row gutter={[8, 8]} justify="end">
              <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                <Button onClick={handleDataOfOmCancel} block>
                  {t('omView.close')}
                </Button>
              </Col>
              {formProps.showCreateButton && (
                <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Button
                    type="primary"
                    loading={buttonWorking}
                    onClick={handleDataOfOmSave(setRedirectFlag)}
                    block
                  >
                    {t('omView.save')}
                  </Button>
                </Col>
              )}
              {formProps.showSaveButton && (
                <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Button type="primary" loading={buttonWorking} onClick={handleDataOfOmSave()} block>
                    {t('omView.save')}
                  </Button>
                </Col>
              )}
              {formProps.showCloseButton && (
                <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Button type="primary" onClick={handleDataOfOmSave(setOmCloseModalOpen)} block>
                    {t('omView.closeCase')}
                  </Button>
                </Col>
              )}
              {formProps.showVoidButton && (
                <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Popconfirm
                    title={t('omView.voidConfirm')}
                    okText={t('omView.voidConfirmOk')}
                    cancelText={t('omView.voidConfirmCancel')}
                    onConfirm={handleOmVoid}
                    placement="topRight"
                  >
                    <Button danger type="primary" loading={buttonWorking} block>
                      {t('omView.void')}
                    </Button>
                  </Popconfirm>
                </Col>
              )}
            </Row>
          </div>
        )}
      </div>

      {/* 跳出視窗-處理歷程 */}
      {omWorkModalProps.open && (
        <RfdmeModal width="800px" open={omWorkModalProps.open}>
          <OmWorkEditor
            omFormId={dataOfOm.omFormID}
            workData={omWorkModalProps.data}
            users={listOfOmUser.filter(
              (f) =>
                (dataOfOm.userInChargeIDs || []).includes(f.value) ||
                (dataOfOm.userCooperateIDs || []).includes(f.value) ||
                (user.operations.includes('OMS_always-in-om-work-user-list') && user.userName === f.label)
            )}
            closeFn={handleOmWorkModalClose}
            setDataChanged={setDataChangedOfOmWork}
            setReloadFlag={setReloadOmWorkFlag}
            isCidFormIdRef={dataOfOm.cidFormID || dataOfOm.omSource === 9 ? true : false}
          />
        </RfdmeModal>
      )}

      {/* 跳出視窗-表單結案 */}
      <RfdmeModal width="600px" open={omCloseModalOpen}>
        <div className="app-modal-header">
          <div className="ant-modal-title">{t('omView.closeCase')}</div>
        </div>
        <Button
          className="app-modal-close"
          icon={<CloseOutlined />}
          type="text"
          onClick={handleCloseModalClose}
        />
        <div className="app-modal-content" style={{ height: '150px' }}>
          <RfdmeSelect
            label={t('omView.executionResult')}
            options={listOfOmExecutionResult}
            value={omExecutionResult.value}
            msg={omExecutionResult.msg}
            onChange={handleOmExecutionResultChange}
          />
        </div>
        <div className="app-modal-footer">
          <Row gutter={[8, 8]}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button block onClick={handleCloseModalClose}>
                {t('omView.close')}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button block type="primary" loading={buttonWorking} onClick={handleOmClose}>
                {t('omView.confirm')}
              </Button>
            </Col>
          </Row>
        </div>
      </RfdmeModal>
    </>
  );
};

export default OmView;
