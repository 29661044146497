import React, { useState, useEffect, useContext, useMemo } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { message, PageHeader, Tabs, Button, Result } from 'antd';
import Info from './info';
import ModuleCleaning from './moduleCleaning';
import RealTimeData from './realTimeData';
import HistoryAnalysis from './historyAnalysis';
import Health from './health';
import MonitorAnalysis from './monitorAnalysis';
import InvAnalysis from './invAnalysis';
import GuaranteedPowerAnalysis from './guaranteedPowerAnalysis';
import CidStatistics from './cidStatistics';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { IsValidAppSyncRsps } from '../../utils/valid';
import { AppSyncQueryTextFormat } from '../../utils/format';
import { GetAppSyncRspsErrorMessage } from '../../utils/parse';
import useListOfPv from '../../hooks/useListOfPv';
import { useTranslation } from 'react-i18next';
//
const GetObjectID = (urlPath) => {
  const objectId = (urlPath || '').replace('/pvs/', '');
  return objectId || null;
};

//
const Object = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [dataOfObject, setDataOfObject] = useState({});
  const [basicLoading, setBasicLoading] = useState(false);
  const [tabkey, setTabkey] = useState('info');
  const [grafanaConfig, setGrafanaConfig] = useState([]);
  const objectId = GetObjectID(location.pathname);
  // grafanaConfig 與 tab item 的對應
  const GRAFANA_CONFIG_MAPPING = useMemo(() => [
    {
      code: 'object-realtime',
      item: { seq: 1, label: t('object.realTimeData'), key: 'realTimeData' },
    },
    {
      code: 'object-history-analysis',
      item: { seq: 2, label: t('object.historyDataAnalysis'), key: 'historyAnalysis' },
    },
    {
      code: 'object-health',
      item: { seq: 3, label: t('object.siteAvailabilityAndHealth'), key: 'health' },
    },
    {
      code: 'object-monitor-analysis',
      item: { seq: 4, label: t('object.monitoringSystemAnalysis'), key: 'monitorAnalysis' },
    },
    {
      code: 'object-inv-analysis',
      item: { seq: 5, label: t('object.inverterAnalysis'), key: 'invAnalysis' },
    },
    {
      code: 'object-guaranteed-power-analysis',
      item: { seq: 6, label: t('object.guaranteedPowerAnalysis'), key: 'guaranteedPowerAnalysis' },
    },
    {
      code: 'object-modulecleaning-pr',
      item: { seq: 7, label: t('object.moduleCleaningTimeRecord'), key: 'moduleCleaning' },
    },
    {
      code: 'object-cid-statistics',
      item: { seq: 8, label: t('object.cidStatistics'), key: 'cidStatistics' },
    },
  ], [t]);
  const requireVerification = useMemo(() => {
    return (
      user.operations.includes('OBJECTINFO_investor-view') ||
      user.operations.includes('OBJECTINFO_external-view')
    );
  }, [user.operations]);
  const { data: pvList } = useListOfPv(requireVerification);

  const handleTabChange = (value) => {
    setTabkey(value);
  };

  const isAuthorized = useMemo(() => {
    if (!user) return false;
    if (!requireVerification) return true;
    if (!Array.isArray(pvList) || pvList.length === 0 || !pvList.find((pv) => pv.value === objectId))
      return false;

    return true;
  }, [user, requireVerification, objectId, pvList]);

  useEffect(() => {
    if (!objectId) return;
    if (!isAuthorized) return;

    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery($objectID: String!) {
               om_getObjectContent(objectID: $objectID) {
                  objectID
                  objectName
                  region
                  capacity
                  projectID
                  spvName
                  annualPower
                  decayRate
                  meterBeginDate
                  guaranteeBeginDate
                  monitorBeginDate
                  electricianLicenseSemester
                  preparatoryPermitSemester
                  constructionPermitSemester
                  totalEnergy
                  qryGuaranteeStartDate
                  qryGuaranteeEndDate
                  totalEnergyThisYear
                  currentYear
                  annualPowerThisYear
                  currentEndDate
               }
             }
            `
          ),
          variables: {
            objectID: objectId,
          },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('object.failedToQuerySiteContent', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        const resData = res.data.data.om_getObjectContent || {};
        resData.guaranteeAchieve =
          resData.annualPowerThisYear && resData.totalEnergyThisYear
            ? resData.totalEnergyThisYear / (resData.annualPowerThisYear * resData.capacity)
            : null;

        setDataOfObject(resData);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('object.querySiteContentError'));
          console.error('load object content error', err);
        }
      })
      .then(() => {
        setBasicLoading(false);
      });

    //grafana url
    axios
      .post(
        appConfigs.sharedAppSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery($system: String, $kind: String) {
              shared_getGrafanaEmbeddingList(system: $system, kind: $kind) {
                code
                id
                url
               }
             }
            `
          ),
          variables: {
            system: appConfigs.systemID,
            kind: 'objectInfo',
          },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('object.failedToGetGrafanaEmbeddingInfo', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        setGrafanaConfig(res.data.data.shared_getGrafanaEmbeddingList);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error('load grafanaUrlApi error', err);
          message.error(t('object.getGrafanaEmbeddingInfoError'));
        }
      });
  }, [user, objectId, isAuthorized, t]);

  const formProps = useMemo(() => {
    const result = {
      urlMap: new Map(),
      tabItems: [{ seq: 0, label: t('object.siteInfo'), key: 'info' }],
    };
    if (!grafanaConfig || !grafanaConfig.length) {
      return result;
    }
    //
    let tmpMap = new Map();
    let tmpItems = [{ seq: 0, label: t('object.siteInfo'), key: 'info' }];
    for (let i = 0; i < grafanaConfig.length; i++) {
      let tab = GRAFANA_CONFIG_MAPPING.find((item) =>
        new RegExp(`^${item.code}`).test(grafanaConfig[i].code)
      );
      if (tab) {
        if (!tmpMap.has(tab.item.key)) {
          tmpMap.set(tab.item.key, grafanaConfig[i].url);
        }
        if (!tmpItems.find((item) => item.key === tab.key)) {
          tmpItems.push(tab.item);
        }
      }
    }
    tmpItems.sort((a, b) => a.seq - b.seq);
    result.urlMap = tmpMap;
    result.tabItems = tmpItems;

    return result;
  }, [grafanaConfig, GRAFANA_CONFIG_MAPPING, t]);

  const title = dataOfObject.objectName ? dataOfObject.objectName + ` (${dataOfObject.objectID})` : null;

  if (pvList && !isAuthorized) {
    return (
      <>
        <PageHeader className="app-page-header rwd-btn-wrap" />
        <div className="app-page-content">
          <Result
            status="403"
            title={t('object.noViewPermission')}
            subTitle={t('object.noViewPermissionMessage')}
            extra={
              <Button type="primary" onClick={() => navigate('/pvs')}>
                {t('object.backToSiteList')}
              </Button>
            }
          />
        </div>
      </>
    );
  }

  return (
    <>
      {/* page header */}
      <PageHeader
        className="app-page-header rwd-btn-wrap"
        onBack={() => navigate('/pvs')}
        title={title}
        footer={<Tabs activeKey={tabkey} onChange={handleTabChange} items={formProps?.tabItems || []} />}
      />
      {tabkey === 'info' && (
        <Info basicLoading={basicLoading} dataOfObject={dataOfObject} setBasicLoading={setBasicLoading} />
      )}
      {tabkey === 'realTimeData' && <RealTimeData url={formProps?.urlMap?.get(tabkey)} objectID={objectId} />}
      {tabkey === 'historyAnalysis' && (
        <HistoryAnalysis url={formProps?.urlMap?.get(tabkey)} objectID={objectId} />
      )}
      {tabkey === 'health' && <Health url={formProps?.urlMap?.get(tabkey)} objectID={objectId} />}
      {tabkey === 'monitorAnalysis' && (
        <MonitorAnalysis url={formProps?.urlMap?.get(tabkey)} objectID={objectId} />
      )}
      {tabkey === 'invAnalysis' && <InvAnalysis url={formProps?.urlMap?.get(tabkey)} objectID={objectId} />}
      {tabkey === 'guaranteedPowerAnalysis' && (
        <GuaranteedPowerAnalysis url={formProps?.urlMap?.get(tabkey)} objectID={objectId} />
      )}
      {tabkey === 'moduleCleaning' && (
        <ModuleCleaning url={formProps?.urlMap?.get(tabkey)} objectID={objectId} />
      )}
      {tabkey === 'cidStatistics' && (
        <CidStatistics grafanaUrl={formProps?.urlMap?.get(tabkey)} objectID={objectId} />
      )}
    </>
  );
};

export default Object;
