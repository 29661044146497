import React, { useState, useEffect } from 'react';
import { Modal, Tabs, Row, Col, Radio, InputNumber, Checkbox, Button, Select, Space, Tooltip } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;
const tabBody = {
  height: '300px',
  overflowY: 'auto',
  marginTop: 8,
};

const SettingsModal = ({
  loading,
  pvList,
  filterData,
  handleFilterDataChange,
  defaultPv,
  setDefaultPv,
  isCarouselActive,
  carouselInterval,
  setCarouselInterval,
}) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [customInterval, setCustomInterval] = useState(300);
  const [selectedPvs, setSelectedPvs] = useState(filterData.objectID || []);
  const [selectedDefaultPv, setSelectedDefaultPv] = useState(defaultPv);

  useEffect(() => {
    // 初始化時從 localStorage 獲取設置
    const savedSettings = JSON.parse(localStorage.getItem('dashboardSettings'));
    if (savedSettings) {
      setSelectedPvs(savedSettings.selectedPvs || []);
      setSelectedDefaultPv(savedSettings.selectedDefaultPv || null);
      setCarouselInterval(savedSettings.carouselInterval || 15000);
    }
  }, [setCarouselInterval]);

  const showModal = () => {
    if (!isCarouselActive) {
      setIsModalVisible(true);
    }
  };

  const handleOk = () => {
    handleFilterDataChange('objectID')(selectedPvs);
    setDefaultPv(selectedDefaultPv);
    // 保存設置到 localStorage
    localStorage.setItem(
      'dashboardSettings',
      JSON.stringify({
        selectedPvs,
        selectedDefaultPv,
        carouselInterval,
      })
    );
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleIntervalChange = (e) => {
    if (e.target.value === 'custom') {
      setCarouselInterval(customInterval * 1000);
    } else {
      setCarouselInterval(e.target.value);
    }
  };

  const handleCustomIntervalChange = (value) => {
    setCustomInterval(value);
    setCarouselInterval(value * 1000);
  };

  const handlePvSelectionChange = (checkedValues) => {
    setSelectedPvs(checkedValues);
  };

  const handleDefaultPvChange = (value) => {
    setSelectedDefaultPv(value);
  };

  return (
    <>
      <Tooltip title={t('spvDashboard.settingsModal.settings')}>
        <Button icon={<SettingOutlined />} onClick={showModal} disabled={isCarouselActive} />
      </Tooltip>
      <Modal
        title={t('spvDashboard.settingsModal.playbackSettings')}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={t('spvDashboard.settingsModal.set')}
        cancelText={t('spvDashboard.settingsModal.cancel')}
      >
        <Tabs defaultActiveKey="2">
          <TabPane tab={t('spvDashboard.settingsModal.defaultSite')} key="1">
            <div style={tabBody}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                  <Select
                    options={pvList}
                    onChange={handleDefaultPvChange}
                    value={selectedDefaultPv}
                    placeholder={t('spvDashboard.settingsModal.selectSite')}
                  />
                </Col>
              </Row>
            </div>
          </TabPane>
          <TabPane tab={t('spvDashboard.settingsModal.playbackSites')} key="2">
            <div style={tabBody}>
              <Checkbox.Group
                style={{ width: '100%' }}
                value={selectedPvs}
                onChange={handlePvSelectionChange}
              >
                <Space direction="vertical">
                  {pvList.map((pv) => (
                    <Checkbox key={pv.value} value={pv.value}>
                      {pv.label}
                    </Checkbox>
                  ))}
                </Space>
              </Checkbox.Group>
            </div>
          </TabPane>
          <TabPane tab={t('spvDashboard.settingsModal.playbackInterval')} key="3">
            <div style={tabBody}>
              <Radio.Group onChange={handleIntervalChange} value={carouselInterval}>
                <Space direction="vertical">
                  <Radio value={15000}>{t('spvDashboard.settingsModal.seconds', { seconds: 15 })}</Radio>
                  <Radio value={30000}>{t('spvDashboard.settingsModal.seconds', { seconds: 30 })}</Radio>
                  <Radio value={45000}>{t('spvDashboard.settingsModal.seconds', { seconds: 45 })}</Radio>
                  <Radio value={60000}>{t('spvDashboard.settingsModal.seconds', { seconds: 60 })}</Radio>
                  <Radio value="custom">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {t('spvDashboard.settingsModal.custom')}
                      <InputNumber
                        min={1}
                        value={customInterval}
                        onChange={handleCustomIntervalChange}
                        style={{ marginLeft: 8, width: 160 }}
                        addonAfter={t('spvDashboard.settingsModal.secondsUnit')}
                      />
                    </div>
                  </Radio>
                </Space>
              </Radio.Group>
            </div>
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default SettingsModal;
