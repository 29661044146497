import { useState, useMemo, useContext, useCallback, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next'; // 新增這行
//
import RfdmeDatePicker from '../DatePicker';
import RfdmeInput from '../Input';
import RfdmeSelect from '../Select';
import RfdmeTextArea from '../TextArea';
import RfdmeUpload from '../Upload';
import RfdmeModal from '../Modal';
import { Row, Col, Button, Table, Space, Tooltip, Popconfirm, message, Divider } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
//
import appConfigs from '../../configs';
import useConfigOfSystem from '../../hooks/useConfigOfSystem';
import useListOfOcap from '../../hooks/useListOfOcap';
import useListOfOmComponent from '../../hooks/useListOfOmComponent';
import AuthContext from '../../contexts/authProvider';
import { IsValidApiGatewayRsps, ValidFormData, IsNumber } from '../../utils/valid';
import { SetKeyToArray } from '../../utils/format';

//
const GenerateInitialDataOfOmWork = (omFormId, workData) => {
  const result = {};
  result.omFormID = omFormId;

  if (workData) {
    result.seq = workData.seq;
    result.startTime = workData.startTime ? moment(workData.startTime) : null;
    result.endTime = workData.endTime ? moment(workData.endTime) : null;
    result.userID = workData.userID || undefined;
    result.workContent = workData.workContent;
    result.recommend = workData.recommend;
    result.logbook = workData.logbook;

    if (workData.ocapIDs) {
      result.ocapIDs = Array.isArray(workData.ocapIDs) ? workData.ocapIDs.map((m) => m.ocapID) : [];
    } else {
      result.ocapIDs = Array.isArray(workData.ocapList) ? workData.ocapList.map((m) => m.ocapID) : [];
    }

    result.componentList = workData.componentList;
    result.fileList = workData.fileList;
  }

  return result;
};

//
const OmWorkEditor = ({
  omFormId,
  workData,
  users,
  closeFn,
  setDataChanged,
  setReloadFlag,
  isCidFormIdRef,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [dataOfOmWork, setDataOfOmWork] = useState(GenerateInitialDataOfOmWork(omFormId, workData));
  const [dataOfComponent, setDataOfComponent] = useState({ value: undefined });
  const [dataOfAmount, setDataOfAmount] = useState({ value: undefined });
  const [saving, setSaving] = useState(false);
  const [componentModalProps, setComponentModalProps] = useState({ open: false, mode: 'add' });
  const { data: systemConfigs } = useConfigOfSystem();
  const { data: listOfOcap } = useListOfOcap();
  const { data: listOfOmComponent } = useListOfOmComponent();
  //
  const [dataChangedOfOmWork, setDataChangedOfOmWork] = useState(false);
  const [dataChangedOfComponent, setDataChangedOfComponent] = useState(false);
  //
  const listOfEnabledOcap = useMemo(() => {
    if (!Array.isArray(listOfOcap)) return [];
    if (!Array.isArray(dataOfOmWork.ocapIDs)) return listOfOcap.filter((f) => f.enable === true);
    return listOfOcap.filter((f) => dataOfOmWork.ocapIDs.includes(f.value) || f.enable === true);
  }, [listOfOcap, dataOfOmWork.ocapIDs]);
  // ========== 檢查資料異動 ==========
  //
  useEffect(() => {
    const handleWindowClose = (event) => {
      if (dataChangedOfOmWork || dataChangedOfOmWork) {
        event.preventDefault();
        event.returnValue = '系統可能不會儲存您所做的變更';
      }
    };

    window.addEventListener('beforeunload', handleWindowClose);

    // 清除 listener
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, [dataChangedOfOmWork, dataChangedOfComponent]);

  // ========== OM 處理歷程表單相關 ==========
  //
  const handleDataOfOmWorkChange = (name) => (e) => {
    const modifyValue = e && e.target ? e.target.value : e;
    const current = { ...dataOfOmWork };
    current[name] = modifyValue;
    delete current[`${name}_msg`];
    setDataOfOmWork(current);
    setDataChangedOfOmWork(true);
    setDataChanged && setDataChanged(true);
  };
  const handleClose = () => {
    let modalClose = false;
    if (dataChangedOfOmWork) {
      if (window.confirm(t('confirmCloseMessage'))) {
        modalClose = true;
      }
    } else {
      modalClose = true;
    }

    if (modalClose) {
      setDataChanged && setDataChanged(false);
      closeFn && closeFn(false)();
    }
  };
  const handleSave = () => {
    // valid
    const validResult = ValidFormData(dataOfOmWork, [
      { name: 'userID', type: 'select', required: true },
      { name: 'startTime', type: 'datetime', required: true },
      { name: 'endTime', type: 'datetime', required: true },
      { name: 'ocapIDs', type: 'select', required: isCidFormIdRef },
      { name: 'workContent', type: 'input', required: true },
      { name: 'recommend', type: 'input', required: false },
    ]);
    if (!validResult.status) {
      setDataOfOmWork(validResult.data);
      return;
    }
    let parseStartTime = dataOfOmWork.startTime.format('YYYY-MM-DD HH:mm:00');
    let parseEndTime = dataOfOmWork.endTime.format('YYYY-MM-DD HH:mm:00');
    if (dataOfOmWork.startTime.isAfter(dataOfOmWork.endTime)) {
      setDataOfOmWork({
        ...dataOfOmWork,
        endTime_msg: t('endTimeAfterStartTime'),
      });
      return;
    }

    // save data prepare
    const saveData = {};
    saveData.omFormID = dataOfOmWork.omFormID;
    saveData.seq = dataOfOmWork.seq;
    saveData.startTime = parseStartTime;
    saveData.endTime = parseEndTime;
    saveData.userID = dataOfOmWork.userID;
    saveData.ocaps = dataOfOmWork.ocapIDs;
    saveData.components = (dataOfOmWork.componentList || []).map((m) => ({
      componentID: m.componentID,
      amount: m.amount,
    }));
    saveData.workContent = dataOfOmWork.workContent;
    saveData.recommend = dataOfOmWork.recommend;
    saveData.files = dataOfOmWork.fileList;
    saveData.logbook = dataOfOmWork.logbook;
    saveData.timezone = appConfigs.timezone;

    setSaving(true);
    axios
      .post(`${appConfigs.apiGatewayURL}/oms/om/work/save`, saveData, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('saveOmWorkHistoryFailed', { msg: res?.data?.msg }));
          return;
        }

        message.success(t('saveOmWorkHistorySuccess'));
        setDataChanged && setDataChanged(false);
        setReloadFlag && setReloadFlag(true);
        closeFn && closeFn(true)();
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('saveOmWorkHistoryError'));
          console.error('save om work form error: ', err);
        }
      })
      .then(() => {
        setSaving(false);
      });
  };

  // ========== 組件更換相關 ==========
  //
  const handleComponentModalOpen = useCallback(
    (isEdit) => () => {
      setComponentModalProps({ open: true, mode: isEdit ? 'edit' : 'add' });
    },
    []
  );
  const handleComponentModalClose = () => {
    setDataOfComponent({ value: undefined });
    setDataOfAmount({ value: undefined });
    setDataChangedOfComponent(false);
    setComponentModalProps({ open: false, mode: 'add' });
  };
  const handleDataOfComponentChange = (value) => {
    setDataOfComponent({ value: value });
    setDataChangedOfComponent(true);
  };
  const handleDataOfAmountChange = (e) => {
    setDataOfAmount({ value: e.target.value });
    setDataChangedOfComponent(true);
  };
  const handleComponentSave = () => {
    // valid
    if (!dataOfComponent.value) {
      setDataOfComponent((prev) => ({ ...prev, value_msg: '欄位未填寫' }));
      return;
    }
    if (!dataOfAmount.value) {
      setDataOfAmount((prev) => ({ ...prev, value_msg: '欄位未填寫' }));
      return;
    }
    if (!IsNumber(dataOfAmount.value)) {
      setDataOfAmount((prev) => ({ ...prev, value_msg: '欄位需輸入數字' }));
      return;
    }

    // add or update.
    let tmp = [];
    let isUpdate = false;
    if (Array.isArray(dataOfOmWork.componentList)) {
      tmp = dataOfOmWork.componentList.map((m) => {
        if (m.componentID === dataOfComponent.value) {
          isUpdate = true;
          return {
            componentID: m.componentID,
            componentName: m.componentName,
            amount: dataOfAmount.value,
          };
        }
        return m;
      });
    }
    if (!isUpdate) {
      tmp.push({
        componentID: dataOfComponent.value,
        componentName: listOfOmComponent.find((f) => f.value === dataOfComponent.value).label,
        amount: dataOfAmount.value,
      });
    }

    // set and reset.
    setDataOfComponent({ value: undefined });
    setDataOfAmount({ value: undefined });
    setDataOfOmWork((prev) => ({ ...prev, componentList: tmp }));
    setDataChangedOfOmWork(true);
    setDataChangedOfComponent(false);
    handleComponentModalClose();
  };
  const handleComponentSelect = useCallback(
    (componentId) => () => {
      if (!componentId) return;

      const tmp = dataOfOmWork.componentList.find((f) => f.componentID === componentId);
      setDataOfComponent({ value: tmp.componentID });
      setDataOfAmount({ value: tmp.amount });
      handleComponentModalOpen(true)();
    },
    [dataOfOmWork.componentList, handleComponentModalOpen]
  );
  const handleComponentDelete = useCallback(
    (componentId) => () => {
      if (!componentId) return;

      setDataOfOmWork((prev) => ({
        ...prev,
        componentList: prev.componentList.filter((f) => f.componentID !== componentId),
      }));
    },
    []
  );
  const columnsOfComponentTable = useMemo(() => {
    return [
      {
        title: t('componentName'),
        dataIndex: 'componentName',
        key: 'componentName',
        render: (value, record) => {
          if (value) return value;

          if (listOfOmComponent) {
            const item = listOfOmComponent.find((f) => f.value === record.componentID);
            if (item) return item.label;
          }

          return '';
        },
      },
      // {
      //   title: '廠牌',
      //   dataIndex: 'brandName',
      //   key: 'brandName',
      // },
      // {
      //   title: '逆變器編號',
      //   dataIndex: 'inv',
      //   key: 'inv',
      // },
      {
        title: t('amount'),
        dataIndex: 'amount',
        key: 'amount',
      },
      {
        title: t('unit'),
        dataIndex: 'unit',
        key: 'unit',
        width: 60,
        render: (value) => 'pcs',
      },
      {
        title: t('operation'),
        dataIndex: 'componentID',
        key: 'componentID',
        width: 90,
        render: (value) => (
          <Space>
            <Tooltip title={t('edit')}>
              <Button type="text" icon={<EditOutlined />} onClick={handleComponentSelect(value)} />
            </Tooltip>
            <Tooltip title={t('delete')}>
              <Popconfirm
                placement="topLeft"
                title={t('confirmDeleteComponent')}
                okText={t('confirmDelete')}
                cancelText={t('cancel')}
                onConfirm={handleComponentDelete(value)}
              >
                <Button type="text" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          </Space>
        ),
      },
    ];
  }, [handleComponentSelect, handleComponentDelete, listOfOmComponent, t]); // 新增 t 到依賴數組

  // ========== 主畫面 ==========
  //
  return (
    <>
      <div className="app-modal-header">
        <div className="ant-modal-title">
          {!omFormId || !dataOfOmWork.seq ? t('add') : t('edit')} {t('omWorkHistory')}
        </div>
      </div>
      {/* <Button className="app-modal-close" icon={<CloseOutlined />} type="text" onClick={handleClose} /> */}

      <div className="app-modal-content">
        <Row gutter={[8, 24]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <RfdmeDatePicker
              required
              label={t('processStartTime')}
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              value={dataOfOmWork.startTime}
              msg={dataOfOmWork.startTime_msg}
              popupClassName="app-datetimepicker"
              onSelect={handleDataOfOmWorkChange('startTime')}
              onChange={handleDataOfOmWorkChange('startTime')}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <RfdmeDatePicker
              required
              label={t('processEndTime')}
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              value={dataOfOmWork.endTime}
              msg={dataOfOmWork.endTime_msg}
              popupClassName="app-datetimepicker"
              onSelect={handleDataOfOmWorkChange('endTime')}
              onChange={handleDataOfOmWorkChange('endTime')}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <RfdmeSelect
              required
              mode="multiple"
              label={t('actualOnSitePersonnel')}
              options={users}
              value={dataOfOmWork.userID}
              msg={dataOfOmWork.userID_msg}
              onChange={handleDataOfOmWorkChange('userID')}
            />
          </Col>
        </Row>
        <Divider className="app-divider">{t('processAction')}</Divider>
        <Row gutter={[8, 24]}>
          {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}> */}
          <Col xs={24}>
            <RfdmeSelect
              required={isCidFormIdRef}
              mode="multiple"
              label="Ocap"
              options={listOfEnabledOcap}
              value={dataOfOmWork.ocapIDs}
              msg={dataOfOmWork.ocapIDs_msg}
              onChange={handleDataOfOmWorkChange('ocapIDs')}
            />
          </Col>
          {/* 新增實際根因 */}
          {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <RfdmeSelect mode="multiple" label="實際根因" />
          </Col> */}
          <Col span={24}>
            <RfdmeTextArea
              required
              label={t('workDescription')}
              rows={5}
              value={dataOfOmWork.workContent}
              msg={dataOfOmWork.workContent_msg}
              onChange={handleDataOfOmWorkChange('workContent')}
            />
          </Col>
          <Col span={24}>
            <RfdmeTextArea
              label={t('nextSuggestion')}
              rows={5}
              value={dataOfOmWork.recommend}
              msg={dataOfOmWork.recommend_msg}
              onChange={handleDataOfOmWorkChange('recommend')}
            />
          </Col>
        </Row>
        <Divider className="app-divider">{t('componentReplacement')}</Divider>
        <Row gutter={[8, 16]} justify="center">
          <Col span={24}>
            <div className="app-form-item">
              <Table
                size="small"
                pagination={false}
                scroll={{ x: 600 }}
                columns={columnsOfComponentTable}
                dataSource={(dataOfOmWork.componentList || []).map(SetKeyToArray)}
              />
            </div>
          </Col>
          <Col>
            <Button icon={<PlusOutlined />} onClick={handleComponentModalOpen(false)}>
              {t('addComponentReplacement')}
            </Button>
          </Col>
        </Row>
        <Divider className="app-divider">{t('attachment')}</Divider>
        <Row gutter={[8, 24]}>
          <Col span={24}>
            <RfdmeUpload
              label={t('attachment')}
              maxCount={(((systemConfigs || {}).fileUpload || {}).omWorkHistory || {}).number}
              maxSize={(((systemConfigs || {}).fileUpload || {}).omWorkHistory || {}).capacity}
              value={dataOfOmWork.fileList}
              onChange={handleDataOfOmWorkChange('fileList')}
            />
          </Col>
          <Col span={24}>
            <RfdmeTextArea
              label="LogBooks"
              rows={5}
              value={dataOfOmWork.logbook}
              msg={dataOfOmWork.logbook_msg}
              onChange={handleDataOfOmWorkChange('logbook')}
            />
          </Col>
        </Row>
      </div>

      <div className="app-modal-footer">
        <Row gutter={[8, 0]} justify="end">
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <Button onClick={handleClose} block>
              {t('close')}
            </Button>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <Button type="primary" loading={saving} onClick={handleSave} block>
              {t('save')}
            </Button>
          </Col>
        </Row>
      </div>

      <RfdmeModal width="375px" open={componentModalProps.open}>
        <div className="app-modal-header">
          <div className="ant-modal-title">{t('maintainComponentReplacement')}</div>
        </div>

        <div className="app-modal-content" style={{ height: '300px' }}>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <RfdmeSelect
                label={t('sparePartItem')}
                options={listOfOmComponent}
                value={dataOfComponent.value}
                msg={dataOfComponent.value_msg}
                onChange={handleDataOfComponentChange}
                disabled={componentModalProps.mode === 'edit'}
              />
              <div></div>
            </Col>
            {/* 新增廠牌 
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <RfdmeSelect label="廠牌" />
            </Col> */}
            {/* 新增逆變器編號 
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <RfdmeInput label="逆變器編號" />
            </Col> */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <RfdmeInput
                label={t('usageQuantity')}
                value={dataOfAmount.value}
                msg={dataOfAmount.value_msg}
                onChange={handleDataOfAmountChange}
              />
            </Col>
            {/* 新增倉庫
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <RfdmeSelect label="倉庫" />
            </Col>  */}
            {/* 新增單位 
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <RfdmeSelect label="單位" disabled />
            </Col> */}
          </Row>
        </div>

        <div className="app-modal-footer">
          <Row gutter={[8, 8]}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button onClick={handleComponentModalClose} block>
                {t('close')}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button type="primary" onClick={handleComponentSave} block>
                {t('save')}
              </Button>
            </Col>
          </Row>
        </div>
      </RfdmeModal>
    </>
  );
};
export default OmWorkEditor;
