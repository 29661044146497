import { Checkbox } from 'antd';
import ComponentLayout from '../../layout/componentLayout';

const RfdmeCheckbox = ({ required, label, msg, options = [], isGroup = false, ...props }) => {
  return (
    <ComponentLayout label={label} required={required} msg={msg}>
      {isGroup && <Checkbox.Group options={options} {...props} />}
      {!isGroup && <Checkbox {...props} />}
    </ComponentLayout>
  );
};

export default RfdmeCheckbox;
