import moment from 'moment';
import { IsNumber } from './valid';

export const AppSyncQueryTextFormat = (queryText) => {
  return (queryText || '').replaceAll('  ', '').replaceAll('\n', ' ');
};

export const NumberFormat = (value) => {
  if (!IsNumber(value)) {
    return '-';
  } else {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
};

export const DateFormat = (value) => {
  if (!value) return null;
  if (moment.isMoment(value)) return value.format('YYYY-MM-DD');
  return moment(value).format('YYYY-MM-DD');
};

export const DateTimeFormat = (value, noSecond = false) => {
  const formatter = noSecond ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD HH:mm:ss';
  if (!value) return null;
  if (moment.isMoment(value)) return value.format(formatter);
  return moment(value).format(formatter);
};

export const FormObjectToJsonString = (value, momentColNames) => {
  if (!value) return '';
  if (typeof value !== 'object') return '';
  if (Array.isArray(value)) return '';

  const objectData = { ...value };
  const keys = Object.keys(objectData);
  if (!keys || keys.length === 0) return '';

  // empty array is null.
  for (let i = 0; i < keys.length; i++) {
    if (Array.isArray(objectData[keys[i]]) && objectData[keys[i]].length === 0) {
      delete objectData[keys[i]];
    }
  }

  // format moment type field.
  if (Array.isArray(momentColNames) && momentColNames.length > 0) {
    momentColNames.forEach((mcn) => {
      if (objectData[mcn]) {
        const mmt = moment(objectData[mcn]);
        if (mmt.isValid()) {
          objectData[mcn] = mmt.format('YYYY-MM-DD HH:mm:ss');
        }
      }
    });
  }

  return JSON.stringify(objectData);
};

export const JsonStringToFormObject = (jsonString, momentColNames) => {
  if (!jsonString) return null;

  const objectData = JSON.parse(jsonString);

  if (Array.isArray(momentColNames) && momentColNames.length > 0) {
    momentColNames.forEach((mc) => {
      if (objectData[mc]) {
        const mmt = moment(objectData[mc]);
        if (mmt.isValid()) {
          objectData[mc] = mmt;
        }
      }
    });
  }

  return objectData;
};

export const SetKeyToArray = (item, itemIndex) => {
  item.key = itemIndex;
  return item;
};

//
const chineseDigits = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
export const DigitToChineseFormat = (digit) => {
  // 使用嚴格等於檢查null值
  if (digit === null) {
    return '';
  }

  // 將傳入值轉為數字，並檢查是否為NaN
  const num = parseInt(digit, 10);
  if (isNaN(num)) {
    return '';
  }

  // 檢查數字是否在0到9之間並返回對應中文數字，否則返回空字串
  if (num >= 0 && num <= 9) {
    return chineseDigits[num];
  } else {
    return '';
  }
};

// 依據嚴重程度的順序，決定要顯示什麼顏色
export const CidLevelColorFormat = (levelSeq) => {
  let resultColor = 'green';
  switch (levelSeq) {
    case 1:
      resultColor = 'red';
      break;
    case 2:
      resultColor = 'volcano';
      break;
    case 3:
      resultColor = 'gold';
      break;
    default:
      break;
  }
  return resultColor;
};
