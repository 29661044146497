import React from 'react';
import { ClockCircleOutlined } from '@ant-design/icons';

const StatusCard = ({ icon, title, value, unit, timestamp, backgroundColor, color }) => {
  return (
    <div className="app-dashboard-status-card">
      <div
        className="app-dashboard-status-card-inner"
        style={{
          background: backgroundColor,
          color: color,
          borderColor: backgroundColor,
        }}
      >
        <div className="icon">
          <img src={icon} alt="icon" />
        </div>
        <div className="text">
          <p className="describe">{title}</p>
          <p className="value" style={{ color }}>
            {value} <span style={{ fontSize: '14px', fontWeight: 'normal' }}>{unit}</span>
          </p>
          {timestamp && (
            <p>
              <ClockCircleOutlined /> {timestamp}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatusCard;
