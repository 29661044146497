import moment from 'moment';
import appConfigs from '../../configs';
import { JsonStringToFormObject, FormObjectToJsonString } from '../../utils/format';

export const MOMENT_COLUMN_NAMES = [
  'workStartDateBegin',
  'workStartDateEnd',
  'workEndDateBegin',
  'workEndDateEnd',
  'omStartTime',
  'omEndTime',
  'cidStartDate',
  'cidEndDate',
];

export const INITIAL_FILTER_DATA = {
  workStartDateBegin: moment().add(-1, 'months'),
  workStartDateEnd: moment(),
  timezone: appConfigs.timezone,
};

export const GetDataOfFilterFromSession = () => {
  const filterDataStringInSession = window.sessionStorage.getItem('om-work-list-filterData');

  return JsonStringToFormObject(filterDataStringInSession, MOMENT_COLUMN_NAMES);
};

export const SetDefaultDataOfFilterToSession = () => {
  window.sessionStorage.setItem(
    'om-work-list-filterData',
    FormObjectToJsonString(INITIAL_FILTER_DATA, MOMENT_COLUMN_NAMES)
  );
};

export const SetDataOfFilterToSession = (value) => {
  window.sessionStorage.setItem(
    'om-work-list-filterData',
    FormObjectToJsonString(value, MOMENT_COLUMN_NAMES)
  );
};
