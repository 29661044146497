module.exports = {
  systemID: process.env.REACT_APP_SYSTEM_ID,
  appSyncURL: process.env.REACT_APP_APPSYNC_URL,
  sharedAppSyncURL: process.env.REACT_APP_SHARED_APPSYNC_URL,
  apiGatewayURL: process.env.REACT_APP_APIGATEWAY_URL,
  stageName: process.env.REACT_APP_STAGE_NAME,
  timezone: '8',
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  googleMapID: process.env.REACT_APP_GOOGLE_MAP_ID,
};
