import { useContext, useState } from 'react';
import { Upload, Button, Space, message, Row, Col, Image } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import ComponentLayout from '../../layout/componentLayout';
import RfdmeDisplay from '../Display';
import RfdmeInput from '../Input';
import RfdmeModal from '../Modal';
//
import axios from 'axios';
import styled from 'styled-components';
import mime from 'mime';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { IsValidApiGatewayRsps } from '../../utils/valid';
import { useTranslation } from 'react-i18next';
//
const IMAGE_TYPES = [
  'image/apng',
  'image/avif',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/webp',
];

//
const RfdmeUpload = ({
  required,
  label,
  msg,
  value,
  onChange,
  maxCount = 10,
  maxSize = 10,
  downloadOnly,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [fileExtension, setFileExtension] = useState(null);
  const [fileName, setFileName] = useState(null);

  const handleFileBeforeUpload = (file, fileList) => {
    //無檔案，直接返回
    if (!file) return false;

    //上傳檔案數量檢查，若超過限制則給予提示
    if ((value || []).length + 1 > maxCount) {
      message.warn(t('upload.exceedCountLimit', { count: maxCount }));
    }

    //上傳檔案大小檢查，若超過限制則給予提示
    if (file.size / 1048576 > maxSize) {
      message.warn(t('upload.exceedSizeLimit', { size: maxSize }));
    }

    const mimeType = mime.getType(file.name);
    if (IMAGE_TYPES.includes(mimeType)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setFilePreview(reader.result);
    }
    const extension = file.name.indexOf('.') > 0 && file.name.split('.').pop();
    setFileExtension(extension);
    setFileName(extension ? file.name.substring(0, file.name.length - (extension.length + 1)) : file.name);
    setUploadFile(file);
    setPreviewVisible(true);
    return false;
  };

  const handleFileUpload = () => {
    const newFile = new File([uploadFile], `${fileName}.${fileExtension}`, {
      type: uploadFile.type,
    });
    //
    const data = new FormData();
    data.append('file', newFile);

    //檢查通過，開始上傳
    setLoading(true);
    axios
      .post(`${appConfigs.apiGatewayURL}/oms/file/upload`, data, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('upload.failMessage', { msg: res?.data?.msg }));
          return;
        }
        onChange && onChange([...(value || []), res.data.data]);
        if (previewVisible) {
          handlePreviewCancel();
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('upload.errorMessage'));
          console.error('upload file error: ', err);
        }
      })
      .then(() => {
        setLoading(false);
      });

    return false;
  };

  const handleFilePreview = (file) => {
    if (!file || !file.name || !file.uid) return;

    axios
      .post(
        `${appConfigs.apiGatewayURL}/oms/file/download`,
        { fileID: file.uid, fileName: file.name },
        { headers: { Authorization: user.token, accept: '*/*' }, responseType: 'blob' }
      )
      .then((res) => {
        if (!res || !res.data) {
          message.warn(t('upload.cannotDownload', { fileName: file.name }));
          return;
        }

        const mimeType = mime.getType(file.name);
        const blob = new Blob([res.data], { type: mimeType });
        if (IMAGE_TYPES.includes(mimeType)) {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = () => setFilePreview(reader.result);
          setPreviewVisible(true);
          setFileName(file.name);
        } else {
          const blobFile = window.URL.createObjectURL(blob);
          if (blobFile) {
            const link = document.createElement('a');
            link.href = blobFile;
            link.download = file.name;
            link.click();
            window.URL.revokeObjectURL(blobFile);
          }
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('upload.cannotDownload', { fileName: file.name }));
          console.error('download file error', err);
        }
      });
  };

  const handleDownload = () => {
    if (!filePreview || !fileName) return;

    // 確認有副檔名，若無則設定為默認的 .png
    const extension = fileExtension ? `.${fileExtension}` : '.png';

    const link = document.createElement('a');
    link.href = filePreview;
    link.download = `${fileName}${extension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFileRemove = (file) => {
    if (!file || !file.name || !file.uid) return;
    onChange && onChange([...value.filter((f) => f.fileID !== file.uid)]);
  };

  const handlePreviewCancel = () => {
    setFileExtension(null);
    setFileName(null);
    setUploadFile(null);
    setFilePreview(null);
    setPreviewVisible(false);
  };

  let innerFileList = [];
  if (Array.isArray(value))
    innerFileList = value.map((m) => ({ name: m.fileName, uid: m.fileID, url: 'download' }));

  if (downloadOnly) {
    return (
      <>
        <Upload disabled fileList={innerFileList} onPreview={handleFilePreview} />
        <RfdmeModal
          open={previewVisible}
          width={1000}
          style={{ top: '20px' }}
          onCancel={handlePreviewCancel}
          zIndex={1000}
        >
          <div
            className="app-modal-content"
            style={{ height: filePreview ? 'calc(100vh - 300px)' : '150px' }}
          >
            {filePreview && (
              <ImageDiv>
                <Image
                  height={'100%'}
                  style={{ width: '100%', objectFit: 'contain', height: 'auto', maxHeight: '100%' }}
                  src={filePreview}
                />
              </ImageDiv>
            )}
            {uploadFile ? (
              <RfdmeInput
                label={t('upload.fileName')}
                allowClear={true}
                value={fileName}
                suffix={fileExtension && `.${fileExtension}`}
                onChange={(e) => setFileName(e.target.value)}
              />
            ) : (
              <RfdmeDisplay>{fileName}</RfdmeDisplay>
            )}
          </div>
          <div className="app-modal-footer">
            <Row gutter={[8, 0]} justify="end">
              <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                <Button onClick={handlePreviewCancel} block>
                  {t('common.close')}
                </Button>
              </Col>
              {filePreview && (
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Button type="default" onClick={handleDownload} block>
                    {t('common.download')}
                  </Button>
                </Col>
              )}
              {uploadFile && (
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Button type="primary" loading={loading} onClick={handleFileUpload} block>
                    {t('upload.confirmUpload')}
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        </RfdmeModal>
      </>
    );
  }
  return (
    <>
      <ComponentLayout
        label={
          <>
            {label}
            <span class="app-hint" style={{ marginLeft: 6 }}>
              {t('upload.limitHint', { maxCount, maxSize })}
            </span>
          </>
        }
        required={required}
        msg={msg}
      >
        <Upload
          fileList={innerFileList}
          beforeUpload={handleFileBeforeUpload}
          onPreview={handleFilePreview}
          onRemove={handleFileRemove}
        >
          <Space>
            <Button icon={<UploadOutlined />} disabled={(value || []).length >= maxCount} loading={loading}>
              {t('common.upload')}
            </Button>
          </Space>
        </Upload>
      </ComponentLayout>
      <RfdmeModal
        open={previewVisible}
        width={1000}
        style={{ top: '20px' }}
        onCancel={handlePreviewCancel}
        zIndex={1000}
      >
        <div className="app-modal-content" style={{ height: filePreview ? 'calc(100vh - 300px)' : '150px' }}>
          {filePreview && (
            <ImageDiv>
              <Image
                height={'100%'}
                style={{ width: '100%', objectFit: 'contain', height: 'auto', maxHeight: '100%' }}
                alt={t('upload.preview')}
                src={filePreview}
              />
            </ImageDiv>
          )}
          {uploadFile ? (
            <RfdmeInput
              label={t('upload.fileName')}
              allowClear={true}
              value={fileName}
              suffix={fileExtension && `.${fileExtension}`}
              onChange={(e) => setFileName(e.target.value)}
            />
          ) : (
            <RfdmeDisplay>{fileName}</RfdmeDisplay>
          )}
        </div>
        <div className="app-modal-footer">
          <Row gutter={[8, 0]} justify="end">
            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <Button onClick={handlePreviewCancel} block>
                {t('common.close')}
              </Button>
            </Col>
            {!uploadFile && filePreview && (
              <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                <Button type="default" onClick={handleDownload} block>
                  {t('common.download')}
                </Button>
              </Col>
            )}
            {uploadFile && (
              <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                <Button type="primary" loading={loading} onClick={handleFileUpload} block>
                  {t('upload.confirmUpload')}
                </Button>
              </Col>
            )}
          </Row>
        </div>
      </RfdmeModal>
    </>
  );
};

const ImageDiv = styled.div`
  width: 100%;
  height: 90%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export default RfdmeUpload;
