import React, { useState, useMemo, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import { Col, Row, Button, Table, Spin, Card, Tag, message, Result } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import RfdmeInputNumber from '../../components/InputNumber';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { SettingContext } from '../../contexts/settingsProvider';
import { IsValidAppSyncRsps, IsValidApiGatewayRsps } from '../../utils/valid';
import { AppSyncQueryTextFormat, SetKeyToArray } from '../../utils/format';
import { GetAppSyncRspsErrorMessage } from '../../utils/parse';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//設定
const INIT_TABLE_INFO = { page: 1, pageSize: 10 };
//
const ModuleCleaning = ({ url, objectID }) => {
  const { user } = useContext(AuthContext);
  const { dark } = useContext(SettingContext);
  const { t } = useTranslation();
  const [tableInfo, setTableInfo] = useState(INIT_TABLE_INFO);
  const [loading, setLoading] = useState(false);
  const [buttonWorking, setButtonWorking] = useState(false);
  const [dataOfThresholdPR, setDataOfThresholdPR] = useState(0);
  const [dataChangedOfThresholdPR, setDataChangedOfThresholdPR] = useState(0);
  const [cleanActivityList, setCleanActivityList] = useState({});
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const token = user.token?.replace?.(/^Bearer /, '');
  const theme = dark ? 'dark' : 'light';

  useEffect(() => {
    if (isFirstLoad) {
      const tableInfoStringInSession = window.sessionStorage.getItem('module-cleaning-list-tableInfo');
      if (tableInfoStringInSession) {
        const info = JSON.parse(tableInfoStringInSession);
        setTableInfo(info);
      } else {
        window.sessionStorage.setItem('module-cleaning-list-tableInfo', JSON.stringify(INIT_TABLE_INFO));
      }

      setIsFirstLoad(false);
    }
  }, [isFirstLoad]);

  const handleQuery = useCallback(() => {
    //查詢
    setLoading(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery( 
               $objectID: String!, 
               $timezone: String!, 
               $page: Int!, 
               $pageSize: Int! 
             ) {  
               om_getModuleCleanData( 
                 objectID: $objectID, 
                 timezone: $timezone 
                 page: $page 
                 pageSize: $pageSize 
               ) { 
                 setThresholdPR 
                 cleanActivityList { 
                   total 
                   list { 
                     omFormID 
                     cleanDate 
                     cleanAfterPR 
                     cleanBeforePR 
                     cleanBoostPR 
                     cleanCost 
                     cleanExpiryDate 
                     cleanImproveEnergy 
                   } 
                 } 
               } 
             } 
            `
          ),
          variables: {
            objectID: objectID,
            timezone: appConfigs.timezone,
            page: tableInfo.page,
            pageSize: tableInfo.pageSize,
          },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(
            `${t('moduleCleaning.failedToQueryModuleCleanData')}: ${GetAppSyncRspsErrorMessage(res)}`
          );
          return;
        }

        const tmp = res.data.data.om_getModuleCleanData;
        const getPR = tmp.setThresholdPR * 100;
        setDataOfThresholdPR(getPR);
        setDataChangedOfThresholdPR(getPR);
        tmp.cleanActivityList.list = tmp.cleanActivityList.list.map(SetKeyToArray);
        setCleanActivityList(tmp.cleanActivityList);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error('load madule clean data error', err);
          message.error(t('moduleCleaning.queryModuleCleanDataError'));
        }
      })
      .then(() => {
        setLoading(false);
      });

    //
  }, [user, objectID, tableInfo, t]);
  useEffect(() => {
    handleQuery();
  }, [handleQuery]);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'paginate') {
      window.sessionStorage.setItem(
        'module-cleaning-list-tableInfo',
        JSON.stringify({ ...tableInfo, page: pagination.current })
      );
      setTableInfo((tbi) => ({
        ...tbi,
        page: pagination.current,
      }));
    }
  };

  const handleDataOfPRChange = (e) => {
    const modifyValue = e && e.target ? e.target.value : e;
    setDataChangedOfThresholdPR(modifyValue);
  };

  const handleDataOfPRReset = () => {
    setDataChangedOfThresholdPR(dataOfThresholdPR);
  };

  const handleDataOfPRSave = () => {
    if (dataOfThresholdPR === dataChangedOfThresholdPR) {
      message.warning(t('moduleCleaning.dataOfPRNotChanged'));
      return;
    }

    // save data prepare
    const saveData = {};
    saveData.objectID = objectID;
    saveData.thresholdPR = dataChangedOfThresholdPR / 100;
    saveData.timezone = appConfigs.timezone;

    setButtonWorking(true);
    axios
      .post(`${appConfigs.apiGatewayURL}/oms/object/clean/save`, saveData, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(`${t('moduleCleaning.failedToSavePR')}: ${res?.data?.msg}`);
          return;
        }

        setDataOfThresholdPR(dataChangedOfThresholdPR);
        message.success(t('moduleCleaning.savePRCompleted'));
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('moduleCleaning.savePRError'));
          console.error('save PR error: ', err);
        }
      })
      .then(() => {
        setButtonWorking(false);
      });
  };

  //
  const columns = useMemo(() => {
    return [
      {
        title: t('moduleCleaning.omFormID'),
        dataIndex: 'omFormID',
        key: 'omFormID',
        render: (value) => (
          <Link to={`/oms/${value}`} target="_blank">
            {value}
          </Link>
        ),
      },
      {
        title: t('moduleCleaning.cleanDate'),
        dataIndex: 'cleanDate',
        key: 'cleanDate',
        render: (value) => value,
      },
      {
        title: t('moduleCleaning.cleanBeforePR'),
        dataIndex: 'cleanBeforePR',
        key: 'cleanBeforePR',
        render: (value) => (value ? (value * 100).toFixed(2) + ' %' : '-'),
      },
      {
        title: t('moduleCleaning.cleanAfterPR'),
        dataIndex: 'cleanAfterPR',
        key: 'cleanAfterPR',
        render: (value) => (value ? (value * 100).toFixed(2) + ' %' : '-'),
      },
      {
        title: t('moduleCleaning.cleanCost'),
        dataIndex: 'cleanCost',
        key: 'cleanCost',
        render: (value) => {
          if (value) {
            return value.toLocaleString() + ' NTD'; // 將數字格式化，千位數加上逗號
          } else {
            return '-';
          }
        },
      },
      {
        title: t('moduleCleaning.cleanBoostPR'),
        dataIndex: 'cleanBoostPR',
        key: 'cleanBoostPR',
        render: (value) => {
          if (value) {
            if (value > 0) {
              return (
                <Tag color="green">
                  <ArrowUpOutlined style={{ marginRight: '4px' }} />
                  {(value * 100).toFixed(2) + ' %'}
                </Tag>
              );
            } else {
              return (
                <Tag color="red">
                  <ArrowDownOutlined style={{ marginRight: '4px' }} />
                  {Math.abs(value * 100).toFixed(2) + ' %'}
                </Tag>
              );
            }
          } else {
            return '-';
          }
        },
      },
      {
        title: t('moduleCleaning.cleanExpiryDate'),
        dataIndex: 'cleanExpiryDate',
        key: 'cleanExpiryDate',
        render: (value) => (value ? value : '-'),
      },
      {
        title: t('moduleCleaning.cleanImproveEnergy'),
        dataIndex: 'cleanImproveEnergy',
        key: 'cleanImproveEnergy',
        render: (value) => {
          if (value) {
            if (value > 0) {
              return (
                <Tag color="green">
                  <ArrowUpOutlined style={{ marginRight: '4px' }} />
                  {value.toFixed(2) + ' kWh'}
                </Tag>
              );
            } else {
              return (
                <Tag color="red">
                  <ArrowDownOutlined style={{ marginRight: '4px' }} />
                  {Math.abs(value).toFixed(2) + ' kWh'}
                </Tag>
              );
            }
          } else {
            return '-';
          }
        },
      },
    ];
  }, [t]);

  if (!url) {
    return <Result status="404" title="404" subTitle={t('moduleCleaning.pageNotFound')} />;
  }

  return (
    <div className="app-page-content">
      <Spin spinning={loading}>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Card title={t('moduleCleaning.cleanNotificationSetting')}>
              <Row gutter={[8, 8]} align="middle">
                <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                  <RfdmeInputNumber
                    addonBefore="PR"
                    addonAfter="%"
                    value={dataChangedOfThresholdPR}
                    max={100}
                    min={0}
                    size="large"
                    onChange={handleDataOfPRChange}
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={{ span: 3, offset: 6 }} xl={{ span: 3, offset: 12 }}>
                  <Button block onClick={handleDataOfPRReset}>
                    {t('moduleCleaning.reset')}
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                  <Button type="primary" block loading={buttonWorking} onClick={handleDataOfPRSave}>
                    {t('moduleCleaning.save')}
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24}>
            <Card title={t('moduleCleaning.cleanActivityTable')}>
              <Table
                size="small"
                className="collapsble"
                columns={columns}
                dataSource={cleanActivityList.list}
                scroll={{ x: 1440 }}
                pagination={{
                  total: cleanActivityList.total,
                  pageSize: tableInfo.pageSize,
                  showSizeChanger: false,
                  current: tableInfo.page,
                }}
                onChange={handleTableChange}
              />
            </Card>
          </Col>
          <Col span={24}>
            <Card title={t('moduleCleaning.sitePRAnalysisChart')}>
              <iframe
                width={'100%'}
                height={400}
                title={t('moduleCleaning.sitePRAnalysisChart')}
                style={{ border: 0 }}
                src={`${url}&var-plant=${objectID}&auth_token=${token}&theme=${theme}`}
              ></iframe>
            </Card>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default ModuleCleaning;
