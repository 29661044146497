import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Routes, Route, useNavigate, useLocation, Link } from 'react-router-dom';
import { Layout, Menu, Avatar, Drawer, Divider, Dropdown, ConfigProvider } from 'antd';
import zh_TW from 'antd/lib/locale/zh_TW';
import en_US from 'antd/lib/locale/en_US';
import vi_VN from 'antd/lib/locale/vi_VN';
import { PoweroffOutlined, SettingOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
//
import LogoSvg from '../images/logo_s.svg';
import LogoSVg_icon from '../images/logo_icon.svg';
import logo_ch from '../images/logo_ch.svg';
import AuthContext from '../contexts/authProvider';
import { SettingContext } from '../contexts/settingsProvider';
//
import RfdmeBreadcrumb from '../components/Breadcrumb';
import PageUnauthorized from '../pages/PageUnauthorized';
import PageNotFound from '../pages/PageNotFound';
//
import { MenuList as menuList } from './menus';
import { RouteList as routeList } from './routes';
//
import logo_s from '../images/logo_s.svg';
//
import Notification from './notification';

const { Sider, Header, Content } = Layout;

//篩選符合user.menu的item
function filterMenus(data, backendInfo) {
  const tmpAry = _.cloneDeep(data);
  return tmpAry.filter((item) => {
    let matchedBackendItem = backendInfo.find((backendItem) => {
      return item.key === backendItem.menuPath;
    });

    if (matchedBackendItem) {
      item.label = matchedBackendItem.menuName; // 更新 label 属性
    }

    if (item.children) {
      item.children = filterMenus(item.children, backendInfo);
    }

    return matchedBackendItem || (item.children && item.children.length > 0);
  });
}

function searchKey(data, targetKey) {
  let results = [];

  for (let item of data) {
    if (item.key === targetKey) {
      results.push(item);
    }

    if (item.children) {
      results = results.concat(searchKey(item.children, targetKey));
    }
  }

  return results;
}
//主元件
const AppLayout = () => {
  const { user, UnAuthUser } = useContext(AuthContext);
  const { dark } = useContext(SettingContext);
  const [menus, setMenus] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  //routerList
  const RouteList = useMemo(() => routeList(t), [t]);

  //menu
  const MenuList = useMemo(() => menuList(t), [t]);

  const currentSelectedMenuKey = useMemo(() => {
    if (location.pathname === '/') return '/';

    const pathSnippets = location.pathname.split('/').filter((i) => i);

    let menuKey = '';
    for (let i = pathSnippets.length; i > 0; i--) {
      const url = `/${pathSnippets.slice(0, i).join('/')}`;
      // const matchMenu = MenuList.find((f) => f.key === url);
      const matchMenu = searchKey(MenuList, url);
      if (matchMenu.length) {
        menuKey = matchMenu[0].key;
        break;
      }
    }

    return menuKey;
  }, [location, MenuList]);

  const hasBreadcrumbRow = useMemo(() => {
    if (location.pathname === '/') return 'N';
    return 'Y';
  }, [location]);

  const items = useMemo(() => {
    return [
      {
        key: 'settings',
        label: <span>{t('envSettings')}</span>,
        icon: <SettingOutlined />,
        name: t('envSettings'),
        onClick: () => {
          navigate('/settings');
        },
      },
      {
        type: 'divider',
      },
      {
        key: 'signout',
        label: <span>{t('logout')}</span>,
        icon: <PoweroffOutlined />,
        name: t('logout'),
        onClick: () => {
          UnAuthUser();
          navigate('/login');
        },
      },
    ];
  }, [UnAuthUser, navigate, t]);

  useEffect(() => {
    if (user && Array.isArray(user.menus) && user.menus.length > 0) {
      const filterMenu = filterMenus(MenuList, user.menus);
      setMenus(filterMenu);
      //
      let authRouteList = [];
      for (let i = 0; i < RouteList.length; i++) {
        const routeItem = RouteList[i];
        const authRouteItem = user.menus.find((f) => f.menuPath === routeItem.path);
        if (authRouteItem) {
          authRouteList.push({ ...routeItem });
        }
      }
      setRoutes(authRouteList);
    }
  }, [user, MenuList, RouteList]);

  const handleMenuSelected = ({ key }) => {
    navigate(key);
  };
  const handleSiderCollapse = (value) => {
    setCollapsed(value);
  };

  const handleLogout = () => {
    UnAuthUser();
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <ConfigProvider locale={i18n.language === 'zh-TW' ? zh_TW : i18n.language === 'en' ? en_US : vi_VN}>
      <Layout className={`app-layout ${dark ? 'dark' : ''}`}>
        <Sider collapsedWidth="60" collapsible collapsed={collapsed} onCollapse={handleSiderCollapse}>
          <div className="logo-container">
            <img alt="微電能源-O&M2.0系統" src={collapsed ? LogoSVg_icon : i18n.language === 'zh-TW' ? logo_ch : LogoSvg} />
          </div>
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[currentSelectedMenuKey]}
            items={menus}
            onSelect={handleMenuSelected}
            className="app-sidebar-menu"
            style={{ height: 'calc(100vh - 163px)', overflowY: 'auto' }}
          />
        </Sider>
        <Layout className="app-layout">
          <Header className="app-header">
            <div className="app-header-left">
              <div className="app-header-logo-wrap">
                <Link to="/">
                  <img src={logo_s} alt="微電能源-O&M2.0系統" />
                </Link>
              </div>
            </div>
            <div className="app-header-right">
              <Notification />
              <Dropdown menu={{ items }} trigger={['click']} overlayClassName={dark ? 'dark' : ''}>
                <div className="app-header-avatar">
                  <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} size="small">
                    {user.userName && user.userName[0]}
                  </Avatar>
                </div>
              </Dropdown>

              <div
                className="app-header-mobile-menu"
                onClick={() => {
                  showDrawer();
                }}
              >
                <em />
                <em />
              </div>
            </div>
            <Drawer
              title=""
              placement="right"
              onClose={onClose}
              open={open}
              width={280}
              className={dark ? 'dark' : ''}
            >
              <Menu
                mode="inline"
                selectedKeys={[currentSelectedMenuKey]}
                items={menus}
                onSelect={handleMenuSelected}
                className="app-mobile-menu"
              />
              <Divider />
              <div style={{ paddingLeft: 24, marginBottom: 16 }}>
                <Link to="/settings">
                  <SettingOutlined /> <span style={{ marginLeft: 6 }}>{t('envSettings')}</span>
                </Link>
              </div>

              <div onClick={handleLogout} style={{ paddingLeft: 24 }}>
                <PoweroffOutlined /> <span style={{ marginLeft: 6 }}>{t('logout')}</span>
              </div>
            </Drawer>
          </Header>
          {hasBreadcrumbRow === 'Y' && (
            <div className="app-breadcrumb">
              <RfdmeBreadcrumb routes={routes} />
            </div>
          )}
          <Content
            id="scrollableDiv"
            className="app-content"
            style={{ height: hasBreadcrumbRow === 'Y' ? 'calc(100vh - 100px)' : 'calc(100vh - 50px)' }}
          >
            <Routes>
              {routes.map((r, rIdx) => (
                <Route key={`route-${rIdx}`} path={r.path} element={r.element} />
              ))}
              <Route path="/unauthorized" element={<PageUnauthorized />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default AppLayout;
