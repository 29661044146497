import { useState, useMemo, useEffect, useContext } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { message, PageHeader, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import BoosterStationInfo from './info';
import CidStatistics from './cidStatistics';

import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { IsValidAppSyncRsps } from '../../utils/valid';
import { AppSyncQueryTextFormat } from '../../utils/format';
import { GetAppSyncRspsErrorMessage } from '../../utils/parse';

const GetBoosterStationID = () => {
  return (window.location.pathname || '').replace('/boosterStations/', '') || '';
};

const BoosterStation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const [grafanaConfig, setGrafanaConfig] = useState([]);
  const [tabkey, setTabkey] = useState('info');
  const pageTitle = useMemo(() => {
    const name = location?.state?.boosterStationName || t('boosterStation.defaultName');
    return `${name}-${GetBoosterStationID()}`;
  }, [location, t]);

  const GRAFANA_CONFIG_MAPPING = useMemo(() => {
    return {
      'booster-cid-statistics': { seq: 1, label: t('boosterStation.cidStatisticsTab'), key: 'cidStatistics' },
    };
  }, [t]);
  
  const formProps = useMemo(() => {
    const result = {
      tabItems: [{ seq: 0, label: t('boosterStation.circuitConfigurationTab'), key: 'info' }],
      urls: {},
    };
    if (Array.isArray(grafanaConfig) && grafanaConfig.length > 0) {
      for (let i = 0; i < grafanaConfig.length; i++) {
        const g = grafanaConfig[i];
        const matchItem = GRAFANA_CONFIG_MAPPING[g.code];
        if (matchItem) {
          result.tabItems.push({ ...matchItem });
          result.urls[matchItem.key] = g.url;
        }
      }
    }
    return result;
  }, [grafanaConfig, GRAFANA_CONFIG_MAPPING, t]);

  const handleTabChange = (value) => {
    setTabkey(value);
  };

  // 取得此頁面的 Grafana 關聯與資訊
  useEffect(() => {
    axios
      .post(
        appConfigs.sharedAppSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery($system: String, $kind: String) {
              shared_getGrafanaEmbeddingList(system: $system, kind: $kind) {
                code
                url
               }
             }
            `
          ),
          variables: {
            system: appConfigs.systemID,
            kind: 'boosterStationInfo',
          },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(
            t('common.queryGrafanaEmbeddingListFailed', { error: GetAppSyncRspsErrorMessage(res) })
          );
          return;
        }

        setGrafanaConfig(res.data.data.shared_getGrafanaEmbeddingList);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error('load grafanaUrlApi error', err);
          message.error(t('common.queryGrafanaEmbeddingListError'));
        }
      });
  }, [user, t]);

  return (
    <>
      {/* page header */}
      <PageHeader
        className="app-page-header rwd-btn-wrap"
        onBack={() => navigate('/boosterStations')}
        title={pageTitle}
        footer={<Tabs activeKey={tabkey} onChange={handleTabChange} items={formProps?.tabItems || []} />}
      />
      {tabkey === 'info' && <BoosterStationInfo />}
      {tabkey === 'cidStatistics' && (
        <CidStatistics grafanaUrl={formProps.urls[tabkey]} objectID={GetBoosterStationID()} />
      )}
    </>
  );
};

export default BoosterStation;
