import {
  WarningOutlined,
  // HomeOutlined,
  FundOutlined,
  ReconciliationOutlined,
  DesktopOutlined,
  FileSearchOutlined,
  ThunderboltOutlined,
  DashboardOutlined,
  FileTextOutlined,
} from '@ant-design/icons';

const getItem = (seq, label, key, icon, children) => {
  return { seq, label, key, icon, children };
};

export const MenuList = (t) => [
  // getItem('1', null, '/', <HomeOutlined />),
  getItem('2', t('breadcrumb.dashboard'), '/dashboard', <DesktopOutlined />, [
    getItem('2-2', null, '/alertstatistics', null),
    getItem('2-1', null, '/spvlist', null),
    getItem('2-3', null, '/cidamountanalysis', null),
    getItem('2-4', null, '/omamountanalysis', null),
  ]),
  getItem('9', t('breadcrumb.report'), '/report', <FileTextOutlined />, [
    getItem('9-1', null, '/tpcweeklysheet', null),
    getItem('9-2', null, '/tpcrecordsheet', null),
    getItem('9-3', null, '/simplemonthly', null),
    getItem('9-4', null, '/nerbyobjectcompare', null),
    getItem('9-5', null, '/cidtimemonitor', null),
    getItem('9-6', null, '/newsolarplantreview', null),
    getItem('9-7', null, '/cumulativepowergeneration', null),
    getItem('9-8', null, '/dailypowergenerationsummary', null),
    getItem('9-9', null, '/inverterrepaircount', null),
    getItem('9-10', null, '/generationsunlightrelate', null),
    getItem('9-11', null, '/monthlymaintainfreqhour', null),
    getItem('9-12', null, '/yearlyvirutalsunlightanalysis', null),
    getItem('9-13', null, '/virsunlightexpectgenerationcompare', null),
    getItem('9-14', null, '/yearlysolarplantpr', null),
    getItem('9-15', null, '/solarplantavailability', null),
    getItem('9-16', null, '/invertermaxpower', null),
  ]),
  getItem('7', null, '/boosterStations', <ThunderboltOutlined />),
  getItem('3', null, '/pvs', <FundOutlined />),
  getItem('4', null, '/alerts', <WarningOutlined />),
  getItem('5', t('breadcrumb.eventManagement'), '/events', <FileSearchOutlined />, [
    getItem('5-1', null, '/cids', null),
    getItem('5-2', t('breadcrumb.omManagement'), '/events/om', null, [
      getItem('5-2-1', null, '/omtodo', null),
      getItem('5-2-2', null, '/omcalendar', null),
      getItem('5-2-3', null, '/oms', null),
      getItem('5-2-4', null, '/omrecord', null),
      getItem('5-2-5', null, '/omworks', null),
      getItem('5-2-6', null, '/ommorning', null),
    ]),
  ]),
  getItem('8', null, '/spvDashboard', <DashboardOutlined />),
  getItem('6', t('breadcrumb.systemSettings'), '/system', <ReconciliationOutlined />, [
    getItem('6-1', t('breadcrumb.menuManagement'), '/system/configs', null, [
      getItem('6-1-1', null, '/cidconfig', null),
      getItem('6-1-2', null, '/omconfig', null),
    ]),
  ]),
];
