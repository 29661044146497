import React, { useCallback, useEffect, useState, useMemo, useContext } from 'react';
import axios from 'axios';
//import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  Typography,
  Button,
  Row,
  Col,
  Spin,
  message,
  Table,
  Divider,
  PageHeader,
  Card,
  Collapse,
  Dropdown,
  Checkbox,
} from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import RfdmeSelect from '../../components/Select';
import RfdmeInput from '../../components/Input';
import RfdmeDatePicker from '../../components/DatePicker';
import RfdmeModal from '../../components/Modal';
import RfdmeUpload from '../../components/Upload';
//
import useListOfOmSource from '../../hooks/useListOfOmSource';
import useListOfOmUser from '../../hooks/useListOfOmUser';
import useListOfOmStatus from '../../hooks/useListOfOmStatus';
import useListOfOmAction from '../../hooks/useListOfOmAction';
import useListOfOmExtraPv from '../../hooks/useListOfOmExtraPv';
import useListOfCidLevel from '../../hooks/useListOfCidLevel';
import useListOfCidStatus from '../../hooks/useListOfCidStatus';
import useListOfIntegratedDateType from '../../hooks/useListOfIntegratedDateType';
import useListOfPvOwner from '../../hooks/useListOfPvOwner';
import useListOfOmWorkHistoryStatus from '../../hooks/useListOfOmWorkHistoryStatus';
import useListOfCidProblemCategory from '../../hooks/useListOfCidProblemCategory';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { SettingContext } from '../../contexts/settingsProvider';
import { AppSyncQueryTextFormat, SetKeyToArray } from '../../utils/format';
import { IsValidAppSyncRsps, IsValidApiGatewayRsps } from '../../utils/valid';
import { GetFileNameFromResponseHeader, GetAppSyncRspsErrorMessage } from '../../utils/parse';
import { QueryOmContractLevelList } from '../../utils/dataHelpers';
import {
  INITIAL_FILTER_DATA,
  GetDataOfFilterFromSession,
  SetDataOfFilterToSession,
  SetDefaultDataOfFilterToSession,
} from './data';
//
import { useTranslation } from 'react-i18next';

const { Title } = Typography;
const HintTextLabel = styled.span`
  font-size: 12px;
  color: #ccc;
`;

//
const { Panel } = Collapse;
const INIT_TABLE_INFO = { sortColumn: null, sortDirection: 'ascend', page: 1, pageSize: 20 };
const GenerateQueryConditions = (tableInfo) => {
  const conditions = GetDataOfFilterFromSession();

  if (conditions.startDate) conditions.startDate = conditions.startDate.format('YYYY-MM-DD');
  if (conditions.endDate) conditions.endDate = conditions.endDate.format('YYYY-MM-DD');

  //加入 tableInfo
  if (tableInfo) {
    conditions.page = tableInfo.page;
    conditions.pageSize = tableInfo.pageSize;
    conditions.sortColumnName = tableInfo.sortColumn;
    conditions.sortDirection = tableInfo.sortDirection === 'ascend' ? 'asc' : 'desc';
  }

  return conditions;
};

//主要元件
const OmList = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { dark } = useContext(SettingContext);
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [tableInfo, setTableInfo] = useState(INIT_TABLE_INFO);
  const [recordOfOmForm, setRecordOfOmForm] = useState([]);
  const [collaps, setCollaps] = useState(true);
  const [isShowLogBook, setIsShowLogBook] = useState(false);
  const [isShowAttachment, setIsShowAttachment] = useState(false);
  const [open, setOpen] = useState(false);
  //
  const [recordOfTableSelectedRowKeys, setRecordOfTableSelectedRowKeys] = useState([]);
  const [morningReportModalOpen, setMorningReportModalOpen] = useState(false);
  const [morningReportDate, setMorningReportDate] = useState(null);
  const [morningReportExporting, setMorningReportExporting] = useState(false);
  const [listOfOmMorningReportDate, setListOfOmMorningReportDate] = useState([]);
  //
  const [dataOfFilter, setDataOfFilter] = useState({ ...INITIAL_FILTER_DATA });
  //
  const { data: listOfOmSource } = useListOfOmSource();
  const { data: listOfOmExtraPv } = useListOfOmExtraPv();
  const { data: listOfOmUser } = useListOfOmUser();
  const { data: listOfOmStatus } = useListOfOmStatus();
  const { data: listOfOmAction } = useListOfOmAction();
  const { data: listOfDefaultCidLevel } = useListOfCidLevel();
  const { data: listOfCidStatus } = useListOfCidStatus();
  const { data: listOfDateType } = useListOfIntegratedDateType();
  const { data: listOfPvOwner } = useListOfPvOwner();
  const { data: listOfOmWorkHistoryStatus } = useListOfOmWorkHistoryStatus();
  const { data: listOfCidProblemCategory } = useListOfCidProblemCategory();
  const [listOfCidLevel, setListOfCidLevel] = useState([]);

  //
  const listOfPvByOwner = useMemo(() => {
    if (!Array.isArray(listOfOmExtraPv) || listOfOmExtraPv.length === 0) return [];
    if (
      Array.isArray(listOfOmExtraPv) &&
      (!Array.isArray(dataOfFilter.ownerIDs) || dataOfFilter.ownerIDs.length === 0)
    ) {
      return listOfOmExtraPv;
    }

    const items = listOfOmExtraPv.filter((f) => dataOfFilter.ownerIDs.includes(f.ownerID));
    if (items.length === 0) return [];

    return items.map(SetKeyToArray);
  }, [dataOfFilter.ownerIDs, listOfOmExtraPv]);

  // 隨 SPV 與 案場選擇來變更重要性
  useEffect(() => {
    async function fetchData() {
      let items = [];
      const queryCompanyIDs =
        !Array.isArray(dataOfFilter?.ownerIDs) || dataOfFilter.ownerIDs.length < 1
          ? []
          : dataOfFilter.ownerIDs;
      const queryObjectIDs =
        !Array.isArray(dataOfFilter?.objectIDs) || dataOfFilter.objectIDs.length < 1
          ? []
          : dataOfFilter.objectIDs;

      const getOmContractLevelList = await QueryOmContractLevelList(user, queryCompanyIDs, queryObjectIDs);

      if (queryCompanyIDs.length > 0 || queryObjectIDs.length > 0) {
        if (getOmContractLevelList.length > 0) {
          items = [...getOmContractLevelList.sort((a, b) => a.key - b.key)];
        } else {
          items = [...listOfDefaultCidLevel];
        }
      } else {
        items = [...getOmContractLevelList.sort((a, b) => a.key - b.key)];
        listOfDefaultCidLevel.forEach((defaultItem) => {
          const exists = getOmContractLevelList.some((item) => item.label === defaultItem.label);

          if (!exists) {
            // 如果不存在，則將 defaultItem 加入到 getOmContractLevelList
            items.push(defaultItem);
          }
        });
      }

      items = items.map((m) => ({
        label: m.label,
        value: m.label,
      }));

      setListOfCidLevel(items.map(SetKeyToArray));
      setDataOfFilter((currentData) => {
        return { ...currentData, cidLevelNames: [] };
      });
    }
    fetchData();
  }, [user, listOfDefaultCidLevel, dataOfFilter.ownerIDs, dataOfFilter.objectIDs]);

  //
  const selectedRowKeys = useMemo(() => {
    const result = [];

    // recordOfTableSelectedRowKeys 的結構為 [[],[],[],...]
    recordOfTableSelectedRowKeys.forEach((innerArrayItem) => {
      if (Array.isArray(innerArrayItem)) {
        result.push(...innerArrayItem);
      }
    });

    return result;
  }, [recordOfTableSelectedRowKeys]);
  const handleTableRowSelectChange = useCallback(
    (rowKeys, rows) => {
      const tmpRowKeys = [...recordOfTableSelectedRowKeys];
      tmpRowKeys[tableInfo.page - 1] = rowKeys;
      setRecordOfTableSelectedRowKeys(tmpRowKeys);
    },
    [tableInfo.page, recordOfTableSelectedRowKeys]
  );
  const rowSelectionProps = useMemo(() => {
    if (!user.operations.includes('OMS_export-om-form-to-morning-report')) return null;

    return {
      selectedRowKeys: selectedRowKeys,
      onChange: handleTableRowSelectChange,
    };
  }, [user.operations, selectedRowKeys, handleTableRowSelectChange]);

  const dropdownItems = useMemo(
    () => [
      {
        label: (
          <Checkbox
            checked={isShowLogBook}
            onChange={() => {
              setIsShowLogBook((value) => !value);
            }}
          >
            {t('omList.logbookField')}
          </Checkbox>
        ),
        key: 'logbook',
      },
      {
        label: (
          <Checkbox
            checked={isShowAttachment}
            onChange={() => {
              setIsShowAttachment((value) => !value);
            }}
          >
            {t('omList.attachmentField')}
          </Checkbox>
        ),
        key: 'attachment',
      },
    ],
    [isShowLogBook, isShowAttachment, t]
  );
  const handleOpenChange = (flag) => {
    setOpen(flag);
  };
  // =====================
  useEffect(() => {
    const data = GetDataOfFilterFromSession();
    if (data) {
      setDataOfFilter(data);
    } else {
      SetDefaultDataOfFilterToSession();
    }

    setIsFirstLoad(false);
  }, [isFirstLoad]);

  //
  useEffect(() => {
    if (!user.operations.includes('OMS_export-om-form-to-morning-report')) return;

    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: `query OmQuery { om_getMorningReportDateList }`,
          variables: null,
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('omList.failedToGetReportDateList', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        const tmp = res.data.data.om_getMorningReportDateList;
        setListOfOmMorningReportDate(tmp.map((m) => ({ label: m, value: m })));
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omList.getReportDateListError'));
          console.error('load om morning report date error:', err);
        }
      });
  }, [user, t]);

  // =====================
  const handleSetAsDefault = () => {
    SetDefaultDataOfFilterToSession();
    setDataOfFilter({ ...INITIAL_FILTER_DATA });
  };
  const handleSearch = () => {
    if ((dataOfFilter.startDate || dataOfFilter.endDate) && !dataOfFilter.dateType) {
      message.warn(t('omList.selectDateTypeFirst'));
      return;
    }

    SetDataOfFilterToSession(dataOfFilter);
    setTableInfo((tbi) => ({ ...tbi, page: 1 }));
  };
  const handleQuery = useCallback(() => {
    //
    const queryConditions = GenerateQueryConditions(tableInfo);

    //查詢
    setLoading(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery(
                $omFormID: String,
                $omSourceIDs: [Int],
                $omUserInChargeIDs: [String],
                $omStatusIDs: [Int],
                $omActionIDs: [Int],
                $objectIDs: [String],
                $cidFormID: String,
                $cidLevelNames: [String],
                $cidStatusIDs: [Int], 
                $dateType: Int,
                $startDate: String,
                $endDate: String,
                $omWorkHistoryStatus: Boolean,
                $ownerIDs: [String],
                $timezone: String!,
                $page: Int!,
                $pageSize: Int!,
                $sortColumnName: String,
                $sortDirection: String,
                $cidProblemCategoryIDs: [Int]
              ) { 
                om_getOmFormList(
                  omFormID: $omFormID,
                  omSourceIDs: $omSourceIDs,
                  omUserInChargeIDs: $omUserInChargeIDs,
                  omStatusIDs: $omStatusIDs,
                  omActionIDs: $omActionIDs,
                  objectIDs: $objectIDs,
                  cidFormID: $cidFormID,
                  cidLevelNames: $cidLevelNames,
                  cidStatusIDs: $cidStatusIDs,
                  dateType: $dateType,
                  startDate: $startDate,
                  endDate: $endDate,
                  omWorkHistoryStatus: $omWorkHistoryStatus,
                  ownerIDs: $ownerIDs,
                  timezone: $timezone,
                  page: $page,
                  pageSize: $pageSize,
                  sortColumnName: $sortColumnName,
                  sortDirection: $sortDirection,
                  cidProblemCategoryIDs: $cidProblemCategoryIDs
                ) {
                  total
                  list {
                    omFormID
                    omStatus
                    omStatusName
                    omStartTime
                    omEndTime
                    cidFormID
                    cidLevelName
                    omSourceName
                    omObjectName
                    omObjectRegion
                    omUserInChargeName
                    omActionName 
                    omProcessingItem
                    omWorkContent
                    omRecommend
                    omComponents { componentName amount }
                    omNextFormID
                    omOwnerName
                    omLogbook
                    omFileList{
                      fileID
                      fileName
                    }                           
                  }
                }
              }
            `
          ),
          variables: queryConditions,
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('omList.failedToQueryList', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        const tmp = res.data.data.om_getOmFormList;
        tmp.list = tmp.list.map((m, mIdx) => {
          m.key = m.omFormID;
          return m;
        });
        setRecordOfOmForm(tmp);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omList.queryListError'));
          console.error('load om form list error: ', err);
        }
      })
      .then(() => {
        setLoading(false);
      });

    //
  }, [user, tableInfo, t]);
  useEffect(() => {
    if (!isFirstLoad) handleQuery();
  }, [isFirstLoad, handleQuery]);

  // =====================
  const handleDataOfFilterChange = (name) => (e) => {
    const modifyValue = e && e.target ? e.target.value : e;
    const tmp = { ...dataOfFilter };
    tmp[name] = modifyValue;

    if (name === 'dateType' && !modifyValue) {
      tmp.startDate = null;
      tmp.endDate = null;
    }

    setDataOfFilter(tmp);
  };

  // =====================
  const handleTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'paginate') {
      setTableInfo((tbi) => ({
        ...tbi,
        page: pagination.current,
      }));
    }

    if (extra.action === 'sort') {
      if (sorter.field === 'omObjectRegion') {
        sorter.field = 'omObjectRegionSeq';
      }
      setTableInfo((tbi) => ({
        ...tbi,
        sortColumn: sorter.field,
        sortDirection: sorter.order, //"ascend" or "descend"
      }));
    }
  };
  const handleExport = () => {
    //
    const queryConditions = GenerateQueryConditions(tableInfo);

    setExporting(true);
    axios
      .post(
        `${appConfigs.apiGatewayURL}/oms/om/export`,
        { ...queryConditions },
        { headers: { Authorization: user.token, Accept: '*/*' }, responseType: 'blob' }
      )
      .then((res) => {
        if (!res || !res.data) {
          message.error(t('omList.exportListFailed', { msg: res?.data?.msg }));
          return;
        }

        const fileName = GetFileNameFromResponseHeader(res.headers['content-disposition'], 'OM_list.xlsx');
        const blobFile = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = blobFile;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(blobFile);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omList.exportListError'));
          console.error('export OM form list error: ', err);
        }
      })
      .then(() => {
        setExporting(false);
      });
  };

  // =====================
  const handleMorningReportModalOpen = () => {
    if (!Array.isArray(selectedRowKeys) || selectedRowKeys.length === 0) {
      message.warn(t('omList.selectOmFormFirst'));
      return;
    }

    setMorningReportModalOpen(true);
  };
  const handleMorningReportModalClose = () => {
    setMorningReportModalOpen(false);
  };
  const handleMorningReportDateChange = (value) => {
    setMorningReportDate(value);
  };
  const handleExportToMorningReport = () => {
    if (!morningReportDate) {
      message.warn(t('omList.selectReportDateFirst'));
      return;
    }

    setMorningReportExporting(true);
    axios
      .post(
        `${appConfigs.apiGatewayURL}/oms/om/report/morning/export`,
        { reportDate: morningReportDate, omFormIDs: selectedRowKeys },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('omList.failedToExportToMorningReport', { error: res?.data?.msg }));
          return;
        }

        message.success(t('omList.exportToMorningReportSuccess'));
        setRecordOfTableSelectedRowKeys([]);
        setMorningReportDate(null);
        setMorningReportModalOpen(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omList.exportToMorningReportError'));
          console.error('export OM forms to morning report error: ', err);
        }
      })
      .then(() => {
        setMorningReportExporting(false);
      });
  };

  // =====================
  const columns = useMemo(() => {
    const baseCols = [
      {
        title: t('omList.columns.omNumber'),
        dataIndex: 'omFormID',
        key: 'omFormID',
        width: 90,
        sorter: true,
        showSorterTooltip: false,
        render: (value, record) => (
          <>
            <div>
              <Link to={`/oms/${value}`} target="_blank">
                {value}
              </Link>
            </div>
            <HintTextLabel>{record.omNextFormID}</HintTextLabel>
          </>
        ),
      },
      {
        title: t('omList.columns.omStatus'),
        dataIndex: 'omStatusName',
        key: 'omStatusName',
        width: 120,
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('omList.columns.dispatchTime'),
        dataIndex: 'omStartTime',
        key: 'omStartTime',
        width: 150,
        sorter: true,
        showSorterTooltip: false,
        render: (value, record) => (
          <div>
            <div>{record.omStartTime}</div>
            <div>{record.omEndTime}</div>
          </div>
        ),
      },
      {
        title: 'SPV',
        dataIndex: 'omOwnerName',
        key: 'omOwnerName',
        width: 85,
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('omList.columns.importance'),
        dataIndex: 'cidLevelName',
        key: 'cidLevelName',
        width: 85,
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('omList.columns.source'),
        dataIndex: 'omSourceName',
        key: 'omSourceName',
        width: 85,
        sorter: true,
        showSorterTooltip: false,
        render: (value, record) =>
          record.cidFormID ? (
            <div>
              <Link to={`/cids/${record.cidFormID}`} target="_blank">
                {record.cidFormID}
              </Link>
            </div>
          ) : (
            value
          ),
      },
      {
        title: t('omList.columns.relatedSite'),
        dataIndex: 'omObjectName',
        key: 'omObjectName',
        width: 180,
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('omList.columns.region'),
        dataIndex: 'omObjectRegion',
        key: 'omObjectRegion',
        width: 60,
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('omList.columns.mainHandler'),
        dataIndex: 'omUserInChargeName',
        key: 'omUserInChargeName',
        width: 105,
        render: (value) => (Array.isArray(value) ? value.join(', ') : null),
      },
      {
        title: t('omList.columns.action'),
        dataIndex: 'omActionName',
        key: 'omActionName',
        width: 100,
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('omList.columns.processingItem'),
        dataIndex: 'omProcessingItem',
        key: 'omProcessingItem',
        width: 300,
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('omList.columns.workSummary'),
        dataIndex: 'omWorkContent',
        key: 'omWorkContent',
        sorter: true,
        showSorterTooltip: false,
        render: (value, record) => (
          <>
            <b>{record.omWorkContent}</b>
            <div>
              <HintTextLabel>{t('omList.columns.nextSuggestion')}: </HintTextLabel>
              <span>{record.omRecommend}</span>
            </div>
            <div>
              <HintTextLabel>{t('omList.columns.componentReplacement')}: </HintTextLabel>
              <span>
                {!Array.isArray(record.omComponents) || record.omComponents.length === 0
                  ? t('omList.columns.none')
                  : record.omComponents.map((m) => `${m.componentName} ${m.amount}pcs`).join(', ')}
              </span>
            </div>
          </>
        ),
      },
    ];
    if (isShowLogBook) {
      baseCols.push({ title: 'LogBook', dataIndex: 'omLogbook', key: 'omLogbook' });
    }
    if (isShowAttachment) {
      baseCols.push({
        title: t('omList.columns.attachments'),
        dataIndex: 'omFileList',
        key: 'omFileList',
        render: (value) => <RfdmeUpload value={value} downloadOnly />,
      });
    }
    return baseCols;
  }, [isShowLogBook, isShowAttachment, t]);

  // ------------------------------------

  return (
    <>
      {/* page header */}
      <PageHeader
        className="app-page-header rwd-btn-wrap"
        title={t('omList.title')}
        extra={[
          <div key="button-export-morning">
            {user.operations.includes('OMS_export-om-form-to-morning-report') && (
              <Button icon={<ExportOutlined />} onClick={handleMorningReportModalOpen}>
                {t('omList.exportToMorningReport')}
              </Button>
            )}
          </div>,
          <div key="button-export-result">
            {user.operations.includes('OMS_export-om-form') && (
              <Button type="primary" icon={<ExportOutlined />} loading={exporting} onClick={handleExport}>
                {t('omList.exportQueryResults')}
              </Button>
            )}
          </div>,
          <Dropdown.Button
            menu={{
              items: dropdownItems,
            }}
            onOpenChange={handleOpenChange}
            open={open}
            key="drop-column"
            overlayClassName={dark ? 'dark' : ''}
          >
            {t('omList.column')}
          </Dropdown.Button>,
        ]}
      />
      {/* 搜尋面板 */}
      <div className="app-page-searchpanel">
        <Collapse onChange={() => setCollaps((val) => !val)}>
          <Panel header={t('omList.filterConditions')} key="1">
            <Row gutter={[8, 8]}>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('omList.placeholders.spv')}
                  options={listOfPvOwner}
                  value={dataOfFilter.ownerIDs}
                  onChange={handleDataOfFilterChange('ownerIDs')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  placeholder={t('omList.placeholders.dateType')}
                  options={listOfDateType}
                  value={dataOfFilter.dateType}
                  onChange={handleDataOfFilterChange('dateType')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeDatePicker
                  placeholder={t('omList.placeholders.startDate')}
                  value={dataOfFilter.startDate}
                  onChange={handleDataOfFilterChange('startDate')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeDatePicker
                  placeholder={t('omList.placeholders.endDate')}
                  value={dataOfFilter.endDate}
                  onChange={handleDataOfFilterChange('endDate')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeInput
                  placeholder={t('omList.placeholders.omNumber')}
                  value={dataOfFilter.omFormID}
                  onChange={handleDataOfFilterChange('omFormID')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('omList.placeholders.omSource')}
                  options={listOfOmSource}
                  value={dataOfFilter.omSourceIDs}
                  onChange={handleDataOfFilterChange('omSourceIDs')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('omList.placeholders.omAction')}
                  options={listOfOmAction}
                  value={dataOfFilter.omActionIDs}
                  onChange={handleDataOfFilterChange('omActionIDs')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('omList.placeholders.omStatus')}
                  options={listOfOmStatus}
                  value={dataOfFilter.omStatusIDs}
                  onChange={handleDataOfFilterChange('omStatusIDs')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('omList.placeholders.relatedSite')}
                  options={listOfPvByOwner}
                  value={dataOfFilter.objectIDs}
                  onChange={handleDataOfFilterChange('objectIDs')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('omList.placeholders.omUser')}
                  options={listOfOmUser}
                  value={dataOfFilter.omUserInChargeIDs}
                  onChange={handleDataOfFilterChange('omUserInChargeIDs')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeInput
                  placeholder={t('omList.placeholders.cidFormID')}
                  value={dataOfFilter.cidFormID}
                  onChange={handleDataOfFilterChange('cidFormID')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('omList.placeholders.cidLevel')}
                  options={listOfCidLevel}
                  value={dataOfFilter.cidLevelNames}
                  onChange={handleDataOfFilterChange('cidLevelNames')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('omList.placeholders.cidStatus')}
                  options={listOfCidStatus}
                  value={dataOfFilter.cidStatusIDs}
                  onChange={handleDataOfFilterChange('cidStatusIDs')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  placeholder={t('omList.placeholders.omWorkHistoryStatus')}
                  options={listOfOmWorkHistoryStatus}
                  value={dataOfFilter.omWorkHistoryStatus}
                  onChange={handleDataOfFilterChange('omWorkHistoryStatus')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('omList.placeholders.cidProblemCategory')}
                  options={listOfCidProblemCategory}
                  value={dataOfFilter.cidProblemCategoryIDs}
                  onChange={handleDataOfFilterChange('cidProblemCategoryIDs')}
                />
              </Col>
              <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                <Button block loading={loading} onClick={handleSetAsDefault}>
                  {t('omList.reset')}
                </Button>
              </Col>
              <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                <Button type="primary" block loading={loading} onClick={handleSearch}>
                  {t('omList.search')}
                </Button>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </div>
      {/* 列表 */}
      <div className="app-page-content">
        <Card bordered={false} size="small">
          <Spin spinning={loading} tip={t('omList.searching')}>
            <Table
              size="small"
              className="collapsble"
              columns={columns}
              dataSource={recordOfOmForm.list}
              scroll={{ x: 1920, y: collaps ? `calc(100vh - 400px)` : `calc(100vh - 585px)` }}
              rowSelection={rowSelectionProps}
              pagination={{
                total: recordOfOmForm.total,
                pageSize: tableInfo.pageSize,
                showSizeChanger: false,
                current: tableInfo.page,
              }}
              onChange={handleTableChange}
            />
          </Spin>
        </Card>
      </div>
      <RfdmeModal width="350px" open={morningReportModalOpen}>
        <Title level={5}>{t('omList.addMorningReport')}</Title>
        <RfdmeSelect
          options={listOfOmMorningReportDate}
          value={morningReportDate}
          onChange={handleMorningReportDateChange}
        />
        <Divider />
        <Row gutter={[16, 0]} justify="end">
          <Col>
            <Button onClick={handleMorningReportModalClose}>{t('omList.close')}</Button>
          </Col>
          <Col>
            <Button type="primary" loading={morningReportExporting} onClick={handleExportToMorningReport}>
              {t('omList.export')}
            </Button>
          </Col>
        </Row>
      </RfdmeModal>
    </>
  );
};

export default OmList;
