import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import axios from 'axios';
import { message, Col, Row, Spin, Button, Typography, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  ContainerOutlined,
  RiseOutlined,
  ThunderboltOutlined,
  LineChartOutlined,
  FieldTimeOutlined,
} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { IsValidAppSyncRsps } from '../../utils/valid';
import { GetAppSyncRspsErrorMessage } from '../../utils/parse';
import { AppSyncQueryTextFormat, SetKeyToArray } from '../../utils/format';
import {
  RedoOutlined,
  FullscreenOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
  CaretRightOutlined,
  PauseOutlined,
  StopOutlined,
} from '@ant-design/icons';
import useListOfPv from '../../hooks/useListOfPv';
import _ from 'lodash';
import RfdmeCard from './card';
import InfoCard from './infoCard';
import StatusCard from './statusCard';
import ChartCard from './chartCard';
import PieChartCard from './pieChartCard';
import SettingsModal from './settingsModal';
import temperature_icon from '../../images/temperature-icon.svg';
import sun_icon from '../../images/sun-icon.svg';
import cloud_icon from '../../images/cloud-rain-icon.svg';
import wind_icon from '../../images/wind-icon.svg';

const { Title } = Typography;

const SpvDashboard = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [dashboardData, setDashboardData] = useState({});
  const [maxPowerData, setMaxPowerData] = useState({});
  const [cidUnFinishData, setCidUnFinishData] = useState([]);
  const [hourlyPowerData, setHourlyPowerData] = useState([]);
  const [filterData, setFilterData] = useState({ objectID: [] });
  const [defaultPv, setDefaultPv] = useState(null); // 新增 state 存儲預設案場
  const { data: spvPvList } = useListOfPv(true);
  const [objectId, setObjectId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alertList, setAlertList] = useState([]);
  const [dataList, setDataList] = useState({ total: 0, list: [] });
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const location = useLocation();
  const [isCarouselActive, setIsCarouselActive] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [carouselInterval, setCarouselInterval] = useState(15000);
  const [isPaused, setIsPaused] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);

  // 限定的太陽能案場清單
  const solarPlantList = useMemo(() => {
    if (!Array.isArray(spvPvList)) return [];
    return spvPvList.filter(
      (f) =>
        f.objectType === 'SolarPlant' &&
        f.state !== 'C' &&
        (!f.majorFlag || (f.majorFlag === true && f.projectID !== f.value))
    );
  }, [spvPvList]);

  function formatValue(value) {
    if (value && value.toString().includes('.')) {
      const decimalPart = value.toString().split('.')[1];
      if (decimalPart.length > 2) {
        return parseFloat(value).toFixed(2);
      }
    }
    return value;
  }

  const handleFullscreen = () => {
    const elem = document.documentElement;
    if (!document.fullscreenElement) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        // Firefox
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        // IE/Edge
        elem.msRequestFullscreen();
      }
      setFullscreen(true);
    } else {
      handleExitFullscreen();
    }
  };

  const handleExitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      // Firefox
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      // Chrome, Safari and Opera
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      // IE/Edge
      document.msExitFullscreen();
    }
    setFullscreen(false);
  };

  //監聽ESC按鍵來取消全螢幕
  useEffect(() => {
    const handleFullscreenChange = () => {
      setFullscreen(!!document.fullscreenElement);
    };

    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && document.fullscreenElement) {
        handleExitFullscreen();
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const pvList = useMemo(() => {
    if (!Array.isArray(solarPlantList) || solarPlantList.length === 0) return [];

    let ownerID, electricityPeriod;
    if (filterData.ownerID) {
      const splitValues = filterData.ownerID.split('_');
      ownerID = splitValues[0];
      electricityPeriod = splitValues.length > 1 ? splitValues[1] : null;
    }

    let result = _.cloneDeep(solarPlantList);
    if (ownerID && electricityPeriod) {
      result = result.filter(
        (f) =>
          f.ownerID === ownerID &&
          f.electricityPeriod === (electricityPeriod === 'N' ? null : electricityPeriod)
      );
    } else if (ownerID) {
      result = result.filter((f) => f.ownerID === ownerID);
    }

    return result.map((m) => ({ label: m.label, value: m.value }));
  }, [solarPlantList, filterData.ownerID]);

  const handleQuery = useCallback(
    (objectID) => {
      if (!user) return;
      //
      const isInvestorView = user.operations.includes('OBJECTS_investor-view');
      const queryConditions = GenerateQueryConditions(isInvestorView ? objectID : [objectID]);
      const sqlText = BuildSqlQueryText(isInvestorView);
      setLoading(true);
      axios
        .post(
          appConfigs.appSyncURL,
          {
            query: sqlText,
            variables: { ...queryConditions },
          },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (!IsValidAppSyncRsps(res)) {
            message.warn(`載入案場列表清單失敗: ${GetAppSyncRspsErrorMessage(res)}`);
            return;
          }
          const tmp = isInvestorView
            ? res.data.data.om_getAllObjectDataListByInvestor
            : res.data.data.om_getAllObjectDataList;
          tmp.list = tmp.list.map(SetKeyToArray);
          setDataList(tmp);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            console.error('load all object list error', err);
            message.error('載入案場列表清單錯誤');
          }
        })
        .then(() => {
          setLoading(false);
        });

      //
    },
    [user]
  );

  const GenerateQueryConditions = (objectID, listType = 'list') => {
    let queryConditions = {};
    queryConditions.objectID = objectID;
    queryConditions.timezone = appConfigs.timezone;
    queryConditions.listType = listType;
    queryConditions.page = 1;
    queryConditions.pageSize = 20;
    queryConditions.sortColumnName = null;
    queryConditions.sortDirection = 'asc';
    queryConditions.ownerID = null;

    return queryConditions;
  };

  const BuildSqlQueryText = (investorView) => {
    let sqlText = `query OmQuery(
      $objectID: [String],
      $ownerID: [String],
      $region: String,
      $timezone: String!,
      $page: Int!,
      $pageSize: Int!,
      $sortColumnName: String,
      $sortDirection: String,
      $listType: String!
      ) { 
        om_getAllObjectDataList(
          objectID: $objectID,
          ownerID: $ownerID,
          region: $region,
          timezone: $timezone,
          page: $page,
          pageSize: $pageSize,
          sortColumnName: $sortColumnName,
          sortDirection: $sortDirection,
          listType: $listType
        ) {
          total
          list { 
            ownerObjectID
            objectID
            objectName
            region
            capacity
            companyID
            companyName
            totalEnergy
            lossEnergy
            alertExpired
            todoAlertAmount
            todoCidAmount
            todoOmAmount
            controllerWarning 
            inverterWarning
            healthWarning  
            moduleWashWarning
            moduleWashNoCidFlag 
            cumulativePR
            guaranteeAchieveRate
            capacityActivationRate
            capacityHealthRate
          }
        }
      }
    `;
    if (investorView) {
      sqlText = `query OmQuery(
        $objectID: String,
        $ownerID: String,
        $electricityPeriod: String,
        $timezone: String!,
        $page: Int!,
        $pageSize: Int!
        ) { 
          om_getAllObjectDataListByInvestor(
            objectID: $objectID,
            ownerID: $ownerID,
            electricityPeriod: $electricityPeriod,
            timezone: $timezone,
            page: $page,
            pageSize: $pageSize
          ) {
              total
              list { 
                ownerObjectID
                objectID
                objectName
                companyID
                companyName
                electricityPeriod
                cumulativePR
                majorFlag
              }
            }
          }
      `;
    }

    return AppSyncQueryTextFormat(sqlText);
  };

  useEffect(() => {
    if (!isFirstLoad) return;

    const savedSettings = JSON.parse(localStorage.getItem('dashboardSettings'));
    if (savedSettings && savedSettings.selectedPvs) {
      setFilterData((prev) => ({ ...prev, objectID: savedSettings.selectedPvs }));
    }
    if (savedSettings && savedSettings.selectedDefaultPv) {
      setDefaultPv(savedSettings.selectedDefaultPv);
    }

    setIsFirstLoad(false);
  }, [isFirstLoad, location]);

  const fetchData = useCallback(
    async (objectID) => {
      const source = axios.CancelToken.source();
      try {
        setLoading(true);

        const dashboardRes = await axios.post(
          appConfigs.appSyncURL,
          {
            query: AppSyncQueryTextFormat(
              `query OmQuery {
              om_getObjectSpvDashboardInfo(objectID: "${objectID}") {
                time
                temperature
                windSpeed
                precipitation
                globalSolarRadiation
                weatherStationPR
                virtualPR
                capacity
                powerSum
                effectInsolationHour
              }
            }`
            ),
            variables: null,
            cancelToken: source.token,
          },
          { headers: { Authorization: user.token } }
        );

        if (!IsValidAppSyncRsps(dashboardRes)) {
          message.error(`載入 SPV 列表氣象資料失敗: ${GetAppSyncRspsErrorMessage(dashboardRes)}`);
          return;
        }

        setDashboardData(dashboardRes.data.data.om_getObjectSpvDashboardInfo || {});

        const maxPowerRes = await axios.post(
          appConfigs.appSyncURL,
          {
            query: AppSyncQueryTextFormat(
              `query OmQuery {
              om_getObjectSpvDashboardMaxPowerTime(objectID: "${objectID}") {
                time
                powerPerUT
              }
            }`
            ),
            variables: null,
            cancelToken: source.token,
          },
          { headers: { Authorization: user.token } }
        );

        if (!IsValidAppSyncRsps(maxPowerRes)) {
          message.error(`載入 SPV 案場高峰發電量與時間資料失敗: ${GetAppSyncRspsErrorMessage(maxPowerRes)}`);
          return;
        }

        setMaxPowerData(maxPowerRes.data.data.om_getObjectSpvDashboardMaxPowerTime || {});

        const cidUnFinishRes = await axios.post(
          appConfigs.appSyncURL,
          {
            query: AppSyncQueryTextFormat(
              `query OmQuery {
              om_getObjectSpvDashboardCidUnFinishNumber(objectID: "${objectID}") {
                level
                amount
              }
            }`
            ),
            variables: null,
            cancelToken: source.token,
          },
          { headers: { Authorization: user.token } }
        );

        if (!IsValidAppSyncRsps(cidUnFinishRes)) {
          message.error(`載入 SPV 案場未完工資料失敗: ${GetAppSyncRspsErrorMessage(cidUnFinishRes)}`);
          return;
        }

        let tmp = cidUnFinishRes.data.data.om_getObjectSpvDashboardCidUnFinishNumber;
        let transformed = tmp.map((item) => ({
          type: item.level,
          value: item.amount,
        }));
        setCidUnFinishData(transformed || {});

        const hourlyPowerRes = await axios.post(
          appConfigs.appSyncURL,
          {
            query: AppSyncQueryTextFormat(
              `query OmQuery {
              om_getObjectSpvDashboardPowerPerHour(objectID: "${objectID}") {
                time
                dmPowerPerUT
                invPowerPerUT
              }
            }`
            ),
            variables: null,
            cancelToken: source.token,
          },
          { headers: { Authorization: user.token } }
        );

        if (!IsValidAppSyncRsps(hourlyPowerRes)) {
          message.error(`載入 SPV 案場小時發電數據失敗: ${GetAppSyncRspsErrorMessage(hourlyPowerRes)}`);
          return;
        }
        setHourlyPowerData(hourlyPowerRes.data.data.om_getObjectSpvDashboardPowerPerHour || {});

        const alertRes = await axios.post(
          appConfigs.appSyncURL,
          {
            query: AppSyncQueryTextFormat(
              `query OmQuery {
                om_getObjectWarningLightAlertIDList {
                  key
                  alertIDs
                }
              }`
            ),
            variables: null,
            cancelToken: source.token,
          },
          { headers: { Authorization: user.token } }
        );

        if (!IsValidAppSyncRsps(alertRes)) {
          message.warn(`取得案場紅綠燈警告代碼失敗: ${GetAppSyncRspsErrorMessage(alertRes)}`);
          return;
        }
        setAlertList(alertRes.data.data.om_getObjectWarningLightAlertIDList || []);
        handleQuery(objectID);
        setLoading(false);
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error('load spv data error', err);
          message.error('載入 SPV 資料異常');
        }
        setLoading(false);
      }

      return () => {
        source.cancel('Component unmounted');
      };
    },
    [handleQuery, user]
  );

  useEffect(() => {
    if (isCarouselActive && filterData.objectID && filterData.objectID.length > 0) {
      if (Array.isArray(filterData.objectID)) {
        setObjectId(filterData.objectID[0]);
        fetchData(filterData.objectID[0]);
      } else {
        setObjectId(filterData.objectID);
        fetchData(filterData.objectID);
      }

      if (!Array.isArray(filterData.objectID) || filterData.objectID.length === 1) return;
      let currentIndex = 0;
      const intervalId = setInterval(() => {
        if (!isPaused) {
          currentIndex = (currentIndex + 1) % filterData.objectID.length;
          setCurrentIndex(currentIndex);
          setObjectId(filterData.objectID[currentIndex]);
          fetchData(filterData.objectID[currentIndex]);
        }
      }, carouselInterval);

      return () => clearInterval(intervalId);
    }
  }, [user, filterData.objectID, fetchData, isCarouselActive, carouselInterval, isPaused]);

  useEffect(() => {
    if (!isCarouselActive && defaultPv) {
      fetchData(defaultPv);
      setObjectId(defaultPv);
    }
  }, [defaultPv, isCarouselActive, fetchData]);

  const handleStepForward = () => {
    if (Array.isArray(filterData.objectID) && filterData.objectID.length > 1) {
      const nextIndex = (currentIndex + 1) % filterData.objectID.length;
      setCurrentIndex(nextIndex);
      setObjectId(filterData.objectID[nextIndex]);
      fetchData(filterData.objectID[nextIndex]);
    }
  };

  const handleStepBack = () => {
    if (Array.isArray(filterData.objectID) && filterData.objectID.length > 1) {
      const prevIndex = (currentIndex - 1 + filterData.objectID.length) % filterData.objectID.length;
      setCurrentIndex(prevIndex);
      setObjectId(filterData.objectID[prevIndex]);
      fetchData(filterData.objectID[prevIndex]);
    }
  };

  return (
    <div className={fullscreen ? 'full-screen' : ''}>
      <Row gutter={[12, 12]} align="middle" justify="space-between">
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Title level={4} style={{ marginBottom: 0 }}>
            {objectId || '請選擇案場'}
          </Title>
        </Col>
        <Col xs={24} sm={24} md={18} lg={18} xl={18}>
          <div className="app-dashboard-control">
            <Tooltip title="重新整理">
              <Button
                onClick={() => fetchData(objectId)}
                icon={<RedoOutlined />}
                disabled={objectId === null || isCarouselActive}
              />
            </Tooltip>
            <Tooltip title={t('spvDashboard.fullscreen')}>
              <Button icon={<FullscreenOutlined />} onClick={handleFullscreen} />
            </Tooltip>
            <Tooltip title={t('spvDashboard.play')}>
              <Button icon={<CaretRightOutlined />} onClick={() => setIsCarouselActive(true)} />
            </Tooltip>
            <Tooltip title={t('spvDashboard.previous')}>
              <Button
                icon={<StepBackwardOutlined />}
                onClick={handleStepBack}
                disabled={!isCarouselActive || loading}
              />
            </Tooltip>
            <Tooltip title={t('spvDashboard.next')}>
              <Button
                icon={<StepForwardOutlined />}
                onClick={handleStepForward}
                disabled={!isCarouselActive || loading}
              />
            </Tooltip>
            <Tooltip title={t('spvDashboard.pause')}>
              <Button
                icon={<PauseOutlined />}
                onClick={() => setIsPaused(!isPaused)}
                disabled={!isCarouselActive || loading}
              />
            </Tooltip>
            <Tooltip title={t('spvDashboard.stop')}>
              <Button
                icon={<StopOutlined />}
                onClick={() => {
                  setIsCarouselActive(false);
                  setObjectId(defaultPv);
                }}
              />
            </Tooltip>
            <div>
              <SettingsModal
                isCarouselActive={isCarouselActive}
                loading={loading}
                pvList={pvList}
                filterData={filterData}
                handleFilterDataChange={(name) => (value) => setFilterData({ ...filterData, [name]: value })}
                defaultPv={defaultPv}
                setDefaultPv={setDefaultPv}
                carouselInterval={carouselInterval}
                setCarouselInterval={setCarouselInterval}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Spin spinning={loading} className="app-spin" size="large">
        <Row gutter={[12, 12]} style={{ marginTop: 12 }} className="app-dashboard-top">
          <Col xs={24} sm={24} md={24} lg={24} xl={16}>
            <ChartCard data={hourlyPowerData} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            <Row gutter={[12, 12]} style={{ height: 'calc(50% - 6px)' }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <StatusCard
                  icon={temperature_icon}
                  title={t('spvDashboard.realtimeTemperature')}
                  value={formatValue(dashboardData.temperature || 0)}
                  unit="°C"
                  timestamp={dashboardData.time}
                  backgroundColor="rgba(71,219,192,0.1)"
                  color="#229983"
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <StatusCard
                  icon={sun_icon}
                  title={t('spvDashboard.realtimeSunlight')}
                  value={formatValue(dashboardData.globalSolarRadiation * dashboardData.capacity || 0)}
                  unit="KW/㎡"
                  timestamp={dashboardData.time}
                  backgroundColor="rgba(222,89,209,0.1)"
                  color="#C850BC"
                />
              </Col>
            </Row>
            <Row gutter={[12, 12]} style={{ height: 'calc(50% - 6px)', marginTop: 12 }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <StatusCard
                  icon={cloud_icon}
                  title={t('spvDashboard.realtimeRainfall')}
                  value={formatValue(dashboardData.precipitation || 0)}
                  unit="mm"
                  timestamp={dashboardData.time}
                  backgroundColor="rgba(154,71,219,0.1)"
                  color="#9047C9"
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <StatusCard
                  icon={wind_icon}
                  title={t('spvDashboard.realtimeWindSpeed')}
                  value={formatValue(dashboardData.windSpeed || 0)}
                  unit="M/S"
                  timestamp={dashboardData.time}
                  backgroundColor="rgba(225,180,64,0.1)"
                  color="#D0A945"
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={[12, 12]} style={{ marginTop: 12 }} className="app-dashboard-top">
          <Col xs={24} sm={24} md={24} lg={24} xl={16}>
            <Row gutter={[12, 12]} style={{ height: 'calc(50% - 6px)' }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                <InfoCard
                  icon={<ContainerOutlined />}
                  title={t('spvDashboard.installedCapacity')}
                  value={formatValue(dashboardData.capacity || 0)}
                  unit="kWp"
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                <InfoCard
                  icon={<ThunderboltOutlined />}
                  title={t('spvDashboard.todayAccumulatedPower')}
                  value={formatValue(dashboardData.powerSum || 0)}
                  unit="kWh"
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                <InfoCard
                  icon={<FieldTimeOutlined />}
                  title={t('spvDashboard.equivalentSunlightHours')}
                  value={formatValue(dashboardData.effectInsolationHour || 0)}
                  unit={t('spvDashboard.hours')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                <InfoCard
                  icon={<LineChartOutlined />}
                  title={t('spvDashboard.todayPeakPowerAndTime')}
                  value={formatValue(maxPowerData.powerPerUT || 0)}
                  unit="kWh"
                  additionalInfo={`${t('spvDashboard.occurrenceTime')} ${
                    maxPowerData.time || t('spvDashboard.unknown')
                  }`}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                <InfoCard
                  icon={<RiseOutlined />}
                  title={t('spvDashboard.accumulatedPR')}
                  value={formatValue(dashboardData.weatherStationPR || dashboardData.virtualPR || 0)}
                  unit="%"
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                {dataList.list.length > 0 ? (
                  dataList.list.map((item) => (
                    <RfdmeCard key={item.ownerObjectID} item={item} alertList={alertList} alertLink={true} />
                  ))
                ) : (
                  <RfdmeCard key="empty" item={{}} alertList={alertList} alertLink={true} />
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            <PieChartCard data={cidUnFinishData} />
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default SpvDashboard;
