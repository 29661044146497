import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
//
import RfdmeModal from '../../components/Modal';
import { Typography, Row, Col, Button, Table, message, Spin, Divider } from 'antd';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { IsValidAppSyncRsps } from '../../utils/valid';
import { AppSyncQueryTextFormat, SetKeyToArray } from '../../utils/format';
import { GetAppSyncRspsErrorMessage } from '../../utils/parse';

//
const { Title } = Typography;

//
const OmAmountAnalysis = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [listOfAnalysis, setListOfAnalysis] = useState([]);
  const [dataOfDetail, setDataOfDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalProps, setModalProps] = useState({ open: false, selectedIndex: null });

  //
  const ColumnsOfDetailTable = [
    {
      title: t('omAmountAnalysis.omNumber'),
      dataIndex: 'omFormID',
      key: 'omFormID',
      render: (value) => (
        <Link to={`/oms/${value}`} target="_blank">
          {value}
        </Link>
      ),
    },
    {
      title: t('omAmountAnalysis.omStatus'),
      dataIndex: 'omStatusName',
      key: 'omStatusName',
    },
    {
      title: t('omAmountAnalysis.dispatchStartTime'),
      dataIndex: 'omStartTime',
      key: 'omStartTime',
    },
    {
      title: t('omAmountAnalysis.dispatchEndTime'),
      dataIndex: 'omEndTime',
      key: 'omEndTime',
    },
    {
      title: t('omAmountAnalysis.sourceItemOrNumber'),
      dataIndex: 'omSourceName',
      key: 'omSourceName',
      render: (value, record) => {
        if (record.cidFormID) {
          return (
            <Link to={`/cids/${record.cidFormID}`} target="_blank">
              {record.cidFormID}
            </Link>
          );
        } else {
          return value;
        }
      },
    },
    {
      title: t('omAmountAnalysis.relatedSite'),
      dataIndex: 'omObjectName',
      key: 'omObjectName',
    },
    {
      title: t('omAmountAnalysis.mainHandler'),
      dataIndex: 'omUserInChargeName',
      key: 'omUserInChargeName',
      render: (value) => (Array.isArray(value) ? value.join(', ') : ''),
    },
    {
      title: t('omAmountAnalysis.processingItem'),
      dataIndex: 'omProcessingItem',
      key: 'omProcessingItem',
    },
    {
      title: t('omAmountAnalysis.executionResult'),
      dataIndex: 'omExecutionResultName',
      key: 'omExecutionResultName',
    },
  ];

  //===========================
  useEffect(() => {
    setLoading(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery($timezone: String!) {
                om_getOmAmountAnalysis(timezone: $timezone) {
                  cidStatus
                  omStatus
                  data {
                    total
                    list {
                      omFormID
                      omStatusName
                      omStartTime
                      omEndTime
                      omSourceName
                      cidFormID
                      omObjectName
                      omUserInChargeName
                      omActionName
                      omExecutionResultName
                      omProcessingItem
                    }
                  }
                }
              }
            `
          ),
          variables: { timezone: appConfigs.timezone },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(
            t('omAmountAnalysis.failedToQueryAnalysis', { error: GetAppSyncRspsErrorMessage(res) })
          );
          return;
        }

        const tmp = res.data.data.om_getOmAmountAnalysis;

        if (!Array.isArray(tmp)) return;

        const analysisList = [];
        const detailList = {};
        for (let i = 0; i < tmp.length; i++) {
          const tempItem = tmp[i];

          analysisList.push({
            key: i + 1,
            cidStatusName:
              tempItem.cidStatus === 'Y' ? t('omAmountAnalysis.closed') : t('omAmountAnalysis.unclosed'),
            omStatusName:
              tempItem.omStatus === 'Y' ? t('omAmountAnalysis.closed') : t('omAmountAnalysis.unclosed'),
            amount: tempItem.data.total || 0,
          });

          detailList[i + 1] = tempItem.data.list.map(SetKeyToArray);
        }

        setListOfAnalysis(analysisList);
        setDataOfDetail(detailList);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omAmountAnalysis.queryAnalysisError'));
          console.error('load om analysis result error: ', err);
        }
      })
      .then(() => {
        setLoading(false);
      });
  }, [user, t]);

  //===========================
  const handleModalOpen = useCallback(
    (idx) => () => {
      if (!idx) return;
      setModalProps({ open: true, selectedIndex: idx });
    },
    []
  );
  const handleModalClose = () => {
    setModalProps({ open: false, selectedIndex: null });
  };

  //===========================
  const columns = useMemo(() => {
    return [
      {
        title: t('omAmountAnalysis.cidStatus'),
        dataIndex: 'cidStatusName',
        key: 'cidStatusName',
        align: 'center',
      },
      {
        title: t('omAmountAnalysis.omStatus'),
        dataIndex: 'omStatusName',
        key: 'omStatusName',
        align: 'center',
      },
      {
        title: t('omAmountAnalysis.amount'),
        dataIndex: 'amount',
        key: 'amount',
        align: 'center',
        render: (value, record) => (
          <Button type="link" onClick={handleModalOpen(record.key)}>
            {value}
          </Button>
        ),
      },
    ];
  }, [handleModalOpen, t]);

  return (
    <>
      <Title level={4}>{t('omAmountAnalysis.title')}</Title>
      <Spin spinning={loading} tip={t('omAmountAnalysis.searching')}>
        <Table bordered size="small" columns={columns} dataSource={listOfAnalysis} pagination={false} />
      </Spin>
      <RfdmeModal width="80%" open={modalProps.open}>
        <Title level={4}>{t('omAmountAnalysis.statisticsList')}</Title>
        <Table
          bordered
          size="small"
          columns={ColumnsOfDetailTable}
          dataSource={dataOfDetail[modalProps.selectedIndex]}
          pagination={false}
          scroll={{ y: 'calc(100vh - 350px)' }}
        />
        <Divider />
        <Row justify="end">
          <Col>
            <Button onClick={handleModalClose}>{t('omAmountAnalysis.close')}</Button>
          </Col>
        </Row>
      </RfdmeModal>
    </>
  );
};

export default OmAmountAnalysis;
