import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Row, Spin, message, PageHeader, Pagination } from 'antd';
import RfdmeCard from './card';
import { useTranslation } from 'react-i18next';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { IsValidAppSyncRsps } from '../../utils/valid';
import { GetAppSyncRspsErrorMessage } from '../../utils/parse';
import { AppSyncQueryTextFormat } from '../../utils/format';

const INIT_TABLE_INFO = { sortColumn: null, sortDirection: 'ascend', page: 1, pageSize: 20 };

const SpvList = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [dataList, setDataList] = useState({ total: 0, list: [] });
  const [tableInfo, setTableInfo] = useState(INIT_TABLE_INFO);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery {
              om_getSpvMetricsKpiList {
                spvID
                spvName
                electricityPeriod
                majorFlag
                guaranteeStartDate
                guaranteeEndDate
                powerGeneration
                powerIncome
                capacity
                virtualPR
                weatherStationPR
                guaranteeAchieve
                expectedGuaranteeAchieve
              }
             }
            `
          ),
          variables: null,
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.error(`${t('spvList.loadSpvListFailed')}: ${GetAppSyncRspsErrorMessage(res)}`);
          return;
        }
        //
        const data = res?.data?.data?.om_getSpvMetricsKpiList || [];
        setDataList({ total: data.length, list: data });
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error('load spv list error', err);
          message.error(t('spvList.loadSpvListFailed'));
        }
      })
      .then(() => {
        setLoading(false);
      });
  }, [user, t]);

  const handleCardChange = (page) => {
    setTableInfo((tbi) => ({ ...tbi, page }));
  };

  return (
    <>
      {/* page header */}
      <PageHeader className="app-page-header rwd-btn-wrap" title={t('spvList.pageHeaderTitle')} />

      {/* 列表 */}
      <div className="app-page-content">
        <Spin spinning={loading} tip={t('spvList.loadingTip')} style={{ minHeight: 300 }}>
          <Row gutter={[12, 12]}>
            {dataList.list.map((m, mIdx) => (
              <RfdmeCard key={mIdx} data={m} />
            ))}
          </Row>
          <div style={{ textAlign: 'right', margin: '12px 0px' }}>
            <Pagination
              size="small"
              total={dataList.total}
              pageSize={tableInfo.pageSize}
              showSizeChanger={false}
              current={tableInfo.page}
              onChange={handleCardChange}
            />
          </div>
        </Spin>
      </div>
    </>
  );
};

export default SpvList;
