import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import appConfigs from '../configs';
import AuthContext from '../contexts/authProvider';

const useListOfAlertDegree = () => {
  const { user } = useContext(AuthContext);

  return useQuery({
    queryKey: ['query-alert-degree-list'],
    queryFn: () => {
      return axios
        .post(
          appConfigs.sharedAppSyncURL,
          {
            query: `query OmQuery { shared_getAlertDegreeList { degree } }`,
            variables: null,
          },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (res.status === 200 && res.data.data) {
            return res.data.data.shared_getAlertDegreeList.map((m) => ({ label: m.degree, value: m.degree }));
          }
          return [];
        });
    },
    staleTime: 1000 * 60 * 60 * 1, // 1 hours
    cacheTime: 1000 * 60 * 60 * 2, // 2 hours
  });
};

export default useListOfAlertDegree;
