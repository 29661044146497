import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import axios from 'axios';
import bn from 'bignumber.js';
import styled from 'styled-components';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Descriptions, Row, Col, Button, Table, Space, Spin, message, PageHeader, Card } from 'antd';
import { ReloadOutlined, DownloadOutlined } from '@ant-design/icons';
import RfdmeRadio from '../../components/Radio';
import RfdmeSelect from '../../components/Select';
import RfdmeTextArea from '../../components/TextArea';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import useListOfAlertRealComponent from '../../hooks/useListOfAlertRealComponent';
import useListOfAlertMisjudgeType from '../../hooks/useListOfAlertMisjudgeType';
import useListOfAlertRespType from '../../hooks/useListOfAlertRespType';
import { IsValidAppSyncRsps, IsValidApiGatewayRsps, ValidFormData } from '../../utils/valid';
import { AppSyncQueryTextFormat } from '../../utils/format';
import { GetFileNameFromResponseHeader, GetAppSyncRspsErrorMessage } from '../../utils/parse';
import { useTranslation } from 'react-i18next';
//
const BlueSpan = styled.span`
  color: #1388ae;
`;
const BACK_URL = '/alerts';
//設定
const INIT_TABLE_INFO = { sortColumn: null, sortDirection: 'ascend', page: 1, pageSize: 20 };

const GetAlertLogID = (urlPath) => {
  const alertLogId = (urlPath || '').replace('/alerts/', '');
  if (alertLogId) return bn(alertLogId).toNumber();
  return null;
};

//主要元件
const AlertDetail = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const [alertData, setAlertData] = useState({});
  const [defaultMisjudgeData, setDefaultMisjudgeData] = useState({});
  const [signalData, setSignalData] = useState({ total: 0, list: [] });
  const [tableInfo, setTableInfo] = useState(INIT_TABLE_INFO);
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [savingRealComponent, setSavingRealComponent] = useState(false);
  const [savingMisjudge, setSavingMisjudge] = useState(false);
  const [showUpdateComponentRow, setShowUpdateComponentRow] = useState(false);
  const [alertRealComponent, setAlertRealComponent] = useState(null);
  const { data: alertRealComponentList } = useListOfAlertRealComponent();
  const { data: misjudgeTypeList } = useListOfAlertMisjudgeType();
  const { data: respTypeList } = useListOfAlertRespType();
  const navigate = useNavigate();

  const TableColumns = useMemo(
    () => [
      {
        title: 'Signal ID',
        dataIndex: 'signalLogID',
        key: 'signalLogID',
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('alertDetail.colunms.signalDisplayName'),
        dataIndex: 'signalDisplayName',
        key: 'signalDisplayName',
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('alertDetail.colunms.signalType'),
        dataIndex: 'signalType',
        key: 'signalType',
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('alertDetail.colunms.abnormalValue'),
        dataIndex: 'abnormalValue',
        key: 'abnormalValue',
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('alertDetail.colunms.component'),
        dataIndex: 'component',
        key: 'component',
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('alertDetail.colunms.startTime'),
        dataIndex: 'startTime',
        key: 'startTime',
        sorter: true,
        showSorterTooltip: false,
        width: 140,
      },
      {
        title: t('alertDetail.colunms.endTime'),
        dataIndex: 'endTime',
        key: 'endTime',
        sorter: true,
        showSorterTooltip: false,
        width: 140,
      },
    ],
    [t]
  );

  useEffect(() => {
    const alertLogID = GetAlertLogID(location.pathname);
    if (!alertLogID) return;

    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery($alertLogID: Int!, $timezone: String!) { 
              om_getAlertLogContent(alertLogID: $alertLogID, timezone: $timezone) { 
                alertDisplayName
                alertDegree
                cidID
                objectName
                objectID
                ownerName
                avgLossEnergy
                lossEnergy
                days
                alertComponent
                clientDeviceSeq
                brandCompanyName
                misjudge
                misjudgeType
                misjudgeTypeName
                misjudgeReason
                respType
                respTypeName
                cause
                alertName alertOwnerID startTimestamp        
              }
            }`
          ),
          variables: { alertLogID, timezone: appConfigs.timezone },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(
            t('alertDetail.queryAlertLogContentFailed', { error: GetAppSyncRspsErrorMessage(res) })
          );
          return;
        }

        setAlertData(res.data.data.om_getAlertLogContent);
        setDefaultMisjudgeData({
          misjudge: res.data.data.om_getAlertLogContent.misjudge,
          misjudgeType: res.data.data.om_getAlertLogContent.misjudgeType,
          misjudgeReason: res.data.data.om_getAlertLogContent.misjudgeReason,
          respType: res.data.data.om_getAlertLogContent.respType,
          cause: res.data.data.om_getAlertLogContent.cause,
        });
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('alertDetail.queryAlertLogContentError'));
          console.error('AlertDetail query alert log content error', err);
        }
      });
  }, [user, location, t]);

  const handleSignalLogQuery = useCallback(() => {
    const alertLogID = GetAlertLogID(location.pathname);
    if (!alertLogID) return;

    setLoading(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery (
              $alertLogID: Int!, 
              $timezone: String!, 
              $page: Int!, 
              $pageSize: Int!, 
              $sortColumnName: String, 
              $sortDirection: String
            ) { 
              om_getSignalLogListByAlertLogID (
                alertLogID: $alertLogID, 
                timezone: $timezone, 
                page: $page, 
                pageSize: $pageSize, 
                sortColumnName: $sortColumnName, 
                sortDirection: $sortDirection
              ) { 
                total 
                list { 
                  signalLogID 
                  signalDisplayName 
                  signalType 
                  abnormalValue 
                  component 
                  startTime 
                  endTime 
                } 
              } 
            }
            `
          ),
          variables: {
            alertLogID,
            timezone: appConfigs.timezone,
            page: tableInfo.page,
            pageSize: tableInfo.pageSize,
            sortColumnName: tableInfo.sortColumn,
            sortDirection: tableInfo.sortDirection === 'ascend' ? 'asc' : 'desc',
          },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('alertDetail.querySignalLogListFailed', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        const tmp = res.data.data.om_getSignalLogListByAlertLogID;
        tmp.list = tmp.list.map((m, mIdx) => {
          m.key = mIdx;
          return m;
        });
        setSignalData(tmp);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error('AlertDetail query signal log list error', err);
          message.error(t('alertDetail.querySignalLogListError'));
        }
      })
      .then(() => {
        setLoading(false);
      });
  }, [user, location, tableInfo, t]);
  useEffect(() => {
    handleSignalLogQuery();
  }, [handleSignalLogQuery]);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'paginate') {
      setTableInfo((tbi) => ({
        ...tbi,
        page: pagination.current,
      }));
    }

    if (extra.action === 'sort') {
      setTableInfo((tbi) => ({
        ...tbi,
        sortColumn: sorter.field,
        sortDirection: sorter.order, //"ascend" or "descend"
      }));
    }
  };

  const handleExport = () => {
    const alertLogID = GetAlertLogID(location.pathname);
    if (!alertLogID) return;

    setExporting(true);
    axios
      .post(
        `${appConfigs.apiGatewayURL}/oms/signallog/export`,
        { alertLogID, timezone: appConfigs.timezone },
        { headers: { Authorization: user.token, Accept: '*/*' }, responseType: 'blob' }
      )
      .then((res) => {
        if (!res || !res.data) {
          message.error(t('alertDetail.exportFailed', { msg: res?.data?.msg }));
          return;
        }

        const fileName = GetFileNameFromResponseHeader(
          res.headers['content-disposition'],
          t('alertDetail.defaultExportFileName')
        );
        const blobFile = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = blobFile;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(blobFile);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('alertDetail.exportError'));
          console.error('Export error', err);
        }
      })
      .then(() => {
        setExporting(false);
      });
  };

  const handleShowUpdateComponentRow = () => {
    setShowUpdateComponentRow(true);
  };
  const handleHideUpdateComponentRow = () => {
    setAlertRealComponent(null);
    setShowUpdateComponentRow(false);
  };
  const handleAlertRealComponentChange = (value) => {
    setAlertRealComponent(value);
  };
  const handleAlertRealComponentUpdate = () => {
    if (!alertData || !alertData.alertOwnerID || !alertData.alertName || !alertData.startTimestamp) return;

    const saveData = {
      alertOwnerID: alertData.alertOwnerID,
      alertName: alertData.alertName,
      startTimestamp: alertData.startTimestamp,
      realComponent: alertRealComponent,
    };
    //
    setSavingRealComponent(true);
    axios
      .post(`${appConfigs.apiGatewayURL}/oms/alertlog/realcomponent/save`, saveData, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('alertDetail.updateEquipmentLevelFailed', { msg: res?.data?.msg }));
          return;
        }

        handleHideUpdateComponentRow();
        const compItemLabel = alertRealComponentList.find((f) => f.value === alertRealComponent).label;
        setAlertData((prev) => ({ ...prev, alertComponent: compItemLabel }));
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('alertDetail.updateEquipmentLevelError'));
          console.error('Update equipment level error', err);
        }
      })
      .then(() => {
        setSavingRealComponent(false);
      });
  };

  const handleAlertDataChange = (name) => (e) => {
    const modifyValue = e && e.target ? e.target.value : e;
    const tmp = { ...alertData };
    delete tmp[`${name}_msg`];
    tmp[name] = modifyValue;

    if (name === 'misjudge') {
      if (modifyValue === 'Y') {
        tmp.respType = null;
        tmp.cause = null;
        delete tmp.respType_msg;
      } else {
        tmp.misjudgeType = null;
        tmp.misjudgeReason = null;
        delete tmp.misjudgeType_msg;
        delete tmp.misjudgeReason_msg;
      }
    }

    setAlertData(tmp);
  };

  const handleUpdateMisjudgeState = () => {
    if (!alertData || !alertData.alertOwnerID || !alertData.alertName || !alertData.startTimestamp) return;

    //
    let validResult;
    if (alertData.misjudge === 'Y') {
      if (alertData.cidID) {
        validResult = {
          status: false,
          data: {
            ...alertData,
            misjudge_msg: t('alertDetail.cannotSetMisjudgeWithCid'),
          },
        };
      } else {
        validResult = ValidFormData(alertData, [
          { name: 'misjudgeType', type: 'select', required: true },
          { name: 'misjudgeReason', type: 'input', required: true },
        ]);
      }
    } else {
      validResult = ValidFormData(alertData, [{ name: 'respType', type: 'select', required: true }]);
    }
    if (!validResult.status) {
      setAlertData(validResult.data);
      return;
    }

    //
    const saveData = {};
    saveData.alertOwnerID = alertData.alertOwnerID;
    saveData.alertName = alertData.alertName;
    saveData.startTimestamp = alertData.startTimestamp;
    saveData.misjudge = alertData.misjudge || 'N';
    saveData.misjudgeType = alertData.misjudgeType;
    saveData.misjudgeReason = alertData.misjudgeReason;
    saveData.respType = alertData.respType;
    saveData.cause = alertData.cause;
    //
    setSavingMisjudge(true);
    axios
      .post(`${appConfigs.apiGatewayURL}/oms/alertlog/misjudge/save`, saveData, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('alertDetail.updateMisjudgeDataFailed', { msg: res?.data?.msg }));
          return;
        }

        setDefaultMisjudgeData(saveData);
        message.success(t('alertDetail.updateMisjudgeDataSuccess'));
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('alertDetail.updateMisjudgeDataError'));
          console.error('Update misjudge data error', err);
        }
      })
      .then(() => {
        setSavingMisjudge(false);
      });
  };
  const handleRecoverMisjudgeState = () => {
    const tmp = { ...alertData };
    delete tmp.misjudge_msg;
    delete tmp.misjudgeType_msg;
    delete tmp.misjudgeReason_msg;
    delete tmp.respType_msg;
    delete tmp.cause_msg;

    tmp.misjudge = defaultMisjudgeData.misjudge;
    tmp.misjudgeType = defaultMisjudgeData.misjudgeType;
    tmp.misjudgeReason = defaultMisjudgeData.misjudgeReason;
    tmp.respType = defaultMisjudgeData.respType;
    tmp.cause = defaultMisjudgeData.cause;

    setAlertData(tmp);
  };
  const handleBack = () => {
    navigate(BACK_URL);
  };

  return (
    <>
      {/* page header */}
      <PageHeader
        className="app-page-header"
        onBack={handleBack}
        title={t('alertDetail.alertIdTitle', { id: GetAlertLogID(location.pathname) })}
      />
      <div className="app-page-content">
        <section className="app-page-section">
          <Card>
            <Descriptions size="small">
              <Descriptions.Item label={t('alertDetail.alertCategory')}>
                <BlueSpan>{alertData.alertDisplayName}</BlueSpan>
              </Descriptions.Item>
              <Descriptions.Item label={t('alertDetail.severity')}>
                <BlueSpan>{alertData.alertDegree}</BlueSpan>
              </Descriptions.Item>
              <Descriptions.Item label={t('alertDetail.cidNumber')}>
                <Link to={`/cids/${alertData.cidID}`} target="_blank">
                  {alertData.cidID}
                </Link>
              </Descriptions.Item>
              <Descriptions.Item label={t('alertDetail.siteName')}>
                <BlueSpan>{alertData.objectName}</BlueSpan>
              </Descriptions.Item>
              <Descriptions.Item label={t('alertDetail.siteId')}>
                <BlueSpan>{alertData.objectID}</BlueSpan>
              </Descriptions.Item>
              <Descriptions.Item label={t('alertDetail.spv')}>
                <BlueSpan>{alertData.ownerName}</BlueSpan>
              </Descriptions.Item>
              <Descriptions.Item label={t('alertDetail.avgDailyPowerLoss')}>
                <BlueSpan>{bn(alertData.avgLossEnergy).dp(2).toNumber() || '--'} kWh</BlueSpan>
              </Descriptions.Item>
              <Descriptions.Item label={t('alertDetail.cumulativePowerLoss')}>
                <BlueSpan>{bn(alertData.lossEnergy).dp(2).toNumber() || '--'} kWh</BlueSpan>
              </Descriptions.Item>
              <Descriptions.Item label={t('alertDetail.days')}>
                <BlueSpan>{alertData.days}</BlueSpan>
              </Descriptions.Item>
              <Descriptions.Item label={t('alertDetail.equipmentLevel')}>
                <Space>
                  <BlueSpan>{alertData.alertComponent}</BlueSpan>
                  {user.operations.includes('ALERTVIEW_edit-alert-detail') && !showUpdateComponentRow && (
                    <Button size="small" onClick={handleShowUpdateComponentRow}>
                      {t('alertDetail.changeEquipmentLevel')}
                    </Button>
                  )}
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label={t('alertDetail.invNumber')}>
                <BlueSpan>{alertData.clientDeviceSeq}</BlueSpan>
              </Descriptions.Item>
              <Descriptions.Item label={t('alertDetail.brand')}>
                <BlueSpan>{alertData.brandCompanyName}</BlueSpan>
              </Descriptions.Item>
              {showUpdateComponentRow && (
                <Descriptions.Item label={t('alertDetail.actualEquipmentLevel')}>
                  <Space>
                    <RfdmeSelect
                      placeholder={t('alertDetail.actualEquipmentLevel')}
                      options={alertRealComponentList}
                      value={alertRealComponent}
                      onChange={handleAlertRealComponentChange}
                      style={{ width: '130px' }}
                    />
                    <Button
                      size="small"
                      type="primary"
                      loading={savingRealComponent}
                      onClick={handleAlertRealComponentUpdate}
                    >
                      {t('alertDetail.change')}
                    </Button>
                    <Button size="small" onClick={handleHideUpdateComponentRow}>
                      {t('alertDetail.cancel')}
                    </Button>
                  </Space>
                </Descriptions.Item>
              )}
            </Descriptions>
          </Card>
        </section>
        <section className="app-page-section">
          <Card
            title={t('alertDetail.signalList')}
            extra={
              <>
                <Button type="text" icon={<ReloadOutlined />} onClick={handleSignalLogQuery} />
                {user.operations.includes('ALERTVIEW_export-alert-signals') && (
                  <Button
                    type="text"
                    icon={<DownloadOutlined />}
                    loading={exporting}
                    onClick={handleExport}
                  />
                )}
              </>
            }
          >
            <Spin spinning={loading} tip={t('alertDetail.searchingData')}>
              <Table
                bordered
                size="small"
                columns={TableColumns}
                dataSource={signalData.list}
                scroll={{ x: 1920 }}
                pagination={{
                  total: signalData.total,
                  pageSize: tableInfo.pageSize,
                  showSizeChanger: false,
                  current: tableInfo.page,
                }}
                onChange={handleTableChange}
              />
            </Spin>
          </Card>
        </section>
        {user.operations.includes('ALERTVIEW_edit-alert-detail') && (
          <section className="app-page-section">
            <Card>
              <Row gutter={[8, 0]} style={{ marginBottom: 16 }}>
                <Col xs={24} sm={24} md={24} lg={{ span: 12, offset: 6 }} xl={{ span: 8, offset: 8 }}>
                  <RfdmeRadio
                    required
                    label={t('alertDetail.isMisjudgedAnomaly')}
                    options={[
                      { label: t('alertDetail.yes'), value: 'Y' },
                      { label: t('alertDetail.no'), value: 'N' },
                    ]}
                    value={alertData.misjudge}
                    msg={alertData.misjudge_msg}
                    onChange={handleAlertDataChange('misjudge')}
                  />
                </Col>
              </Row>
              {alertData.misjudge === 'Y' && (
                <Row gutter={[8, 16]} style={{ marginBottom: 16 }}>
                  <Col xs={24} sm={24} md={24} lg={{ span: 12, offset: 6 }} xl={{ span: 8, offset: 8 }}>
                    <RfdmeSelect
                      required
                      label={t('alertDetail.misjudgeCategory')}
                      options={misjudgeTypeList}
                      value={alertData.misjudgeType}
                      msg={alertData.misjudgeType_msg}
                      onChange={handleAlertDataChange('misjudgeType')}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={{ span: 12, offset: 6 }} xl={{ span: 8, offset: 8 }}>
                    <RfdmeTextArea
                      required
                      label={t('alertDetail.misjudgeDescription')}
                      rows={5}
                      value={alertData.misjudgeReason}
                      msg={alertData.misjudgeReason_msg}
                      onChange={handleAlertDataChange('misjudgeReason')}
                    />
                  </Col>
                </Row>
              )}
              {alertData.misjudge !== 'Y' && (
                <Row gutter={[8, 16]} style={{ marginBottom: 16 }}>
                  <Col xs={24} sm={24} md={24} lg={{ span: 12, offset: 6 }} xl={{ span: 8, offset: 8 }}>
                    <RfdmeSelect
                      required
                      label={t('alertDetail.responsibilityCategory')}
                      options={respTypeList}
                      value={alertData.respType}
                      msg={alertData.respType_msg}
                      onChange={handleAlertDataChange('respType')}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={{ span: 12, offset: 6 }} xl={{ span: 8, offset: 8 }}>
                    <RfdmeTextArea
                      label={t('alertDetail.reason')}
                      rows={5}
                      value={alertData.cause}
                      onChange={handleAlertDataChange('cause')}
                    />
                  </Col>
                </Row>
              )}
              <Row gutter={[8, 8]}>
                <Col xs={12} sm={12} md={12} lg={{ span: 6, offset: 6 }} xl={{ span: 4, offset: 8 }}>
                  <Button onClick={handleRecoverMisjudgeState} block>
                    {t('alertDetail.cancelChanges')}
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={4}>
                  <Button type="primary" loading={savingMisjudge} onClick={handleUpdateMisjudgeState} block>
                    {t('alertDetail.updateMisjudgeData')}
                  </Button>
                </Col>
              </Row>
            </Card>
          </section>
        )}
      </div>
    </>
  );
};

export default AlertDetail;
