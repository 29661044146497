import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../contexts/authProvider';
import { SettingContext } from '../../contexts/settingsProvider';

const PowerPerformance = ({ url, companyID, electricityPeriod }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { dark } = useContext(SettingContext);
  const theme = dark ? 'dark' : 'light';
  const token = user.token?.replace?.(/^Bearer /, '');

  if (!url || !companyID || !electricityPeriod) {
    return null;
  }

  return (
    <div style={{ margin: '-24px', height: 'calc(100vh - 197px)', overflow: 'hidden' }}>
      <iframe
        id="frame"
        width={'100%'}
        title={t('spv.powerPerformance')}
        style={{ border: 0, height: '100%' }}
        src={`${url}&kiosk=tv&var-spv=${companyID}&var-period=${electricityPeriod}&var-plant=ALL&auth_token=${token}&theme=${theme}`}
      ></iframe>
    </div>
  );
};

export default PowerPerformance;
