import React, { useCallback, useEffect, useState, useContext, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import { Button, PageHeader, Card, Row, Col, message, Divider } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import RfdmeModal from '../../components/Modal';
import OmWorkEditor from '../../components/OmWorkEditor';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { AppSyncQueryTextFormat, DateTimeFormat } from '../../utils/format';
import { IsValidAppSyncRsps, IsValidApiGatewayRsps } from '../../utils/valid';
import { GetAppSyncRspsErrorMessage } from '../../utils/parse';
import { OmStatusConstant } from '../../utils/constant';
//
import useListOfOmUser from '../../hooks/useListOfOmUser';
import { useTranslation } from 'react-i18next';
//
const BACK_URL = '/omrecord';
const INIT_DATA_OF_OM_WORK = { startTime: '', endTime: '' };
const GetOmFormID = (urlPath) => {
  return (urlPath || '').replace('/omrecord/', '');
};

//主要元件
const OmRecordView = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [omFormID, setOmFormID] = useState('');
  const [dataOfOm, setDataOfOm] = useState({});
  const [dataOfOmWorkList, setDataOfOmWorkList] = useState([]);
  const [dataOfOmWork, setDataOfOmWork] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data: listOfOmUser } = useListOfOmUser();
  const location = useLocation();
  const navigate = useNavigate();
  const selectedUserList = useMemo(() => {
    const result = [];

    if (!dataOfOm) return result;
    if (!Array.isArray(listOfOmUser) || listOfOmUser.length === 0) return result;
    if (dataOfOm.userInCharge) {
      if (Array.isArray(dataOfOm.userInCharge) && dataOfOm.userInCharge.length > 0) {
        result.push(...dataOfOm.userInCharge);
      }
    }
    if (dataOfOm.userCooperate) {
      if (Array.isArray(dataOfOm.userCooperate) && dataOfOm.userCooperate.length > 0) {
        result.push(...dataOfOm.userCooperate);
      }
    }

    return listOfOmUser.filter((f) => result.includes(f.value));
  }, [dataOfOm, listOfOmUser]);
  const canEdit = useMemo(() => {
    return [
      OmStatusConstant.PLANNED,
      OmStatusConstant.REQUEST_PREPARATION,
      OmStatusConstant.PROCESSING,
    ].includes(dataOfOm.omStatus);
  }, [dataOfOm.omStatus]);

  // 解析頁面網址，取得預設參數
  useEffect(() => {
    setOmFormID(GetOmFormID(location.pathname));
  }, [location]);

  const loadData = useCallback(() => {
    if (!omFormID) return;

    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery($omFormID: String!, $timezone: String!) { 
              om_getOmFormContent(omFormID: $omFormID, timezone: $timezone) {
                cidFormID
                omSource
                omStatus
                omUserInCharge
                omUserCooperate
                omWorkHistory {
                  seq 
                  startTime
                  endTime
                  workHour
                  userID
                  userName
                  workContent
                  recommend
                  logbook
                  ocapList { ocapID ocapName }
                  componentList { componentID componentName amount }
                  fileList { fileID fileName }    
                }
              }
            }
          `
          ),
          variables: { omFormID: omFormID, timezone: appConfigs.timezone },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(
            `${t('omRecordView.failedToQueryProcessHistory', { error: GetAppSyncRspsErrorMessage(res) })}`
          );
          return;
        }
        //
        const { omStatus, omUserInCharge, omUserCooperate, cidFormID, omSource } =
          res.data.data.om_getOmFormContent;
        setDataOfOm({
          omStatus: omStatus,
          userInCharge: omUserInCharge,
          userCooperate: omUserCooperate,
          cidFormID: cidFormID,
          omSource: omSource,
        });
        //
        const tmpList = res.data.data.om_getOmFormContent.omWorkHistory || [];
        tmpList.map((tmp) => {
          tmp.ocapIDs = Array.isArray(tmp.ocapList) && tmp.ocapList;
          delete tmp.ocapList;
          return tmp;
        });
        tmpList.sort((a, b) => a.seq - b.seq);

        if (!tmpList.length) {
          tmpList.push(INIT_DATA_OF_OM_WORK);
        }
        setDataOfOmWorkList(tmpList);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omRecordView.queryProcessHistoryError'));
          console.error('(omRecordView) load om form work histroy error: ', err);
        }
      });
  }, [user, omFormID, t]);
  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleBack = () => {
    navigate(BACK_URL);
  };

  const handleUpdateProcessTime = (kind, seq) => {
    if (!kind) return;

    setLoading(true);
    axios
      .post(
        `${appConfigs.apiGatewayURL}/oms/om/work/updateProcessTime`,
        { omFormID: omFormID, kind: kind, mode: seq ? 'update' : 'insert' },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(`${t('omRecordView.checkInStartEndFailed', { error: res?.data?.msg })}`);
          return;
        }

        message.success(t('omRecordView.checkInStartEndSuccess'));
        loadData();
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omRecordView.checkInStartEndError'));
          console.error('(omRecordView) update process time error: ', err);
        }
      })
      .then(() => {
        setLoading(false);
      });
  };
  const handleUpdateStartTime = (seq) => () => {
    handleUpdateProcessTime('start', seq);
  };
  const handleUpdateEndTime = (seq) => () => {
    handleUpdateProcessTime('end', seq);
  };

  const handleModalOpen = (record) => () => {
    setDataOfOmWork(record);
    setModalOpen(true);
  };
  const handleModalClose = (reload) => () => {
    setModalOpen(false);
    if (reload) loadData();
  };

  const showAddButton = useMemo(() => {
    if (!dataOfOmWorkList || !dataOfOmWorkList.length) return false;

    const lastOmWork = dataOfOmWorkList[dataOfOmWorkList.length - 1];
    if (!!lastOmWork.startTime && !!lastOmWork.endTime) {
      return true;
    }
    return false;
  }, [dataOfOmWorkList]);

  const handleAddOmWork = () => {
    const tmpList = _.cloneDeep(dataOfOmWorkList);
    tmpList.push(INIT_DATA_OF_OM_WORK);
    setDataOfOmWorkList(tmpList);
  };

  return (
    <>
      {/* page header */}
      <PageHeader className="app-page-header" onBack={handleBack} title={omFormID} />

      {/* 列表 */}
      <div className="app-page-content">
        <Row gutter={[16, 16]}>
          {dataOfOmWorkList.map((work, idx) => (
            <Col xs={24} sm={24} md={12} lg={12} xl={6} key={idx}>
              <Card title={`${t('omRecordView.processHistory')} #${work.seq ? idx + 1 : ''}`}>
                <div className="app-record-checkin-time">
                  <p>
                    {t('omRecordView.startTime')}：
                    <span>{work.startTime && DateTimeFormat(work.startTime, true)}</span>
                  </p>
                  <p>
                    {t('omRecordView.endTime')}：
                    <span>{work.endTime && DateTimeFormat(work.endTime, true)}</span>
                  </p>
                </div>
                <Divider />
                {canEdit && (
                  <div className="app-record-checkin-action">
                    <Row gutter={[8, 16]}>
                      <Col span={24}>
                        <Button
                          type="primary"
                          block
                          loading={loading}
                          onClick={handleUpdateStartTime(work.seq)}
                          disabled={!(idx === dataOfOmWorkList.length - 1)}
                        >
                          {t('omRecordView.start')}
                        </Button>
                      </Col>
                      <Col span={24}>
                        <Button
                          type="primary"
                          danger
                          block
                          loading={loading}
                          onClick={handleUpdateEndTime(work.seq)}
                          disabled={!(idx === dataOfOmWorkList.length - 1)}
                        >
                          {t('omRecordView.end')}
                        </Button>
                      </Col>
                      <Col span={24}>
                        <Button icon={<EditOutlined />} block onClick={handleModalOpen(work)}>
                          {t('omRecordView.fillProcessHistory')}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                )}
              </Card>
            </Col>
          ))}
          {showAddButton && (
            <Col span={24}>
              <Row justify="center">
                <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                  <Button type="dashed" block icon={<PlusOutlined />} onClick={handleAddOmWork}>
                    {t('omRecordView.addProcessHistory')}
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </div>

      {/* 跳出視窗-開OM單 */}
      {modalOpen && (
        <RfdmeModal width={972} open={true}>
          <OmWorkEditor
            omFormId={omFormID}
            workData={dataOfOmWork}
            users={selectedUserList}
            closeFn={handleModalClose}
            isCidFormIdRef={dataOfOm.cidFormID || dataOfOm.omSource === 9 ? true : false}
          />
        </RfdmeModal>
      )}
    </>
  );
};

export default OmRecordView;
