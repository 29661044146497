import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import appConfigs from '../configs';
import AuthContext from '../contexts/authProvider';

const useListOftNotifyKind = () => {
  const { user } = useContext(AuthContext);

  return useQuery({
    queryKey: ['query-notify-kind-list'],
    queryFn: () => {
      return axios
        .post(
          appConfigs.appSyncURL,
          {
            query: `query OmQuery { om_getNotifyKindList { key value } }`,
            variables: null,
          },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (res.status === 200 && res.data.data) {
            return res.data.data.om_getNotifyKindList.map((m) => ({
              label: m.value,
              value: m.key,
            }));
          }
          return [];
        });
    },
    staleTime: 1000 * 60 * 60 * 1, // 1 hours
    cacheTime: 1000 * 60 * 60 * 2, // 2 hours
  });
};

export default useListOftNotifyKind;
