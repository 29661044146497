import Home from '../pages/Home';
import AlertList from '../pages/AlertList';
import AlertDetail from '../pages/AlertDetail';
import CidList from '../pages/CidList';
import CidView from '../pages/CidView';
import CidConfig from '../pages/CidConfig';
import CidAmountAnalysis from '../pages/CidAmountAnalysis';
import OmTodoList from '../pages/OmTodoList';
import OmList from '../pages/OmList';
import OmRecord from '../pages/OmRecord';
import OmRecordView from '../pages/OmRecordView';
import OmWorkList from '../pages/OmWorkList';
import OmView from '../pages/OmView';
import OmConfig from '../pages/OmConfig';
import OmAmountAnalysis from '../pages/OmAmountAnalysis';
import ObjectList from '../pages/ObjectList';
import Object from '../pages/Object';
import OmCalendar from '../pages/OmCalendar';
import AlertStatistics from '../pages/AlertStatistics';
import SpvList from '../pages/SpvList';
import SpvDashboard from '../pages/SpvDashboard';
import Spv from '../pages/Spv';
import Settings from '../pages/Settings';
import BoosterStation from '../pages/BoosterStation';
import BoosterStationList from '../pages/BoosterStationList';
import ReportNerbyObjectCompare from '../pages/ReportNerbyObjectCompare';
import ReportTPCRecordSheet from '../pages/ReportTPCRecordSheet';
import ReportTPCWeeklySheet from '../pages/ReportTPCWeeklySheet';
import ReportCidTimeMonitor from '../pages/ReportCidTimeMonitor';
import ReportNewSolarPlantReview from '../pages/ReportNewSolarPlantReview';
import ReportCumulativePowerGeneration from '../pages/ReportCumulativePowerGeneration';
import ReportDailyPowerGenerationSummary from '../pages/ReportDailyPowerGenerationSummary';
import ReportInverterRepairCount from '../pages/ReportInverterRepairCount';
import ReportGenerationSunlightRelate from '../pages/ReportGenerationSunlightRelate';
import ReportMonthlyMaintainFreqHour from '../pages/ReportMonthlyMaintainFreqHour';
import ReportYearlyVirutalSunlightAnalysis from '../pages/ReportYearlyVirutalSunlightAnalysis';
import ReportVirsunlightExpectGenerationCompare from '../pages/ReportVirsunlightExpectGenerationCompare';
import ReportYearlySolarPlantPR from '../pages/ReportYearlySolarPlantPR';
import ReportSolarPlantAvailability from '../pages/ReportSolarPlantAvailability';
import ReportSimpleMonthly from '../pages/ReportSimpleMonthly';
import ReportInverterMaxPower from '../pages/ReportInverterMaxPower';

export const RouteList = (t) => [
  { path: '/', element: <Home />, breadcrumbName: t('routeList.home') },
  { path: '/alerts', element: <AlertList />, breadcrumbName: t('routeList.alertList') },
  { path: '/alerts/:id', element: <AlertDetail />, breadcrumbName: 'AlertID: ' },
  { path: '/cids', element: <CidList />, breadcrumbName: t('routeList.cidList') },
  { path: '/cids/:id', element: <CidView />, breadcrumbName: t('routeList.cidView') },
  { path: '/cidconfig', element: <CidConfig />, breadcrumbName: t('routeList.cidConfig') },
  { path: '/cidamountanalysis', element: <CidAmountAnalysis />, breadcrumbName: t('routeList.cidAmountAnalysis') },
  { path: '/omtodo', element: <OmTodoList />, breadcrumbName: t('routeList.omTodoList') },
  { path: '/omcalendar', element: <OmCalendar />, breadcrumbName: t('routeList.omCalendar') },
  { path: '/oms', element: <OmList />, breadcrumbName: t('routeList.omList') },
  { path: '/omrecord', element: <OmRecord />, breadcrumbName: t('routeList.omRecord') },
  { path: '/omrecord/:id', element: <OmRecordView />, breadcrumbName: t('routeList.omRecordView') },
  { path: '/oms/:id', element: <OmView />, breadcrumbName: t('routeList.omView') },
  { path: '/omworks', element: <OmWorkList />, breadcrumbName: t('routeList.omWorkList') },
  { path: '/omconfig', element: <OmConfig />, breadcrumbName: t('routeList.omConfig') },
  { path: '/omamountanalysis', element: <OmAmountAnalysis />, breadcrumbName: t('routeList.omAmountAnalysis') },
  { path: '/pvs', element: <ObjectList />, breadcrumbName: t('routeList.objectList') },
  { path: '/pvs/:id', element: <Object />, breadcrumbName: t('routeList.object') },
  { path: '/spvlist', element: <SpvList />, breadcrumbName: t('routeList.spvList') },
  { path: '/spvlist/:id', element: <Spv />, breadcrumbName: t('routeList.spv') },
  { path: '/alertstatistics', element: <AlertStatistics />, breadcrumbName: t('routeList.alertStatistics') },
  { path: '/spvDashboard', element: <SpvDashboard />, breadcrumbName: t('routeList.spvDashboard') },
  { path: '/settings', element: <Settings />, breadcrumbName: t('routeList.settings') },
  { path: '/boosterStations/:id', element: <BoosterStation />, breadcrumbName: t('routeList.boosterStation') },
  { path: '/boosterStations', element: <BoosterStationList />, breadcrumbName: t('routeList.boosterStationList') },
  { path: '/nerbyobjectcompare', element: <ReportNerbyObjectCompare />, breadcrumbName: t('routeList.nerbyObjectCompare') },
  { path: '/tpcrecordsheet', element: <ReportTPCRecordSheet />, breadcrumbName: t('routeList.tpcrecordsheet') },
  { path: '/tpcweeklysheet', element: <ReportTPCWeeklySheet />, breadcrumbName: t('routeList.tpcweeklysheet') },
  { path: '/cidtimemonitor', element: <ReportCidTimeMonitor />, breadcrumbName: t('routeList.cidtimemonitor') },
  { path: '/newsolarplantreview', element: <ReportNewSolarPlantReview />, breadcrumbName: t('routeList.newsolarplantreview') },
  { path: '/simplemonthly', element: <ReportSimpleMonthly />, breadcrumbName: t('routeList.simplemonthly') },
  {
    path: '/cumulativepowergeneration',
    element: <ReportCumulativePowerGeneration />,
    breadcrumbName: t('routeList.cumulativepowergeneration'),
  },
  {
    path: '/dailypowergenerationsummary',
    element: <ReportDailyPowerGenerationSummary />,
    breadcrumbName: t('routeList.dailypowergenerationsummary'),
  },
  { path: '/inverterrepaircount', element: <ReportInverterRepairCount />, breadcrumbName: t('routeList.inverterrepaircount') },
  {
    path: '/generationsunlightrelate',
    element: <ReportGenerationSunlightRelate />,
    breadcrumbName: t('routeList.generationsunlightrelate'),
  },
  {
    path: '/monthlymaintainfreqhour',
    element: <ReportMonthlyMaintainFreqHour />,
    breadcrumbName: t('routeList.monthlymaintainfreqhour'),
  },
  {
    path: '/yearlyvirutalsunlightanalysis',
    element: <ReportYearlyVirutalSunlightAnalysis />,
    breadcrumbName: t('routeList.yearlyvirutalsunlightanalysis'),
  },
  {
    path: '/virsunlightexpectgenerationcompare',
    element: <ReportVirsunlightExpectGenerationCompare />,
    breadcrumbName: t('routeList.virsunlightexpectgenerationcompare'),
  },
  {
    path: '/yearlysolarplantpr',
    element: <ReportYearlySolarPlantPR />,
    breadcrumbName: t('routeList.yearlysolarplantpr'),
  },
  {
    path: '/solarplantavailability',
    element: <ReportSolarPlantAvailability />,
    breadcrumbName: t('routeList.solarplantavailability'),
  },
  {
    path: '/invertermaxpower',
    element: <ReportInverterMaxPower />,
    breadcrumbName: t('routeList.invertermaxpower'),
  },
];
