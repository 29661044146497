import React, { useState, useEffect } from 'react';
import { Popover, Button, Radio } from 'antd';
import ComponentLayout from '../../layout/componentLayout';
import styled from 'styled-components';

const predefinedColors = [
  '#F3A8DA',
  '#E797E9',
  '#FCCB00',
  '#75E876',
  '#65BDC6',
  '#86B8F0',
  '#8AA9DD',
  '#B8A5DA',
  '#EB9694',
  '#FAD0C3',
  '#FEF3BD',
  '#C1E1C5',
  '#BEDADC',
  '#C4DEF6',
  '#BED3F3',
  '#D4C4FB',
];

const RfdmeColorPicker = ({ required, label, msg, size = 40, value, onColorChange, disabled = false }) => {
  const [selectedColor, setSelectedColor] = useState(null);

  useEffect(() => {
    if (value) {
      setSelectedColor(value);
    } else {
      setSelectedColor(predefinedColors[0]);
      if (onColorChange) {
        onColorChange(predefinedColors[0]);
      }
    }
  }, [value, onColorChange]);

  const handleColorChange = (e) => {
    const newColor = e.target.value;
    setSelectedColor(newColor);
    if (onColorChange) {
      onColorChange(newColor);
    }
  };

  const content = (
    <PickerDiv>
      <Radio.Group
        onChange={handleColorChange}
        value={selectedColor}
        style={{ display: 'flex', flexWrap: 'wrap', width: 200 }}
      >
        {predefinedColors.map((color) => (
          <Radio.Button
            key={color}
            value={color}
            style={{
              backgroundColor: color,
              width: 25,
              height: 25,
              outline: selectedColor === color ? 'rgb(255, 255, 255) solid 2px' : 'none',
              boxShadow: selectedColor === color ? 'rgba(0, 0, 0, 0.25) 0px 0px 5px 2px' : 'none',
              padding: 0,
              margin: 0,
            }}
          />
        ))}
      </Radio.Group>
    </PickerDiv>
  );

  return (
    <ComponentLayout label={label} msg={msg} required={required}>
      {disabled ? (
        <Button
          style={{
            backgroundColor: selectedColor,
            width: size,
            height: size,
            padding: 0,
            border: 'none',
          }}
          disabled={disabled}
        >
          　
        </Button>
      ) : (
        <Popover content={content} trigger="click" placement="right">
          <Button
            style={{
              backgroundColor: selectedColor,
              width: size,
              height: size,
              padding: 0,
              border: 'none',
            }}
          >
            　
          </Button>
        </Popover>
      )}
    </ComponentLayout>
  );
};

const PickerDiv = styled.div`
  width: 200px;
  background: rgb(255, 255, 255);
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 12px;
  border-radius: 4px;
`;

export default RfdmeColorPicker;
