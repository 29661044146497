import React, { useContext } from 'react';
import { Modal } from 'antd';
import { SettingContext } from '../../contexts/settingsProvider';

const RfdmeModal = (props) => {
  const { dark } = useContext(SettingContext);
  return (
    <Modal
      footer={false}
      closable={false}
      style={{ top: '20px' }}
      width={props.width}
      {...props}
      className={dark ? 'dark' : ''}
    >
      {props.children}
    </Modal>
  );
};

export default RfdmeModal;
