import React from 'react';

const RfdmeDisplay = ({ label, children }) => {
  return (
    <div className="app-form-item">
      <div className="app-form-label">{label}</div>
      <div className="app-form-input">{children}</div>
    </div>
  );
};

export default RfdmeDisplay;
