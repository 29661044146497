import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import { Col, Row, Card, PageHeader, Typography, Spin, message, Table } from 'antd';
import BoosterStationMarker from '../../components/BoosterStationMarker';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { IsValidAppSyncRsps } from '../../utils/valid';
import { GetAppSyncRspsErrorMessage } from '../../utils/parse';
import { AppSyncQueryTextFormat } from '../../utils/format';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const defaultMapOptions = {
  mapId: appConfigs.googleMapID,
  fullscreenControl: false,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: true,
  streetViewControl: false,
  rotateControl: false,
  panControl: true,
  gestureHandling: 'greedy',
  minZoom: 6.7,
  defaultCenter: { lat: 23.6078, lng: 120.9605 },
  defaultZoom: 7.8,
};

const BoosterStationList = () => {
  const { user } = useContext(AuthContext);
  const [boosterStationList, setBoosterStationList] = useState([]);
  const [markerList, setMarkerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const columns = [
    {
      title: t('boosterStationList.name'),
      dataIndex: 'boosterStationName',
      key: 'boosterStationName',
      width: 75,
      render: (_, record) => (
        <>
          <Link
            to={`/boosterStations/${record.boosterStationID}`}
            state={{ boosterStationName: record.boosterStationName }}
          >
            {record.boosterStationID}-{record.boosterStationName}
          </Link>
        </>
      ),
    },
    {
      title: t('boosterStationList.realTimePower'),
      dataIndex: 'realPower',
      key: 'realPower',
      width: 80,
      render: (value) =>
        value
          ? `${Number(value).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })} kW`
          : '-',
    },
    {
      title: t('boosterStationList.powerFactor'),
      dataIndex: 'powerFactor',
      key: 'powerFactor',
      width: 80,
      render: (value) =>
        value
          ? `${Number(value).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`
          : '-',
    },
    {
      title: t('boosterStationList.averageVoltage'),
      dataIndex: 'averageVoltage',
      key: 'averageVoltage',
      width: 80,
      render: (value) =>
        value
          ? `${Number(value).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })} V`
          : '-',
    },
    {
      title: t('boosterStationList.region'),
      dataIndex: 'region',
      key: 'region',
      width: 80,
    },
    {
      title: t('boosterStationList.address'),
      dataIndex: 'address',
      key: 'address',
      width: 80,
    },
  ];

  useEffect(() => {
    setLoading(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery($timezone: String!) {
              om_getBoosterStationDataList(timezone: $timezone) {
                boosterStationID
                boosterStationName
                region
                address
                latitude
                longitude
                realtimeData {
                  objectID
                  deviceID
                  powerFactor
                  realPower
                  time
                  averageVoltage
                }
              }
            }
            `
          ),
          variables: { timezone: appConfigs.timezone },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.error(
            t('boosterStationList.failedToLoadBoosterStationList', { error: GetAppSyncRspsErrorMessage(res) })
          );
          return;
        }
        //
        const data = res?.data?.data?.om_getBoosterStationDataList || [];
        const markers = data.map((m, idx) => ({
          key: idx,
          position:
            m.latitude !== null && m.longitude !== null ? { lat: m.latitude, lng: m.longitude } : null,
          id: m.boosterStationID,
          name: m.boosterStationName,
          description: m.region,
        }));
        setMarkerList(markers);
        setBoosterStationList(
          data.map((m, idx) => ({
            key: idx,
            boosterStationID: m.boosterStationID,
            boosterStationName: m.boosterStationName,
            region: m.region,
            address: m.address,
            powerFactor: m.realtimeData?.powerFactor,
            realPower: m.realtimeData?.realPower,
            averageVoltage: m.realtimeData?.averageVoltage,
          }))
        );
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error('load booster station list error', err);
          message.error(t('boosterStationList.failedToLoadBoosterStationListException'));
        }
      })
      .then(() => {
        setLoading(false);
      });
  }, [user, t]);

  return (
    <>
      {/* page header */}
      <PageHeader className="app-page-header rwd-btn-wrap" title={t('boosterStationList.title')} />
      <div className="app-page-content">
        <Spin spinning={loading} tip={t('common.loadingDataMessage')} style={{ minHeight: 300 }}>
          <Row gutter={[12, 12]}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <Card
                size="small"
                className="card-alert"
                title={<Title level={5}>{t('boosterStationList.boosterStationLocation')}</Title>}
              >
                <div style={{ height: '70vh', width: '100%', position: 'relative' }}>
                  <APIProvider apiKey={appConfigs.googleMapsApiKey}>
                    <Map {...defaultMapOptions} />
                    {markerList.map((marker, idx) => (
                      <BoosterStationMarker
                        key={idx}
                        position={marker.position}
                        id={marker.id}
                        name={marker.name}
                        description={marker.description}
                      />
                    ))}
                  </APIProvider>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
              <Card
                size="small"
                className="card-alert"
                title={<Title level={5}>{t('boosterStationList.boosterStationList')}</Title>}
              >
                <Table columns={columns} dataSource={boosterStationList} />
              </Card>
            </Col>
          </Row>
        </Spin>
      </div>
    </>
  );
};

export default BoosterStationList;
