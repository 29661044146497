import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import appConfigs from '../configs';
import AuthContext from '../contexts/authProvider';

const useListOfPv = (enabled = true) => {
  const { user } = useContext(AuthContext);

  return useQuery({
    queryKey: ['query-pv-list'],
    queryFn: () => {
      return axios
        .post(
          appConfigs.sharedAppSyncURL,
          {
            query: `query OmQuery($companyID: String, $electricityPeriod: String) { 
              shared_getSolarPlantList(companyID: $companyID, electricityPeriod: $electricityPeriod) { 
                ownerObjectID ownerObjectName electricityPeriod ownerID ownerName projectID majorFlag region capacity objectType state
              } 
            }`,
            variables: null,
          },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (res.status === 200 && res.data.data) {
            return res.data.data.shared_getSolarPlantList.map((m) => ({
              label: m.ownerObjectID + '-' + m.ownerObjectName,
              value: m.ownerObjectID,
              projectID: m.projectID,
              majorFlag: m.majorFlag,
              electricityPeriod: m.electricityPeriod,
              ownerID: m.ownerID,
              ownerName: m.ownerName,
              region: m.region,
              capacity: m.capacity,
              objectType: m.objectType,
              state: m.state,
            }));
          }
          return [];
        });
    },
    staleTime: 1000 * 60 * 60 * 1, // 1 hours
    cacheTime: 1000 * 60 * 60 * 2, // 2 hours
    enabled: enabled,
  });
};

export default useListOfPv;
