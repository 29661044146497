import React, { useContext, useState, useMemo, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
//
import { Card, Tooltip, Space, Button, Row, Col, Timeline, Table, message, Tag } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import Input from '../../components/Input';
import Select from '../../components/Select';
import DatePicker from '../../components/DatePicker';
//
import useListOfCidDateTypeBySPV from '../../hooks/useListOfCidDateTypeBySPV';
import useListOfCidStatusBySPV from '../../hooks/useListOfCidStatusBySPV';
import useListOfCidLevel from '../../hooks/useListOfCidLevel';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { SettingContext } from '../../contexts/settingsProvider';
import { AppSyncQueryTextFormat, SetKeyToArray, DateFormat } from '../../utils/format';
import { GetAppSyncRspsErrorMessage } from '../../utils/parse';
import { IsValidAppSyncRsps } from '../../utils/valid';
import { QueryOmContractLevelList } from '../../utils/dataHelpers';
import { useTranslation } from 'react-i18next';

const DefaultCidListFilter = {
  dateType: 1,
  startDate: moment().startOf('M'),
  endDate: moment().endOf('M'),
};

const CidStatistics = ({ grafanaUrl, companyID, electricityPeriod }) => {
  const { user } = useContext(AuthContext);
  const { dark } = useContext(SettingContext);
  const theme = dark ? 'dark' : 'light';
  const token = user.token?.replace?.(/^Bearer /, '');
  const [uncloseAmount, setUncloseAmount] = useState({ check: 0, process: 0 });
  const [uncloseFatalList, setUncloseFatalList] = useState([]);
  const uncloseTotal = useMemo(() => {
    return uncloseAmount.check + uncloseAmount.process;
  }, [uncloseAmount]);
  //
  const { data: listOfCidDateType } = useListOfCidDateTypeBySPV();
  const { data: listOfCidStatus } = useListOfCidStatusBySPV();
  const { data: listOfDefaultCidLevel } = useListOfCidLevel();
  const [listOfPv, setListOfPv] = useState([]);
  const [listOfCidLevel, setListOfCidLevel] = useState([]);
  //
  const [filterData, setFilterData] = useState({ ...DefaultCidListFilter });
  const [cidFormList, setCidFormList] = useState([]);
  const isMaintainMode = useMemo(() => {
    return !user.operations.includes('SPVINFO_investor-view');
  }, [user]);

  const { t } = useTranslation();

  // -------------------------------------------------
  const UncloseFatalTableColumn = useMemo(() => [
    {
      title: t('spv.objectCidStatistics.relatedObject'),
      dataIndex: 'ownerObjectID',
      key: 'ownerObjectID',
      render: (value, record) => (
        <>
          {value}_{record.ownerObjectName}
        </>
      ),
    },
    {
      title: t('spv.objectCidStatistics.cidFormID'),
      dataIndex: 'cidFormID',
      key: 'cidFormID',
      render: (value) => (
        <Link to={`/cids/${value}`} target="_blank">
          {value}
        </Link>
      ),
    },
    {
      title: t('spv.objectCidStatistics.statusName'),
      dataIndex: 'statusName',
      key: 'statusName',
    },
    {
      title: t('spv.objectCidStatistics.occurDate'),
      dataIndex: 'occurDate',
      key: 'occurDate',
    },
  ],[t]);
  const CidFormTableColumn = useMemo(() => [
    {
      title: t('spv.objectCidStatistics.levelName'),
      dataIndex: 'levelName',
      key: 'levelName',
      width: 120,
      showSorterTooltip: false,
      sorter: (a, b) => a.level - b.level,
      render: (value, record) => (
        <Tag
          color={
            record.levelSeq === 1
              ? 'red'
              : record.levelSeq === 2
              ? 'volcano'
              : record.levelSeq === 3
              ? 'gold'
              : 'green'
          }
        >
          {value}
        </Tag>
      ),
    },
    {
      title: t('spv.objectCidStatistics.cidFormID'),
      dataIndex: 'cidFormID',
      key: 'cidFormID',
      width: 120,
      showSorterTooltip: false,
      sorter: (a, b) => a.cidFormID.localeCompare(b.cidFormID),
      render: (value) => (
        <Link to={`/cids/${value}`} target="_blank">
          {value}
        </Link>
      ),
    },
    {
      title: t('spv.objectCidStatistics.createDate'),
      dataIndex: 'createDate',
      key: 'createDate',
      width: 120,
      showSorterTooltip: false,
      sorter: (a, b) => a.createDate.localeCompare(b.createDate),
    },
    {
      title: t('spv.objectCidStatistics.occurDate'),
      dataIndex: 'occurDate',
      key: 'occurDate',
      width: 120,
      showSorterTooltip: false,
      sorter: (a, b) => a.occurDate.localeCompare(b.occurDate),
    },
    {
      title: t('spv.objectCidStatistics.maintainStartDate'),
      dataIndex: 'maintainStartDate',
      key: 'maintainStartDate',
      width: 120,
      showSorterTooltip: false,
      sorter: (a, b) => a.maintainStartDate.localeCompare(b.maintainStartDate),
    },
    {
      title: t('spv.objectCidStatistics.maintainEndDate'),
      dataIndex: 'maintainEndDate',
      key: 'maintainEndDate',
      width: 120,
      showSorterTooltip: false,
      sorter: (a, b) => a.maintainEndDate.localeCompare(b.maintainEndDate),
    },
    {
      title: t('spv.objectCidStatistics.relatedObject'),
      dataIndex: 'ownerObjectID',
      key: 'ownerObjectID',
      width: 200,
      showSorterTooltip: false,
      sorter: (a, b) => a.ownerObjectID.localeCompare(b.ownerObjectID),
      render: (value, record) => {
        if (Array.isArray(record.ownerObjectNames)) {
          return record.ownerObjectNames.map((m, mIdx) => <div key={mIdx}>{m}</div>);
        } else {
          return '';
        }
      },
    },
    {
      title: t('spv.objectCidStatistics.issue'),
      dataIndex: 'issue',
      key: 'issue',
      showSorterTooltip: false,
      sorter: (a, b) => a.issue.localeCompare(b.issue),
    },
    {
      title: t('spv.objectCidStatistics.statusName'),
      dataIndex: 'statusName',
      key: 'statusName',
      width: 120,
      showSorterTooltip: false,
      sorter: (a, b) => a.status - b.status,
    },
    {
      title: t('spv.objectCidStatistics.invSeq'),
      dataIndex: 'invSeq',
      key: 'invSeq',
      showSorterTooltip: false,
      sorter: (a, b) => a.issue.localeCompare(b.issue),
    },
    {
      title: t('spv.objectCidStatistics.respTypeLevel'),
      dataIndex: 'respTypeLevel',
      key: 'respTypeLevel',
      showSorterTooltip: false,
      sorter: (a, b) => (a.respTypeLevel2Name || '').localeCompare(b.respTypeLevel2Name),
      render: (value, record) => (
        <>{[record.respTypeLevel1Name, record.respTypeLevel2Name].filter((f) => f).join(' / ')}</>
      ),
    },
  ],[t]);

  // 載入：即時未結案CID - 處理流程
  useEffect(() => {
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery($companyID: String!, $electricityPeriod: String, $maintainView: Boolean) { 
              om_getCidUncloseAmountSummary(companyID: $companyID, electricityPeriod: $electricityPeriod, maintainView: $maintainView) {
                step
                amount   
              }    
            }
            `
          ),
          variables: {
            companyID: companyID,
            electricityPeriod: electricityPeriod,
            maintainView: isMaintainMode,
          },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('spv.objectCidStatistics.failedToQueryUnclosedCidForm', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        const resData = res.data.data.om_getCidUncloseAmountSummary;
        if (Array.isArray(resData) && resData.length > 0) {
          const result = { check: 0, process: 0 };
          for (let i = 0; i < resData.length; i++) {
            if (resData[i].step === 'check') result.check = resData[i].amount || 0;
            if (resData[i].step === 'process') result.process = resData[i].amount || 0;
          }
          setUncloseAmount(result);
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('spv.objectCidStatistics.queryUnclosedCidFormError'));
          console.error('load CID unclose amount summary error: ', err);
        }
      });
  }, [user, companyID, electricityPeriod, isMaintainMode, t]);

  // 載入：即時未結案CID - 未結案 Fatal 案場清單
  useEffect(() => {
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery($companyID: String!, $electricityPeriod: String, $maintainView: Boolean, $timezone: String!) { 
              om_getCidUncloseFatalList(companyID: $companyID, electricityPeriod: $electricityPeriod, maintainView:$maintainView, timezone:$timezone) {
                ownerObjectID
                ownerObjectName
                cidFormID
                statusName
                occurDate
              }    
            }
            `
          ),
          variables: {
            companyID: companyID,
            electricityPeriod: electricityPeriod,
            maintainView: isMaintainMode,
            timezone: appConfigs.timezone,
          },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('spv.objectCidStatistics.failedToQueryCidForm', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        const resData = res.data.data.om_getCidUncloseFatalList;
        if (Array.isArray(resData) && resData.length > 0) {
          setUncloseFatalList(resData.map(SetKeyToArray));
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('spv.objectCidStatistics.queryCidFormError'));
          console.error('load CID unclose and fatal list error: ', err);
        }
      });
  }, [user, companyID, electricityPeriod, isMaintainMode, t]);

  // 載入：CID列表篩選條件 - 相關案場
  useEffect(() => {
    axios
      .post(
        appConfigs.sharedAppSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery($companyID: String, $electricityPeriod: String) { 
              shared_getSolarPlantList(companyID: $companyID, electricityPeriod: $electricityPeriod) {
                ownerObjectID
                ownerObjectName
              }    
            }
            `
          ),
          variables: { companyID: companyID, electricityPeriod: electricityPeriod },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          return;
        }

        const resData = res.data.data.shared_getSolarPlantList;
        if (Array.isArray(resData) && resData.length > 0) {
          setListOfPv(
            resData.map((m) => ({ label: m.ownerObjectName + '-' + m.ownerObjectID, value: m.ownerObjectID }))
          );
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error('load object list for spv error: ', err);
        }
      });
  }, [user, companyID, electricityPeriod]);

  // 載入：CID列表篩選條件 - 重要性
  useEffect(() => {
    async function fetchData() {
      if (!companyID) return;

      const getOmContractLevelList = await QueryOmContractLevelList(user, [companyID], []);
      if (getOmContractLevelList.length > 0) {
        setListOfCidLevel(getOmContractLevelList.sort((a, b) => a.seq - b.seq));
      } else {
        setListOfCidLevel(listOfDefaultCidLevel.sort((a, b) => a.seq - b.seq));
      }
    }
    fetchData();
  }, [user, companyID, listOfDefaultCidLevel]);

  // 載入：CID 列表
  const LoadCidFormList = useCallback(
    (conditions) => {
      axios
        .post(
          appConfigs.appSyncURL,
          {
            query: AppSyncQueryTextFormat(
              `query OmQuery(
                $companyID: String! 
                $electricityPeriod: String
                $cidFormID: String
                $dateType: String
                $startDate: String
                $endDate: String
                $ownerObjectID: String
                $level: String
                $issue: String
                $status: String
                $timezone: String
                $maintainView: Boolean
              ) { 
                om_getCidFormListBySPV(
                  companyID: $companyID, 
                  electricityPeriod: $electricityPeriod,
                  cidFormID: $cidFormID,
                  dateType: $dateType,
                  startDate: $startDate,
                  endDate: $endDate,
                  ownerObjectID: $ownerObjectID,
                  level: $level,
                  issue: $issue,
                  status: $status,
                  timezone: $timezone, 
                  maintainView: $maintainView
                ) {
                  level
                  levelName
                  levelSeq
                  cidFormID
                  createDate
                  occurDate
                  maintainStartDate
                  maintainEndDate
                  ownerObjectID
                  ownerObjectNames
                  issue
                  statusName
                  status
                  invSeq
                  respTypeLevel1Name
                  respTypeLevel2Name
                }   
              }`
            ),
            variables: {
              companyID: companyID,
              electricityPeriod: electricityPeriod,
              cidFormID: conditions.cidFormID,
              dateType: `${conditions.dateType}`,
              startDate: DateFormat(conditions.startDate),
              endDate: DateFormat(conditions.endDate),
              ownerObjectID: conditions.ownerObjectID,
              level: conditions.level,
              issue: conditions.issue,
              status: conditions.status,
              timezone: appConfigs.timezone,
              maintainView: isMaintainMode,
            },
          },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (!IsValidAppSyncRsps(res)) {
            message.warn(t('spv.objectCidStatistics.failedToQueryCidForm', { error: GetAppSyncRspsErrorMessage(res) }));
            return;
          }

          setCidFormList((res.data.data.om_getCidFormListBySPV || []).map(SetKeyToArray));
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            message.error(t('spv.objectCidStatistics.abnormalToQueryCidForm'));
            console.error('load CID list error: ', err);
          }
        });
    },
    [user, companyID, electricityPeriod, isMaintainMode, t]
  );
  useEffect(() => {
    LoadCidFormList({ ...DefaultCidListFilter });
  }, [LoadCidFormList]);

  // -------------------------------------------------

  const handleFilterDataChange = (name) => (e) => {
    let modifyValue = null;
    switch (name) {
      case 'cidFormID':
      case 'issue':
        modifyValue = e?.target?.value;
        break;
      default:
        modifyValue = e;
        break;
    }

    setFilterData((prev) => ({ ...prev, [name]: modifyValue }));
  };
  const handleFilterDataReset = () => {
    setFilterData({ ...DefaultCidListFilter });
  };
  const handleSearch = () => {
    LoadCidFormList(filterData);
  };

  // -------------------------------------------------
  return (
    <div className="app-page-content">
      <div>
        <Card
          title={
            <Space>
              <div>{t('spv.objectCidStatistics.realtimeUnclosedCid', { count: uncloseTotal })}</div>
              <Tooltip
                placement="right"
                title={t('spv.objectCidStatistics.unclosedCidTooltip')}
              >
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
          }
        >
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={24} md={24} lg={4}>
              <div style={{ fontSize: '16px', fontWeight: 'bold', color: '#a9a9a9', marginBottom: '20px' }}>
                {t('spv.objectCidStatistics.processFlow')}
              </div>
              <Timeline>
                <Timeline.Item>
                  <Space>
                    <div>{t('spv.objectCidStatistics.problemClarification')}</div>
                    <div>{uncloseAmount.check}</div>
                    <Tooltip placement="right" title={t('spv.objectCidStatistics.potentialFaultIdentifiedButStillNeedAnalysis')}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Space>
                </Timeline.Item>
                <Timeline.Item>
                  <Space>
                    <div>{t('spv.objectCidStatistics.taskAssignment')}</div>
                    <div>{uncloseAmount.process}</div>
                    <Tooltip placement="right" title={t('spv.objectCidStatistics.problemConfirmedAndReadyForRepair')}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Space>
                </Timeline.Item>
              </Timeline>
            </Col>
            <Col xs={24} sm={24} md={24} lg={20}>
              <div style={{ fontSize: '16px', fontWeight: 'bold', color: '#a9a9a9', marginBottom: '20px' }}>
                {t('spv.objectCidStatistics.cidList')}
              </div>
              <Table
                columns={UncloseFatalTableColumn}
                dataSource={uncloseFatalList}
                footer={false}
                pagination={false}
                size="small"
              />
            </Col>
          </Row>
        </Card>
      </div>

      <div style={{ margin: '16px -16px 0px -16px' }}>
        <iframe
          id="frame"
          width={'100%'}
          height={isMaintainMode ? 1300 : 900}
          title={t('spv.objectCidStatistics.cidStatistics')}
          style={{ border: 0 }}
          src={`${grafanaUrl}&var-spv=${companyID}&var-period=${electricityPeriod}&var-plant=&auth_token=${token}&theme=${theme}`}
        ></iframe>
      </div>

      <div>
        <Card
          title="CID列表"
          extra={
            <Row gutter={[8, 8]} justify="end">
              <Col xs={12} sm={3} md={3} lg={2}>
                <Input
                  placeholder={t('spv.objectCidStatistics.numberID')}
                  value={filterData.cidFormID}
                  onChange={handleFilterDataChange('cidFormID')}
                />
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <Select
                  placeholder={t('spv.objectCidStatistics.dateType')}
                  options={listOfCidDateType}
                  style={{ width: '100%' }}
                  value={filterData.dateType}
                  onChange={handleFilterDataChange('dateType')}
                />
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <DatePicker
                  placeholder={t('spv.objectCidStatistics.startDate')}
                  value={filterData.startDate}
                  onChange={handleFilterDataChange('startDate')}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <DatePicker
                  placeholder={t('spv.objectCidStatistics.endDate')}
                  value={filterData.endDate}
                  onChange={handleFilterDataChange('endDate')}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <Select
                  showSearch
                  placeholder={t('spv.objectCidStatistics.relatedObject')}
                  options={listOfPv}
                  style={{ width: '100%' }}
                  value={filterData.ownerObjectID}
                  onChange={handleFilterDataChange('ownerObjectID')}
                />
              </Col>
              <Col xs={12} sm={3} md={3} lg={2}>
                <Select
                  placeholder={t('spv.objectCidStatistics.levelName')}
                  options={listOfCidLevel}
                  style={{ width: '100%' }}
                  value={filterData.level}
                  onChange={handleFilterDataChange('level')}
                />
              </Col>
              <Col xs={12} sm={3} md={3} lg={2}>
                <Input
                  placeholder={t('spv.objectCidStatistics.issue')}
                  style={{ width: '100%' }}
                  value={filterData.issue}
                  onChange={handleFilterDataChange('issue')}
                />
              </Col>
              <Col xs={12} sm={3} md={3} lg={2}>
                <Select
                  placeholder={t('spv.objectCidStatistics.statusName')}
                  options={listOfCidStatus}
                  style={{ width: '100%' }}
                  value={filterData.status}
                  onChange={handleFilterDataChange('status')}
                />
              </Col>
              <Col xs={12} sm={3} md={3} lg={2}>
                <Button onClick={handleFilterDataReset} style={{ width: '100%' }}>
                  {t('spv.objectCidStatistics.reset')}
                </Button>
              </Col>
              <Col xs={12} sm={3} md={3} lg={2}>
                <Button type="primary" onClick={handleSearch} style={{ width: '100%' }}>
                  {t('spv.objectCidStatistics.search')}
                </Button>
              </Col>
            </Row>
          }
        >
          <Table columns={CidFormTableColumn} dataSource={cidFormList} footer={false} scroll={{ x: 1500 }} />
        </Card>
      </div>
    </div>
  );
};

export default CidStatistics;
