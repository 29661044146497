import React, { useState, useMemo, useCallback, useContext } from 'react';
import axios from 'axios';
import bn from 'bignumber.js';
import { useTranslation } from 'react-i18next';
//
import RfdmeModal from '../../components/Modal';
import RfdmeDisplay from '../../components/Display';
import RfdmeInput from '../../components/Input';
import RfdmeSelect from '../../components/Select';
import {
  Row,
  Col,
  Button,
  Tooltip,
  Tabs,
  Divider,
  message,
  Typography,
  Space,
  Table,
  Card,
  PageHeader,
  Collapse,
} from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
//
import appConfigs from '../../configs';
import useListOfCidLevel from '../../hooks/useListOfCidLevel';
import useListOfCidSource from '../../hooks/useListOfCidSource';
import useListOfCidStatus from '../../hooks/useListOfCidStatus';
import useListOfCidProblemCategory from '../../hooks/useListOfCidProblemCategory';
import useListOfCidRootCause from '../../hooks/useListOfCidRootCause';
import useListOfCidSolution from '../../hooks/useListOfCidSolution';
import useListOfCidRespType from '../../hooks/useListOfCidRespType';
//
import AuthContext from '../../contexts/authProvider';
import { ValidFormData, IsValidApiGatewayRsps, IsNullOrUndefined } from '../../utils/valid';
import { SetKeyToArray } from '../../utils/format';
//
const { Title } = Typography;
const { Panel } = Collapse;
//
const optionsOfRootCauseLevel = [
  {
    key: 1,
    label: 'Level 1',
    value: 1,
  },
  {
    key: 2,
    label: 'Level 2',
    value: 2,
  },
  {
    key: 3,
    label: 'Level 3',
    value: 3,
  },
];

const optionsOfRespTypeLevel = [
  {
    key: 1,
    label: 'Level 1',
    value: 1,
  },
  {
    key: 2,
    label: 'Level 2',
    value: 2,
  },
];
//
const CidConfig = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [dataOfConfig, setDataOfConfig] = useState({});
  const [dataOfTabType, setDataOfTabType] = useState({ type: 'level', typeName: '重要性' });
  const [dataOfRootCause, setDataOfRootCause] = useState({});
  const [dataOfSolution, setDataOfSolution] = useState({});
  const [dataOfRespType, setDataOfRespType] = useState({});
  const { data: listOfLevel, refetch: loadLevel } = useListOfCidLevel();
  const { data: listOfSource, refetch: loadSource } = useListOfCidSource();
  const { data: listOfStatus, refetch: loadStatus } = useListOfCidStatus();
  const { data: listOfProblemCategory, refetch: loadProblemCategory } = useListOfCidProblemCategory();
  const { data: listOfRootCause, refetch: loadRootCause } = useListOfCidRootCause();
  const { data: listOfSolution, refetch: loadSolution } = useListOfCidSolution();
  const { data: listOfRespType, refetch: loadRespType } = useListOfCidRespType();
  //
  const [modalOpen, setModalOpen] = useState(false);
  const [modalRootCauseOpen, setModalRootCauseOpen] = useState(false);
  const [modalSolutionOpen, setModalSolutionOpen] = useState(false);
  const [modalRespTypeOpen, setModalRespTypeOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  //=================================================================
  const handleModalOpen = useCallback(
    (record) => () => {
      setDataOfConfig(record);
      setModalOpen(true);
    },
    []
  );
  const handleModalClose = () => {
    setDataOfConfig({});
    setModalOpen(false);
  };

  //=================================================================
  const optionsOfSolutionLevel = [
    {
      key: 1,
      label: t('cidConfig.solutionCategory'),
      value: 1,
    },
    {
      key: 2,
      label: t('cidConfig.implementedSolution'),
      value: 2,
    },
  ];

  //=================================================================
  const handleDataOfTabTypeChange = (activeKey) => {
    if (!activeKey) return;

    let t = '';
    let tName = '';

    switch (activeKey) {
      case '1':
        t = 'level';
        tName = '重要性';
        break;
      case '2':
        t = 'source';
        tName = '來源';
        break;
      case '3':
        t = 'status';
        tName = '狀態';
        break;
      case '4':
        t = 'problemCategory';
        tName = '問題分類';
        break;
      case '5':
        t = 'rootCause';
        tName = '實際根因';
        break;
      case '6':
        t = 'solution';
        tName = '實施對策';
        break;
      case '7':
        t = 'respType';
        tName = '責任歸屬';
        break;
      default:
        break;
    }

    setDataOfTabType({ type: t, typeName: tName });
  };
  const handleDataOfConfigChange = (name) => (e) => {
    setDataOfConfig((prev) => ({ ...prev, [name]: e.target.value, [`${name}_msg`]: '' }));
  };

  const handleSave = () => {
    // valid
    const validResult = ValidFormData(dataOfConfig, [
      { name: 'seq', type: 'number', required: true },
      { name: 'label', type: 'input', required: true },
    ]);
    if (!validResult.status) {
      setDataOfConfig(validResult.data);
      return;
    }

    //
    const saveData = {};
    saveData.key = dataOfConfig.value;
    saveData.value = dataOfConfig.label;
    saveData.seq = bn(dataOfConfig.seq).toNumber();

    //
    let apiUrl = '';
    if (dataOfTabType.type === 'level') apiUrl = '/oms/cid/config/level/save';
    if (dataOfTabType.type === 'source') apiUrl = '/oms/cid/config/source/save';
    if (dataOfTabType.type === 'status') apiUrl = '/oms/cid/config/status/save';
    if (dataOfTabType.type === 'problemCategory') apiUrl = '/oms/cid/config/problemCategory/save';

    //
    axios
      .post(`${appConfigs.apiGatewayURL}${apiUrl}`, saveData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('cidConfig.failedToSaveSettings', { error: res?.data?.msg }));
          return;
        }

        //reload
        if (dataOfTabType.type === 'level') loadLevel();
        if (dataOfTabType.type === 'source') loadSource();
        if (dataOfTabType.type === 'status') loadStatus();
        if (dataOfTabType.type === 'problemCategory') loadProblemCategory();

        setDataOfConfig({});
        setModalOpen(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('cidConfig.saveConfigError'));
          console.error('save config error: ', err);
        }
      });
  };

  //=================================================================
  const columns = useMemo(() => {
    return [
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        width: 60,
        render: (value, record) => (
          <Space>
            <Tooltip title={t('cidConfig.edit')}>
              <Button icon={<EditOutlined />} onClick={handleModalOpen(record)} />
            </Tooltip>
          </Space>
        ),
      },
      {
        title: t('cidConfig.sequence'),
        dataIndex: 'seq',
        key: 'seq',
        align: 'center',
        width: 90,
      },
      {
        title: t('cidConfig.key'),
        dataIndex: 'value',
        key: 'value',
        align: 'center',
        width: 90,
      },
      {
        title: t('cidConfig.value'),
        dataIndex: 'label',
        key: 'label',
      },
    ];
  }, [handleModalOpen, t]);

  //=================================================================
  const flattenData = useCallback((data) => {
    let result = [];
    let maxLevel3 = 0;

    // Process each item in the data
    data.forEach((item) => {
      const level1 = { label: item.label, key: item.value };
      const rowSpan = item.children.length;
      // Process each child of the item
      if (rowSpan) {
        item.children.forEach((child) => {
          const level2 = { label: child.label, key: child.value };

          // Check if this item has more level3 items than the current maximum
          if (child.children.length > maxLevel3) {
            maxLevel3 = child.children.length;
          }

          // Create an object for each grandchild
          const grandchildren = child.children.map((grandchild, index) => {
            return { [`level3-${index + 1}`]: { label: grandchild.label, key: grandchild.value } };
          });

          // Create a single object that combines all the properties
          const row = {
            level1: level1,
            level1RowSpan: rowSpan,
            level2,
            ...Object.assign({}, ...grandchildren),
          };

          // Add the object to the result
          result.push(row);
        });
      } else {
        result.push({ level1, level1RowSpan: rowSpan });
      }
    });
    return { list: result.map(SetKeyToArray), max: maxLevel3 };
  }, []);

  const listOfLevel1RootCause = useMemo(() => {
    if (!Array.isArray(listOfRootCause) || listOfRootCause.length === 0) return [];

    const items = listOfRootCause.filter((f) => f.level === 1);
    if (items.length === 0) return [];

    return items.map(SetKeyToArray);
  }, [listOfRootCause]);

  const listOfLevel2RootCause = useMemo(() => {
    if (!Array.isArray(listOfRootCause) || listOfRootCause.length === 0) return [];
    if (!dataOfRootCause.parent1) return [];

    const items = listOfRootCause.filter((f) => f.level === 2 && f.parent === dataOfRootCause.parent1);
    if (items.length === 0) return [];

    return items.map(SetKeyToArray);
  }, [listOfRootCause, dataOfRootCause.parent1]);

  // 處理並取得轉換後的根因資料
  const objectOfFlattenRootCause = useMemo(() => {
    if (!Array.isArray(listOfRootCause) || listOfRootCause.length === 0)
      return { list: [], max: 0, parentMap: [] };

    const parentMap = new Map(); //父節點的對應
    const map = new Map(); //節點的對應
    const topLevelNodes = [];
    // 以 value 為 key，將每個 item 存入 map 中。
    listOfRootCause.forEach((item) => {
      const copyItem = { ...item, children: [] };
      map.set(item.value, copyItem);
      if (item.level === 1) {
        // 將 level 1 節點加入 topLevelNodes。
        topLevelNodes.push(copyItem);
      }
    });

    // 建立 parent-children 關係。
    listOfRootCause.forEach((item) => {
      if (item.parent !== null) {
        parentMap.set(item.value, item.parent);
        const parentItem = map.get(item.parent);
        if (parentItem) {
          parentItem.children.push(map.get(item.value));
        }
      }
    });

    // 根據每個節點的 seq 對其子節點進行排序。
    for (let item of map.values()) {
      item.children.sort((a, b) => a.seq - b.seq);
    }
    const tmpList = topLevelNodes.sort((a, b) => a.seq - b.seq);

    return { ...flattenData(tmpList), parentMap: parentMap };
  }, [listOfRootCause, flattenData]);

  const handleModalRootCauseOpen = useCallback(
    (level, key) => () => {
      if (!Array.isArray(listOfRootCause) || listOfRootCause.length === 0) return;
      if (!objectOfFlattenRootCause || objectOfFlattenRootCause.parentMap.length === 0) return;

      if (!level || IsNullOrUndefined(key)) {
        setDataOfRootCause({});
      } else {
        const item = listOfRootCause.find((f) => f.level === level && f.value === key);
        if (!item) return;

        let current = item.value;
        const parents = [];
        if (level > 1) {
          while (current !== undefined) {
            let parentKey = objectOfFlattenRootCause.parentMap.get(current);

            if (parentKey !== undefined) {
              const parent = listOfRootCause.find((f) => f.value === parentKey);
              if (parent) {
                parents.push(parent);
                current = parentKey;
              } else {
                break;
              }
            } else {
              break;
            }
          }
        }
        setDataOfRootCause({ ...item, parents: parents.sort((a, b) => a.level - b.level) });
      }
      setModalRootCauseOpen(true);
    },
    [objectOfFlattenRootCause, listOfRootCause]
  );

  const handleModalRootCauseClose = () => {
    setDataOfRootCause({});
    setModalRootCauseOpen(false);
  };

  const handleDataOfRootCauseChange = (name) => (e) => {
    const modifyValue = e && e.target ? e.target.value : e;
    const tmp = { ...dataOfRootCause };
    tmp[name] = modifyValue;
    delete tmp[`${name}_msg`];

    if (name === 'level' && !modifyValue) {
      tmp.parent1 = null;
      tmp.parent2 = null;
      tmp.label = null;
    }

    setDataOfRootCause(tmp);
  };

  const columnsOfRootCause = useMemo(() => {
    const dataSource = objectOfFlattenRootCause.list;
    if (!Array.isArray(dataSource) || dataSource.length === 0) {
      return [
        {
          title: 'Level 1',
          dataIndex: 'level1',
          key: 'level1',
        },
        {
          title: 'Level 2',
          dataIndex: 'level2',
          key: 'level2',
        },
        {
          title: 'Level 3',
          dataIndex: 'level3',
          key: 'level3',
        },
      ];
    }

    let cols = [
      {
        title: 'Level 1',
        dataIndex: 'level1',
        key: 'level1',
        width: 150,
        fixed: 'left',
        render: (value) => (
          <Space>
            <div>{value?.label}</div>
            {value && (
              <Button
                size="small"
                icon={<EditOutlined />}
                style={{ color: 'rgba(155,155,155,0.5)' }}
                onClick={handleModalRootCauseOpen(1, value?.key)}
              />
            )}
          </Space>
        ),
        onCell: (row, rowIndex) => {
          // Find the number of rows with the same level1 value
          const rowSpan = dataSource.reduce(
            (acc, curRow, curIndex) => (curIndex >= rowIndex && curRow.level1 === row.level1 ? acc + 1 : acc),
            0
          );

          // If the previous row has the same level1 value, set rowSpan to 0
          const prevRow = dataSource[rowIndex - 1];
          if (prevRow && prevRow.level1 === row.level1) {
            return { rowSpan: 0, style: { backgroundColor: 'rgba(95,193,33,0.1)' } };
          }

          // Return the rowSpan
          return { rowSpan, style: { backgroundColor: 'rgba(95,193,33,0.1)' } };
        },
      },
      {
        title: 'Level 2',
        dataIndex: 'level2',
        key: 'level2',
        width: 180,
        fixed: 'left',
        render: (value) => (
          <Space>
            <div>{value?.label}</div>
            {value && (
              <Button
                size="small"
                icon={<EditOutlined />}
                style={{ color: 'rgba(155,155,155,0.5)' }}
                onClick={handleModalRootCauseOpen(2, value?.key)}
              />
            )}
          </Space>
        ),
        onCell: () => {
          return { style: { backgroundColor: 'rgba(11,129,230,0.1)' } };
        },
      },
    ];
    cols.push({
      title: () => {
        return <div style={{ textAlign: 'left' }}>Level 3</div>;
      },
      dataIndex: 'level3-1',
      key: 'level3-1',
      colSpan: objectOfFlattenRootCause.max,
      render: (value) => (
        <Space>
          <div>{value?.label}</div>
          {value && (
            <Button
              size="small"
              icon={<EditOutlined />}
              style={{ color: 'rgba(155,155,155,0.5)' }}
              onClick={handleModalRootCauseOpen(3, value?.key)}
            />
          )}
        </Space>
      ),
    });
    for (let i = 1; i < objectOfFlattenRootCause.max; i++) {
      cols.push({
        dataIndex: `level3-${i + 1}`,
        key: `level3-${i + 1}`,
        render: (value) => (
          <Space>
            <div>{value?.label}</div>
            {value && (
              <Button
                size="small"
                icon={<EditOutlined />}
                style={{ color: 'rgba(155,155,155,0.5)' }}
                onClick={handleModalRootCauseOpen(3, value?.key)}
              />
            )}
          </Space>
        ),
      });
    }
    return cols;
  }, [objectOfFlattenRootCause, handleModalRootCauseOpen]);

  const handleRootCauseSave = () => {
    // valid
    const validResult = ValidFormData(dataOfRootCause, [
      { name: 'level', type: 'number', required: true },
      { name: 'parent2', type: 'number', required: !dataOfRootCause.value && dataOfRootCause.level > 2 },
      { name: 'parent1', type: 'number', required: !dataOfRootCause.value && dataOfRootCause.level > 1 },
      { name: 'label', type: 'input', required: true },
    ]);
    if (!validResult.status) {
      setDataOfRootCause(validResult.data);
      return;
    }

    //
    let parent = dataOfRootCause.parent;
    if (!dataOfRootCause.value && dataOfRootCause.level > 1) {
      //新增
      parent = dataOfRootCause.level === 3 ? dataOfRootCause.parent2 : dataOfRootCause.parent1;
    }
    const saveData = {
      key: dataOfRootCause.value,
      seq: dataOfRootCause.seq,
      value: dataOfRootCause.label,
      level: dataOfRootCause.level,
      parent: parent,
    };

    setSaving(true);
    axios
      .post(`${appConfigs.apiGatewayURL}/oms/cid/config/rootCause/save`, saveData, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('cidConfig.failedToSaveSettings', { error: res?.data?.msg }));
          return;
        }

        //reload
        loadRootCause();

        setDataOfRootCause({});
        setModalRootCauseOpen(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('cidConfig.saveConfigError'));
          console.error('save config error: ', err);
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  //=================================================================
  const listOfLevel1Solution = useMemo(() => {
    if (!Array.isArray(listOfSolution) || listOfSolution.length === 0) return [];

    const items = listOfSolution.filter((f) => f.level === 1);
    if (items.length === 0) return [];

    return items.map(SetKeyToArray);
  }, [listOfSolution]);

  const listOfLevel2RootCauseForSolution = useMemo(() => {
    if (!Array.isArray(listOfRootCause) || listOfRootCause.length === 0) return [];
    if (!dataOfSolution.parent1) return [];

    const items = listOfRootCause.filter((f) => f.level === 2 && f.parent === dataOfSolution.parent1);
    if (items.length === 0) return [];

    return items.map(SetKeyToArray);
  }, [listOfRootCause, dataOfSolution.parent1]);

  // 處理並取得轉換後的對策資料
  const objectOfFlattenSolution = useMemo(() => {
    if (
      !Array.isArray(listOfRootCause) ||
      listOfRootCause.length === 0 ||
      !Array.isArray(listOfSolution) ||
      listOfSolution.length === 0
    )
      return { list: [], max: 0, parentMap: [] };

    const listOfMerge = [
      ...listOfRootCause.filter((f) => f.level < 3),
      ...listOfSolution.filter((f) => f.level === 2).map((m) => ({ ...m, level: 3 })),
    ];

    const parentMap = new Map(); //父節點的對應
    const map = new Map(); //節點的對應
    const topLevelNodes = [];
    // 以 value 為 key，將每個 item 存入 map 中。
    listOfMerge.forEach((item) => {
      const copyItem = { ...item, children: [] };
      map.set(`${item.level}_${item.value}`, copyItem);
      if (item.level === 1) {
        // 將 level 1 節點加入 topLevelNodes。
        topLevelNodes.push(copyItem);
      }
    });

    // 建立 parent-children 關係。
    listOfMerge.forEach((item) => {
      if (item.parent !== null) {
        parentMap.set(`${item.level}_${item.value}`, item.parent);
        const parentItem = map.get(`${item.level - 1}_${item.parent}`);
        if (parentItem) {
          parentItem.children.push(map.get(`${item.level}_${item.value}`));
        }
      }
    });

    // 根據每個節點的 seq 對其子節點進行排序。
    for (let item of map.values()) {
      item.children.sort((a, b) => a.seq - b.seq);
    }
    const tmpList = topLevelNodes.sort((a, b) => a.seq - b.seq);

    return { ...flattenData(tmpList), parentMap: parentMap };
  }, [listOfRootCause, listOfSolution, flattenData]);

  const handleModalSolutionOpen = useCallback(
    (level, key) => () => {
      if (!Array.isArray(listOfSolution) || listOfSolution.length === 0) return;
      if (level === 2 && (!objectOfFlattenSolution || objectOfFlattenSolution.parentMap.length === 0)) return;

      if (!level || IsNullOrUndefined(key)) {
        setDataOfSolution({});
      } else {
        const item = listOfSolution.find((f) => f.level === level && f.value === key);
        if (!item) return;

        let current = `3_${item.value}`;
        const parents = [];
        if (level === 2) {
          while (current !== undefined) {
            let parentKey = objectOfFlattenSolution.parentMap.get(current);

            if (parentKey !== undefined) {
              const parent = listOfRootCause.find((f) => f.value === parentKey);
              if (parent) {
                parents.push(parent);
                current = `${parent.level}_${parentKey}`;
              } else {
                break;
              }
            } else {
              break;
            }
          }
        }
        setDataOfSolution({ ...item, parents: parents.sort((a, b) => a.level - b.level) });
      }
      setModalSolutionOpen(true);
    },
    [listOfSolution, objectOfFlattenSolution, listOfRootCause]
  );

  const handleModalSolutionClose = () => {
    setDataOfSolution({});
    setModalSolutionOpen(false);
  };

  const handleDataOfSolutionChange = (name) => (e) => {
    const modifyValue = e && e.target ? e.target.value : e;
    const tmp = { ...dataOfSolution };
    tmp[name] = modifyValue;
    delete tmp[`${name}_msg`];

    if (name === 'level') {
      if (!modifyValue) {
        tmp.parent1 = null;
        tmp.parent2 = null;
        tmp.label = null;
        tmp.seq = null;
      } else if (modifyValue === 1) {
        if (!tmp.seq) {
          const maxSeq = Math.max(...listOfLevel1Solution.map((o) => o.seq)) || 0;
          tmp.seq = maxSeq + 1;
        }
      }
    }

    setDataOfSolution(tmp);
  };

  const columnsOfLevel1Solution = useMemo(() => {
    return [
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        width: 60,
        render: (value, record) => (
          <Space>
            <Tooltip title={t('cidConfig.edit')}>
              <Button icon={<EditOutlined />} onClick={handleModalSolutionOpen(1, record.value)} />
            </Tooltip>
          </Space>
        ),
      },
      {
        title: t('cidConfig.sequence'),
        dataIndex: 'seq',
        key: 'seq',
        align: 'center',
        width: 70,
      },
      {
        title: t('cidConfig.value'),
        dataIndex: 'label',
        key: 'label',
      },
    ];
  }, [handleModalSolutionOpen, t]);

  const columnsOfLevel2Solution = useMemo(() => {
    const dataSource = objectOfFlattenSolution.list;
    if (!Array.isArray(dataSource) || dataSource.length === 0) {
      return [
        {
          title: t('cidConfig.actualRootCauseLevel', { level: 1 }),
          dataIndex: 'level1',
          key: 'level1',
        },
        {
          title: t('cidConfig.actualRootCauseLevel', { level: 2 }),
          dataIndex: 'level2',
          key: 'level2',
        },
        {
          title: t('cidConfig.implementedSolution'),
          dataIndex: 'level3',
          key: 'level3',
        },
      ];
    }

    let cols = [
      {
        title: t('cidConfig.actualRootCauseLevel', { level: 1 }),
        dataIndex: 'level1',
        key: 'level1',
        width: 150,
        fixed: 'left',
        onCell: (row, rowIndex) => {
          // Find the number of rows with the same level1 value
          const rowSpan = dataSource.reduce(
            (acc, curRow, curIndex) => (curIndex >= rowIndex && curRow.level1 === row.level1 ? acc + 1 : acc),
            0
          );

          // If the previous row has the same level1 value, set rowSpan to 0
          const prevRow = dataSource[rowIndex - 1];
          if (prevRow && prevRow.level1 === row.level1) {
            return { rowSpan: 0, style: { backgroundColor: 'rgba(95,193,33,0.1)' } };
          }

          // Return the rowSpan
          return { rowSpan, style: { backgroundColor: 'rgba(95,193,33,0.1)' } };
        },
        render: (value) => value && value.label,
      },
      {
        title: t('cidConfig.actualRootCauseLevel', { level: 2 }),
        dataIndex: 'level2',
        key: 'level2',
        width: 180,
        fixed: 'left',
        onCell: () => {
          return { style: { backgroundColor: 'rgba(11,129,230,0.1)' } };
        },
        render: (value) => value && value.label,
      },
    ];

    cols.push({
      title: () => {
        return <div style={{ textAlign: 'left' }}>{t('cidConfig.implementedSolution')}</div>;
      },
      dataIndex: 'level3-1',
      key: 'level3-1',
      colSpan: objectOfFlattenSolution.max,
      render: (value) => (
        <Space>
          <div>{value?.label}</div>
          {value && (
            <Button
              size="small"
              icon={<EditOutlined />}
              style={{ color: 'rgba(155,155,155,0.5)' }}
              onClick={handleModalSolutionOpen(2, value?.key)}
            />
          )}
        </Space>
      ),
    });
    for (let i = 1; i < objectOfFlattenSolution.max; i++) {
      cols.push({
        dataIndex: `level3-${i + 1}`,
        key: `level3-${i + 1}`,
        render: (value) => (
          <Space>
            <div>{value?.label}</div>
            {value && (
              <Button
                size="small"
                icon={<EditOutlined />}
                style={{ color: 'rgba(155,155,155,0.5)' }}
                onClick={handleModalSolutionOpen(2, value?.key)}
              />
            )}
          </Space>
        ),
      });
    }
    return cols;
  }, [objectOfFlattenSolution, handleModalSolutionOpen, t]);

  const handleSolutionSave = () => {
    // valid
    const validResult = ValidFormData(dataOfSolution, [
      { name: 'level', type: 'number', required: true },
      { name: 'seq', type: 'number', required: dataOfSolution.level === 1 },
      { name: 'parent2', type: 'number', required: !dataOfSolution.value && dataOfSolution.level > 1 },
      { name: 'parent1', type: 'number', required: !dataOfSolution.value && dataOfSolution.level > 1 },
      { name: 'label', type: 'input', required: true },
    ]);
    if (!validResult.status) {
      setDataOfSolution(validResult.data);
      return;
    }

    //
    let parent = dataOfSolution.parent;
    if (!dataOfSolution.value && dataOfSolution.level > 1) {
      //新增
      parent = dataOfSolution.parent2;
    }
    const saveData = {
      key: dataOfSolution.value,
      seq: dataOfSolution.seq,
      value: dataOfSolution.label,
      level: dataOfSolution.level > 1 ? 2 : dataOfSolution.level,
      parent: parent,
    };

    setSaving(true);
    axios
      .post(`${appConfigs.apiGatewayURL}/oms/cid/config/solution/save`, saveData, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('cidConfig.failedToSaveSettings', { error: res?.data?.msg }));
          return;
        }

        //reload
        loadSolution();

        setDataOfSolution({});
        setModalSolutionOpen(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('cidConfig.saveConfigError'));
          console.error('save config error: ', err);
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  //=================================================================

  const respTypeFlattenData = useCallback((data) => {
    let result = [];
    let maxLevel2 = 0;

    // Process each item in the data
    data.forEach((item) => {
      const level1 = { label: item.label, key: item.value };
      const rowSpan = item.children.length;
      // Process each child of the item
      if (rowSpan) {
        if (item.children.length > maxLevel2) {
          maxLevel2 = item.children.length;
        }

        // Create an object for each grandchild
        const grandchildren = item.children.map((grandchild, index) => {
          return { [`level2-${index + 1}`]: { label: grandchild.label, key: grandchild.value } };
        });

        // Create a single object that combines all the properties
        const row = {
          level1: level1,
          level1RowSpan: rowSpan,
          ...Object.assign({}, ...grandchildren),
        };

        // Add the object to the result
        result.push(row);
      } else {
        result.push({ level1, level1RowSpan: rowSpan });
      }
    });
    return { list: result.map(SetKeyToArray), max: maxLevel2 };
  }, []);

  const listOfLevel1RespType = useMemo(() => {
    if (!Array.isArray(listOfRespType) || listOfRespType.length === 0) return [];

    const items = listOfRespType.filter((f) => f.level === 1);
    if (items.length === 0) return [];

    return items.map(SetKeyToArray);
  }, [listOfRespType]);

  const objectOfFlattenRespType = useMemo(() => {
    if (!Array.isArray(listOfRespType) || listOfRespType.length === 0)
      return { list: [], max: 0, parentMap: [] };

    const parentMap = new Map(); //父節點的對應
    const map = new Map(); //節點的對應
    const topLevelNodes = [];
    // 以 value 為 key，將每個 item 存入 map 中。
    listOfRespType.forEach((item) => {
      const copyItem = { ...item, children: [] };
      map.set(item.value, copyItem);
      if (item.level === 1) {
        // 將 level 1 節點加入 topLevelNodes。
        topLevelNodes.push(copyItem);
      }
    });

    // 建立 parent-children 關係。
    listOfRespType.forEach((item) => {
      if (item.parent !== null) {
        parentMap.set(item.value, item.parent);
        const parentItem = map.get(item.parent);
        if (parentItem) {
          parentItem.children.push(map.get(item.value));
        }
      }
    });

    // 根據每個節點的 seq 對其子節點進行排序。
    for (let item of map.values()) {
      item.children.sort((a, b) => a.seq - b.seq);
    }
    const tmpList = topLevelNodes.sort((a, b) => a.seq - b.seq);

    return { ...respTypeFlattenData(tmpList), parentMap: parentMap };
  }, [listOfRespType, respTypeFlattenData]);

  const handleModalRespTypeOpen = useCallback(
    (level, key) => () => {
      if (!Array.isArray(listOfRespType) || listOfRespType.length === 0) return;
      if (!objectOfFlattenRespType || objectOfFlattenRespType.parentMap.length === 0) return;

      if (!level || IsNullOrUndefined(key)) {
        setDataOfRespType({});
      } else {
        const item = listOfRespType.find((f) => f.level === level && f.value === key);
        if (!item) return;

        let current = item.value;
        const parents = [];
        if (level > 1) {
          while (current !== undefined) {
            let parentKey = objectOfFlattenRespType.parentMap.get(current);

            if (parentKey !== undefined) {
              const parent = listOfRespType.find((f) => f.value === parentKey);
              if (parent) {
                parents.push(parent);
                current = parentKey;
              } else {
                break;
              }
            } else {
              break;
            }
          }
        }
        setDataOfRespType({ ...item, parents: parents.sort((a, b) => a.level - b.level) });
      }
      setModalRespTypeOpen(true);
    },
    [objectOfFlattenRespType, listOfRespType]
  );

  const handleModalRespTypeClose = () => {
    setDataOfRespType({});
    setModalRespTypeOpen(false);
  };

  const handleDataOfRespTypeChange = (name) => (e) => {
    const modifyValue = e && e.target ? e.target.value : e;
    const tmp = { ...dataOfRespType };
    tmp[name] = modifyValue;
    delete tmp[`${name}_msg`];

    if (name === 'level' && !modifyValue) {
      tmp.parent1 = null;
      tmp.parent2 = null;
      tmp.label = null;
    }

    setDataOfRespType(tmp);
  };

  const columnsOfRespType = useMemo(() => {
    const dataSource = objectOfFlattenRespType.list;
    if (!Array.isArray(dataSource) || dataSource.length === 0) {
      return [
        {
          title: 'Level 1',
          dataIndex: 'level1',
          key: 'level1',
        },
        {
          title: 'Level 2',
          dataIndex: 'level2',
          key: 'level2',
        },
      ];
    }

    let cols = [
      {
        title: 'Level 1',
        dataIndex: 'level1',
        key: 'level1',
        width: 150,
        fixed: 'left',
        render: (value) => (
          <Space>
            <div>{value?.label}</div>
            {value && (
              <Button
                size="small"
                icon={<EditOutlined />}
                style={{ color: 'rgba(155,155,155,0.5)' }}
                onClick={handleModalRespTypeOpen(1, value?.key)}
              />
            )}
          </Space>
        ),
        onCell: (row, rowIndex) => {
          // Find the number of rows with the same level1 value
          const rowSpan = dataSource.reduce(
            (acc, curRow, curIndex) => (curIndex >= rowIndex && curRow.level1 === row.level1 ? acc + 1 : acc),
            0
          );

          // If the previous row has the same level1 value, set rowSpan to 0
          const prevRow = dataSource[rowIndex - 1];
          if (prevRow && prevRow.level1 === row.level1) {
            return { rowSpan: 0, style: { backgroundColor: 'rgba(95,193,33,0.1)' } };
          }

          // Return the rowSpan
          return { rowSpan, style: { backgroundColor: 'rgba(95,193,33,0.1)' } };
        },
      },
    ];
    cols.push({
      title: () => {
        return <div style={{ textAlign: 'left' }}>Level 2</div>;
      },
      dataIndex: 'level2-1',
      key: 'level2-1',
      colSpan: objectOfFlattenRespType.max,
      render: (value) => (
        <Space>
          <div>{value?.label}</div>
          {value && (
            <Button
              size="small"
              icon={<EditOutlined />}
              style={{ color: 'rgba(155,155,155,0.5)' }}
              onClick={handleModalRespTypeOpen(2, value?.key)}
            />
          )}
        </Space>
      ),
    });
    for (let i = 1; i < objectOfFlattenRespType.max; i++) {
      cols.push({
        dataIndex: `level2-${i + 1}`,
        key: `level2-${i + 1}`,
        render: (value) => (
          <Space>
            <div>{value?.label}</div>
            {value && (
              <Button
                size="small"
                icon={<EditOutlined />}
                style={{ color: 'rgba(155,155,155,0.5)' }}
                onClick={handleModalRespTypeOpen(2, value?.key)}
              />
            )}
          </Space>
        ),
      });
    }
    return cols;
  }, [objectOfFlattenRespType, handleModalRespTypeOpen]);

  const handleRespTypeSave = () => {
    // valid
    const validResult = ValidFormData(dataOfRespType, [
      { name: 'level', type: 'number', required: true },
      { name: 'parent1', type: 'number', required: !dataOfRespType.value && dataOfRespType.level > 1 },
      { name: 'label', type: 'input', required: true },
    ]);
    if (!validResult.status) {
      setDataOfRespType(validResult.data);
      return;
    }

    //
    let parent = dataOfRespType.parent;
    if (!dataOfRespType.value && dataOfRespType.level > 1) {
      //新增
      parent = dataOfRespType.parent1;
    }
    const saveData = {
      key: dataOfRespType.value,
      seq: dataOfRespType.seq,
      value: dataOfRespType.label,
      level: dataOfRespType.level,
      parent: parent,
    };

    setSaving(true);
    axios
      .post(`${appConfigs.apiGatewayURL}/oms/cid/config/respType/save`, saveData, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('cidConfig.failedToSaveSettings', { error: res?.data?.msg }));
          return;
        }

        //reload
        loadRespType();

        setDataOfRespType({});
        setModalRespTypeOpen(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('cidConfig.saveConfigError'));
          console.error('save config error: ', err);
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <>
      {/* page header */}
      <PageHeader
        className="app-page-header"
        title={t('cidConfig.cidMenuItemManagement')}
        footer={
          <Tabs
            defaultActiveKey="wait"
            onChange={handleDataOfTabTypeChange}
            items={[
              { label: t('cidConfig.defaultImportance'), key: '1' },
              { label: t('cidConfig.source'), key: '2' },
              { label: t('cidConfig.status'), key: '3' },
              { label: t('cidConfig.problemCategory'), key: '4' },
              { label: t('cidConfig.actualRootCause'), key: '5' },
              { label: t('cidConfig.implementedSolution'), key: '6' },
              { label: t('cidConfig.responsibility'), key: '7' },
            ]}
          />
        }
      />

      {/* 重要性 */}
      {dataOfTabType.type === 'level' && (
        <Card size="small">
          <Row gutter={[8, 16]}>
            <Col span={3}>
              <Button type="primary" icon={<PlusOutlined />} onClick={handleModalOpen({})}>
                {t('cidConfig.createOption')}
              </Button>
            </Col>
            <Col span={21} style={{ display: 'flex', alignItems: 'center' }}>
              <>
                <div>{t('cidConfig.cidImportanceDescription')}</div>
              </>
            </Col>
            <Col span={24}>
              <Table
                bordered
                pagination={false}
                size="small"
                columns={columns}
                dataSource={listOfLevel}
                scroll={{ y: 'calc(100vh - 380px)' }}
              />
            </Col>
          </Row>
        </Card>
      )}

      {/* 來源 */}
      {dataOfTabType.type === 'source' && (
        <Card size="small">
          <Row gutter={[8, 16]}>
            <Col>
              <Button type="primary" icon={<PlusOutlined />} onClick={handleModalOpen({})}>
                {t('cidConfig.createOption')}
              </Button>
            </Col>
            <Col span={24}>
              <Table
                bordered
                pagination={false}
                size="small"
                columns={columns}
                dataSource={listOfSource}
                scroll={{ y: 'calc(100vh - 380px)' }}
              />
            </Col>
          </Row>
        </Card>
      )}

      {/* 狀態 */}
      {dataOfTabType.type === 'status' && (
        <Card size="small">
          <Row gutter={[8, 16]}>
            <Col>
              <Button type="primary" icon={<PlusOutlined />} onClick={handleModalOpen({})}>
                {t('cidConfig.createOption')}
              </Button>
            </Col>
            <Col span={24}>
              <Table
                bordered
                pagination={false}
                size="small"
                columns={columns}
                dataSource={listOfStatus}
                scroll={{ y: 'calc(100vh - 380px)' }}
              />
            </Col>
          </Row>
        </Card>
      )}

      {/* 問題分類 */}
      {dataOfTabType.type === 'problemCategory' && (
        <Card size="small">
          <Row gutter={[8, 16]}>
            <Col>
              <Button type="primary" icon={<PlusOutlined />} onClick={handleModalOpen({})}>
                {t('cidConfig.createOption')}
              </Button>
            </Col>
            <Col span={24}>
              <Table
                bordered
                pagination={false}
                size="small"
                columns={columns}
                dataSource={listOfProblemCategory}
                scroll={{ y: 'calc(100vh - 380px)' }}
              />
            </Col>
          </Row>
        </Card>
      )}

      {/* 實際根因 */}
      {dataOfTabType.type === 'rootCause' && (
        <Card size="small">
          <Row gutter={[8, 16]}>
            <Col>
              <Button type="primary" icon={<PlusOutlined />} onClick={handleModalRootCauseOpen()}>
                {t('cidConfig.createOption')}
              </Button>
            </Col>
            <Col span={24}>
              <Table
                bordered
                pagination={false}
                size="small"
                columns={columnsOfRootCause}
                dataSource={objectOfFlattenRootCause.list}
                scroll={{ x: 'max-content', y: 'calc(100vh - 380px)' }}
              />
            </Col>
          </Row>
        </Card>
      )}

      {/* 實施對策 */}
      {dataOfTabType.type === 'solution' && (
        <Card size="small">
          <Row gutter={[8, 16]}>
            <Col>
              <Button type="primary" icon={<PlusOutlined />} onClick={handleModalSolutionOpen()}>
                {t('cidConfig.createOption')}
              </Button>
            </Col>
            <Col span={24}>
              <Collapse accordion>
                <Panel header={t('cidConfig.solutionCategory')} key="1">
                  <Table
                    bordered
                    pagination={false}
                    size="small"
                    columns={columnsOfLevel1Solution}
                    dataSource={listOfLevel1Solution}
                    scroll={{ y: 'calc(100vh - 520px)' }}
                  />
                </Panel>
                <Panel header={t('cidConfig.implementedSolution')} key="2">
                  <Table
                    bordered
                    pagination={false}
                    size="small"
                    columns={columnsOfLevel2Solution}
                    dataSource={objectOfFlattenSolution.list}
                    scroll={{ x: 'max-content', y: 'calc(100vh - 520px)' }}
                  />
                </Panel>
              </Collapse>
            </Col>
            <Col span={24}></Col>
          </Row>
        </Card>
      )}

      {/* 責任歸屬 */}
      {dataOfTabType.type === 'respType' && (
        <Card size="small">
          <Row gutter={[8, 16]}>
            <Col>
              <Button type="primary" icon={<PlusOutlined />} onClick={handleModalRespTypeOpen()}>
                {t('cidConfig.createOption')}
              </Button>
            </Col>
            <Col span={24}>
              <Table
                bordered
                pagination={false}
                size="small"
                columns={columnsOfRespType}
                dataSource={objectOfFlattenRespType.list}
                scroll={{ x: 'max-content', y: 'calc(100vh - 380px)' }}
              />
            </Col>
          </Row>
        </Card>
      )}

      <RfdmeModal width="600px" open={modalOpen}>
        <Title level={4}>
          {dataOfConfig.value ? t('cidConfig.edit') : t('cidConfig.add')}-{dataOfTabType.typeName}
        </Title>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <RfdmeDisplay label={t('cidConfig.key')}>
              {dataOfConfig.value || t('cidConfig.autoSet')}
            </RfdmeDisplay>
          </Col>
          <Col span={12} />

          <Col span={12}>
            <RfdmeInput
              required
              label={t('cidConfig.sequence')}
              value={dataOfConfig.seq}
              msg={dataOfConfig.seq_msg}
              onChange={handleDataOfConfigChange('seq')}
            />
          </Col>
          <Col span={12} />

          <Col span={24}>
            <RfdmeInput
              required
              label={t('cidConfig.value')}
              value={dataOfConfig.label}
              msg={dataOfConfig.label_msg}
              onChange={handleDataOfConfigChange('label')}
            />
          </Col>
        </Row>
        <Divider />
        <Row gutter={[16, 0]} justify="end">
          <Col>
            <Button onClick={handleModalClose}>{t('cidConfig.close')}</Button>
          </Col>
          <Col>
            <Button type="primary" onClick={handleSave}>
              {t('cidConfig.save')}
            </Button>
          </Col>
        </Row>
      </RfdmeModal>

      {/* 實際根因彈框 */}
      <RfdmeModal width="600px" open={modalRootCauseOpen}>
        <Title level={4}>
          {dataOfRootCause.value ? t('cidConfig.edit') : t('cidConfig.add')}-{dataOfTabType.typeName}
        </Title>
        <Row gutter={[16, 16]}>
          {dataOfRootCause.value ? (
            <>
              {dataOfRootCause.parents?.map((p) => (
                <Col span={24}>
                  <RfdmeInput addonBefore={`Level ${p.level}`} value={p.label} disabled />
                </Col>
              ))}
              <Col span={24}>
                <RfdmeInput
                  required
                  addonBefore={`Level ${dataOfRootCause.level}`}
                  value={dataOfRootCause.label}
                  msg={dataOfRootCause.label_msg}
                  onChange={handleDataOfRootCauseChange('label')}
                />
              </Col>
            </>
          ) : (
            <>
              <Col span={6}>
                <RfdmeSelect
                  required
                  label={t('cidConfig.level')}
                  options={optionsOfRootCauseLevel}
                  value={dataOfRootCause.level}
                  msg={dataOfRootCause.level_msg}
                  onChange={handleDataOfRootCauseChange('level')}
                />
              </Col>
              <Col span={18} />
              {dataOfRootCause.level > 1 && (
                <Col span={24}>
                  <RfdmeSelect
                    required
                    label="Level 1"
                    options={listOfLevel1RootCause}
                    value={dataOfRootCause.parent1}
                    msg={dataOfRootCause.parent1_msg}
                    onChange={handleDataOfRootCauseChange('parent1')}
                  />
                </Col>
              )}
              {dataOfRootCause.level > 2 && (
                <Col span={24}>
                  <RfdmeSelect
                    required
                    label="Level 2"
                    options={listOfLevel2RootCause}
                    value={dataOfRootCause.parent2}
                    msg={dataOfRootCause.parent2_msg}
                    onChange={handleDataOfRootCauseChange('parent2')}
                  />
                </Col>
              )}
              {dataOfRootCause.level && (
                <Col span={24}>
                  <RfdmeInput
                    required
                    label={t('cidConfig.name')}
                    value={dataOfRootCause.label}
                    msg={dataOfRootCause.label_msg}
                    onChange={handleDataOfRootCauseChange('label')}
                  />
                </Col>
              )}
            </>
          )}
        </Row>
        <Divider />
        <Row gutter={[8, 0]} justify="end">
          <Col>
            <Button onClick={handleModalRootCauseClose} disabled={saving}>
              {t('close')}
            </Button>
          </Col>
          <Col>
            <Button type="primary" loading={saving} onClick={handleRootCauseSave}>
              {t('save')}
            </Button>
          </Col>
        </Row>
      </RfdmeModal>

      {/* 實施對策彈框 */}
      <RfdmeModal width="600px" open={modalSolutionOpen}>
        <Title level={4}>
          {dataOfSolution.value ? t('cidConfig.edit') : t('cidConfig.add')}-{dataOfTabType.typeName}
        </Title>
        <Row gutter={[16, 16]}>
          {dataOfSolution.value && dataOfSolution.level === 2 ? (
            <>
              {dataOfSolution.parents?.map((p) => (
                <Col span={24}>
                  <RfdmeInput addonBefore={`Level ${p.level}`} value={p.label} disabled />
                </Col>
              ))}
              <Col span={24}>
                <RfdmeInput
                  required
                  addonBefore={`${t('cidConfig.implementedSolution')}`}
                  value={dataOfSolution.label}
                  msg={dataOfSolution.label_msg}
                  onChange={handleDataOfSolutionChange('label')}
                />
              </Col>
            </>
          ) : (
            <>
              {!dataOfSolution.value && (
                <>
                  <Col span={12}>
                    <RfdmeSelect
                      required
                      label={t('cidConfig.level')}
                      options={optionsOfSolutionLevel}
                      value={dataOfSolution.level}
                      msg={dataOfSolution.level_msg}
                      onChange={handleDataOfSolutionChange('level')}
                    />
                  </Col>
                  <Col span={12} />
                </>
              )}
              {dataOfSolution.level === 1 && (
                <>
                  <Col span={12}>
                    <RfdmeInput
                      required
                      label={t('cidConfig.sequence')}
                      value={dataOfSolution.seq}
                      msg={dataOfSolution.seq_msg}
                      onChange={handleDataOfSolutionChange('seq')}
                    />
                  </Col>
                  <Col span={12} />

                  <Col span={24}>
                    <RfdmeInput
                      required
                      label={t('cidConfig.value')}
                      value={dataOfSolution.label}
                      msg={dataOfSolution.label_msg}
                      onChange={handleDataOfSolutionChange('label')}
                    />
                  </Col>
                </>
              )}
              {dataOfSolution.level === 2 && (
                <>
                  <Col span={24}>
                    <RfdmeSelect
                      required
                      label="Level 1"
                      options={listOfLevel1RootCause}
                      value={dataOfSolution.parent1}
                      msg={dataOfSolution.parent1_msg}
                      onChange={handleDataOfSolutionChange('parent1')}
                    />
                  </Col>
                  <Col span={24}>
                    <RfdmeSelect
                      required
                      label="Level 2"
                      options={listOfLevel2RootCauseForSolution}
                      value={dataOfSolution.parent2}
                      msg={dataOfSolution.parent2_msg}
                      onChange={handleDataOfSolutionChange('parent2')}
                    />
                  </Col>
                  {dataOfSolution.level && (
                    <Col span={24}>
                      <RfdmeInput
                        required
                        label={t('cidConfig.value')}
                        value={dataOfSolution.label}
                        msg={dataOfSolution.label_msg}
                        onChange={handleDataOfSolutionChange('label')}
                      />
                    </Col>
                  )}
                </>
              )}
            </>
          )}
        </Row>
        <Divider />
        <Row gutter={[8, 0]} justify="end">
          <Col>
            <Button onClick={handleModalSolutionClose} disabled={saving}>
              {t('close')}
            </Button>
          </Col>
          <Col>
            <Button type="primary" loading={saving} onClick={handleSolutionSave}>
              {t('save')}
            </Button>
          </Col>
        </Row>
      </RfdmeModal>

      {/* 責任歸屬彈框 */}
      <RfdmeModal width="600px" open={modalRespTypeOpen}>
        <Title level={4}>
          {dataOfRespType.value ? t('cidConfig.edit') : t('cidConfig.add')}-{dataOfTabType.typeName}
        </Title>
        <Row gutter={[16, 16]}>
          {dataOfRespType.value ? (
            <>
              {dataOfRespType.parents?.map((p) => (
                <Col span={24}>
                  <RfdmeInput addonBefore={`Level ${p.level}`} value={p.label} disabled />
                </Col>
              ))}
              <Col span={24}>
                <RfdmeInput
                  required
                  addonBefore={`Level ${dataOfRespType.level}`}
                  value={dataOfRespType.label}
                  msg={dataOfRespType.label_msg}
                  onChange={handleDataOfRespTypeChange('label')}
                />
              </Col>
            </>
          ) : (
            <>
              <Col span={6}>
                <RfdmeSelect
                  required
                  label={t('cidConfig.level')}
                  options={optionsOfRespTypeLevel}
                  value={dataOfRespType.level}
                  msg={dataOfRespType.level_msg}
                  onChange={handleDataOfRespTypeChange('level')}
                />
              </Col>
              <Col span={18} />
              {dataOfRespType.level > 1 && (
                <Col span={24}>
                  <RfdmeSelect
                    required
                    label="Level 1"
                    options={listOfLevel1RespType}
                    value={dataOfRespType.parent1}
                    msg={dataOfRespType.parent1_msg}
                    onChange={handleDataOfRespTypeChange('parent1')}
                  />
                </Col>
              )}
              {dataOfRespType.level && (
                <Col span={24}>
                  <RfdmeInput
                    required
                    label={t('cidConfig.name')}
                    value={dataOfRespType.label}
                    msg={dataOfRespType.label_msg}
                    onChange={handleDataOfRespTypeChange('label')}
                  />
                </Col>
              )}
            </>
          )}
        </Row>
        <Divider />
        <Row gutter={[8, 0]} justify="end">
          <Col>
            <Button onClick={handleModalRespTypeClose} disabled={saving}>
              {t('close')}
            </Button>
          </Col>
          <Col>
            <Button type="primary" loading={saving} onClick={handleRespTypeSave}>
              {t('save')}
            </Button>
          </Col>
        </Row>
      </RfdmeModal>
    </>
  );
};

export default CidConfig;
