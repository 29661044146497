import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
//
import RfdmeSelect from '../../components/Select';
import RfdmeInput from '../../components/Input';
import RfdmeDatePicker from '../../components/DatePicker';
import { Button, Row, Col, Divider, Typography, message } from 'antd';
//
import useListOfOmDateType from '../../hooks/useListOfOmDateType';
import useListOfOmSource from '../../hooks/useListOfOmSource';
import useListOfOmUser from '../../hooks/useListOfOmUser';
import useListOfOmStatus from '../../hooks/useListOfOmStatus';
import useListOfOmExecutionResult from '../../hooks/useListOfOmExecutionResult';
import useListOfOmAction from '../../hooks/useListOfOmAction';
import useListOfOmExtraPv from '../../hooks/useListOfOmExtraPv';
import useListOfCidDateType from '../../hooks/useListOfCidDateType';
import useListOfCidLevel from '../../hooks/useListOfCidLevel';
import useListOfCidStatus from '../../hooks/useListOfCidStatus';
import useListOfCidSource from '../../hooks/useListOfCidSource';
import useListOfCidUserInCharge from '../../hooks/useListOfCidUserInCharge';
import useListOfCidProblemCategory from '../../hooks/useListOfCidProblemCategory';
import useListOfCidErrorCode from '../../hooks/useListOfCidErrorCode';
//
import AuthContext from '../../contexts/authProvider';
import { SetKeyToArray } from '../../utils/format';
import { QueryOmContractLevelList } from '../../utils/dataHelpers';
import {
  INITIAL_FILTER_DATA,
  GetDataOfFilterFromSession,
  SetDataOfFilterToSession,
  SetDefaultDataOfFilterToSession,
} from './data';
import { useTranslation } from 'react-i18next';

//
const { Title } = Typography;
const ScrollDiv = styled.div`
  width: 100%;
  max-height: calc(100vh - 350px);
  padding-right: 10px;
  overflow-x: hidden;
  overflow-y: auto;
`;

//
const OmWorkFilter = ({ searchFn, closeFn }) => {
  //
  const { user } = useContext(AuthContext);
  const [dataOfFilter, setDataOfFilter] = useState({ ...INITIAL_FILTER_DATA });
  //
  const { data: listOfOmDateType } = useListOfOmDateType();
  const { data: listOfOmSource } = useListOfOmSource();
  const { data: listOfOmExtraPv } = useListOfOmExtraPv();
  const { data: listOfOmUser } = useListOfOmUser();
  const { data: listOfOmStatus } = useListOfOmStatus();
  const { data: listOfOmExecutionResult } = useListOfOmExecutionResult();
  const { data: listOfOmAction } = useListOfOmAction();
  const { data: listOfCidDateType } = useListOfCidDateType();
  const { data: listOfDefaultCidLevel } = useListOfCidLevel();
  const { data: listOfCidStatus } = useListOfCidStatus();
  const { data: listOfCidSource } = useListOfCidSource();
  const { data: listOfCidUserInCharge } = useListOfCidUserInCharge();
  const { data: listOfCidProblemCategory } = useListOfCidProblemCategory();
  const { data: listOfCidErrorCode } = useListOfCidErrorCode();
  const [listOfCidLevel, setListOfCidLevel] = useState([]);
  const { t } = useTranslation();

  // 隨案場選擇來變更重要性
  useEffect(() => {
    async function fetchData() {
      let items = [];
      const queryObjectIDs =
        !Array.isArray(dataOfFilter?.objectIDs) || dataOfFilter.objectIDs.length < 1
          ? []
          : dataOfFilter.objectIDs;

      const getOmContractLevelList = await QueryOmContractLevelList(user, [], queryObjectIDs);

      if (queryObjectIDs.length > 0) {
        if (getOmContractLevelList.length > 0) {
          items = [...getOmContractLevelList.sort((a, b) => a.key - b.key)];
        } else {
          items = [...listOfDefaultCidLevel];
        }
      } else {
        items = [...getOmContractLevelList.sort((a, b) => a.key - b.key)];
        listOfDefaultCidLevel.forEach((defaultItem) => {
          const exists = getOmContractLevelList.some((item) => item.label === defaultItem.label);

          if (!exists) {
            // 如果不存在，則將 defaultItem 加入到 getOmContractLevelList
            items.push(defaultItem);
          }
        });
      }

      items = items.map((m) => ({
        label: m.label,
        value: m.label,
      }));

      setListOfCidLevel(items.map(SetKeyToArray));
      setDataOfFilter((currentData) => {
        return { ...currentData, cidLevelNames: [] };
      });
    }
    fetchData();
  }, [user, listOfDefaultCidLevel, dataOfFilter.objectIDs]);

  // =====================
  // load temporary(default) filter conditions.
  useEffect(() => {
    const data = GetDataOfFilterFromSession();
    if (data) {
      setDataOfFilter(data);
    } else {
      SetDefaultDataOfFilterToSession();
    }
  }, []);

  // =====================
  const handleDataOfFilterChange = (name) => (e) => {
    const modifyValue = e && e.target ? e.target.value : e;
    const tmp = { ...dataOfFilter };
    tmp[name] = modifyValue;

    if (name === 'omDateType' && !modifyValue) {
      tmp.omStartTime = null;
      tmp.omEndTime = null;
    }

    if (name === 'cidDateType' && !modifyValue) {
      tmp.cidStartDate = null;
      tmp.cidEndDate = null;
    }

    setDataOfFilter(tmp);
  };
  const handleSearch = () => {
    if ((dataOfFilter.omStartTime || dataOfFilter.omEndTime) && !dataOfFilter.omDateType) {
      message.warn(t('omWorkList.filter.pleaseSelectOmDateType'));
      return;
    }
    if ((dataOfFilter.cidStartDate || dataOfFilter.cidEndDate) && !dataOfFilter.cidDateType) {
      message.warn(t('omWorkList.filter.pleaseSelectCidDateType'));
      return;
    }

    SetDataOfFilterToSession(dataOfFilter);
    searchFn && searchFn();
    closeFn && closeFn();
  };
  const handleSetAsDefault = () => {
    SetDefaultDataOfFilterToSession();
    setDataOfFilter({ ...INITIAL_FILTER_DATA });
  };
  const handleClose = () => {
    closeFn && closeFn();
  };

  // ------------------------------------

  return (
    <>
      <Title level={3}>{t('omWorkList.filter.filterConditions')}</Title>
      <ScrollDiv>
        <Divider orientation="left">{t('omWorkList.filter.omProcessConditions')}</Divider>
        <Row gutter={[16, 8]}>
          <Col span={6}>
            <RfdmeDatePicker
              label={t('omWorkList.filter.workStartDateBegin')}
              value={dataOfFilter.workStartDateBegin}
              onChange={handleDataOfFilterChange('workStartDateBegin')}
            />
          </Col>
          <Col span={6}>
            <RfdmeDatePicker
              label={t('omWorkList.filter.workStartDateEnd')}
              value={dataOfFilter.workStartDateEnd}
              onChange={handleDataOfFilterChange('workStartDateEnd')}
            />
          </Col>
          <Col span={6}>
            <RfdmeDatePicker
              label={t('omWorkList.filter.workEndDateBegin')}
              value={dataOfFilter.workEndDateBegin}
              onChange={handleDataOfFilterChange('workEndDateBegin')}
            />
          </Col>
          <Col span={6}>
            <RfdmeDatePicker
              label={t('omWorkList.filter.workEndDateEnd')}
              value={dataOfFilter.workEndDateEnd}
              onChange={handleDataOfFilterChange('workEndDateEnd')}
            />
          </Col>
          <Col span={12}>
            <RfdmeSelect
              mode="multiple"
              label={t('omWorkList.filter.actualAttendees')}
              options={listOfOmUser}
              value={dataOfFilter.workUserIDs}
              onChange={handleDataOfFilterChange('workUserIDs')}
            />
          </Col>
          <Col span={12}>
            <RfdmeSelect
              mode="multiple"
              label={t('omWorkList.filter.relatedObject')}
              options={listOfOmExtraPv}
              value={dataOfFilter.objectIDs}
              onChange={handleDataOfFilterChange('objectIDs')}
            />
          </Col>
          <Col span={12}>
            <RfdmeInput
              label={t('omWorkList.filter.workContent')}
              value={dataOfFilter.omWorkContent}
              onChange={handleDataOfFilterChange('omWorkContent')}
            />
          </Col>
          <Col span={12}>
            <RfdmeInput
              label={t('omWorkList.filter.recommend')}
              value={dataOfFilter.omRecommend}
              onChange={handleDataOfFilterChange('omRecommend')}
            />
          </Col>
        </Row>

        <Divider orientation="left">{t('omWorkList.filter.omFormConditions')}</Divider>
        <Row gutter={[16, 8]}>
          <Col span={6}>
            <RfdmeInput
              label={t('omWorkList.filter.omNumber')}
              value={dataOfFilter.omFormID}
              onChange={handleDataOfFilterChange('omFormID')}
            />
          </Col>
          <Col span={6}>
            <RfdmeSelect
              label={t('omWorkList.filter.omDateType')}
              options={listOfOmDateType}
              value={dataOfFilter.omDateType}
              onChange={handleDataOfFilterChange('omDateType')}
            />
          </Col>
          <Col span={6}>
            <RfdmeDatePicker
              label={t('omWorkList.filter.omStartDate')}
              value={dataOfFilter.omStartTime}
              onChange={handleDataOfFilterChange('omStartTime')}
            />
          </Col>
          <Col span={6}>
            <RfdmeDatePicker
              label={t('omWorkList.filter.omEndDate')}
              value={dataOfFilter.omEndTime}
              onChange={handleDataOfFilterChange('omEndTime')}
            />
          </Col>
          <Col span={8}>
            <RfdmeSelect
              mode="multiple"
              label={t('omWorkList.filter.omSource')}
              options={listOfOmSource}
              value={dataOfFilter.omSourceIDs}
              onChange={handleDataOfFilterChange('omSourceIDs')}
            />
          </Col>
          <Col span={8}>
            <RfdmeSelect
              mode="multiple"
              label={t('omWorkList.filter.omStatus')}
              options={listOfOmStatus}
              value={dataOfFilter.omStatusIDs}
              onChange={handleDataOfFilterChange('omStatusIDs')}
            />
          </Col>
          <Col span={8}>
            <RfdmeSelect
              mode="multiple"
              label={t('omWorkList.filter.omActionIDs')}
              options={listOfOmAction}
              value={dataOfFilter.omActionIDs}
              onChange={handleDataOfFilterChange('omActionIDs')}
            />
          </Col>
          <Col span={8}>
            <RfdmeSelect
              mode="multiple"
              label={t('omWorkList.filter.omExecutionResult')}
              options={listOfOmExecutionResult}
              value={dataOfFilter.omExecutionResultIDs}
              onChange={handleDataOfFilterChange('omExecutionResultIDs')}
            />
          </Col>
        </Row>
        <Divider orientation="left">{t('omWorkList.filter.cidFormConditions')}</Divider>
        <Row gutter={[16, 8]}>
          <Col span={6}>
            <RfdmeInput
              label={t('omWorkList.filter.cidNumber')}
              value={dataOfFilter.cidFormID}
              onChange={handleDataOfFilterChange('cidFormID')}
            />
          </Col>
          <Col span={6}>
            <RfdmeSelect
              label={t('omWorkList.filter.cidDateType')}
              options={listOfCidDateType}
              value={dataOfFilter.cidDateType}
              onChange={handleDataOfFilterChange('cidDateType')}
            />
          </Col>
          <Col span={6}>
            <RfdmeDatePicker
              label={t('omWorkList.filter.cidStartDateTime')}
              value={dataOfFilter.cidStartDate}
              onChange={handleDataOfFilterChange('cidStartDate')}
            />
          </Col>
          <Col span={6}>
            <RfdmeDatePicker
              label={t('omWorkList.filter.cidEndDateTime')}
              value={dataOfFilter.cidEndDate}
              onChange={handleDataOfFilterChange('cidEndDate')}
            />
          </Col>

          <Col span={8}>
            <RfdmeSelect
              mode="multiple"
              label={t('omWorkList.filter.cidLevel')}
              options={listOfCidLevel}
              value={dataOfFilter.cidLevelNames}
              onChange={handleDataOfFilterChange('cidLevelNames')}
            />
          </Col>
          <Col span={8}>
            <RfdmeSelect
              mode="multiple"
              label={t('omWorkList.filter.cidStatus')}
              options={listOfCidStatus}
              value={dataOfFilter.cidStatusIDs}
              onChange={handleDataOfFilterChange('cidStatusIDs')}
            />
          </Col>
          <Col span={8}>
            <RfdmeSelect
              mode="multiple"
              label={t('omWorkList.filter.cidSource')}
              options={listOfCidSource}
              value={dataOfFilter.cidSourceIDs}
              onChange={handleDataOfFilterChange('cidSourceIDs')}
            />
          </Col>

          <Col span={12}>
            <RfdmeSelect
              mode="multiple"
              label={t('omWorkList.filter.cidProblemCategory')}
              options={listOfCidProblemCategory}
              value={dataOfFilter.cidProblemCategoryIDs}
              onChange={handleDataOfFilterChange('cidProblemCategoryIDs')}
            />
          </Col>
          <Col span={12}>
            <RfdmeSelect
              mode="multiple"
              label={t('omWorkList.filter.cidErrorCode')}
              options={listOfCidErrorCode}
              value={dataOfFilter.cidErrorCodeIDs}
              onChange={handleDataOfFilterChange('cidErrorCodeIDs')}
            />
          </Col>
          <Col span={12}>
            <RfdmeSelect
              mode="multiple"
              label={t('omWorkList.filter.cidUserInCharge')}
              options={listOfCidUserInCharge}
              value={dataOfFilter.cidUserInChargeIDs}
              onChange={handleDataOfFilterChange('cidUserInChargeIDs')}
            />
          </Col>

          <Col span={12}>
            <RfdmeInput
              label={t('omWorkList.filter.cidTopic')}
              value={dataOfFilter.cidTopic}
              onChange={handleDataOfFilterChange('cidTopic')}
            />
          </Col>
          <Col span={12}>
            <RfdmeInput
              label={t('omWorkList.filter.cidCurrentProgress')}
              value={dataOfFilter.cidCurrentProgress}
              onChange={handleDataOfFilterChange('cidCurrentProgress')}
            />
          </Col>
          <Col span={12}>
            <RfdmeInput
              label={t('omWorkList.filter.cidPossibleRootCause')}
              value={dataOfFilter.cidPossibleRootCause}
              onChange={handleDataOfFilterChange('cidPossibleRootCause')}
            />
          </Col>
          <Col span={12}>
            <RfdmeInput
              label={t('omWorkList.filter.cidRootCause')}
              value={dataOfFilter.cidRootCause}
              onChange={handleDataOfFilterChange('cidRootCause')}
            />
          </Col>
          <Col span={12}>
            <RfdmeInput
              label={t('omWorkList.filter.cidSolution')}
              value={dataOfFilter.cidSolution}
              onChange={handleDataOfFilterChange('cidSolution')}
            />
          </Col>
        </Row>
      </ScrollDiv>
      <Divider />
      <Row gutter={[16, 0]} justify="end">
        <Col>
          <Button onClick={handleSetAsDefault}>{t('omWorkList.filter.reset')}</Button>
        </Col>
        <Col>
          <Button onClick={handleClose}>{t('omWorkList.filter.close')}</Button>
        </Col>
        <Col>
          <Button type="primary" onClick={handleSearch}>
            {t('omWorkList.filter.search')}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default OmWorkFilter;
