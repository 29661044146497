import React, { useCallback, useEffect, useState, useMemo, useContext } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Typography, Button, Row, Col, Space, Spin, message, Table, Switch } from 'antd';
import { ExportOutlined, FilterOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import RfdmeModal from '../../components/Modal';
import RfdmeUpload from '../../components/Upload';
import OmFilter from './filter';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { AppSyncQueryTextFormat, SetKeyToArray, DateFormat } from '../../utils/format';
import { IsValidAppSyncRsps } from '../../utils/valid';
import { GetFileNameFromResponseHeader, GetAppSyncRspsErrorMessage } from '../../utils/parse';
import { GetDataOfFilterFromSession, SetDefaultDataOfFilterToSession } from './data';
//
const { Title } = Typography;

//
const INIT_TABLE_INFO = { sortColumn: null, sortDirection: 'ascend', page: 1, pageSize: 20 };
const GenerateQueryConditions = (tableInfo) => {
  const conditions = GetDataOfFilterFromSession();

  if (conditions.workStartDateBegin)
    conditions.workStartDateBegin = DateFormat(conditions.workStartDateBegin);
  if (conditions.workStartDateEnd) conditions.workStartDateEnd = DateFormat(conditions.workStartDateEnd);
  if (conditions.workEndDateBegin) conditions.workEndDateBegin = DateFormat(conditions.workEndDateBegin);
  if (conditions.workEndDateEnd) conditions.workEndDateEnd = DateFormat(conditions.workEndDateEnd);
  if (conditions.omStartTime) conditions.omStartTime = DateFormat(conditions.omStartTime);
  if (conditions.omEndTime) conditions.omEndTime = DateFormat(conditions.omEndTime);
  if (conditions.cidStartDate) conditions.cidStartDate = DateFormat(conditions.cidStartDate);
  if (conditions.cidEndDate) conditions.cidEndDate = DateFormat(conditions.cidEndDate);

  //加入 tableInfo
  if (tableInfo) {
    conditions.page = tableInfo.page;
    conditions.pageSize = tableInfo.pageSize;
    conditions.sortColumnName = tableInfo.sortColumn;
    conditions.sortDirection = tableInfo.sortDirection === 'ascend' ? 'asc' : 'desc';
  }

  return conditions;
};

//主要元件
const OmWorkList = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [tableInfo, setTableInfo] = useState(INIT_TABLE_INFO);
  const [recordOfOmWorkForm, setRecordOfOmWorkForm] = useState({ total: 0, list: [] });
  const [isShowLogBook, setIsShowLogBook] = useState(false);
  const [isExportLogBook, setIsExportLogBook] = useState(false);

  // =====================
  useEffect(() => {
    const data = GetDataOfFilterFromSession();
    if (!data) SetDefaultDataOfFilterToSession();
  }, []);

  // =====================
  const handleSearch = () => {
    setTableInfo((prev) => ({ ...prev, page: 1 }));
  };

  const handleQuery = useCallback(() => {
    //
    const queryConditions = GenerateQueryConditions(tableInfo);

    //查詢
    setLoading(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery(
                $workStartDateBegin: String
                $workStartDateEnd: String
                $workEndDateBegin: String
                $workEndDateEnd: String
                $workUserIDs: [String],
                $objectIDs: [String],
                $omWorkContent: String,
                $omRecommend: String,
                $omFormID: String,
                $omDateType: Int,
                $omStartTime: String,
                $omEndTime: String,
                $omStatusIDs: [Int],
                $omSourceIDs: [Int],
                $omActionIDs: [Int],
                $omExecutionResultIDs: [Int],
                $cidFormID: String,
                $cidDateType: Int,
                $cidStartDate: String,
                $cidEndDate: String,
                $cidLevelNames: [String],
                $cidStatusIDs: [Int],
                $cidUserInChargeIDs: [String],
                $cidSourceIDs: [Int], 
                $cidProblemCategoryIDs: [Int], 
                $cidErrorCodeIDs: [String],
                $cidTopic: String,
                $cidCurrentProgress: String,
                $cidPossibleRootCause: String,
                $cidRootCause: String,
                $cidSolution: String,
                $timezone: String!,
                $page: Int!,
                $pageSize: Int!,
                $sortColumnName: String,
                $sortDirection: String
              ) { 
                om_getOmWorkHistoryList(
                  workStartDateBegin: $workStartDateBegin
                  workStartDateEnd: $workStartDateEnd
                  workEndDateBegin: $workEndDateBegin
                  workEndDateEnd: $workEndDateEnd
                  workUserIDs: $workUserIDs,
                  objectIDs: $objectIDs,
                  omWorkContent: $omWorkContent,
                  omRecommend: $omRecommend,
                  omFormID: $omFormID,
                  omDateType: $omDateType,
                  omStartTime: $omStartTime,
                  omEndTime: $omEndTime,
                  omStatusIDs: $omStatusIDs,
                  omSourceIDs: $omSourceIDs,
                  omActionIDs: $omActionIDs,
                  omExecutionResultIDs: $omExecutionResultIDs,
                  cidFormID: $cidFormID,
                  cidDateType: $cidDateType,
                  cidStartDate: $cidStartDate,
                  cidEndDate: $cidEndDate,
                  cidLevelNames: $cidLevelNames,
                  cidStatusIDs: $cidStatusIDs,
                  cidUserInChargeIDs: $cidUserInChargeIDs,
                  cidSourceIDs : $cidSourceIDs ,
                  cidProblemCategoryIDs : $cidProblemCategoryIDs,
                  cidErrorCodeIDs: $cidErrorCodeIDs,
                  cidTopic: $cidTopic,
                  cidCurrentProgress: $cidCurrentProgress,
                  cidPossibleRootCause: $cidPossibleRootCause,
                  cidRootCause: $cidRootCause,
                  cidSolution: $cidSolution,
                  timezone: $timezone,
                  page: $page,
                  pageSize: $pageSize,
                  sortColumnName: $sortColumnName,
                  sortDirection: $sortDirection
                ) {
                  total
                  list {
                    omFormID
                    objectName
                    startTime
                    endTime
                    workHour
                    actionName
                    userName
                    workContent
                    recommend
                    logbook
                    fileList { fileID fileName }
                  }
                }
              }
            `
          ),
          variables: queryConditions,
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('omWorkList.failedToQueryList', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        const tmp = res.data.data.om_getOmWorkHistoryList;
        tmp.list = tmp.list.map(SetKeyToArray);
        setRecordOfOmWorkForm(tmp);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omWorkList.queryListError'));
          console.error('load om work form list error: ', err);
        }
      })
      .then(() => {
        setLoading(false);
      });

    //
  }, [user, tableInfo, t]);
  useEffect(() => {
    handleQuery();
  }, [handleQuery]);

  // =====================
  const handleOmFilterModalOpen = () => {
    setFilterModalOpen(true);
  };
  const handleOmFilterModalClose = () => {
    setFilterModalOpen(false);
  };

  // =====================
  const handleTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'paginate') {
      setTableInfo((tbi) => ({
        ...tbi,
        page: pagination.current,
      }));
    }

    if (extra.action === 'sort') {
      setTableInfo((tbi) => ({
        ...tbi,
        sortColumn: sorter.field,
        sortDirection: sorter.order, //"ascend" or "descend"
      }));
    }
  };
  const handleExport = () => {
    //
    const queryConditions = GenerateQueryConditions(tableInfo);

    setExporting(true);
    axios
      .post(
        `${appConfigs.apiGatewayURL}/oms/om/work/export`,
        { ...queryConditions, showLogbook: isExportLogBook },
        { headers: { Authorization: user.token, Accept: '*/*' }, responseType: 'blob' }
      )
      .then((res) => {
        if (!res || !res.data) {
          message.error(t('omWorkList.exportFailed', { msg: res?.data?.msg }));
          return;
        }

        const fileName = GetFileNameFromResponseHeader(
          res.headers['content-disposition'],
          'OM處理歷程列表.xlsx'
        );
        const blobFile = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = blobFile;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(blobFile);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omWorkList.exportError'));
          console.error('export om work form list error: ', err);
        }
      })
      .then(() => {
        setExporting(false);
      });
  };

  // =====================
  const columns = useMemo(() => {
    const baseCols = [
      {
        title: t('omWorkList.columns.omNumber'),
        dataIndex: 'omFormID',
        key: 'omFormID',
        align: 'center',
        sorter: true,
        showSorterTooltip: false,
        width: 100,
        render: (value) => (
          <Link to={`/oms/${value}`} target="_blank">
            {value}
          </Link>
        ),
      },
      {
        title: t('omWorkList.columns.site'),
        dataIndex: 'objectName',
        key: 'objectName',
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('omWorkList.columns.handleDate'),
        dataIndex: 'startTime',
        key: 'startDate',
        align: 'center',
        sorter: true,
        showSorterTooltip: false,
        width: 100,
        render: (value) => DateFormat(value),
      },
      {
        title: t('omWorkList.columns.startTime'),
        dataIndex: 'startTime',
        key: 'startTime',
        align: 'center',
        width: 70,
        render: (value) => (value ? moment(value).format('HH:mm') : ''),
      },
      {
        title: t('omWorkList.columns.endTime'),
        dataIndex: 'endTime',
        key: 'endTime',
        align: 'center',
        width: 70,
        render: (value) => (value ? moment(value).format('HH:mm') : ''),
      },
      {
        title: () => (
          <div style={{ textAlign: 'center' }}>
            <div>{t('omWorkList.columns.workHour')}</div>
            <div>{t('omWorkList.columns.minutes')}</div>
          </div>
        ),
        dataIndex: 'workHour',
        key: 'workHour',
        align: 'center',
        width: 70,
      },
      {
        title: t('omWorkList.columns.action'),
        dataIndex: 'actionName',
        key: 'actionName',
        align: 'center',
      },
      {
        title: t('omWorkList.columns.actualAttendees'),
        dataIndex: 'userName',
        key: 'userName',
        align: 'center',
        render: (value) => (
          <div>{Array.isArray(value) && value.map((m, mIdx) => m && <div key={mIdx}>{m}</div>)}</div>
        ),
      },
      {
        title: t('omWorkList.columns.workDescription'),
        dataIndex: 'workContent',
        key: 'workContent',
      },
      {
        title: t('omWorkList.columns.nextSuggestion'),
        dataIndex: 'recommend',
        key: 'recommend',
      },
      {
        title: t('omWorkList.columns.attachments'),
        dataIndex: 'fileList',
        key: 'fileList',
        render: (value) => <RfdmeUpload value={value} downloadOnly />,
      },
    ];

    if (isShowLogBook) {
      baseCols.push({ title: 'LogBook', dataIndex: 'logbook', key: 'logbook' });
    }

    return baseCols;
  }, [isShowLogBook, t]);

  // ------------------------------------

  return (
    <>
      <Title level={4}>{t('omWorkList.title')}</Title>
      <Row justify="end">
        <Col>
          <Space>
            <Switch
              checkedChildren={t('omWorkList.showLogBookField')}
              unCheckedChildren={t('omWorkList.hideLogBookField')}
              checked={isShowLogBook}
              onChange={(value) => {
                setIsShowLogBook(value);
              }}
            />
            <Switch
              checkedChildren={t('omWorkList.exportLogBookField')}
              unCheckedChildren={t('omWorkList.notExportLogBookField')}
              checked={isExportLogBook}
              onChange={(value) => {
                setIsExportLogBook(value);
              }}
            />
            <Button icon={<FilterOutlined />} onClick={handleOmFilterModalOpen}>
              {t('omWorkList.filterConditions')}
            </Button>
            {user.operations.includes('OMWORKS_export-om-work-form') && (
              <Button type="primary" icon={<ExportOutlined />} loading={exporting} onClick={handleExport}>
                {t('omWorkList.exportQueryResults')}
              </Button>
            )}
          </Space>
        </Col>
      </Row>
      <br />
      <Spin spinning={loading} tip={t('omWorkList.searching')}>
        <Table
          bordered
          size="small"
          columns={columns}
          dataSource={recordOfOmWorkForm.list}
          scroll={{ x: 1920 }}
          pagination={{
            total: recordOfOmWorkForm.total,
            pageSize: tableInfo.pageSize,
            showSizeChanger: false,
            current: tableInfo.page,
          }}
          onChange={handleTableChange}
        />
      </Spin>
      <RfdmeModal width="50%" open={filterModalOpen}>
        <OmFilter searchFn={handleSearch} closeFn={handleOmFilterModalClose} />
      </RfdmeModal>
    </>
  );
};

export default OmWorkList;
