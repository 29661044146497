import React, { useContext, useEffect, useMemo } from 'react';
import { Typography } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//
import AuthContext from '../../contexts/authProvider';
import { MenuList as menuList } from '../../layout/menus';
//
const { Title } = Typography;
//
const flattenMenu = (menu, parentSeq = '') => {
  let flatList = [];
  menu.forEach((item) => {
    const newSeq = parentSeq ? `${parentSeq}-${item.seq}` : item.seq;
    flatList.push({ ...item, seq: newSeq }); // Add the parent seq if exists
    if (item.children && item.children.length > 0) {
      flatList = [...flatList, ...flattenMenu(item.children, newSeq)]; // Recursively flatten children
    }
  });
  return flatList;
};

const Home = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const MenuList = useMemo(() => menuList(t), [t]);
  useEffect(() => {
    if (user?.menus?.length > 0) {
      const userAccessibleMenus = flattenMenu(MenuList).filter((menuItem) =>
        user.menus.some((userMenu) => menuItem.key === userMenu.menuPath)
      );
      // Sort the filtered list by seq
      userAccessibleMenus.sort((a, b) => a.seq.localeCompare(b.seq));

      const defaultMenuPath = '/alertstatistics';
      const defaultMenuAvailable = user.menus.some((menu) => menu.menuPath === defaultMenuPath);
      const firstMenuPath = defaultMenuAvailable
        ? defaultMenuPath
        : userAccessibleMenus.length > 0
        ? userAccessibleMenus[0].key
        : '/'; // Fallback to root if no accessible menus

      if (location.pathname !== firstMenuPath) {
        navigate(firstMenuPath);
      }
    }
  }, [user, navigate, location.pathname, MenuList]);

  return <Title level={2}>Welcome to O&M 2.0 system !</Title>;
};

export default Home;
