import React from 'react';
import { Card, Row, Col } from 'antd';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { useTranslation } from 'react-i18next';

const COLORS = ['#1EA4D0', '#47DBC0', '#FFBB28', '#FF8042'];

const PieChartCard = ({ data }) => {
  const { t } = useTranslation();
  const total = data.reduce((sum, entry) => sum + entry.value, 0);
  const hasData = data && data.length > 0;

  const defaultData = [{ value: 1, type: 'default' }];

  return (
    <Card title={t('spvDashboard.pieChartCard.unresolvedWorkOrderImportanceDistribution')} className="app-dashboard-card">
      <Row gutter={[12, 12]} style={{ height: '100%' }}>
        <Col xs={16} sm={16} md={16} lg={16} xl={16} style={{ height: '100%' }}>
          <div style={{ position: 'relative', height: '100%' }}>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={hasData ? data : defaultData}
                  cx="50%"
                  cy="50%"
                  innerRadius={80}
                  outerRadius={100}
                  fill="#1EA4D0"
                  paddingAngle={5}
                  dataKey="value"
                  startAngle={90}
                  endAngle={450}
                  stroke="0"
                  isAnimationActive={false}
                >
                  {(hasData ? data : defaultData).map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            <div className="app-piechart-text">
              Total
              <br />
              <span>{hasData ? total : 0}</span>
            </div>
          </div>
        </Col>
        <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ height: '100%' }}>
          <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <div>
              {hasData ? (
                data.map((entry, index) => (
                  <div key={`data-${index}`} style={{ color: COLORS[index % COLORS.length] }}>
                    {`${entry.type}: ${entry.value} (${((entry.value / total) * 100).toFixed(2)}%)`}
                  </div>
                ))
              ) : (
                <div key="data-0" style={{ color: COLORS[0] }}>
                  No Data Available
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default PieChartCard;
