export const GetAppSyncRspsErrorMessage = (res) => {
  if (!res) return '';
  if (!res.data) return '';
  if (!Array.isArray(res.data.errors) || res.data.errors.length === 0) return '';
  return res.data.errors[0].message || '';
};

export const GetFileNameFromResponseHeader = (headerValue, defaultFileName) => {
  if (!headerValue) return defaultFileName;

  let result = headerValue.split(';');
  if (!Array.isArray(result) || result.length === 0) return defaultFileName;

  result = result.find((f) => f.includes('filename='));
  if (!result) return defaultFileName;

  result = result.replace('filename=', '').trim();
  if (!result) return defaultFileName;

  result = decodeURIComponent(result);

  return result;
};
