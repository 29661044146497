import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import appConfigs from '../configs';
import AuthContext from '../contexts/authProvider';

const useListOfCidUserInCharge = (enabled = true) => {
  const { user } = useContext(AuthContext);

  return useQuery({
    queryKey: ['query-cid-user-in-charge-list'],
    queryFn: () => {
      return axios
        .post(
          appConfigs.appSyncURL,
          {
            query: `query OmQuery { om_getCidUserList { userID userName } }`,
            variables: null,
          },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (res.status === 200 && res.data.data) {
            return res.data.data.om_getCidUserList.map((m) => ({
              label: m.userName,
              value: m.userID,
            }));
          }
          return [];
        });
    },
    staleTime: 1000 * 60 * 60 * 1, // 1 hours
    cacheTime: 1000 * 60 * 60 * 2, // 2 hours
    enabled: enabled,
  });
};

export default useListOfCidUserInCharge;
