import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
//
import { Button, Popover, Tooltip, Spin, message, Badge, List, Avatar } from 'antd';
import {
  BellOutlined,
  EyeInvisibleOutlined,
  SnippetsOutlined,
  SoundOutlined,
  CheckCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
//
import appConfigs from '../configs';
import AuthContext from '../contexts/authProvider';
import { SetKeyToArray } from '../utils/format';
import { IsValidApiGatewayRsps, IsValidAppSyncRsps } from '../utils/valid';
import { GetAppSyncRspsErrorMessage } from '../utils/parse';

const Notification = () => {
  const { user } = useContext(AuthContext);
  //
  const [popOpen, setPopOpen] = useState(false);
  const [notifyInfo, setNotifyInfo] = useState({ count: 0, data: [] });
  const [saving, setSaving] = useState(false);

  //================================================
  const loadNotifications = useCallback(() => {
    if (!user) {
      return;
    }

    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: `query OmQuery($timezone: String!) { 
                    om_getNotificationList(timezone: $timezone) { 
                      notifyID 
                      kind 
                      content 
                      link 
                      omFormIDs
                      omFormList { omFormID assignDate }
                      isRead 
                      crDate 
                    } 
                  }
                 `,
          variables: { timezone: appConfigs.timezone },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(`取得通知訊息失敗: ${GetAppSyncRspsErrorMessage(res)}`);
          return;
        }

        const tmp = res.data.data.om_getNotificationList;
        const unReadCount = tmp.filter((d) => d.isRead === 'N').length;
        const newData = _.chain(tmp).sortBy('crDate').reverse().map(SetKeyToArray).value();
        setNotifyInfo({ count: unReadCount, data: newData || [] });
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error('取得通知訊息異常');
          console.error('load notification list error', err);
        }
      });
  }, [user]);

  useEffect(() => {
    loadNotifications();
  }, [loadNotifications]);

  //================================================
  const handlePopOpenChange = (newOpen) => {
    setPopOpen(newOpen);
  };
  const handleNotifyRead =
    (notifyIds, closeModalFlag = false) =>
    () => {
      if (Array.isArray(notifyIds) && notifyIds.length > 0) {
        setSaving(true);
        axios
          .post(
            `${appConfigs.apiGatewayURL}/oms/notify/setRead`,
            { notifyIds },
            { headers: { Authorization: user.token } }
          )
          .then((res) => {
            if (!IsValidApiGatewayRsps(res)) {
              message.warn(`通知訊息讀取失敗: ` + res?.data?.msg);
              return;
            }

            message.success(`通知訊息讀取完成`);
            loadNotifications();
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              message.error(`通知訊息讀取異常`);
              console.error('通知訊息讀取異常: ', err);
            }
          })
          .finally(() => {
            setSaving(false);
          });
      }
    };
  const handleNotifyHide = (notifyIds) => () => {
    if (Array.isArray(notifyIds) && notifyIds.length > 0) {
      setSaving(true);
      axios
        .post(
          `${appConfigs.apiGatewayURL}/oms/notify/setHide`,
          { notifyIds },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (!IsValidApiGatewayRsps(res)) {
            message.warn(`通知訊息隱藏失敗: ` + res?.data?.msg);
            return;
          }

          message.success(`通知訊息隱藏完成`);
          loadNotifications();
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            message.error(`通知訊息隱藏異常`);
            console.error('通知訊息隱藏異常: ', err);
          }
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  //================================================
  return (
    <div>
      <Popover
        title="通知"
        placement="bottomRight"
        trigger="click"
        open={popOpen}
        content={
          <NotificationConent
            data={notifyInfo.data}
            saving={saving}
            readFn={handleNotifyRead}
            hideFn={handleNotifyHide}
            closeFn={handlePopOpenChange}
          />
        }
        onOpenChange={handlePopOpenChange}
      >
        <div className="btn-icon-only btn-round">
          <Badge count={notifyInfo.count}>
            <BellOutlined />
          </Badge>
        </div>
      </Popover>
    </div>
  );
};

const NotificationConent = ({ data, saving, readFn, hideFn, closeFn }) => {
  const actions = useCallback((item, hideFn, readFn) => {
    const arr = [
      <Tooltip title={<span>設為隱藏</span>} key="action1">
        <Button icon={<EyeInvisibleOutlined />} type="link" size="small" onClick={hideFn([item.notifyID])} />
      </Tooltip>,
    ];
    if (item.isRead === 'N') {
      arr.unshift([
        <Tooltip title={<span>設為已讀</span>} key="action2">
          <Button icon={<CheckCircleOutlined />} type="link" size="small" onClick={readFn([item.notifyID])} />
        </Tooltip>,
      ]);
    }
    return arr;
  }, []);

  const handleReadAll = useCallback(
    (data) => () => {
      if (data.length === 0) return;
      const ids = [];
      data.forEach((item) => {
        ids.push(item.notifyID);
      });
      readFn(ids)();
    },
    [readFn]
  );

  const handleHideAll = useCallback(
    (data) => () => {
      if (data.length === 0) return;
      const ids = [];
      data.forEach((item) => {
        ids.push(item.notifyID);
      });
      hideFn(ids)();
    },
    [hideFn]
  );

  const handleLink = useCallback(
    (notifyID) => () => {
      if (notifyID) {
        readFn([notifyID])();
        closeFn(false);
      }
    },
    [readFn, closeFn]
  );

  const cidListFn = useCallback((value) => {
    if (Array.isArray(value) && value.length > 0) {
      return value.map((v, vIdx) => (
        <Link key={vIdx} to={`/oms/${v.omFormID}`} target="_blank" className="link-list-inline">
          {moment().format('YYYY-MM-DD') === v.assignDate && <WarningOutlined className="text-warning" />}
          {v.omFormID}
        </Link>
      ));
    } else {
      return null;
    }
  }, []);

  return (
    <div className="app-notification">
      <Spin spinning={saving} tip="資料處理中，請稍候...">
        <div className="app-notification-actions">
          <span className="btn-action" onClick={handleReadAll(data)}>
            全部設為已讀
          </span>
          <span className="btn-action" onClick={handleHideAll(data)}>
            全部設為隱藏
          </span>
        </div>
        <List
          itemLayout="horizontal"
          dataSource={data}
          split={false}
          size="small"
          renderItem={(item) => (
            <List.Item actions={actions(item, hideFn, readFn)}>
              <List.Item.Meta
                avatar={
                  <Avatar
                    style={{ backgroundColor: item.kind === 'cidWithdraw' ? '#faad14' : '#1ea4d0' }}
                    icon={item.kind === 'cidWithdraw' ? <SnippetsOutlined /> : <SoundOutlined />}
                  />
                }
                title={
                  <>
                    {item.link ? (
                      <Link
                        to={item.link}
                        onClick={handleLink(item.notifyID)}
                        className={item.isRead === 'N' ? 'font-black' : null}
                      >
                        {item.content}
                      </Link>
                    ) : (
                      <span className={item.isRead === 'N' ? 'font-black' : null}>{item.content}</span>
                    )}
                  </>
                }
                description={
                  <>
                    {item.omFormList?.length > 0 && '相關OM表單：'}
                    {cidListFn(item.omFormList)}
                    <p style={{ marginBottom: 0 }}>{item.crDate}</p>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </Spin>
    </div>
  );
};

export default Notification;
