import axios from 'axios';
import React, { useContext, useEffect, useState, useCallback } from 'react';
//
import { message, PageHeader, Spin } from 'antd';
import appConfigs from '../../configs';
import { AppSyncQueryTextFormat } from '../../utils/format';
import { IsValidAppSyncRsps } from '../../utils/valid';
import { GetAppSyncRspsErrorMessage } from '../../utils/parse';
import AuthContext from '../../contexts/authProvider';
import { SettingContext } from '../../contexts/settingsProvider';
import { useTranslation } from 'react-i18next';

const AlertStatistics = () => {
  const { user } = useContext(AuthContext);
  const { dark } = useContext(SettingContext);
  const [grafanaConfig, setGrafanaConfig] = useState([]);
  const [loading, setLoading] = useState(false);
  const embeddingUrl = grafanaConfig?.shared_getGrafanaEmbeddingList;
  const url = embeddingUrl?.filter?.((item) => /^alertstatistics/.test(item.code))?.[0]?.url;
  const theme = dark ? 'dark' : 'light';
  const token = user.token?.replace?.(/^Bearer /, '');
  const { t } = useTranslation();

  const handleQuery = useCallback(() => {
    setLoading(true);
    axios
      .post(
        appConfigs.sharedAppSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery($system: String, $kind: String) {
              shared_getGrafanaEmbeddingList(system: $system, kind: $kind) {
                code
                id
                url
               }
             }
            `
          ),
          variables: {
            system: appConfigs.systemID,
            kind: 'alertstatistics',
          },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(
            t('common.queryGrafanaEmbeddingListFailed', { error: GetAppSyncRspsErrorMessage(res) })
          );
          return;
        }

        setGrafanaConfig(res.data.data);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('common.queryGrafanaEmbeddingListError'));
          console.error('load grafanaUrlApi error', err);
        }
      })
      .then(() => {
        setLoading(false);
      });
  }, [user, t]);

  useEffect(() => {
    handleQuery();
  }, [handleQuery]);

  if (loading || !url) {
    return (
      <>
        <PageHeader className="app-page-header" title={t('AlertStatistics.title')} />
        <div className="app-page-content">
          <div style={{ display: 'flex', minHeight: 300, justifyContent: 'center', alignItems: 'center' }}>
            <Spin spinning={loading} tip={t('common.loadingDataMessage')} />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <PageHeader className="app-page-header" title={t('AlertStatistics.title')} />
      <div style={{ margin: '-24px', height: 'calc(100vh - 156px)', overflow: 'hidden' }}>
        <iframe
          id="frame"
          width={'100%'}
          title={t('AlertStatistics.title')}
          style={{ border: 0, height: '100%' }}
          src={`${url}&kiosk=tv&auth_token=${token}&theme=${theme}`}
        ></iframe>
      </div>
    </>
  );
};

export default AlertStatistics;
