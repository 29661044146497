import React, { useState, useMemo, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import bn from 'bignumber.js';
import moment from 'moment';
import styled from 'styled-components';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Col,
  Row,
  Collapse,
  DatePicker,
  Button,
  Table,
  Spin,
  Tooltip,
  message,
  Input,
  PageHeader,
  Card,
  Tag,
} from 'antd';
import { InfoCircleOutlined, ReloadOutlined, DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import RfdmeSelect from '../../components/Select';
import { useTranslation } from 'react-i18next';

//
import appConfigs from '../../configs';
import useListOfAlertComponent from '../../hooks/useListOfAlertComponent';
import useListOfAlertDegree from '../../hooks/useListOfAlertDegree';
import useListOfAlertID from '../../hooks/useListOfAlertID';
import useListOfAlertLogCidStatus from '../../hooks/useListOfAlertLogCidStatus';
import useListOfAlertLogStatus from '../../hooks/useListOfAlertLogStatus';
import useListOfPv from '../../hooks/useListOfPv';
import useListOfPvOwner from '../../hooks/useListOfPvOwner';
import AuthContext from '../../contexts/authProvider';
import { IsValidAppSyncRsps } from '../../utils/valid';
import { AppSyncQueryTextFormat, JsonStringToFormObject, SetKeyToArray } from '../../utils/format';
import { GetFileNameFromResponseHeader, GetAppSyncRspsErrorMessage } from '../../utils/parse';

//
const { Panel } = Collapse;
const RedDotDiv = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 100px;
  background: #fa541c;
`;
const GreenDotDiv = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 100px;
  background: #52c41a;
`;

//設定
const INITIAL_FILTER_DATA = {
  alertType: ['Availability', 'Performance'],
  alertLogStatus: true,
  alertLogCidStatus: 1,
};
const INIT_TABLE_INFO = { sortColumn: null, sortDirection: 'ascend', page: 1, pageSize: 20 };

const GenerateQueryConditions = (tableInfo) => {
  //從 session storage 取出查詢條件
  const conditions = JSON.parse(window.sessionStorage.getItem('alert-list-filterData'));
  if (conditions.startBeginTime) conditions.startBeginTime = moment(conditions.startBeginTime);
  if (conditions.startEndTime) conditions.startEndTime = moment(conditions.startEndTime);
  if (conditions.endBeginTime) conditions.endBeginTime = moment(conditions.endBeginTime);
  if (conditions.endEndTime) conditions.endEndTime = moment(conditions.endEndTime);

  //重組查詢參數
  let queryConditions = {};
  queryConditions.ownerID = conditions.pvOwner;
  queryConditions.alertDegree = conditions.alertDegree;
  queryConditions.alertType = conditions.alertType && conditions.alertType.join(',');
  queryConditions.alertID = conditions.alertID && conditions.alertID.join(',');
  queryConditions.alertComponent = conditions.alertComponent;
  queryConditions.alertStatus = conditions.alertLogStatus;
  queryConditions.objectID = conditions.pv;
  queryConditions.cidStatus = conditions.alertLogCidStatus;
  queryConditions.clientDeviceSeq = conditions.clientDeviceSeq;
  queryConditions.startBeginTime =
    conditions.startBeginTime && conditions.startBeginTime.format('YYYY-MM-DD HH:mm:ss');
  queryConditions.startEndTime =
    conditions.startEndTime && conditions.startEndTime.format('YYYY-MM-DD HH:mm:ss');
  queryConditions.endBeginTime =
    conditions.endBeginTime && conditions.endBeginTime.format('YYYY-MM-DD HH:mm:ss');
  queryConditions.endEndTime = conditions.endEndTime && conditions.endEndTime.format('YYYY-MM-DD HH:mm:ss');
  queryConditions.timezone = appConfigs.timezone;
  if (tableInfo) {
    queryConditions.page = tableInfo.page;
    queryConditions.pageSize = tableInfo.pageSize;
    queryConditions.sortColumnName = tableInfo.sortColumn;
    queryConditions.sortDirection = tableInfo.sortDirection === 'ascend' ? 'asc' : 'desc';
    window.sessionStorage.setItem('alert-list-tableInfo', JSON.stringify(tableInfo));
  }

  return queryConditions;
};

//主要元件
const AlertList = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [tableInfo, setTableInfo] = useState(INIT_TABLE_INFO);
  const [filterData, setFilterData] = useState(INITIAL_FILTER_DATA);
  const [dataList, setDataList] = useState({ total: 0, list: [] });
  const [recordOfTableSelectedRowKeys, setRecordOfTableSelectedRowKeys] = useState([]);
  const [recordOfTableSelectedRows, setRecordOfTableSelectedRows] = useState([]);
  const [recordOfTableExpandKey, setRecordOfTableExpandKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [expandLoading, setExpandLoading] = useState(false);
  const [addCidLoading, setAddCidLoading] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [collaps, setCollaps] = useState(true);
  const { data: alertComponentList } = useListOfAlertComponent();
  const { data: alertDegreeList } = useListOfAlertDegree();
  const { data: alertIdList } = useListOfAlertID();
  const { data: alertLogCidStatusList } = useListOfAlertLogCidStatus();
  const { data: alertLogStatusList } = useListOfAlertLogStatus();
  const { data: pvList } = useListOfPv();
  const { data: pvOwnerList } = useListOfPvOwner();
  const { t } = useTranslation();

  const filteredPvList = useMemo(() => {
    if (!Array.isArray(pvList)) return [];
    return pvList.filter((f) => f.state !== 'C').map((m) => ({ label: m.label, value: m.value }));
  }, [pvList]);
  const selectedRowKeys = useMemo(() => {
    const result = [];

    // recordOfTableSelectedRowKeys 的結構為 [[],[],[],...]
    recordOfTableSelectedRowKeys.forEach((innerArrayItem) => {
      if (Array.isArray(innerArrayItem)) {
        result.push(...innerArrayItem);
      }
    });

    return result;
  }, [recordOfTableSelectedRowKeys]);
  const selectedRows = useMemo(() => {
    const result = [];

    // recordOfTableSelectedRows 的結構為 [[],[],[],...]
    recordOfTableSelectedRows.forEach((innerArrayItem) => {
      if (Array.isArray(innerArrayItem)) {
        result.push(...innerArrayItem);
      }
    });

    return result;
  }, [recordOfTableSelectedRows]);
  const alertTypeList = useMemo(() => {
    if (alertIdList && Array.isArray(alertIdList)) {
      return [...new Set(alertIdList.map((data) => data.alertType))].map((d, idx) => {
        return {
          key: idx,
          label: d,
          value: d,
        };
      });
    }
  }, [alertIdList]);
  const alertIDFilterList = useMemo(() => {
    if (
      alertIdList &&
      Array.isArray(alertIdList) &&
      Array.isArray(filterData.alertType) &&
      filterData.alertType?.length > 0
    ) {
      return alertIdList.filter((data) => filterData.alertType.includes(data.alertType));
    }
    return alertIdList || [];
  }, [alertIdList, filterData.alertType]);

  useEffect(() => {
    if (isFirstLoad) {
      const filterDataFromObject = window.localStorage.getItem('alert-list-object-filterData');
      const filterDataStringInSession = window.sessionStorage.getItem('alert-list-filterData');
      const filterDataFormLocationState = new URLSearchParams(window.location.search) || { size: 0 };

      if (filterDataFormLocationState.size > 0) {
        const paramsFilter = {
          pv: filterDataFormLocationState.get('objectID'),
          alertID: filterDataFormLocationState.get('alertIDs')
            ? filterDataFormLocationState.get('alertIDs').split(',')
            : [],
          alertLogStatus: true,
          alertType: ['Availability', 'Performance'],
          clientDeviceSeq: filterDataFormLocationState.get('invID'),
        };
        window.sessionStorage.setItem('alert-list-filterData', JSON.stringify(paramsFilter));
        setFilterData(paramsFilter);
      } else if (filterDataFromObject) {
        const queryParams = JSON.parse(filterDataFromObject);
        setTableInfo((tbi) => {
          const tableInfo = { ...tbi, page: 1 };
          window.sessionStorage.setItem('alert-list-tableInfo', JSON.stringify(tableInfo));
        });
        const paramsFilter = {
          pv: queryParams.objectID,
          alertID: queryParams.alertList,
          alertLogStatus: queryParams.warning === 0 ? false : true,
          alertType: queryParams.alertType || [],
        };

        window.sessionStorage.setItem('alert-list-filterData', JSON.stringify(paramsFilter));
        setFilterData(paramsFilter);

        window.localStorage.removeItem('alert-list-object-filterData');
      } else if (filterDataStringInSession) {
        const conditions = JSON.parse(filterDataStringInSession);
        if (conditions.startBeginTime) conditions.startBeginTime = moment(conditions.startBeginTime);
        if (conditions.startEndTime) conditions.startEndTime = moment(conditions.startEndTime);
        if (conditions.endBeginTime) conditions.endBeginTime = moment(conditions.endBeginTime);
        if (conditions.endEndTime) conditions.endEndTime = moment(conditions.endEndTime);
        setFilterData(conditions);
      } else {
        window.sessionStorage.setItem('alert-list-filterData', JSON.stringify(INITIAL_FILTER_DATA));
      }

      const tableInfoStringInSession = window.sessionStorage.getItem('alert-list-tableInfo');
      if (tableInfoStringInSession) {
        const info = JSON.parse(tableInfoStringInSession);
        setTableInfo(info);
      } else {
        window.sessionStorage.setItem('alert-list-tableInfo', JSON.stringify(INIT_TABLE_INFO));
      }

      setIsFirstLoad(false);
    }
  }, [isFirstLoad, location]);

  const handleFilterDataChange = (name) => (value) => {
    const modifyValue = value && value.target ? value.target.value : value;
    setFilterData((prev) => {
      let newState = {
        ...prev,
        [name]: modifyValue,
      };

      if (name === 'alertType' && Array.isArray(prev.alertID) && prev.alertID.length > 0) {
        newState.alertID = alertIdList.reduce((filteredAlertIDs, obj) => {
          if (modifyValue.includes(obj.alertType) && prev.alertID.includes(obj.value)) {
            filteredAlertIDs.push(obj.value);
          }
          return filteredAlertIDs;
        }, []);
      }
      return newState;
    });
  };

  const handleReset = () => {
    setFilterData({ ...INITIAL_FILTER_DATA });
    window.sessionStorage.setItem('alert-list-filterData', JSON.stringify(INITIAL_FILTER_DATA));
    setTableInfo((tbi) => ({ ...tbi, page: 1 }));
    //關閉展開的項目
    setRecordOfTableExpandKey('');
  };
  const handleSearch = () => {
    window.sessionStorage.setItem('alert-list-filterData', JSON.stringify(filterData));
    setTableInfo((tbi) => ({ ...tbi, page: 1 }));
    setRecordOfTableSelectedRowKeys([]);
    setRecordOfTableSelectedRows([]);
    //關閉展開的項目
    setRecordOfTableExpandKey('');
  };
  const handleQuery = useCallback(() => {
    //
    const queryConditions = GenerateQueryConditions(tableInfo);

    //查詢
    setLoading(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery(
              $alertType: String, 
              $alertID: String, 
              $alertDegree: String,
              $alertComponent: String,
              $alertStatus: Boolean,
              $cidStatus: String,
              $ownerID: String,
              $objectID: String,
              $clientDeviceSeq: String,
              $startBeginTime: String,
              $startEndTime: String,
              $endBeginTime: String,
              $endEndTime: String,
              $timezone: String!,
              $page: Int!,
              $pageSize: Int!,
              $sortColumnName: String,
              $sortDirection: String 
              ) { 
                om_getAlertLogList(
                  alertType: $alertType,
                  alertID: $alertID,
                  alertDegree: $alertDegree,
                  alertComponent: $alertComponent,
                  alertStatus: $alertStatus,
                  cidStatus: $cidStatus,
                  ownerID: $ownerID,
                  objectID: $objectID,
                  clientDeviceSeq: $clientDeviceSeq,
                  startBeginTime: $startBeginTime,
                  startEndTime: $startEndTime,
                  endBeginTime: $endBeginTime,
                  endEndTime: $endEndTime,
                  timezone: $timezone,
                  page: $page,
                  pageSize: $pageSize,
                  sortColumnName: $sortColumnName,
                  sortDirection: $sortDirection 
                ) {
                  total
                  list { 
                    alertComponent
                    alertDegree
                    alertDisplayName
                    alertID
                    alertLogID
                    alertStatusName
                    avgLossEnergy
                    cidID
                    cidStatus
                    cidStatusName
                    clientDeviceSeq
                    days
                    endTime
                    lossEnergy
                    objectID
                    objectName
                    ownerID
                    ownerName
                    startTime
                    alertName
                    alertOwnerID
                    startTimestamp
                    details
                  }
                }
              }
            `
          ),
          variables: queryConditions,
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(`${t('AlertList.queryAlertListFailed', { error: GetAppSyncRspsErrorMessage(res) })}`);
          return;
        }

        const tmp = res.data.data.om_getAlertLogList;
        tmp.list = tmp.list.map((m, mIdx) => {
          m.key = m.alertLogID;
          if (!Array.isArray(m.details)) m.details = [];
          m.details = m.details.map((m) => JsonStringToFormObject(m));
          return m;
        });
        setDataList(tmp);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('AlertList.queryAlertListError'));
          console.error('load alert log list error', err);
        }
      })
      .then(() => {
        setLoading(false);
      });

    //
  }, [user, tableInfo, t]);
  useEffect(() => {
    if (!isFirstLoad) handleQuery();
  }, [isFirstLoad, handleQuery]);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'paginate') {
      window.sessionStorage.setItem(
        'alert-list-tableInfo',
        JSON.stringify({ ...tableInfo, page: pagination.current })
      );
      setTableInfo((tbi) => ({
        ...tbi,
        page: pagination.current,
      }));
    }

    if (extra.action === 'sort') {
      window.sessionStorage.setItem(
        'alert-list-tableInfo',
        JSON.stringify({ ...tableInfo, sortColumn: sorter.field, sortDirection: sorter.order })
      );
      setTableInfo((tbi) => ({
        ...tbi,
        sortColumn: sorter.field,
        sortDirection: sorter.order, //"ascend" or "descend"
      }));
    }
  };
  const handleTableRowSelectChange = useCallback(
    (rowKeys, rows) => {
      const tmpRowKeys = [...recordOfTableSelectedRowKeys];
      const tmpRows = [...recordOfTableSelectedRows];

      tmpRowKeys[tableInfo.page - 1] = rowKeys;
      tmpRows[tableInfo.page - 1] = rows;

      setRecordOfTableSelectedRowKeys(tmpRowKeys);
      setRecordOfTableSelectedRows(tmpRows);
    },
    [tableInfo.page, recordOfTableSelectedRowKeys, recordOfTableSelectedRows]
  );

  const rowSelectionProps = useMemo(() => {
    if (!user.operations.includes('ALERTS_insert-cid-form')) return null;

    return {
      selectedRowKeys: selectedRowKeys,
      onChange: handleTableRowSelectChange,
      getCheckboxProps: (record) => ({
        disabled: record.cidStatus === 4 || record.cidID, // 誤判及已開Cid單的Alert不可選擇
      }),
    };
  }, [user.operations, selectedRowKeys, handleTableRowSelectChange]);

  const handleExport = () => {
    //
    const queryConditions = GenerateQueryConditions(null);

    setExporting(true);
    axios
      .post(
        `${appConfigs.apiGatewayURL}/oms/alertlog/export`,
        { ...queryConditions },
        { headers: { Authorization: user.token, Accept: '*/*' }, responseType: 'blob' }
      )
      .then((res) => {
        if (!res || !res.data) {
          message.error(t('common.exportFailed', { error: res?.data?.msg }));
          return;
        }

        //
        const fileName = GetFileNameFromResponseHeader(res.headers['content-disposition'], '異常列表.xlsx');
        const blobFile = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = blobFile;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(blobFile);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('common.exportError'));
          console.error('匯出異常', err);
        }
      })
      .then(() => {
        setExporting(false);
      });
  };

  const handleAddCideForm = async () => {
    if (!Array.isArray(selectedRowKeys) || selectedRowKeys.length === 0) {
      message.warn(t('AlertList.createCidFormWarningMessage'));
      return;
    }
    let alertList = [];
    let queryList = [];
    setAddCidLoading(true);
    for (let index = 0; index < selectedRows.length; index++) {
      const elem = selectedRows[index];
      if (Array.isArray(elem.details) && elem.details.length) {
        if (elem.detailContent) {
          alertList = alertList.concat(elem.detailContent);
        } else {
          queryList = queryList.concat(elem.details);
        }
      } else {
        alertList.push(elem);
      }
    }
    if (queryList.length) {
      await axios
        .post(
          appConfigs.appSyncURL,
          {
            query: AppSyncQueryTextFormat(
              `query OmQuery(
                  $alertArray: [AlertInput],
                  $timezone: String!
                  ) { 
                    om_getAlertLogDetail(
                      alertArray: $alertArray,
                      timezone: $timezone
                    ) {
                        alertComponent
                        alertDegree
                        alertDisplayName
                        alertID
                        alertLogID
                        alertStatusName
                        avgLossEnergy
                        cidID
                        cidStatus
                        cidStatusName
                        clientDeviceSeq
                        days
                        endTime
                        lossEnergy
                        objectID
                        objectName
                        ownerID
                        ownerName
                        startTime
                        alertName
                        alertOwnerID
                        startTimestamp
                    }
                  }
                `
            ),
            variables: {
              alertArray: queryList,
              timezone: appConfigs.timezone,
            },
          },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (!IsValidAppSyncRsps(res)) {
            message.warn(
              t('AlertList.queryAlertLogDetailFailed', {
                error: GetAppSyncRspsErrorMessage(res),
              })
            );
            return;
          }

          const tmp = res.data.data.om_getAlertLogDetail;
          alertList = alertList.concat(tmp);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            message.error(t('AlertList.queryAlertLogDetailError'));
            console.error('load alert log detail error', err);
          }
        })
        .finally(() => {
          setAddCidLoading(false);
        });
    } else {
      setAddCidLoading(false);
    }

    navigate('/cids/自動編號', {
      state: { isNew: true, selectedAlertLogList: alertList, redirectUrl: '/alerts' },
    });
  };

  const columns = useMemo(() => {
    return [
      Table.EXPAND_COLUMN,
      {
        title: t('AlertList.number'),
        dataIndex: 'alertLogID',
        key: 'alertLogID',
        sorter: true,
        showSorterTooltip: false,
        render: (value) => (
          <Link to={`/alerts/${value}`} target="_blank">
            {value}
          </Link>
        ),
      },
      {
        title: t('AlertList.spv'),
        dataIndex: 'ownerName',
        key: 'ownerName',
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: (
          <Row gutter={[0, 0]} align="middle">
            <Col span={18}>{t('AlertList.severity')}</Col>
            <Col span={6} style={{ textAlign: 'center' }}>
              <Tooltip
                title={
                  <>
                    <div>{t('AlertList.fatal')}</div>
                    <div>{t('AlertList.high')}</div>
                    <div>{t('AlertList.medium')}</div>
                    <div>{t('AlertList.low')}</div>
                  </>
                }
              >
                <InfoCircleOutlined />
              </Tooltip>
            </Col>
          </Row>
        ),
        dataIndex: 'alertDegree',
        key: 'alertDegree',
        sorter: true,
        showSorterTooltip: false,
        width: 110,
        render: (value) => {
          return (
            <Tag
              color={
                value === 'Fatal' ? 'red' : value === '高' ? 'volcano' : value === '中' ? 'gold' : 'green'
              }
            >
              {value}
            </Tag>
          );
        },
      },
      {
        title: t('AlertList.avgDailyPowerLoss'),
        dataIndex: 'avgLossEnergy',
        key: 'avgLossEnergy',
        sorter: true,
        showSorterTooltip: false,
        width: 150,
        render: (value, record) => (
          <Row gutter={[4, 0]} align="middle" justify="flex-start" style={{ padding: '0px 5px' }}>
            <Col>
              {record.alertStatusName === 'active' && <RedDotDiv />}
              {record.alertStatusName !== 'active' && <GreenDotDiv />}
            </Col>
            {/* {value && <Col>{bn(value).dp(2).toNumber()} kWh</Col>} */}
            {value && <Col>{bn(value).toFixed(2)} kWh</Col>}
            {!value && <Col> - </Col>}
          </Row>
        ),
      },
      {
        title: t('AlertList.cumulativePowerLoss'),
        dataIndex: 'lossEnergy',
        key: 'lossEnergy',
        sorter: true,
        showSorterTooltip: false,
        width: 120,
        render: (value, record) => (
          <Row gutter={[4, 0]} align="middle" justify="flex-start" style={{ padding: '0px 5px' }}>
            <Col>
              {record.alertStatusName === 'active' && <RedDotDiv />}
              {record.alertStatusName !== 'active' && <GreenDotDiv />}
            </Col>
            {/* {value && <Col>{bn(value).dp(2).toNumber()} kWh</Col>} */}
            {value && <Col>{bn(value).toFixed(2)} kWh</Col>}
            {!value && <Col> - </Col>}
          </Row>
        ),
      },
      {
        title: t('AlertList.days'),
        dataIndex: 'days',
        key: 'days',
        sorter: true,
        showSorterTooltip: false,
        width: 60,
      },
      {
        title: t('AlertList.alertCode'),
        dataIndex: 'alertID',
        key: 'alertID',
        sorter: true,
        showSorterTooltip: false,
        width: 105,
      },
      {
        title: t('AlertList.alertName'),
        dataIndex: 'alertDisplayName',
        key: 'alertDisplayName',
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('AlertList.equipmentLevel'),
        dataIndex: 'alertComponent',
        key: 'alertComponent',
        sorter: true,
        showSorterTooltip: false,
        width: 90,
      },
      {
        title: t('AlertList.invNumber'),
        dataIndex: 'clientDeviceSeq',
        key: 'clientDeviceSeq',
        sorter: true,
        showSorterTooltip: false,
        width: 85,
      },
      {
        title: t('AlertList.siteId'),
        dataIndex: 'objectID',
        key: 'objectID',
        sorter: true,
        showSorterTooltip: false,
        width: 75,
        render: (value, record) => (
          <Link to={`/pvs/${value}`} target="_blank">
            {value}
          </Link>
        ),
      },
      {
        title: t('AlertList.siteName'),
        dataIndex: 'objectName',
        key: 'objectName',
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('AlertList.alertStatus'),
        dataIndex: 'alertStatusName',
        key: 'alertStatusName',
        sorter: true,
        showSorterTooltip: false,
        width: 100,
        render: (value) => {
          if (value === 'active') return <Tag color="red">{value}</Tag>;
          return <Tag color="green">{value}</Tag>;
        },
      },
      {
        title: t('AlertList.cidNumber'),
        dataIndex: 'cidID',
        key: 'cidID',
        sorter: true,
        showSorterTooltip: false,
        width: 85,
        render: (value) => (
          <Link to={`/cids/${value}`} target="_blank">
            {value}
          </Link>
        ),
      },
      {
        title: t('AlertList.cidStatus'),
        dataIndex: 'cidStatusName',
        key: 'cidStatusName',
        sorter: true,
        showSorterTooltip: false,
        width: 85,
      },
      {
        title: t('AlertList.startTime'),
        dataIndex: 'startTime',
        key: 'startTime',
        sorter: true,
        showSorterTooltip: false,
        width: 140,
      },
      {
        title: t('AlertList.endTime'),
        dataIndex: 'endTime',
        key: 'endTime',
        sorter: true,
        showSorterTooltip: false,
        width: 140,
        render: (value, record) => (record.alertStatusName === 'active' ? '' : value),
      },
    ];
  }, [t]);
  // 展開欄位
  const expandedRowRender = (record) => {
    const detailColumns = [
      {
        title: t('AlertList.number'),
        dataIndex: 'alertLogID',
        key: 'alertLogID',
        showSorterTooltip: false,
        width: 65,
        render: (value) => (
          <Link to={`/alerts/${value}`} target="_blank">
            {value}
          </Link>
        ),
      },
      {
        title: t('AlertList.spv'),
        dataIndex: 'ownerName',
        key: 'ownerName',
        showSorterTooltip: false,
      },
      {
        title: t('AlertList.severity'),
        dataIndex: 'alertDegree',
        key: 'alertDegree',
        showSorterTooltip: false,
        width: 110,
        render: (value) => {
          return (
            <Tag
              color={
                value === 'Fatal' ? 'red' : value === '高' ? 'volcano' : value === '中' ? 'gold' : 'green'
              }
            >
              {value}
            </Tag>
          );
        },
      },
      {
        title: t('AlertList.avgDailyPowerLoss'),
        dataIndex: 'avgLossEnergy',
        key: 'avgLossEnergy',
        showSorterTooltip: false,
        width: 150,
        render: (value, record) => (
          <Row gutter={[4, 0]} align="middle" justify="flex-start" style={{ padding: '0px 5px' }}>
            <Col>
              {record.alertStatusName === 'active' && <RedDotDiv />}
              {record.alertStatusName !== 'active' && <GreenDotDiv />}
            </Col>
            {/* {value && <Col>{bn(value).dp(2).toNumber()} kWh</Col>} */}
            {value && <Col>{bn(value).toFixed(2)} kWh</Col>}
            {!value && <Col> - </Col>}
          </Row>
        ),
      },
      {
        title: t('AlertList.cumulativePowerLoss'),
        dataIndex: 'lossEnergy',
        key: 'lossEnergy',
        showSorterTooltip: false,
        width: 120,
        render: (value, record) => (
          <Row gutter={[4, 0]} align="middle" justify="flex-start" style={{ padding: '0px 5px' }}>
            <Col>
              {record.alertStatusName === 'active' && <RedDotDiv />}
              {record.alertStatusName !== 'active' && <GreenDotDiv />}
            </Col>
            {/* {value && <Col>{bn(value).dp(2).toNumber()} kWh</Col>} */}
            {value && <Col>{bn(value).toFixed(2)} kWh</Col>}
            {!value && <Col> - </Col>}
          </Row>
        ),
      },
      {
        title: t('AlertList.days'),
        dataIndex: 'days',
        key: 'days',
        showSorterTooltip: false,
        width: 60,
      },
      {
        title: t('AlertList.alertCode'),
        dataIndex: 'alertID',
        key: 'alertID',
        showSorterTooltip: false,
        width: 105,
      },
      {
        title: t('AlertList.alertName'),
        dataIndex: 'alertDisplayName',
        key: 'alertDisplayName',
        showSorterTooltip: false,
      },
      {
        title: t('AlertList.equipmentLevel'),
        dataIndex: 'alertComponent',
        key: 'alertComponent',
        showSorterTooltip: false,
        width: 90,
      },
      {
        title: t('AlertList.invNumber'),
        dataIndex: 'clientDeviceSeq',
        key: 'clientDeviceSeq',
        showSorterTooltip: false,
        width: 85,
      },
      {
        title: t('AlertList.siteId'),
        dataIndex: 'objectID',
        key: 'objectID',
        showSorterTooltip: false,
        width: 75,
        render: (value, record) => (
          <Link to={`/pvs/${value}`} target="_blank">
            {value}
          </Link>
        ),
      },
      {
        title: t('AlertList.siteName'),
        dataIndex: 'objectName',
        key: 'objectName',
        showSorterTooltip: false,
      },
      {
        title: t('AlertList.alertStatus'),
        dataIndex: 'alertStatusName',
        key: 'alertStatusName',
        showSorterTooltip: false,
        width: 100,
        render: (value) => {
          if (value === 'active') return <Tag color="red">{value}</Tag>;
          return <Tag color="green">{value}</Tag>;
        },
      },
      {
        title: t('AlertList.cidNumber'),
        dataIndex: 'cidID',
        key: 'cidID',
        showSorterTooltip: false,
        width: 85,
        render: (value) => (
          <Link to={`/cids/${value}`} target="_blank">
            {value}
          </Link>
        ),
      },
      {
        title: t('AlertList.cidStatus'),
        dataIndex: 'cidStatusName',
        key: 'cidStatusName',
        showSorterTooltip: false,
        width: 85,
      },
      {
        title: t('AlertList.startTime'),
        dataIndex: 'startTime',
        key: 'startTime',
        showSorterTooltip: false,
        width: 140,
      },
      {
        title: t('AlertList.endTime'),
        dataIndex: 'endTime',
        key: 'endTime',
        showSorterTooltip: false,
        width: 140,
        render: (value, record) => (record.alertStatusName === 'active' ? '' : value),
      },
    ];
    return (
      <Table
        columns={detailColumns}
        loading={expandLoading}
        dataSource={record.detailContent}
        pagination={false}
      />
    );
  };
  const handleExpandForTable = useCallback(
    (expanded, record) => {
      if (expanded) {
        if (!record.detailContent) {
          setExpandLoading(true);
          axios
            .post(
              appConfigs.appSyncURL,
              {
                query: AppSyncQueryTextFormat(
                  `query OmQuery(
                  $alertArray: [AlertInput],
                  $timezone: String!
                  ) { 
                    om_getAlertLogDetail(
                      alertArray: $alertArray,
                      timezone: $timezone
                    ) {
                        alertComponent
                        alertDegree
                        alertDisplayName
                        alertID
                        alertLogID
                        alertStatusName
                        avgLossEnergy
                        cidID
                        cidStatus
                        cidStatusName
                        clientDeviceSeq
                        days
                        endTime
                        lossEnergy
                        objectID
                        objectName
                        ownerID
                        ownerName
                        startTime
                        alertName
                        alertOwnerID
                        startTimestamp
                    }
                  }
                `
                ),
                variables: {
                  alertArray: record.details,
                  timezone: appConfigs.timezone,
                },
              },
              { headers: { Authorization: user.token } }
            )
            .then((res) => {
              if (!IsValidAppSyncRsps(res)) {
                message.warn(
                  t('AlertList.queryAlertLogGroupContentFailed', {
                    error: GetAppSyncRspsErrorMessage(res),
                  })
                );
                return;
              }

              record.detailContent = res.data.data.om_getAlertLogDetail.map(SetKeyToArray);
            })
            .catch((err) => {
              if (!axios.isCancel(err)) {
                message.error(t('AlertList.queryAlertLogGroupContentError'));
                console.error('load alert log detail error', err);
              }
            })
            .finally(() => {
              setExpandLoading(false);
            });
        }
      } else {
        setRecordOfTableExpandKey('');
      }
      setRecordOfTableExpandKey(expanded ? record.alertLogID : '');
    },
    [setRecordOfTableExpandKey, user.token, t]
  );

  return (
    <>
      {/* page header */}
      <PageHeader
        className="app-page-header rwd-btn-wrap"
        title={t('AlertList.title')}
        extra={[
          <div key="button-cid">
            {user.operations.includes('ALERTS_insert-cid-form') && (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                loading={addCidLoading}
                onClick={handleAddCideForm}
              >
                {t('AlertList.addCID')}
              </Button>
            )}
          </div>,
          <Button icon={<ReloadOutlined />} onClick={handleSearch} key="button-refresh">
            {t('AlertList.refresh')}
          </Button>,
          <div key="button-alertList">
            {user.operations.includes('ALERTS_export-alert-list') && (
              <Button icon={<DownloadOutlined />} loading={exporting} onClick={handleExport}>
                {t('AlertList.downloadAlertList')}
              </Button>
            )}
          </div>,
        ]}
      />
      {/* 搜尋面板 */}
      <div className="app-page-searchpanel">
        <Collapse onChange={() => setCollaps((val) => !val)}>
          <Panel header={t('AlertList.filterConditions')} key="1">
            <Row gutter={[8, 8]}>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  showSearch
                  placeholder={t('AlertList.spv')}
                  options={pvOwnerList}
                  value={filterData.pvOwner}
                  onChange={handleFilterDataChange('pvOwner')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  placeholder={t('AlertList.severity')}
                  options={alertDegreeList}
                  value={filterData.alertDegree}
                  onChange={handleFilterDataChange('alertDegree')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  showSearch
                  mode="multiple"
                  placeholder={t('AlertList.alertCategory')}
                  options={alertTypeList}
                  value={filterData.alertType}
                  onChange={handleFilterDataChange('alertType')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  showSearch
                  mode="multiple"
                  placeholder={t('AlertList.alertCode')}
                  options={alertIDFilterList}
                  value={filterData.alertID}
                  onChange={handleFilterDataChange('alertID')}
                  maxTagTextLength={12}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  placeholder={t('AlertList.equipmentLevel')}
                  options={alertComponentList}
                  value={filterData.alertComponent}
                  onChange={handleFilterDataChange('alertComponent')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  showSearch
                  placeholder={t('AlertList.siteName')}
                  options={filteredPvList}
                  value={filterData.pv}
                  onChange={handleFilterDataChange('pv')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  placeholder={t('AlertList.alertStatus')}
                  options={alertLogStatusList}
                  value={filterData.alertLogStatus}
                  onChange={handleFilterDataChange('alertLogStatus')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  placeholder={t('AlertList.cidStatus')}
                  options={alertLogCidStatusList}
                  value={filterData.alertLogCidStatus}
                  onChange={handleFilterDataChange('alertLogCidStatus')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <DatePicker
                  showTime
                  allowClear
                  placeholder={t('AlertList.startTimeBegin')}
                  value={filterData.startBeginTime}
                  onChange={handleFilterDataChange('startBeginTime')}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <DatePicker
                  showTime
                  allowClear
                  placeholder={t('AlertList.startTimeEnd')}
                  value={filterData.startEndTime}
                  onChange={handleFilterDataChange('startEndTime')}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <DatePicker
                  showTime
                  allowClear
                  placeholder={t('AlertList.endTimeBegin')}
                  value={filterData.endBeginTime}
                  onChange={handleFilterDataChange('endBeginTime')}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <DatePicker
                  showTime
                  allowClear
                  placeholder={t('AlertList.endTimeEnd')}
                  value={filterData.endEndTime}
                  onChange={handleFilterDataChange('endEndTime')}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <Input
                  allowClear
                  placeholder={t('AlertList.invNumber')}
                  value={filterData.clientDeviceSeq}
                  onChange={handleFilterDataChange('clientDeviceSeq')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={{ span: 3, offset: 12 }} xl={{ span: 3, offset: 12 }}>
                <Button block onClick={handleReset}>
                  {t('AlertList.reset')}
                </Button>
              </Col>
              <Col xs={24} sm={24} md={12} lg={3} xl={3}>
                <Button type="primary" block onClick={handleSearch}>
                  {t('AlertList.search')}
                </Button>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </div>
      {/* 列表 */}
      <div className="app-page-content">
        <Card bordered={false} size="small">
          <Spin spinning={loading} tip={t('AlertList.searchingData')}>
            <Table
              size="small"
              columns={columns}
              dataSource={dataList.list}
              scroll={{ x: 1920, y: collaps ? `calc(100vh - 400px)` : `calc(100vh - 585px)` }}
              rowSelection={rowSelectionProps}
              pagination={{
                total: dataList.total,
                pageSize: tableInfo.pageSize,
                showSizeChanger: false,
                current: tableInfo.page,
              }}
              expandable={{
                expandedRowRender,
                expandedRowKeys: [recordOfTableExpandKey],
                onExpand: handleExpandForTable,
                rowExpandable: (record) => Array.isArray(record.details) && record.details.length > 0,
              }}
              onChange={handleTableChange}
            />
          </Spin>
        </Card>
      </div>
    </>
  );
};

export default AlertList;
