import { useEffect, useContext, useState, useMemo } from 'react';
import axios from 'axios';
import { message, Typography, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { SettingContext } from '../../contexts/settingsProvider';
import { IsValidAppSyncRsps } from '../../utils/valid';
import { AppSyncQueryTextFormat } from '../../utils/format';
import { GetAppSyncRspsErrorMessage } from '../../utils/parse';

const ReportNerbyObjectCompare = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { dark } = useContext(SettingContext);
  const [grafanaUrls, setGrafanUrls] = useState({ url1: '', url2: '' });
  
  const token = useMemo(() => {
    return user.token?.replace?.(/^Bearer /, '');
  }, [user]);
  
  const theme = useMemo(() => {
    return dark ? 'dark' : 'light';
  }, [dark]);

  useEffect(() => {
    axios
      .post(
        appConfigs.sharedAppSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery($system: String, $kind: String) {
              shared_getGrafanaEmbeddingList(system: $system, kind: $kind) {
                code
                id
                url
              }
            }
            `
          ),
          variables: {
            system: appConfigs.systemID,
            kind: 'reportnerbyobjectcompare',
          },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('reportNerbyObjectCompare.failedToGetGrafanaEmbeddingInfo', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        const data = res.data.data.shared_getGrafanaEmbeddingList || [];
        if (!Array.isArray(data) || data.length === 0) {
          message.warn(t('reportNerbyObjectCompare.failedToGetGrafanaUrl'));
          return;
        }

        let url1 = data.find((f) => f.code === 'report-nerby-object-compare-by-project');
        if (url1) url1 = url1.url;

        let url2 = data.find((f) => f.code === 'report-nerby-object-compare-by-county');
        if (url2) url2 = url2.url;

        setGrafanUrls({ url1, url2 });
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error('load grafanaUrlApi error', err);
          message.error(t('reportNerbyObjectCompare.getGrafanaEmbeddingInfoError'));
        }
      });
  }, [user, t]);

  return (
    <>
      <Typography.Title level={3}>{t('reportNerbyObjectCompare.titleByProject')}</Typography.Title>
      {grafanaUrls.url1 ? (
        <div style={{ width: '100%', height: '1080px', overflow: 'hidden' }}>
          <iframe
            id="frame"
            width={'100%'}
            title={t('reportNerbyObjectCompare.titleByProject')}
            style={{ border: 0, height: '100%' }}
            src={`${grafanaUrls.url1}&auth_token=${token}&theme=${theme}`}
          ></iframe>
        </div>
      ) : (
        <Spin />
      )}
      <br />
      <br />
      <Typography.Title level={3}>{t('reportNerbyObjectCompare.titleByCounty')}</Typography.Title>
      {grafanaUrls.url2 ? (
        <div style={{ width: '100%', height: '1200px', overflow: 'hidden' }}>
          <iframe
            id="frame"
            width={'100%'}
            title={t('reportNerbyObjectCompare.titleByCounty')}
            style={{ border: 0, height: '100%' }}
            src={`${grafanaUrls.url2}&auth_token=${token}&theme=${theme}`}
          ></iframe>
        </div>
      ) : (
        <Spin />
      )}
    </>
  );
};

export default ReportNerbyObjectCompare;
