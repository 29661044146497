import React, { useCallback, useEffect, useState, useMemo, useContext } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Col, Row, Button, Spin, message, Table, PageHeader, Card, Collapse, Switch } from 'antd';
import { PlusOutlined, DownloadOutlined, ReloadOutlined } from '@ant-design/icons';
import RfdmeInput from '../../components/Input';
import RfdmeDatePicker from '../../components/DatePicker';
import RfdmeSelect from '../../components/Select';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import useListOfCidDateType from '../../hooks/useListOfCidDateType';
import useListOfCidLevel from '../../hooks/useListOfCidLevel';
import useListOfCidStatus from '../../hooks/useListOfCidStatus';
import useListOfCidSource from '../../hooks/useListOfCidSource';
import useListOfCidUserInCharge from '../../hooks/useListOfCidUserInCharge';
import useListOfCidProblemCategory from '../../hooks/useListOfCidProblemCategory';
import useListOfOmExtraPv from '../../hooks/useListOfOmExtraPv';
import {
  AppSyncQueryTextFormat,
  DateTimeFormat,
  JsonStringToFormObject,
  SetKeyToArray,
} from '../../utils/format';
import { IsValidAppSyncRsps } from '../../utils/valid';
import {
  INITIAL_FILTER_DATA,
  GetDataOfFilterFromSession,
  SetDataOfFilterToSession,
  SetDefaultDataOfFilterToSession,
} from './data';
import { GetFileNameFromResponseHeader, GetAppSyncRspsErrorMessage } from '../../utils/parse';
import { QueryOmContractLevelList } from '../../utils/dataHelpers';
import { useTranslation } from 'react-i18next';
//
const { Panel } = Collapse;
//
const HintText = styled.div`
  font-size: 12px;
`;
const HintTextLabel = styled.span`
  font-size: 12px;
  color: #ccc;
`;
const AlignLeftDiv = styled.div`
  width: 100%;
  text-align: left;
`;
const AlignRightDiv = styled.div`
  width: 100%;
  text-align: right;
`;

//
const INIT_TABLE_INFO = { sortColumn: null, sortDirection: 'ascend', page: 1, pageSize: 20 };
const GenerateQueryConditions = (tableInfo) => {
  const conditions = GetDataOfFilterFromSession();

  if (conditions.startDate) conditions.startDate = conditions.startDate.format('YYYY-MM-DD');
  if (conditions.endDate) conditions.endDate = conditions.endDate.format('YYYY-MM-DD');

  //加入 tableInfo
  if (tableInfo) {
    conditions.page = tableInfo.page;
    conditions.pageSize = tableInfo.pageSize;
    conditions.sortColumnName = tableInfo.sortColumn;
    conditions.sortDirection = tableInfo.sortDirection === 'ascend' ? 'asc' : 'desc';
  }

  return conditions;
};

//主要元件
const CidList = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [collaps, setCollaps] = useState(true);
  const [tableInfo, setTableInfo] = useState(INIT_TABLE_INFO);
  const [dataOfFilter, setDataOfFilter] = useState({ ...INITIAL_FILTER_DATA });
  const [recordOfCidForm, setRecordOfCidForm] = useState([]);
  const [listOfCidLevel, setListOfCidLevel] = useState([]);
  //
  const { data: listOfCidDateType } = useListOfCidDateType();
  const { data: listOfDefaultCidLevel } = useListOfCidLevel();
  const { data: listOfCidStatus } = useListOfCidStatus();
  const { data: listOfCidSource } = useListOfCidSource();
  const { data: listOfCidUserInCharge } = useListOfCidUserInCharge();
  const { data: listOfCidProblemCategory } = useListOfCidProblemCategory();
  const { data: listOfPv } = useListOfOmExtraPv();
  //
  const listOfPvOwner = useMemo(() => {
    if (!Array.isArray(listOfPv) || listOfPv.length === 0) return [];

    return _.chain(listOfPv)
      .map((m) => ({ label: m.ownerName, value: m.ownerID }))
      .unionBy('value')
      .sortBy(['label'])
      .value();
  }, [listOfPv]);
  const listOfPvByOwner = useMemo(() => {
    if (!Array.isArray(listOfPv) || listOfPv.length === 0) return [];
    if (
      Array.isArray(listOfPv) &&
      (!Array.isArray(dataOfFilter.ownerIDs) || dataOfFilter.ownerIDs.length === 0)
    ) {
      return listOfPv;
    }

    const items = listOfPv.filter((f) => dataOfFilter.ownerIDs.includes(f.ownerID));
    if (items.length === 0) return [];

    return items.map(SetKeyToArray);
  }, [dataOfFilter.ownerIDs, listOfPv]);

  // 隨 SPV 與 案場選擇來變更重要性
  useEffect(() => {
    async function fetchData() {
      let items = [];
      const queryCompanyIDs =
        !Array.isArray(dataOfFilter?.ownerIDs) || dataOfFilter.ownerIDs.length < 1
          ? []
          : dataOfFilter.ownerIDs;
      const queryObjectIDs =
        !Array.isArray(dataOfFilter?.objectIDs) || dataOfFilter.objectIDs.length < 1
          ? []
          : dataOfFilter.objectIDs;

      const getOmContractLevelList = await QueryOmContractLevelList(user, queryCompanyIDs, queryObjectIDs);

      if (queryCompanyIDs.length > 0 || queryObjectIDs.length > 0) {
        if (getOmContractLevelList.length > 0) {
          items = [...getOmContractLevelList.sort((a, b) => a.key - b.key)];
        } else {
          items = [...listOfDefaultCidLevel];
        }
      } else {
        items = [...getOmContractLevelList.sort((a, b) => a.key - b.key)];
        listOfDefaultCidLevel.forEach((defaultItem) => {
          const exists = getOmContractLevelList.some((item) => item.label === defaultItem.label);

          if (!exists) {
            // 如果不存在，則將 defaultItem 加入到 getOmContractLevelList
            items.push(defaultItem);
          }
        });
      }

      items = items.map((m) => ({
        label: m.label,
        value: m.label,
      }));

      setListOfCidLevel(items.map(SetKeyToArray));
      setDataOfFilter((currentData) => {
        return { ...currentData, levelNames: [] };
      });
    }
    fetchData();
  }, [user, listOfDefaultCidLevel, dataOfFilter.ownerIDs, dataOfFilter.objectIDs]);

  // =====================
  useEffect(() => {
    const data = GetDataOfFilterFromSession();
    if (data) {
      setDataOfFilter(data);
    } else {
      SetDefaultDataOfFilterToSession();
    }
  }, []);

  // =====================
  const handleSetAsDefault = () => {
    SetDefaultDataOfFilterToSession();
    setDataOfFilter({ ...INITIAL_FILTER_DATA });
  };
  const handleSearch = () => {
    if ((dataOfFilter.startDate || dataOfFilter.endDate) && !dataOfFilter.dateType) {
      message.warn(t('cidList.selectTimeItemFirst'));
      return;
    }

    SetDataOfFilterToSession(dataOfFilter);
    setTableInfo((prev) => ({ ...prev, page: 1 }));
  };
  const handleQuery = useCallback(() => {
    //
    const queryConditions = GenerateQueryConditions(tableInfo);

    //查詢
    setLoading(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery(
                $cidFormID: String,
                $dateType: Int,
                $startDate: String,
                $endDate: String,
                $encrypt: Boolean,
                $levelNames: [String],
                $userInCharges: [String],  
                $statusIDs: [Int], 
                $objectIDs: [String],
                $sourceIDs: [Int], 
                $problemCategoryIDs: [Int], 
                $errorCodeIDs: [String],
                $issue: String,
                $currentProgress: String,
                $rootCause: String,
                $solution: String,
                $ownerIDs: [String],
                $recordOnlyFlag: Boolean,
                $timezone: String!,
                $page: Int!,
                $pageSize: Int!,
                $sortColumnName: String,
                $sortDirection: String
              ) { 
                om_getCidFormList(
                  cidFormID: $cidFormID,
                  dateType: $dateType,
                  startDate: $startDate,
                  endDate: $endDate,
                  encryption: $encrypt,
                  levelNames: $levelNames,
                  userInCharges: $userInCharges,
                  statusIDs: $statusIDs,
                  objectIDs: $objectIDs,
                  sourceIDs: $sourceIDs,
                  problemCategoryIDs: $problemCategoryIDs,
                  errorCodeIDs: $errorCodeIDs,
                  issue: $issue,
                  currentProgress: $currentProgress,
                  rootCause: $rootCause,
                  solution: $solution,
                  ownerIDs: $ownerIDs,
                  recordOnlyFlag: $recordOnlyFlag,
                  timezone: $timezone,
                  page: $page,
                  pageSize: $pageSize,
                  sortColumnName: $sortColumnName,
                  sortDirection: $sortDirection
                ) {
                  total
                  list {
                    levelName
                    cidFormID
                    startDate
                    createDate
                    arrivalTime
                    objectList
                    userName
                    statusName
                    continueDay
                    omFormList 
                    sourceName
                    problemCategoryName
                    errorCodeName
                    topic
                    currentProgress
                    possibleRootCause
                    rootCause
                    solution
                    issue
                    rootCauseLevel1Name
                    rootCauseLevel2Name
                    rootCauseLevel3Name
                    solutionLevel1Name
                    solutionLevel2Name
                  }
                }
              }`
          ),
          variables: queryConditions,
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('cidList.failedToQueryList', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        const tmp = res.data.data.om_getCidFormList;
        tmp.list = tmp.list.map((m, mIdx) => {
          m.key = m.cidFormID;

          // 處理 omFormList (JSON 字串的陣列)
          if (!Array.isArray(m.omFormList)) m.omFormList = [];
          m.omFormList = m.omFormList.filter((f) => f).map((m) => JsonStringToFormObject(m));

          // 處理 objectList (JSON 字串的陣列)
          if (!Array.isArray(m.objectList)) m.objectList = [];
          m.objectList = m.objectList.filter((f) => f).map((m) => JsonStringToFormObject(m));

          // 處理實際根因(階層)
          let rootCauseLevel = [];
          if (m.rootCauseLevel1Name) rootCauseLevel.push(m.rootCauseLevel1Name);
          if (m.rootCauseLevel2Name) rootCauseLevel.push(m.rootCauseLevel2Name);
          if (m.rootCauseLevel3Name) rootCauseLevel.push(m.rootCauseLevel3Name);
          m.rootCauseLevel = rootCauseLevel;

          // 處理實施對策(階層)
          let solutionLevel = [];
          if (m.solutionLevel1Name) solutionLevel.push(m.solutionLevel1Name);
          if (m.solutionLevel2Name) solutionLevel.push(m.solutionLevel2Name);
          m.solutionLevel = solutionLevel;

          // find the latest update time.
          const maxUpdateTimeItem = _.chain(m.omFormList)
            .filter((f) => f.omUpdateTime)
            .maxBy('omUpdateTime')
            .value();
          m.omUpdateTime = (maxUpdateTimeItem || {}).omUpdateTime || '';

          // return.
          return m;
        });

        setRecordOfCidForm(tmp);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('cidList.queryListError'));
          console.error('load cid form list error: ', err);
        }
      })
      .then(() => {
        setLoading(false);
      });

    //
  }, [user, tableInfo, t]);
  useEffect(() => {
    handleQuery();
  }, [handleQuery]);

  // =====================
  const handleDataOfFilterChange = (name) => (e) => {
    const modifyValue = e && e.target ? e.target.value : e;
    const tmp = { ...dataOfFilter };
    tmp[name] = modifyValue;
    delete tmp[`${name}_msg`];

    if (name === 'dateType' && !modifyValue) {
      tmp.startDate = null;
      tmp.endDate = null;
    } else if (name === 'ownerIDs' && Array.isArray(modifyValue) && modifyValue.length > 0 && tmp.objectIDs) {
      const items = listOfPv.filter(
        (f) => tmp.objectIDs.includes(f.value) && modifyValue.includes(f.ownerID)
      );
      tmp.objectIDs = items && items.length > 0 ? items.map((i) => i.value) : [];
    }

    setDataOfFilter(tmp);
  };

  // =====================
  const handleTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'paginate') {
      setTableInfo((tbi) => ({
        ...tbi,
        page: pagination.current,
      }));
    }

    if (extra.action === 'sort') {
      setTableInfo((tbi) => ({
        ...tbi,
        sortColumn: sorter.field,
        sortDirection: sorter.order, //"ascend" or "descend"
      }));
    }
  };
  const handleExport = () => {
    //
    const queryConditions = GenerateQueryConditions(tableInfo);

    setExporting(true);
    axios
      .post(
        `${appConfigs.apiGatewayURL}/oms/cid/export`,
        { ...queryConditions },
        { headers: { Authorization: user.token, Accept: '*/*' }, responseType: 'blob' }
      )
      .then((res) => {
        if (!res || !res.data) {
          message.error(t('cidList.exportListFailed', { msg: res?.data?.msg }));
          return;
        }

        const fileName = GetFileNameFromResponseHeader(res.headers['content-disposition'], 'CID列表.xlsx');
        const blobFile = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = blobFile;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(blobFile);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('cidList.exportListError'));
          console.error('export CID form list error: ', err);
        }
      })
      .then(() => {
        setExporting(false);
      });
  };

  // =====================
  const columns = useMemo(() => {
    return [
      {
        title: t('cidList.importance'),
        dataIndex: 'levelName',
        key: 'levelName',
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('cidList.cidNumber'),
        dataIndex: 'cidFormID',
        key: 'cidFormID',
        sorter: true,
        showSorterTooltip: false,
        render: (value, record) => (
          <Link to={`/cids/${value}`} target="_blank">
            {value}
          </Link>
        ),
      },
      {
        title: t('cidList.occurDate'),
        dataIndex: 'startDate',
        key: 'startDate',
        sorter: true,
        showSorterTooltip: false,
        render: (value) => DateTimeFormat(value),
      },
      {
        title: t('cidList.createDate'),
        dataIndex: 'createDate',
        key: 'createDate',
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('cidList.arrivalTime'),
        dataIndex: 'arrivalTime',
        key: 'arrivalTime',
      },
      {
        title: t('cidList.relatedSite'),
        dataIndex: 'objectList',
        key: 'objectList',
        render: (value) => {
          if (Array.isArray(value) && value.length > 0) {
            return value.map((v, vIdx) => (
              <div key={vIdx}>
                <div>
                  <HintTextLabel>{v.ownerName}</HintTextLabel>
                </div>
                <div>{`${v.objectID}_${v.objectName}`}</div>
              </div>
            ));
          } else {
            return null;
          }
        },
      },
      {
        title: t('cidList.personInCharge'),
        dataIndex: 'userName',
        key: 'userName',
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('cidList.cidStatus'),
        dataIndex: 'statusName',
        key: 'statusName',
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('cidList.continueDays'),
        dataIndex: 'continueDay',
        key: 'continueDay',
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: (
          <>
            <AlignLeftDiv>{t('cidList.omNumber')}</AlignLeftDiv>
            <AlignRightDiv>[{t('cidList.omStatus')}]</AlignRightDiv>
          </>
        ),
        dataIndex: 'omFormList',
        key: 'omFormList',
        render: (value) => {
          if (Array.isArray(value) && value.length > 0) {
            return value.map((v, vIdx) => (
              <Link key={vIdx} to={`/oms/${v.omFormID}`} target="_blank">
                <AlignLeftDiv>{v.omFormID}</AlignLeftDiv>
                <AlignRightDiv>
                  <HintText>[{v.omStatusName || t('cidList.noStatus')}]</HintText>
                </AlignRightDiv>
              </Link>
            ));
          } else {
            return null;
          }
        },
      },
      {
        title: t('cidList.omUpdateTime'),
        dataIndex: 'omUpdateTime',
        key: 'omUpdateTime',
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('cidList.source'),
        dataIndex: 'sourceName',
        key: 'sourceName',
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('cidList.problemCategory'),
        dataIndex: 'problemCategoryName',
        key: 'problemCategoryName',
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('cidList.issue'),
        dataIndex: 'issue',
        key: 'issue',
        sorter: true,
        showSorterTooltip: false,
        render: (value, record) => (
          <>
            <div>
              <strong>{record.issue}</strong>
            </div>
            <div>
              <HintTextLabel>{t('cidList.currentProgress')}：</HintTextLabel>
              <span>{record.currentProgress}</span>
            </div>
            <div>
              <HintTextLabel>{t('cidList.rootCause')}：</HintTextLabel>
              <span>
                {record.rootCause && !record.rootCauseLevel.length
                  ? record.rootCause
                  : record.rootCauseLevel.map((o) => o).join(' / ')}
              </span>
            </div>
            <div>
              <HintTextLabel>{t('cidList.solution')}：</HintTextLabel>
              <span>
                {record.solution && !record.solutionLevel.length
                  ? record.solution
                  : record.solutionLevel.map((o) => o).join(' / ')}
              </span>
            </div>
          </>
        ),
      },
    ];
  }, [t]);

  // ------------------------------------

  return (
    <>
      {/* page header */}
      <PageHeader
        className="app-page-header"
        title={t('cidList.management')}
        extra={[
          <div key="button-cid-auto">
            {user.operations.includes('CIDS_insert-cid-form') && (
              <Link to="/cids/自動編號" state={{ isNew: true, redirectUrl: '/cids' }}>
                <Button type="primary" icon={<PlusOutlined />}>
                  {t('cidList.addCid')}
                </Button>
              </Link>
            )}
          </div>,
          <Button icon={<ReloadOutlined />} onClick={handleSearch} key="button-refresh">
            {t('cidList.refresh')}
          </Button>,
          <div key="button-export">
            {user.operations.includes('CIDS_export-cid-form') && (
              <Button icon={<DownloadOutlined />} loading={exporting} onClick={handleExport}>
                {t('cidList.exportQueryResults')}
              </Button>
            )}
          </div>,
        ]}
      />
      {/* 搜尋面板 */}
      <div className="app-page-searchpanel">
        <Collapse onChange={() => setCollaps((val) => !val)}>
          <Panel header={t('cidList.filterConditions')} key="1">
            <Row gutter={[8, 8]}>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeInput
                  placeholder={t('cidList.numberPlaceholder')}
                  value={dataOfFilter.cidFormID}
                  onChange={handleDataOfFilterChange('cidFormID')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  placeholder={t('cidList.timeItemPlaceholder')}
                  options={listOfCidDateType}
                  value={dataOfFilter.dateType}
                  onChange={handleDataOfFilterChange('dateType')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeDatePicker
                  placeholder={t('cidList.startDatePlaceholder')}
                  value={dataOfFilter.startDate}
                  onChange={handleDataOfFilterChange('startDate')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeDatePicker
                  placeholder={t('cidList.endDatePlaceholder')}
                  value={dataOfFilter.endDate}
                  onChange={handleDataOfFilterChange('endDate')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('cidList.spvPlaceholder')}
                  options={listOfPvOwner}
                  value={dataOfFilter.ownerIDs}
                  onChange={handleDataOfFilterChange('ownerIDs')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('cidList.relatedSitePlaceholder')}
                  options={listOfPvByOwner}
                  value={dataOfFilter.objectIDs}
                  onChange={handleDataOfFilterChange('objectIDs')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('cidList.importancePlaceholder')}
                  options={listOfCidLevel}
                  value={dataOfFilter.levelNames}
                  onChange={handleDataOfFilterChange('levelNames')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('cidList.statusPlaceholder')}
                  options={listOfCidStatus}
                  value={dataOfFilter.statusIDs}
                  onChange={handleDataOfFilterChange('statusIDs')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('cidList.sourcePlaceholder')}
                  options={listOfCidSource}
                  value={dataOfFilter.sourceIDs}
                  onChange={handleDataOfFilterChange('sourceIDs')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('cidList.problemCategoryPlaceholder')}
                  options={listOfCidProblemCategory}
                  value={dataOfFilter.problemCategoryIDs}
                  onChange={handleDataOfFilterChange('problemCategoryIDs')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeInput
                  allowClear={true}
                  placeholder={t('cidList.issuePlaceholder')}
                  value={dataOfFilter.issue}
                  onChange={handleDataOfFilterChange('issue')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeInput
                  allowClear={true}
                  placeholder={t('cidList.currentProgressPlaceholder')}
                  value={dataOfFilter.currentProgress}
                  onChange={handleDataOfFilterChange('currentProgress')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeInput
                  allowClear={true}
                  placeholder={t('cidList.rootCausePlaceholder')}
                  value={dataOfFilter.rootCause}
                  onChange={handleDataOfFilterChange('rootCause')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeInput
                  allowClear={true}
                  placeholder={t('cidList.solutionPlaceholder')}
                  value={dataOfFilter.solution}
                  onChange={handleDataOfFilterChange('solution')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('cidList.personInChargePlaceholder')}
                  options={listOfCidUserInCharge}
                  value={dataOfFilter.userInCharges}
                  onChange={handleDataOfFilterChange('userInCharges')}
                />
              </Col>
              <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                <Switch
                  style={{ marginBottom: '6px', marginRight: '6px' }}
                  checkedChildren={t('cidList.recordOnly')}
                  unCheckedChildren={t('cidList.notRecordOnly')}
                  checked={dataOfFilter.recordOnlyFlag}
                  onChange={handleDataOfFilterChange('recordOnlyFlag')}
                />
              </Col>
              <Col xs={12} sm={12} md={6} lg={{ span: 3, offset: 6 }} xl={{ span: 3, offset: 6 }}>
                <Button block loading={loading} onClick={handleSetAsDefault}>
                  {t('cidList.reset')}
                </Button>
              </Col>
              <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                <Button type="primary" block loading={loading} onClick={handleSearch}>
                  {t('cidList.search')}
                </Button>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </div>
      {/* 列表 */}
      <div className="app-page-content">
        <Card bordered={false} size="small">
          <Spin spinning={loading} tip={t('cidList.searching')}>
            <Table
              size="small"
              columns={columns}
              dataSource={recordOfCidForm.list}
              scroll={{ x: 1920, y: collaps ? `calc(100vh - 420px)` : `calc(100vh - 650px)` }}
              pagination={{
                total: recordOfCidForm.total,
                pageSize: tableInfo.pageSize,
                showSizeChanger: false,
                current: tableInfo.page,
              }}
              onChange={handleTableChange}
            />
          </Spin>
        </Card>
      </div>
    </>
  );
};

export default CidList;
