import axios from 'axios';
import appConfigs from '../configs';

export const QueryOmContractLevelList = (user, queryCompanyID, queryObjectID) => {
  return axios
    .post(
      appConfigs.appSyncURL,
      {
        query: `query OmQuery($companyIDs: [String], $objectIDs: [String]) { 
          om_getOmContractLevelList(companyIDs: $companyIDs, objectIDs: $objectIDs) { 
            key value seq desc 
          } 
        }`,
        variables: { companyIDs: queryCompanyID, objectIDs: queryObjectID },
      },
      { headers: { Authorization: user.token } }
    )
    .then((res) => {
      if (res.status === 200 && res.data.data) {
        return res.data.data.om_getOmContractLevelList.map((m) => ({
          label: m.value,
          value: m.key,
          seq: m.seq,
          desc: m.desc,
        }));
      }
      return [];
    });
};
