import { Radio } from 'antd';
import ComponentLayout from '../../layout/componentLayout';

const RfdmeRadio = ({ required, label, msg, ...props }) => {
  return (
    <ComponentLayout label={label} required={required} msg={msg}>
      <Radio.Group style={{ width: '100%' }} {...props} />
    </ComponentLayout>
  );
};

export default RfdmeRadio;
