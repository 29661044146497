import React, { useState, useCallback } from 'react';
import { AdvancedMarker, InfoWindow, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import { Col, Row } from 'antd';
//
import mapMarker from '../../images/map-marker.svg';
import mapMarkerSelect from '../../images/map-marker-select.svg';
//
import MADOU from '../../images/boosterStation/MADOU.jpg';
import TJPD from '../../images/boosterStation/TJPD.jpg';
import icon from '../../images/boosterStation/icon.jpg';

const MarkerWithInfoWindow = ({ position, id, name, description, ...props }) => {
  const [markerRef, marker] = useAdvancedMarkerRef();
  const [infoWindowShown, setInfoWindowShown] = useState(false);
  const handleMarkerClick = useCallback(() => setInfoWindowShown((isShown) => !isShown), []);

  const handleClose = useCallback(() => setInfoWindowShown(false), []);
  const imageSource = id === 'MADOU' ? MADOU : id === 'TJPD' ? TJPD : icon;

  return (
    <>
      <AdvancedMarker ref={markerRef} position={position} onClick={handleMarkerClick} {...props}>
        <img alt="marker" src={infoWindowShown ? mapMarkerSelect : mapMarker} width={32} height={32} />
      </AdvancedMarker>
      {infoWindowShown && (
        <InfoWindow anchor={marker} onClose={handleClose} headerDisabled={true} minWidth={250}>
          <div className="app-googlemap-info-window">
            <Row gutter={[12, 12]} align="middle" style={{ width: '100%' }}>
              <Col span={9}>
                <div className="image">
                  <img alt="icon" src={imageSource} />
                </div>
              </Col>
              <Col span={15}>
                <div className="text">
                  <div>
                    <h4>{id}</h4>
                    <h3>{name}</h3>
                    <p>{description}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default MarkerWithInfoWindow;
