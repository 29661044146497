import React, { useContext } from 'react';
import { PageHeader, Card, Row, Col, Switch, Select, message } from 'antd';
import { SettingContext } from '../../contexts/settingsProvider';
import setting from '../../images/settings.svg';
import setting_light from '../../images/settings_light.svg';
import { useTranslation } from 'react-i18next';
import appConfigs from '../../configs';
import axios from 'axios';
import { AppSyncQueryTextFormat } from '../../utils/format';
import { GetAppSyncRspsErrorMessage } from '../../utils/parse';
import { IsValidAppSyncRsps } from '../../utils/valid';
import AuthContext from '../../contexts/authProvider';

const { Option } = Select;

//主要元件
const Settings = () => {
  const { user, UpdateMenus } = useContext(AuthContext);
  const { dark, setDark } = useContext(SettingContext);
  const { t, i18n } = useTranslation();

  const onChange = () => {
    setDark(!dark);
  };

  const handleLanguageChange = (value) => {
    localStorage.setItem('i18nextLng', value);
    axios
      .post(
        appConfigs.sharedAppSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery($systemID: String!) { 
              shared_getAuthMenuList(systemID: $systemID) {
                menuName
                menuPath
              }
            }
            `
          ),
          variables: {
            systemID: appConfigs.systemID,
          },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('settings.failedToQueryMenu', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        const resData = res.data.data.shared_getAuthMenuList;
        if (Array.isArray(resData) && resData.length > 0) {
          UpdateMenus(resData);
        }
        i18n.changeLanguage(value);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('settings.errorToQueryMenu'));
          console.error('load menu error: ', err);
        }
      });
  };

  return (
    <>
      <PageHeader className="app-page-header" title={t('settings.title')} />
      <div className="app-page-content">
        <Row
          gutter={[
            { xs: 12, sm: 16, md: 16, lg: 16 },
            { xs: 12, sm: 16, md: 16, lg: 16 },
          ]}
        >
          <Col xs={24} sm={24} md={16} lg={12} xl={8}>
            <Row
              gutter={[
                { xs: 12, sm: 16, md: 16, lg: 16 },
                { xs: 12, sm: 16, md: 16, lg: 16 },
              ]}
            >
              <Col span={24}>
                <Card size="default" title={t('settings.environmentSettings')}>
                  <div className="list-wrap">
                    <div className="list-item">
                      <div className="list-title">{t('settings.darkMode')}</div>
                      <div className="list-switch">
                        <Switch checked={dark} onChange={onChange} />
                      </div>
                    </div>
                    <div className="list-item">
                      <div className="list-title">{t('settings.language')}</div>
                      <div className="list-switch">
                        <Select
                          defaultValue={i18n.language}
                          value={i18n.language}
                          style={{ width: 140 }}
                          onChange={handleLanguageChange}
                          popupClassName={dark ? 'dark' : ''}
                        >
                          <Option value="en">{t('settings.english')}</Option>
                          <Option value="zh-TW">{t('settings.chinese')}</Option>
                          <Option value="vi">{t('settings.vietnamese')}</Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="lockman">
        <img className="" src={dark ? setting : setting_light} alt={t('settings.settingImage')} />
      </div>
    </>
  );
};

export default Settings;
