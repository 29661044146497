export const CidStatusConstant = {
  CREATE: 9, //新建立
  REQUEST_SEND: 10, //提出請求
  REQUEST_RECEIVED: 11, //請求已接收
  REQUEST_PREPARATION: 12, //請求準備中
  PLANNED: 13, //已規劃派工
  PROCESSING: 14, //請求處理中
  ACCEPTANCE: 15, //驗收中
  REJECT: 16, //拒絕請求
  CLOSED: 6, //結案
  VOID: 8, //作廢
};

export const OmStatusConstant = {
  REQUEST_PREPARATION: 4, //請求準備中
  PLANNED: 5, //已規劃派工
  PROCESSING: 6, //請求處理中
  ISSUE_ONGOING: 7, //已派工，問題持續
  ISSUE_OBSERVE: 8, //已派工，問題觀察
  ISSUE_RESOLVED: 10, //已派工，問題解決
  ISSUE_ONGOING_BUT_FOLLOW_UP: 11, //已派工，問題持續(已接續開單)
  VOID: 9, //作廢
  VOID_BY_WITHDRAW: 12,
};
