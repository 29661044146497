import React, { useContext, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, useSearchParams } from 'react-router-dom';
//
import appConfigs from '../configs';
import AuthContext from '../contexts/authProvider';
//
import AppLayout from './appLayout';
import Login from '../pages/Login';

const App = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (appConfigs.stageName) {
      document.title = `RFDME - O&M 2.0 - ${appConfigs.stageName}`;
    }
  }, []);

  return (
    <Routes>
      {!user && (
        <>
          <Route path="/login" element={<Login homePagePath={searchParams.get('redirect') || '/'} />} />
          <Route path="*" element={<Navigate to={`/login?redirect=${location.pathname}`} />} />
        </>
      )}
      {!!user && (
        <>
          <Route path="/login" element={<Navigate to="/" />} />
          <Route path="*" element={<AppLayout />} />
        </>
      )}
    </Routes>
  );
};

export default App;
