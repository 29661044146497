/* eslint-disable react/prop-types */
import React, { createContext } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';

export const SettingContext = createContext();

export default function SettingProvider({ children }) {
  const [dark, setDark] = useLocalStorage('dark', 'true');

  const defaultValue = {
    dark,
    setDark,
  };

  return <SettingContext.Provider value={defaultValue}>{children}</SettingContext.Provider>;
}
