import { useContext, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { Col, Row, Table, Select, message } from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { IsValidApiGatewayRsps } from '../../utils/valid';
import { OmStatusConstant } from '../../utils/constant';
import { DateFormat } from '../../utils/format';

//
const Div = styled.div`
  tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff;
  }

  tr.drop-over-upward td {
    border-top: 2px dashed #1890ff;
  }
`;
const GrayTitle = styled.div`
  font-size: 12px;
  color: #ccc;
`;

//
const type = 'DraggableBodyRow';
const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = useRef(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: {
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{
        cursor: 'move',
        ...style,
      }}
      {...restProps}
    />
  );
};

//
const OmMorningReportTable = ({ data, executionResultList, readonly = false }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [recordOfTable, setRecordOfTable] = useState([]);

  //
  const ColumnsOfTable = [
    {
      title: t('omCalendar.report.dispatchDate'),
      dataIndex: 'omStartTime',
      key: 'omStartTime',
      align: 'center',
      width: 120,
      render: (value, record) => (
        <>
          <div>{DateFormat(record.omStartTime)}</div>
          <div>{DateFormat(record.omEndTime)}</div>
        </>
      ),
    },
    {
      title: t('omCalendar.report.relatedSite'),
      dataIndex: 'omObjectName',
      key: 'omObjectName',
      width: 150,
    },
    {
      title: t('omCalendar.report.region'),
      dataIndex: 'omObjectRegion',
      key: 'omObjectRegion',
      align: 'center',
      width: 70,
    },
    {
      title: t('omCalendar.report.source'),
      dataIndex: 'cidFormID',
      key: 'cidFormID',
      align: 'center',
      width: 90,
      render: (value, record) => {
        if (!!value) {
          return (
            <Link to={`/cids/${value}`} target="_blank">
              {value}
            </Link>
          );
        }
        return record.omSourceName;
      },
    },
    {
      title: t('omCalendar.report.omNumber'),
      dataIndex: 'omFormID',
      key: 'omFormID',
      align: 'center',
      width: 110,
      render: (value) => {
        return (
          <Link to={`/oms/${value}`} target="_blank">
            {value}
          </Link>
        );
      },
    },
    {
      title: t('omCalendar.report.mainHandler'),
      dataIndex: 'omUserInChargeName',
      key: 'omUserInChargeName',
      width: 150,
      render: (value) => {
        if (Array.isArray(value)) return value.filter((v) => v).map((m, mIdx) => <div key={mIdx}>{m}</div>);
        return null;
      },
    },
    {
      title: t('omCalendar.report.processingItem'),
      dataIndex: 'omProcessingItem',
      key: 'omProcessingItem',
    },
    {
      title: t('omCalendar.report.workSummary'),
      dataIndex: 'omWorkContent',
      key: 'omWorkContent',
      render: (value, record) => (
        <>
          <strong>{value || t('omCalendar.report.none')}</strong>
          <Row>
            <Col>
              <GrayTitle>{t('omCalendar.report.nextSuggestion')}：</GrayTitle>
            </Col>
            <Col>{record.omRecommend}</Col>
          </Row>
          <Row>
            <Col>
              <GrayTitle>{t('omCalendar.report.componentReplacement')}：</GrayTitle>
            </Col>
            <Col>
              {Array.isArray(record.omComponents)
                ? record.omComponents
                    .filter((v) => v.componentName)
                    .map((m) => `${m.componentName} ${m.amount}pcs`)
                    .join(', ')
                : null}
            </Col>
          </Row>
        </>
      ),
    },
  ];

  useEffect(() => {
    setRecordOfTable([...data]);
  }, [data]);

  //
  const handleExecutionResultUpdate = (omFormID) => (value) => {
    axios
      .post(
        `${appConfigs.apiGatewayURL}/oms/om/close`,
        { omFormID, executionResult: value },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('omCalendar.report.failedToUpdateExecutionResult', { error: res?.data?.msg }));
          return;
        }
        message.success(t('omCalendar.report.updateExecutionResultSuccess'));

        setRecordOfTable((prev) => {
          const item = prev.find((f) => f.omFormID === omFormID);
          if (item) item.status = OmStatusConstant.ISSUE_RESOLVED;
          return [...prev];
        });
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omCalendar.report.updateExecutionResultError'));
          console.error('update OM form execution result error: ', err);
        }
      });
  };
  const handleOmFormRowMove = (dragIndex, hoverIndex) => {
    // no move
    if (dragIndex === hoverIndex) return;

    // sort
    const targetList = [...recordOfTable];
    const dragRow = targetList.splice(dragIndex, 1)[0];
    targetList.splice(hoverIndex, 0, dragRow);

    // update DB
    axios
      .post(
        `${appConfigs.apiGatewayURL}/oms/om/calendar/report/sort`,
        { list: targetList.map((m) => m.omFormID) },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('omCalendar.report.failedToSortOmForm', { error: res?.data?.msg }));
          return;
        }
        setRecordOfTable(targetList);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omCalendar.report.sortOmFormError'));
          console.error('drag-sort OM form from report table error: ', err);
        }
      });
  };

  // =====================================================
  if (readonly) {
    return (
      <Div>
        <Table
          size="small"
          bordered
          pagination={false}
          columns={[
            ...ColumnsOfTable,
            { title: t('omCalendar.report.status'), dataIndex: 'statusName', key: 'statusName', width: 120 },
          ]}
          dataSource={recordOfTable}
          scroll={{ y: 'calc(100vh - 240px)' }}
        />
      </Div>
    );
  } else {
    return (
      <Div>
        <DndProvider backend={HTML5Backend}>
          <Table
            size="small"
            bordered
            pagination={false}
            columns={[
              ...ColumnsOfTable,
              {
                title: t('omCalendar.report.close'),
                dataIndex: 'omStatus',
                key: 'omStatus',
                align: 'center',
                width: 140,
                render: (value, record) =>
                  [OmStatusConstant.PROCESSING, OmStatusConstant.ISSUE_OBSERVE].includes(value) ? (
                    <Select
                      options={executionResultList}
                      onChange={handleExecutionResultUpdate(record.omFormID)}
                    />
                  ) : (
                    record.omStatusName
                  ),
              },
            ]}
            dataSource={recordOfTable}
            components={{ body: { row: DraggableBodyRow } }}
            onRow={(_, index) => ({ index, moveRow: handleOmFormRowMove })}
            scroll={{ y: 'calc(100vh - 240px)' }}
          />
        </DndProvider>
      </Div>
    );
  }
};

export default OmMorningReportTable;
