import { Link } from 'react-router-dom';
import { Row, Col, Button, Badge, Card } from 'antd';
import { OmStatusConstant } from '../../utils/constant';
import { useTranslation } from 'react-i18next';

const OmRecordCard = ({ data }) => {
  const { t } = useTranslation();
  const showCheckButton = [
    OmStatusConstant.REQUEST_PREPARATION,
    OmStatusConstant.PLANNED,
    OmStatusConstant.PROCESSING,
  ].includes(data?.omStatus);

  const buttonColWidth = showCheckButton ? 12 : 24;

  return (
    <Col xs={24} sm={24} md={12} lg={12} xl={6}>
      <Badge.Ribbon text={t('omRecord.filled')} color="green" style={{ display: data.omWorkContent ? 'block' : 'none' }}>
        <Card title={data.omFormID} size="small">
          <div className="app-card-content">
            <p>
              {t('omRecord.formStatus')}：
              <span className="text-black">{data.omStatusName || '-'}</span>
            </p>
            <p>
              {t('omRecord.expectedDispatchTime')}：
              <span className="text-black">{data.omStartTime || t('omRecord.notScheduled')}</span>
            </p>
            <p>
              {t('omRecord.site')}：
              <span className="text-black">{data.omObjectName}</span>
            </p>
            <p>
              {t('omRecord.processingItem')}：
              <span className="text-black">{data.omProcessingItem}</span>
            </p>
            <p>
              {t('omRecord.nextSuggestion')}：
              <span className="text-black">{data.omRecommend || t('omRecord.none')}</span>
            </p>
          </div>

          <div className="app-card-footer">
            <Row gutter={[8, 8]}>
              <Col span={buttonColWidth}>
                <Link to={`/oms/${data.omFormID}`}>
                  <Button block>{t('omRecord.details')}</Button>
                </Link>
              </Col>
              {showCheckButton && (
                <Col span={buttonColWidth}>
                  <Link to={`/omrecord/${data.omFormID}`}>
                    <Button block>{t('omRecord.checkIn')}</Button>
                  </Link>
                </Col>
              )}
            </Row>
          </div>
        </Card>
      </Badge.Ribbon>
    </Col>
  );
};

export default OmRecordCard;
