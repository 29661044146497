import axios from 'axios';
import bn from 'bignumber.js';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
//
import {
  DeleteOutlined,
  InfoCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import {
  AutoComplete,
  Button,
  Card,
  Cascader,
  Col,
  Divider,
  PageHeader,
  Popconfirm,
  Row,
  Spin,
  Steps,
  Table,
  Tag,
  Tooltip,
  message,
  InputNumber,
} from 'antd';
import RfdmeCheckbox from '../../components/Checkbox';
import RfdmeDatePicker from '../../components/DatePicker';
import RfdmeDisplay from '../../components/Display';
import RfdmeInput from '../../components/Input';
import RfdmeInputNumber from '../../components/InputNumber';
import RfdmeModal from '../../components/Modal';
import RfdmeSelect from '../../components/Select';
import RfdmeTextArea from '../../components/TextArea';
import RfdmeUpload from '../../components/Upload';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import useConfigOfSystem from '../../hooks/useConfigOfSystem';
import useListOfAlertID from '../../hooks/useListOfAlertID';
import useListOfCidLevel from '../../hooks/useListOfCidLevel';
import useListOfCidProblemCategory from '../../hooks/useListOfCidProblemCategory';
import useListOfCidNotificationReason from '../../hooks/useListOfCidNotificationReason';
import useListOfCidSource from '../../hooks/useListOfCidSource';
import useListOfOcap from '../../hooks/useListOfOcap';
import useListOfCidRootCause from '../../hooks/useListOfCidRootCause';
import useListOfCidSolution from '../../hooks/useListOfCidSolution';
import useListOfCidRespType from '../../hooks/useListOfCidRespType';
import useListOfCidUserInCharge from '../../hooks/useListOfCidUserInCharge';
import useListOfAlertComponent from '../../hooks/useListOfAlertComponent';
import useListOfOmExtraPv from '../../hooks/useListOfOmExtraPv';
import { CidStatusConstant } from '../../utils/constant';
import { AppSyncQueryTextFormat, DateFormat, SetKeyToArray, DateTimeFormat } from '../../utils/format';
import { GetAppSyncRspsErrorMessage } from '../../utils/parse';
import { IsValidApiGatewayRsps, IsValidAppSyncRsps, ValidFormData } from '../../utils/valid';
import { OmStatusConstant } from '../../utils/constant';
import { QueryOmContractLevelList } from '../../utils/dataHelpers';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

//
const RedDiv = styled.div`
  color: #fa541c;
`;
const GreenDiv = styled.div`
  color: #52c41a;
`;
const RedDotDiv = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 100px;
  background: #fa541c;
`;
const GreenDotDiv = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 100px;
  background: #52c41a;
`;

const GetCidFormID = (urlPath) => {
  return (urlPath || '').replace('/cids/', '');
};
//
const buildSqlQuery = (hasPermissionToShowMaintenanceInfo, column) => {
  // 初始化基本查詢字段
  let cidFields = new Set([
    'cidFormID',
    'startDate',
    'levelName',
    'recordOnlyFlag',
    'isSendEmail',
    'objectIDs',
    'issue',
    'alertEndTime',
  ]);

  let alertLogFields = new Set([]),
    ocapFields = new Set([]),
    omFormFields = new Set([]),
    omWorkHistoryFields = new Set([]),
    askOmLogFields = new Set([]);

  // 根據用戶操作擴展查詢字段
  if (hasPermissionToShowMaintenanceInfo) {
    const maintenanceCidFields = [
      'encrypt',
      'status',
      'statusName',
      'source',
      'sourceName',
      'userInCharge',
      'userInChargeName',
      'problemCategory',
      'problemCategoryName',
      'errorCode',
      'errorCodeName',
      'notificationReason',
      'notificationReasonName',
      'topic',
      'currentProgress',
      'possibleRootCause',
      'rootCause',
      'solution',
      'logbook',
      'ocapIDs',
      'rootCauseLevel1',
      'rootCauseLevel2',
      'rootCauseLevel3',
      'rootCauseLevel1Name',
      'rootCauseLevel2Name',
      'rootCauseLevel3Name',
      'solutionLevel1',
      'solutionLevel2',
      'solutionLevel1Name',
      'solutionLevel2Name',
      'alertComponent',
      'clientDeviceSeq',
      'alertStartTime',
      'alertEndTime',
      'totalLossEnergy',
      'respTypeLevel1',
      'respTypeLevel2',
      'boosterCircuitRef',
      'circuitID',
      'level',
    ];
    // 異常列表
    const maintenanceAlertLogFields = [
      'alertLogID',
      'ownerName',
      'alertDegree',
      'avgLossEnergy',
      'lossEnergy',
      'days',
      'alertID',
      'alertDisplayName',
      'alertComponent',
      'clientDeviceSeq',
      'objectID',
      'objectName',
      'alertStatusName',
      'startTime',
      'endTime',
      'alertOwnerID',
      'alertName',
      'startTimestamp',
      'ownerType',
    ];
    // OCAP
    const maintenanceOcapFields = ['ocapID', 'ocapName', 'ocapRefDate'];
    // 委託維運歷程
    const maintenanceAskOmLogFields = [
      'askTime',
      'askReason',
      'askUserName',
      'takeTime',
      'withdrawTime',
      'withdrawReason',
      'rejectTime',
      'rejectReason',
      'completeTime',
    ];
    // OM 相關資訊
    const maintenanceOmFormFields = [
      'omFormID',
      'omStartTime',
      'omEndTime',
      'omUserInChargeName',
      'omStatusName',
    ];
    // OM 處理歷程
    const maintenanceOmWorkHistoryFields = ['seq', 'startTime', 'endTime', 'logbook'];

    maintenanceCidFields.forEach((field) => cidFields.add(field));
    maintenanceAlertLogFields.forEach((field) => alertLogFields.add(field));
    maintenanceOcapFields.forEach((field) => ocapFields.add(field));
    maintenanceAskOmLogFields.forEach((field) => askOmLogFields.add(field));
    maintenanceOmFormFields.forEach((field) => omFormFields.add(field));
    maintenanceOmWorkHistoryFields.forEach((field) => omWorkHistoryFields.add(field));
  }

  // 根據時間點擴展查詢字段
  column
    .filter((t) => t.use.includes(hasPermissionToShowMaintenanceInfo ? 'maintenance' : 'basic'))
    .forEach((timePoint) => {
      switch (timePoint.type) {
        case 'cid':
          cidFields.add(timePoint.fieldName);
          break;
        case 'askOmLogList':
          askOmLogFields.add(timePoint.fieldName);
          break;
        case 'omFormList':
          omFormFields.add(timePoint.fieldName);
          break;
        case 'omWorkHistory':
          omWorkHistoryFields.add(timePoint.fieldName);
          break;
        default:
          break;
      }
    });

  // 構建GraphQL查詢語句
  const sqlQuery = `
    query OmQuery($cidFormID: String!, $timezone: String!) { 
      om_getCidFormContent(cidFormID: $cidFormID, timezone: $timezone) { 
        ${cidFields.size > 0 ? Array.from(cidFields).join(' ') : ''} 
        ${alertLogFields.size > 0 ? `alertLogList { ${Array.from(alertLogFields).join(' ')} } ` : ''} 
        ${ocapFields.size > 0 ? `ocapList { ${Array.from(ocapFields).join(' ')} } ` : ''} 
        ${askOmLogFields.size > 0 ? `askOmLogList { ${Array.from(askOmLogFields).join(' ')} } ` : ''} 
        ${
          omFormFields.size > 0 || omWorkHistoryFields.size > 0
            ? `omFormList { ${omFormFields.size > 0 ? `${Array.from(omFormFields).join(' ')}` : ''}${
                omWorkHistoryFields.size > 0
                  ? ` omWorkHistory { ${Array.from(omWorkHistoryFields).join(' ')} } `
                  : ''
              } } `
            : ''
        }
        fileList { fileID fileName }
        respTypeLevel1Name respTypeLevel2Name
        alertComponentCapacity
      }
    }
  `;

  return AppSyncQueryTextFormat(sqlQuery);
};

const CidView = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  //
  const TOTAL_TIME_POINTS = useMemo(
    () => [
      {
        seq: 0,
        use: ['basic', 'maintenance'],
        type: 'cid',
        fieldName: 'alertStartTime',
        title: t('cidView.timePoints.alertStartTime'),
      },
      {
        seq: 1,
        use: ['maintenance'],
        type: 'cid',
        fieldName: 'createTime',
        title: t('cidView.timePoints.createTime'),
      },
      {
        seq: 2,
        use: ['maintenance'],
        type: 'askOmLogList',
        fieldName: 'askTime',
        title: t('cidView.timePoints.firstAskTime'),
      },
      {
        seq: 2,
        use: ['basic'],
        type: 'cid',
        fieldName: 'firstOmAskTime',
        title: t('cidView.timePoints.askTime'),
      },
      {
        seq: 3,
        use: ['maintenance'],
        type: 'askOmLogList',
        fieldName: 'takeTime',
        title: t('cidView.timePoints.firstOmAcceptTime'),
      },
      {
        seq: 4,
        use: ['maintenance'],
        type: 'omFormList',
        fieldName: 'omPrepareTime',
        title: t('cidView.timePoints.firstOmStartTime'),
      },
      {
        // 維運
        seq: 5,
        use: ['maintenance'],
        type: 'omWorkHistory',
        fieldName: 'startTime',
        title: t('cidView.timePoints.arrivalTime'),
      },
      {
        // 投資人
        seq: 5,
        use: ['basic'],
        type: 'cid',
        fieldName: 'arrivalTime',
        title: t('cidView.timePoints.arrivalTime'),
      },
      {
        seq: 6,
        use: ['maintenance'],
        type: 'cid',
        fieldName: 'alertEndTime',
        title: t('cidView.timePoints.alertEndTime'),
      },
      {
        // 維運
        seq: 7,
        use: ['maintenance'],
        type: 'omWorkHistory',
        fieldName: 'endTime',
        title: t('cidView.timePoints.powerRestorationTime'),
      },
      {
        // 投資人
        seq: 7,
        use: ['basic'],
        type: 'cid',
        fieldName: 'powerRestorationTime',
        title: t('cidView.timePoints.powerRestorationTime'),
      },
      {
        seq: 8,
        use: ['maintenance'],
        type: 'askOmLogList',
        fieldName: 'completeTime',
        title: t('cidView.timePoints.completeTime'),
      },
      {
        seq: 9,
        use: ['maintenance'],
        type: 'cid',
        fieldName: 'closeTime',
        title: t('cidView.timePoints.closeTime'),
      },
    ],
    [t]
  );
  const ColumnsOfSolarPlantTable = (isEditing) => [
    {
      title: t('cidView.solarPlantTable.siteCode'),
      dataIndex: 'value',
      key: 'value',
      width: 100,
      render: (value) =>
        isEditing ? (
          value
        ) : (
          <Link to={`/pvs/${value}`} target="_blank">
            {value}
          </Link>
        ),
    },
    {
      title: t('cidView.solarPlantTable.siteName'),
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: t('cidView.solarPlantTable.region'),
      dataIndex: 'region',
      key: 'region',
      width: 120,
    },
    {
      title: t('cidView.solarPlantTable.capacity'),
      dataIndex: 'capacity',
      key: 'capacity',
      width: 120,
      render: (value) => (value ? bn(value).toFixed(3) + ' kWp' : ''),
    },
    {
      title: t('cidView.solarPlantTable.spv'),
      dataIndex: 'ownerName',
      key: 'ownerName',
      width: 250,
    },
  ];
  const ColumnsOfAlertLogTable = (
    isEditing,
    deleteAlertLogFn,
    addAlertRecommendFn,
    deleteAlertRecommendFn
  ) => {
    const cols = [
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: 70,
        render: (value, record) => (
          <>
            {record.recommend ? (
              <Row gutter={[8, 0]} justify="space-around">
                <Col span={10}>
                  <Button
                    style={{ width: '100%' }}
                    type="text"
                    size="small"
                    icon={<CheckOutlined />}
                    onClick={addAlertRecommendFn(
                      record.alertOwnerID,
                      record.alertName,
                      record.startTimestamp
                    )}
                  />
                </Col>
                <Col span={10}>
                  <Popconfirm
                    title={t('cidView.alertLogTable.confirmExclude')}
                    okText={t('cidView.alertLogTable.confirm')}
                    cancelText={t('cidView.alertLogTable.cancel')}
                    placement="topRight"
                    onConfirm={deleteAlertRecommendFn(
                      record.alertOwnerID,
                      record.alertName,
                      record.startTimestamp
                    )}
                  >
                    <Button style={{ width: '100%' }} type="text" size="small" icon={<CloseOutlined />} />
                  </Popconfirm>
                </Col>
              </Row>
            ) : (
              <Row justify="center">
                <Col span={10}>
                  <Button
                    style={{ width: '100%' }}
                    type="text"
                    size="small"
                    icon={<DeleteOutlined />}
                    onClick={deleteAlertLogFn(record.alertOwnerID, record.alertName, record.startTimestamp)}
                  />
                </Col>
              </Row>
            )}
          </>
        ),
      },
      {
        title: t('cidView.alertLogTable.id'),
        dataIndex: 'alertLogID',
        key: 'alertLogID',
        render: (value, record) => (
          <Row gutter={[4, 0]} align="middle" justify="flex-start" style={{ padding: '0px 5px' }}>
            <Col>
              {isEditing ? (
                value
              ) : (
                <Link to={`/alerts/${value}`} target="_blank">
                  {value}
                </Link>
              )}
            </Col>
            <Col>{record.recommend && <Tag color="#f50">New</Tag>}</Col>
          </Row>
        ),
      },
      {
        title: t('cidView.alertLogTable.spv'),
        dataIndex: 'ownerName',
        key: 'ownerName',
      },
      {
        title: (
          <Row gutter={[0, 0]} align="middle">
            <Col span={12}>{t('cidView.alertLogTable.severity')}</Col>
            <Col span={12} style={{ textAlign: 'center' }}>
              <Tooltip
                title={
                  <>
                    <div>{t('cidView.alertLogTable.fatal')}</div>
                    <div>{t('cidView.alertLogTable.high')}</div>
                    <div>{t('cidView.alertLogTable.medium')}</div>
                    <div>{t('cidView.alertLogTable.low')}</div>
                  </>
                }
              >
                <InfoCircleOutlined />
              </Tooltip>
            </Col>
          </Row>
        ),
        dataIndex: 'alertDegree',
        key: 'alertDegree',
        width: 90,
      },
      {
        title: t('cidView.alertLogTable.avgDailyLoss'),
        dataIndex: 'avgLossEnergy',
        key: 'avgLossEnergy',
        width: 90,
        render: (value, record) =>
          isEditing ? (
            <Row gutter={[4, 0]} align="middle">
              <Col>{record.alertStatusName === 'active' ? <RedDotDiv /> : <GreenDotDiv />}</Col>
              <Col>{value ? bn(value).dp(2).toNumber() + 'kWh' : ' - '}</Col>
            </Row>
          ) : (
            <>{value ? bn(value).dp(2).toNumber() + 'kWh' : ''}</>
          ),
      },
      {
        title: t('cidView.alertLogTable.totalLoss'),
        dataIndex: 'lossEnergy',
        key: 'lossEnergy',
        width: 90,
        render: (value) => <>{value ? bn(value).dp(2).toNumber() + 'kWh' : ''}</>,
      },
      {
        title: t('cidView.alertLogTable.days'),
        dataIndex: 'days',
        key: 'days',
        width: 50,
      },
      {
        title: t('cidView.alertLogTable.alertCode'),
        dataIndex: 'alertID',
        key: 'alertID',
        width: 105,
      },
      {
        title: t('cidView.alertLogTable.alertCategory'),
        dataIndex: 'alertDisplayName',
        key: 'alertDisplayName',
      },
      {
        title: t('cidView.alertLogTable.deviceLevel'),
        dataIndex: 'alertComponent',
        key: 'alertComponent',
        width: 90,
      },
      {
        title: t('cidView.alertLogTable.invNumber'),
        dataIndex: 'clientDeviceSeq',
        key: 'clientDeviceSeq',
        width: 50,
      },
      {
        title: t('cidView.alertLogTable.siteId'),
        dataIndex: 'objectID',
        key: 'objectID',
        width: 75,
      },
      {
        title: t('cidView.alertLogTable.site'),
        dataIndex: 'objectName',
        key: 'objectName',
      },
      {
        title: t('cidView.alertLogTable.alertStatus'),
        dataIndex: 'alertStatusName',
        key: 'alertStatusName',
        width: 80,
        render: (value) =>
          isEditing ? (
            value === 'active' ? (
              <RedDiv>{value}</RedDiv>
            ) : (
              <GreenDiv>{value}</GreenDiv>
            )
          ) : (
            <>{value}</>
          ),
      },
      {
        title: t('cidView.alertLogTable.startTime'),
        dataIndex: 'startTime',
        key: 'startTime',
        width: 120,
      },
      {
        title: t('cidView.alertLogTable.endTime'),
        dataIndex: 'endTime',
        key: 'endTime',
        width: 120,
      },
    ];

    if (!isEditing) delete cols[0];

    return cols;
  };
  const ColumnsOfOcapTable = [
    {
      title: t('cidView.ocapTable.code'),
      dataIndex: 'ocapID',
      key: 'ocapID',
    },
    {
      title: t('cidView.ocapTable.name'),
      dataIndex: 'ocapName',
      key: 'ocapName',
    },
  ];
  const ColumnsOfAskOmLogTable = [
    {
      title: t('cidView.askOmLogTable.requestTime'),
      dataIndex: 'askTime',
      key: 'askTime',
      width: 150,
    },
    {
      title: t('cidView.askOmLogTable.requestContent'),
      dataIndex: 'askReason',
      key: 'askReason',
    },
    {
      title: t('cidView.askOmLogTable.requestor'),
      dataIndex: 'askUserName',
      key: 'askUserName',
      width: 150,
    },
    {
      title: t('cidView.askOmLogTable.acceptTime'),
      dataIndex: 'takeTime',
      key: 'takeTime',
      width: 150,
    },
    {
      title: t('cidView.askOmLogTable.withdrawTime'),
      dataIndex: 'withdrawTime',
      key: 'withdrawTime',
      width: 150,
    },
    {
      title: t('cidView.askOmLogTable.withdrawReason'),
      dataIndex: 'withdrawReason',
      key: 'withdrawReason',
    },
    {
      title: t('cidView.askOmLogTable.rejectTime'),
      dataIndex: 'rejectTime',
      key: 'rejectTime',
      width: 150,
    },
    {
      title: t('cidView.askOmLogTable.rejectReason'),
      dataIndex: 'rejectReason',
      key: 'rejectReason',
    },
  ];
  const ColumnsOfOmFormTable = [
    {
      title: t('cidView.omFormTable.omNumber'),
      dataIndex: 'omFormID',
      key: 'omFormID',
      render: (value) => (
        <Link to={`/oms/${value}`} target="_blank">
          {value}
        </Link>
      ),
    },
    {
      title: t('cidView.omFormTable.startTime'),
      dataIndex: 'omWorkHistory',
      key: 'startTime',
      render: (value) => {
        if (Array.isArray(value) && value.length > 0) {
          return value.map((item, index) => {
            return (
              <span style={{ display: 'block' }} key={index + item.startTime}>
                {item.startTime}
              </span>
            );
          });
        } else {
          return null;
        }
      },
    },
    {
      title: t('cidView.omFormTable.endTime'),
      dataIndex: 'omWorkHistory',
      key: 'endTime',
      render: (value) => {
        if (Array.isArray(value) && value.length > 0) {
          return value.map((item, index) => {
            return <li key={index + item.endTime}>{item.endTime}</li>;
          });
        } else {
          return null;
        }
      },
    },
    {
      title: t('cidView.omFormTable.logbook'),
      dataIndex: 'omWorkHistory',
      key: 'Logbook',
      render: (value) => {
        if (Array.isArray(value) && value.length > 0) {
          return value.map((item, index) => {
            return <li key={index + item.logbook}>{item.logbook}</li>;
          });
        } else {
          return null;
        }
      },
    },
    {
      title: t('cidView.omFormTable.mainHandler'),
      dataIndex: 'omUserInChargeName',
      key: 'omUserInChargeName',
    },
    {
      title: t('cidView.omFormTable.dispatchStatus'),
      dataIndex: 'omStatusName',
      key: 'omStatusName',
    },
  ];
  // 判斷是否顯示維運相關資訊
  const hasPermissionToShowMaintenanceInfo = useMemo(() => {
    return (user?.operations || []).includes('CIDVIEW_show-maintenance-info');
  }, [user]);
  // get data from state
  const isNew = location.state?.isNew;
  const selectedAlertLogList = location.state?.selectedAlertLogList;
  const redirectUrl = location.state?.redirectUrl;
  //
  const [loading, setLoading] = useState(false);
  const [recommendLoading, setRecommendLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(redirectUrl ? true : false);
  const [redirectFlag, setRedirectFlag] = useState(false);
  const [alertRecommendFlag, setAlertRecommendFlag] = useState(false);
  // 'data-' variable: non-array type data that can be modified.
  const [dataOfCid, setDataOfCid] = useState({});
  const [dataOfCidOrigin, setDataOfCidOrigin] = useState({});
  const [dataOfAddAlertLogID, setDataOfAddAlertLogID] = useState('');
  const [dataOfAskReason, setDataOfAskReason] = useState({ value: '', msg: '' });
  const [dataOfOmWorkHistory, setDataOfOmWorkHistory] = useState([]);
  // 'record-' variable: array type data that can be modified.
  const [recordOfAlertLog, setRecordOfAlertLog] = useState([]);
  // 'list-' variable: array type data that can NOT be modified.
  const { data: listOfAlertID } = useListOfAlertID(hasPermissionToShowMaintenanceInfo);
  const { data: listOfOmExtraPv } = useListOfOmExtraPv(true);
  const { data: listOfOcap } = useListOfOcap(hasPermissionToShowMaintenanceInfo);
  const { data: listOfDefaultCidLevel } = useListOfCidLevel(hasPermissionToShowMaintenanceInfo);
  const { data: listOfCidSource } = useListOfCidSource(hasPermissionToShowMaintenanceInfo);
  const { data: listOfCidProblemCategory } = useListOfCidProblemCategory(hasPermissionToShowMaintenanceInfo);
  const { data: listOfCidNotificationReason } = useListOfCidNotificationReason(
    hasPermissionToShowMaintenanceInfo
  );
  const { data: listOfCidRootCause } = useListOfCidRootCause(hasPermissionToShowMaintenanceInfo);
  const { data: listOfCidSolution } = useListOfCidSolution(hasPermissionToShowMaintenanceInfo);
  const { data: listOfCidRespType } = useListOfCidRespType(hasPermissionToShowMaintenanceInfo);
  const { data: listOfCidUserInCharge } = useListOfCidUserInCharge(hasPermissionToShowMaintenanceInfo);
  const { data: listOfAlertComponent } = useListOfAlertComponent(hasPermissionToShowMaintenanceInfo);
  const [listOfBoosterCircuit, setListOfBoosterCircuit] = useState([]);
  const [listOfCidLevel, setListOfCidLevel] = useState([]);
  // variables for control view.
  const { data: systemConfigs } = useConfigOfSystem(hasPermissionToShowMaintenanceInfo);
  const [addingAlert, setAddingAlert] = useState(false);
  const [buttonWorking, setButtonWorking] = useState(false);
  const [sendRequestModelOpen, setSendRequestModelOpen] = useState(false);
  const [showClosePopconfirm, setShowClosePopconfirm] = useState(false);
  const [withdrawModalProps, setWithdrawModalProps] = useState({ open: false, cidFormID: '' });
  const [withdrawReason, setWithdrawReason] = useState({ value: '', msg: '' });
  //
  const [dataChangedOfCid, setDataChangedOfCid] = useState(false);
  const [dataChangedOfAddAlertLogID, setDataChangedOfAddAlertLogID] = useState(false);
  const [dataChangedOfAskReason, setDataChangedOfAskReason] = useState(false);
  const [dataChangedOfWithdrawReason, setDataChangedOfWithdrawReason] = useState(false);
  const [dataChangedOfSummary, setDataChangedOfSummary] = useState(false);
  const [alertOptions, setAlertOptions] = useState([]);
  const [deviceSeqOptions, setDeviceSeqOptions] = useState([]);
  //
  const listOfSolarPlant = useMemo(() => {
    if (!Array.isArray(listOfOmExtraPv) || listOfOmExtraPv.length === 0) return [];

    if (isEditing) {
      return listOfOmExtraPv.filter((f) => f.state !== 'C');
    } else {
      return listOfOmExtraPv;
    }
  }, [listOfOmExtraPv, isEditing]);
  const listOfSelectedSolarPlant = useMemo(() => {
    if (!Array.isArray(dataOfCid.objectIDs) || dataOfCid.objectIDs.length === 0) return [];
    if (!Array.isArray(listOfSolarPlant) || listOfSolarPlant.length === 0) return [];

    const items = listOfSolarPlant.filter((f) => dataOfCid.objectIDs.includes(f.value));
    if (items.length === 0) return [];

    const result = _.cloneDeep(items);
    if (dataOfCid.circuitID) {
      result[0]['label'] += ` - ${t('cidView.circuit')}${dataOfCid.circuitID}`;
    }

    return result.map(SetKeyToArray);
  }, [dataOfCid.objectIDs, dataOfCid.circuitID, listOfSolarPlant, t]);
  //
  const listOfLevelRootCause = useMemo(() => {
    if (!Array.isArray(listOfCidRootCause) || listOfCidRootCause.length === 0) return [];
    const map = new Map();
    const topLevelNodes = [];
    // 以 value 為 key，將每個 item 存入 map 中。
    listOfCidRootCause.forEach((item) => {
      const copyItem = { ...item, children: [] };
      map.set(item.value, copyItem);
      if (item.level === 1) {
        // 將 level 1 節點加入 topLevelNodes。
        topLevelNodes.push(copyItem);
      }
    });

    // 建立 parent-children 關係。
    listOfCidRootCause.forEach((item) => {
      if (item.parent !== null) {
        const parentItem = map.get(item.parent);
        if (parentItem) {
          parentItem.children.push(map.get(item.value));
        }
      }
    });

    // 根據每個節點的 seq 對其子節點進行排序。
    for (let item of map.values()) {
      item.children.sort((a, b) => a.seq - b.seq);
    }

    const tmpList = topLevelNodes.sort((a, b) => a.seq - b.seq);
    return tmpList.map(SetKeyToArray);
  }, [listOfCidRootCause]);
  const listOfLevel1Solution = useMemo(() => {
    if (!Array.isArray(listOfCidSolution) || listOfCidSolution.length === 0) return [];

    const items = listOfCidSolution.filter((f) => f.level === 1);
    if (items.length === 0) return [];

    return items.map(SetKeyToArray);
  }, [listOfCidSolution]);
  const listOfLevel2Solution = useMemo(() => {
    if (!Array.isArray(listOfCidSolution) || listOfCidSolution.length === 0) return [];
    if (!Array.isArray(dataOfCid.rootCauseLevel) || dataOfCid.rootCauseLevel.length < 2) return [];

    const items = listOfCidSolution.filter((f) => f.level === 2 && f.parent === dataOfCid.rootCauseLevel[1]);
    if (items.length === 0) return [];
    return items.map(SetKeyToArray);
  }, [listOfCidSolution, dataOfCid.rootCauseLevel]);
  const listOfLevelRespType = useMemo(() => {
    if (!Array.isArray(listOfCidRespType) || listOfCidRespType.length === 0) return [];
    const map = new Map();
    const topLevelNodes = [];
    // 以 value 為 key，將每個 item 存入 map 中。
    listOfCidRespType.forEach((item) => {
      const copyItem = { ...item, children: [] };
      map.set(item.value, copyItem);
      if (item.level === 1) {
        // 將 level 1 節點加入 topLevelNodes。
        topLevelNodes.push(copyItem);
      }
    });

    // 建立 parent-children 關係。
    listOfCidRespType.forEach((item) => {
      if (item.parent !== null) {
        const parentItem = map.get(item.parent);
        if (parentItem) {
          parentItem.children.push(map.get(item.value));
        }
      }
    });

    // 根據每個節點的 seq 對其子節點進行排序。
    for (let item of map.values()) {
      item.children.sort((a, b) => a.seq - b.seq);
    }

    const tmpList = topLevelNodes.sort((a, b) => a.seq - b.seq);
    return tmpList.map(SetKeyToArray);
  }, [listOfCidRespType]);
  const listOfEnabledOcap = useMemo(() => {
    if (!Array.isArray(listOfOcap)) return [];
    if (!Array.isArray(dataOfCid.ocapIDs)) return listOfOcap.filter((f) => f.enable === true);
    return listOfOcap.filter((f) => dataOfCid.ocapIDs.includes(f.value) || f.enable === true);
  }, [listOfOcap, dataOfCid.ocapIDs]);
  //
  const listOfTimeStep = useMemo(() => {
    let currentStep = 0;
    const steps = [];
    const result = { items: steps, current: currentStep };

    // 檢查 data 對象是否有效
    if (!dataOfCid || Object.keys(dataOfCid).length === 0) {
      return result;
    }
    if (dataOfCid.recordOnlyFlag === true) {
      return result;
    }

    const createTimeStep = (checkStep, timeValue) => {
      let displayValue = null;
      if (timeValue) {
        if (moment.isMoment(timeValue)) {
          displayValue = DateTimeFormat(timeValue, true);
        } else if (typeof timeValue === 'string') {
          displayValue = timeValue.substring(0, 16);
        }
      }
      const timeStep = {
        title: checkStep.title,
        description: displayValue ? displayValue : undefined,
      };
      steps.push(timeStep);
    };

    let filteredAskLog = [];
    if (Array.isArray(dataOfCid.askOmLogList) && dataOfCid.askOmLogList.length > 0) {
      filteredAskLog = dataOfCid.askOmLogList.filter((a) => !a.withdrawTime && !a.rejectTime);
    }
    TOTAL_TIME_POINTS.filter((t) =>
      t.use.includes(hasPermissionToShowMaintenanceInfo ? 'maintenance' : 'basic')
    ).forEach((tp) => {
      const key = `${tp.type}.${tp.fieldName}`;
      switch (key) {
        case 'askOmLogList.askTime':
        case 'askOmLogList.takeTime':
          createTimeStep(tp, filteredAskLog.length > 0 && filteredAskLog[0][tp.fieldName]);
          break;
        case 'askOmLogList.completeTime':
          createTimeStep(
            tp,
            dataOfCid.status === 6
              ? filteredAskLog.length > 0 && filteredAskLog[filteredAskLog.length - 1][tp.fieldName]
              : null
          );
          break;
        case 'omFormList.omPrepareTime':
          createTimeStep(
            tp,
            Array.isArray(dataOfCid.omFormList) &&
              dataOfCid.omFormList.length > 0 &&
              dataOfCid.omFormList[0].omPrepareTime
          );
          break;
        case 'omWorkHistory.startTime':
          let arrivalTime;
          if (Array.isArray(dataOfCid.omFormList) && dataOfCid.omFormList.length > 0) {
            const workStartTimes = dataOfCid.omFormList.reduce((acc, form) => {
              const startTimes = form.omWorkHistory
                .map((work) => work.startTime)
                .filter((startTime) => !!startTime) // 過濾掉無效值
                .map((startTime) => moment(startTime));
              return acc.concat(startTimes);
            }, []);

            if (workStartTimes.length > 0) {
              arrivalTime = moment.min(workStartTimes);
            }
          }
          createTimeStep(tp, arrivalTime);
          break;
        case 'omWorkHistory.endTime':
          let powerRestorationTime;
          if (
            dataOfCid.status === 6 &&
            Array.isArray(dataOfCid.omFormList) &&
            dataOfCid.omFormList.length > 0
          ) {
            const processOmForm = dataOfCid.omFormList.filter((f) =>
              [
                OmStatusConstant.REQUEST_PREPARATION,
                OmStatusConstant.PROCESSING,
                OmStatusConstant.PLANNED,
              ].includes(f.omStatus)
            );
            if (!processOmForm || processOmForm.length === 0) {
              const workEndTimes = dataOfCid.omFormList.reduce((acc, form) => {
                const endTimes = form.omWorkHistory
                  .map((work) => work.endTime)
                  .filter((endTime) => !!endTime) // 過濾掉無效值
                  .map((endTime) => moment(endTime));
                return acc.concat(endTimes);
              }, []);

              if (workEndTimes.length > 0) {
                powerRestorationTime = moment.max(workEndTimes);
              }
            }
          }
          createTimeStep(tp, powerRestorationTime);
          break;
        default:
          createTimeStep(tp, dataOfCid[tp.fieldName]);
          break;
      }
    });
    steps.sort((a, b) => a.seq - b.seq);
    // 設定目前階段
    const lastIndexWithDescription = steps.reduceRight((acc, curr, idx) => {
      return acc === -1 && curr.description ? idx : acc;
    }, -1);
    if (lastIndexWithDescription > 0) currentStep = lastIndexWithDescription;

    return { items: steps, current: currentStep };
  }, [dataOfCid, hasPermissionToShowMaintenanceInfo, TOTAL_TIME_POINTS]);
  //
  const isComponentSeqDisabled = useMemo(() => {
    if (recordOfAlertLog.length === 0 || deviceSeqOptions.length === 0 || !dataOfCid.clientDeviceSeq)
      return false;
    //
    const existOption = deviceSeqOptions.find((option) => option.value === dataOfCid.clientDeviceSeq);
    return existOption ? true : false;
  }, [recordOfAlertLog, deviceSeqOptions, dataOfCid.clientDeviceSeq]);
  //
  const formProps = useMemo(() => {
    const result = {
      canEdit: false,
      showCreateButton: false,
      showUpdateButton: false,
      showSubmitButton: false,
      showCloseButton: false,
      showVoidButton: false,
      showReSubmitButton: false,
      showWithdrawButton: false,
      showSummaryButton: false,
      showNotificationReason: true,
      canTriggerNotification: false,
      disabledReadOnly: true, // 僅記錄
    };

    //
    if (
      user.operations.includes('CIDS_edit-cid-form') &&
      ![CidStatusConstant.CLOSED, CidStatusConstant.VOID].includes(dataOfCid.status)
    ) {
      result.canEdit = true;

      if (dataOfCid.status) {
        result.showUpdateButton = true;
      } else {
        result.showCreateButton = true;
        result.disabledReadOnly = false;
      }
    }

    //
    if ([CidStatusConstant.CREATE, CidStatusConstant.REJECT].includes(dataOfCid.status)) {
      result.disabledReadOnly = false;
      if (dataOfCid.recordOnlyFlag === true) {
        result.showCloseButton = true;
        result.showVoidButton = true;
      } else {
        result.showSubmitButton = true;
        result.showVoidButton = true;
      }
    }

    //
    if (
      [
        CidStatusConstant.REQUEST_SEND,
        CidStatusConstant.REQUEST_RECEIVED,
        CidStatusConstant.REQUEST_PREPARATION,
        CidStatusConstant.PLANNED,
      ].includes(dataOfCid.status)
    ) {
      result.showWithdrawButton = true;
    }

    //
    if (dataOfCid.status === CidStatusConstant.ACCEPTANCE) {
      result.showReSubmitButton = true;
      result.showCloseButton = true;
    }

    // 只有 cid 結案後，才會出現儲存總結的按鈕
    if (dataOfCid.status === CidStatusConstant.CLOSED) {
      result.showSummaryButton = true;
    }

    // 當尚未有狀態或作廢的時候，不顯示觸發通知區塊
    if (!dataOfCid.status || dataOfCid.status === CidStatusConstant.VOID) {
      result.showNotificationReason = false;
    }
    if (user.operations.includes('CIDS_can-send-mail-to-investor')) {
      result.canTriggerNotification = true;
    }

    return result;
  }, [user, dataOfCid.status, dataOfCid.recordOnlyFlag]);
  const isAfter2024 = useMemo(() => {
    return moment(dataOfCid.createTime).isSameOrAfter(moment('2024-01-01'));
  }, [dataOfCid.createTime]);

  //
  const summaryRef = useRef(null);
  useEffect(() => {
    if (dataOfCid.status === CidStatusConstant.CLOSED && summaryRef.current) {
      summaryRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [dataOfCid.status]);

  // ========== 載入資料 ==========
  const handleQuery = useCallback(() => {
    const cidFormID = GetCidFormID(location.pathname);
    if (!user || !cidFormID) return;
    if (user && user.operations.includes('CIDS_edit-cid-form') && location.state?.isNew) {
      setIsEditing(true);
      return;
    }

    setLoading(true);
    const querySQLText = buildSqlQuery(hasPermissionToShowMaintenanceInfo, TOTAL_TIME_POINTS);

    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: querySQLText,
          variables: { cidFormID: cidFormID, timezone: appConfigs.timezone },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('cidView.failedToQueryCidForm', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }
        const resData = res.data.data.om_getCidFormContent;
        resData.occurDate = resData.startDate && moment(resData.startDate);
        resData.objectIDs = resData.objectIDs || [];
        resData.ocapIDs = resData.ocapIDs || [];
        resData.files = resData.fileList || [];
        resData.alertLogList = resData.alertLogList || [];
        //
        let rootCauseLevel = [];
        if (resData.rootCauseLevel1) rootCauseLevel.push(resData.rootCauseLevel1);
        if (resData.rootCauseLevel2) rootCauseLevel.push(resData.rootCauseLevel2);
        if (resData.rootCauseLevel3) rootCauseLevel.push(resData.rootCauseLevel3);
        let rootCauseLevelName = [];
        if (resData.rootCauseLevel1Name) rootCauseLevelName.push(resData.rootCauseLevel1Name);
        if (resData.rootCauseLevel2Name) rootCauseLevelName.push(resData.rootCauseLevel2Name);
        if (resData.rootCauseLevel3Name) rootCauseLevelName.push(resData.rootCauseLevel3Name);
        resData.rootCauseLevel = rootCauseLevel;
        resData.rootCauseLevelName = rootCauseLevelName.map((o) => o).join(' / ');

        if (Array.isArray(resData.omFormList)) {
          resData.omFormList = resData.omFormList.map((m, mIdx) => {
            m.key = mIdx;
            m.omUserInChargeName = Array.isArray(m.omUserInChargeName)
              ? m.omUserInChargeName.filter((f) => f).join(',')
              : '';
            return m;
          });
          setDataOfOmWorkHistory(resData.omFormList);
        }

        if (Array.isArray(resData.askOmLogList) && resData.askOmLogList.length > 0) {
          resData.askOmLogList = resData.askOmLogList.map(SetKeyToArray);
        }

        if (Array.isArray(resData.ocapList)) {
          resData.ocapList = resData.ocapList.map(SetKeyToArray);
        }

        if (Array.isArray(resData.alertLogList)) {
          resData.alertLogs = resData.alertLogList.map(({ alertOwnerID, alertName, startTimestamp }) => ({
            alertOwnerID,
            alertName,
            startTimestamp,
          }));
          resData.alertLogList = resData.alertLogList.map(SetKeyToArray);
          resData.alertLogList = resData.alertLogList.sort(
            (a, b) => moment(a.startTime).unix() - moment(b.startTime).unix()
          );
          setRecordOfAlertLog(resData.alertLogList);

          if (!resData.alertStartTime) {
            resData.alertStartTime = resData.alertLogList.reduce((min, item) => {
              return min === null || item.startTime < min ? item.startTime : min;
            }, null);
          }

          if (!resData.alertEndTime) {
            resData.alertEndTime = resData.alertLogList.reduce((max, item) => {
              return max === null || item.endTime > max ? item.endTime : max;
            }, null);
          }
        }

        resData.alertStartTime = resData.alertStartTime ? moment(resData.alertStartTime) : resData.occurDate;
        resData.alertEndTime = resData.alertEndTime ? moment(resData.alertEndTime) : null;
        let respTypeLevel = [];
        if (resData.respTypeLevel1) respTypeLevel.push(resData.respTypeLevel1);
        if (resData.respTypeLevel2) respTypeLevel.push(resData.respTypeLevel2);
        let respTypeLevelName = [];
        if (resData.respTypeLevel1Name) respTypeLevelName.push(resData.respTypeLevel1Name);
        if (resData.respTypeLevel2Name) respTypeLevelName.push(resData.respTypeLevel2Name);
        resData.respTypeLevel = respTypeLevel;
        resData.respTypeLevelName = respTypeLevelName.map((o) => o).join(' / ');

        setDataOfCid(resData);
        setDataOfCidOrigin(resData);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('cidView.queryCidFormError'));
          console.error('load om form error: ', err);
        }
      })
      .then(() => {
        setLoading(false);
        setAlertRecommendFlag(false); // 觸發Alert推薦
      });
  }, [user, location, hasPermissionToShowMaintenanceInfo, t, TOTAL_TIME_POINTS]);
  useEffect(() => {
    handleQuery();
  }, [handleQuery]);

  // 載入推薦的 alert
  const handleQueryAlertRecommend = useCallback(() => {
    if (!formProps.canEdit) return;
    if (!dataOfCid.cidFormID) return;
    if (recordOfAlertLog.length === 0) return;
    if (alertRecommendFlag) return;

    setRecommendLoading(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery($cidFormID: String!, $timezone: String!) { 
              om_getCidAlertRecommend(cidFormID: $cidFormID, timezone: $timezone) {
                data {
                  list {
                    alertLogID ownerName alertDegree avgLossEnergy lossEnergy days 
                    alertID alertDisplayName alertComponent clientDeviceSeq objectID objectName alertStatusName startTime endTime
                    misjudge alertName alertOwnerID startTimestamp
                  }
                }
              }
            }
          `
          ),
          variables: { cidFormID: dataOfCid.cidFormID, timezone: appConfigs.timezone },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(
            t('cidView.failedToQueryRecommendedAlerts', { error: GetAppSyncRspsErrorMessage(res) })
          );
          return;
        }
        const list = res.data.data.om_getCidAlertRecommend?.data?.list;
        if (!list || list.length === 0) {
          return; // 無推薦的alert
        }

        const tmplist = _.cloneDeep(recordOfAlertLog);
        const recommends = list.filter(
          ({ alertName, alertOwnerID, startTimestamp }) =>
            !tmplist.some(
              (e) =>
                e.alertName === alertName &&
                e.alertOwnerID === alertOwnerID &&
                e.startTimestamp === startTimestamp
            )
        );
        recommends.map((obj) => {
          obj.recommend = true;
          return obj;
        });
        const combinedList = [...tmplist, ...recommends].sort((a, b) =>
          moment(a.startTimestamp).diff(moment(b.startTimestamp))
        );
        setAlertRecommendFlag(true);
        setRecordOfAlertLog(combinedList.map(SetKeyToArray));
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('cidView.queryRecommendedAlertsError'));
          console.error('load om form error: ', err);
        }
      })
      .then(() => {
        setRecommendLoading(false);
      });
  }, [user, formProps.canEdit, recordOfAlertLog, dataOfCid.cidFormID, alertRecommendFlag, t]);
  useEffect(() => {
    handleQueryAlertRecommend();
  }, [handleQueryAlertRecommend]);
  // 設定畫面效果 highlight record.
  useEffect(() => {
    const trElements = document.querySelectorAll('.cid-alertlist-table tr');
    if (trElements.length > 0) {
      trElements.forEach((t) => {
        if (t.style.backgroundColor) t.style.backgroundColor = '';
      });
    }

    const elements = document.querySelectorAll('.ant-table-row-highlight');
    if (elements.length > 0) {
      elements.forEach((t) => {
        if (!t.style.backgroundColor) t.style.backgroundColor = '#fff9df';
      });
    }
  }, [recordOfAlertLog]);

  // ========== 返回上頁 ==========
  useEffect(() => {
    if (!redirectFlag) return;

    if (redirectUrl) {
      navigate(redirectUrl);
    } else {
      navigate(-1);
    }
  }, [redirectUrl, redirectFlag, navigate]);

  // ========== 取消編輯 ==========
  const handleCancelEdit = (reload) => {
    setDataChangedOfCid(false);
    setDataChangedOfAddAlertLogID(false);
    setDataChangedOfAskReason(false);
    setDataChangedOfWithdrawReason(false);
    setIsEditing(false);
    setDataChangedOfSummary(false);
    if (reload) {
      handleQuery();
    }
  };
  // ========== 資料處理 ==========
  //
  // 從異常列表新增的CID單
  useEffect(() => {
    if (!Array.isArray(selectedAlertLogList) || selectedAlertLogList.length === 0) return;

    let minStartTime = '';
    const objectIds = [];
    const possibleRootCause = [];
    for (let i = 0; i < selectedAlertLogList.length; i++) {
      const s = selectedAlertLogList[i];

      // 發生日期
      if (!minStartTime || moment(minStartTime).isAfter(s.startTime)) {
        minStartTime = s.startTime;
      }

      // 相關案場
      if (!objectIds.includes(s.objectID)) objectIds.push(s.objectID);
    }
    selectedAlertLogList.sort((a, b) => moment(a.startTimestamp).diff(moment(b.startTimestamp)));
    const issue = new Set(selectedAlertLogList.map((alert) => `${alert.alertID}${alert.alertDisplayName}`));
    setDataOfCid((prev) => ({
      ...prev,
      occurDate: minStartTime ? moment(minStartTime) : null,
      objectIDs: objectIds,
      possibleRootCause: possibleRootCause.join(','),
      alertLogs: selectedAlertLogList.map(({ alertName, alertOwnerID, startTimestamp }) => ({
        alertName,
        alertOwnerID,
        startTimestamp,
      })),
      issue: Array.from(issue).join(','),
      alertComponent: selectedAlertLogList[0].alertComponent,
      clientDeviceSeq: selectedAlertLogList[0].clientDeviceSeq
        ? parseInt(selectedAlertLogList[0].clientDeviceSeq)
        : null,
    }));
    setRecordOfAlertLog(selectedAlertLogList.map(SetKeyToArray));
  }, [selectedAlertLogList]);

  // 問題現象的選項
  useEffect(() => {
    if (!Array.isArray(listOfAlertID) || listOfAlertID.length === 0) return;

    setAlertOptions(
      listOfAlertID.map((a, index) => {
        return { key: index, label: a.label.replace('-', ''), value: a.label.replace('-', '') };
      })
    );
  }, [listOfAlertID]);

  // ========== 檢查資料異動 ==========
  //
  useEffect(() => {
    const handleWindowClose = (event) => {
      if (
        dataChangedOfCid ||
        dataChangedOfAddAlertLogID ||
        dataChangedOfAskReason ||
        dataChangedOfWithdrawReason ||
        dataChangedOfSummary
      ) {
        event.preventDefault();
        event.returnValue = t('cidView.unsavedChanges');
      }
    };

    window.addEventListener('beforeunload', handleWindowClose);

    // 清除 listener
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, [
    dataChangedOfCid,
    dataChangedOfAddAlertLogID,
    dataChangedOfAskReason,
    dataChangedOfWithdrawReason,
    dataChangedOfSummary,
    t,
  ]);
  // 隨案場選擇來變更迴路選項
  useEffect(() => {
    if (!hasPermissionToShowMaintenanceInfo) return;
    if (!Array.isArray(dataOfCid?.objectIDs)) return;
    if (dataOfCid.objectIDs.length < 1) return;

    axios
      .post(
        appConfigs.sharedAppSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `
            query OmQuery($objectID: String!) {
              shared_getBoosterCircuitList(objectID: $objectID) {
                boosterCircuitID
                circuitID
              }
            }
            `
          ),
          variables: { objectID: dataOfCid.objectIDs[0] },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('cidView.failedToQueryCircuitList', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }
        const list = res.data.data.shared_getBoosterCircuitList || [];
        setListOfBoosterCircuit(list.map((m) => ({ label: m.circuitID, value: m.boosterCircuitID })));

        setDataOfCid((currentData) => {
          return { ...currentData, boosterCircuitRef: null };
        });
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('cidView.queryCircuitListError'));
          console.error('load booster circuit list error: ', err);
        }
      });
  }, [user, dataOfCid.objectIDs, hasPermissionToShowMaintenanceInfo, t]);

  // 隨案場選擇來變更重要性
  useEffect(() => {
    async function fetchData() {
      if (!hasPermissionToShowMaintenanceInfo) return;
      if (!Array.isArray(dataOfCid?.objectIDs)) return;
      if (dataOfCid.objectIDs.length < 1) {
        setListOfCidLevel([]);
        return;
      }

      const getOmContractLevelList = await QueryOmContractLevelList(user, [], [dataOfCid.objectIDs[0]]);
      if (getOmContractLevelList.length > 0) {
        setListOfCidLevel(getOmContractLevelList.sort((a, b) => a.seq - b.seq));
      } else {
        setListOfCidLevel(listOfDefaultCidLevel.sort((a, b) => a.seq - b.seq));
      }
    }
    fetchData();
  }, [user, dataOfCid.objectIDs, hasPermissionToShowMaintenanceInfo, listOfDefaultCidLevel]);

  // 隨案場、設備等級載入INV編號清單
  useEffect(() => {
    if (
      !hasPermissionToShowMaintenanceInfo ||
      !Array.isArray(dataOfCid.objectIDs) ||
      dataOfCid.objectIDs.length === 0 ||
      !dataOfCid.alertComponent ||
      dataOfCid.alertComponent !== 'INV'
    ) {
      setDeviceSeqOptions([]);
      return;
    }

    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(`
          query OmQuery($objectIDs: [String!], $alertComponent: String!) { 
            om_getClientDeviceSeqList(objectIDs: $objectIDs, alertComponent: $alertComponent) {
              seq originSeq
            }
          }
        `),
          variables: { objectIDs: dataOfCid.objectIDs, alertComponent: dataOfCid.alertComponent },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('cidView.failedToQueryInvList', { error: GetAppSyncRspsErrorMessage(res) }));
          return [];
        }
        const newOptions = res.data.data.om_getClientDeviceSeqList.map((m) => ({
          label: m.originSeq && m.seq !== m.originSeq ? `${m.seq} [${m.originSeq}]` : m.seq,
          value: m.seq,
        }));
        setDeviceSeqOptions(newOptions);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('cidView.queryInvListError'));
          console.error('load device seq list error: ', err);
        }
      });
  }, [user.token, dataOfCid.objectIDs, dataOfCid.alertComponent, hasPermissionToShowMaintenanceInfo, t]);

  // ========== CID 表單相關 ==========
  //
  const handleDataOfCidChange = (name) => (e) => {
    const modifyValue = e && e.target ? e.target.value : e;
    const current = { ...dataOfCid };
    current[name] = modifyValue;
    delete current[`${name}_msg`];

    if (name === 'rootCauseLevel' && current.solutionLevel2) {
      let item = listOfCidSolution.find((f) => f.value === current.solutionLevel2);
      if (!item || item.parent !== modifyValue[1]) {
        current.solutionLevel2 = null;
      }
    } else if (['encrypt', 'recordOnlyFlag'].includes(name)) {
      current[name] = e.target.checked;
    } else if (name === 'alertComponent') {
      delete current['clientDeviceSeq_msg']; // 一併移除INV編號的msg
    }

    setDataOfCid(current);
    setDataChangedOfCid(true);
    setDataChangedOfSummary(true);
  };
  const handleDataOfCidSave = (cbFn) => () => {
    // valid
    const validResult = ValidFormData(dataOfCid, [
      { name: 'occurDate', type: 'datetime', required: true },
      { name: 'level', type: 'select', required: true },
      { name: 'objectIDs', type: 'select-multi', required: true },
      { name: 'source', type: 'select', required: true },
      { name: 'userInCharge', type: 'select', required: true },
      { name: 'problemCategory', type: 'select', required: true },
      { name: 'issue', type: 'input', required: true },
      {
        name: 'clientDeviceSeq',
        type: 'input',
        required: dataOfCid.alertComponent && dataOfCid.alertComponent === 'INV',
      },
    ]);
    if (!validResult.status) {
      setDataOfCid(validResult.data);
      return;
    }
    // 檢核alert的案場是否都有在相關案場裡
    let recommend = [];
    if (recordOfAlertLog.length > 0) {
      let objectsValid = true;
      recordOfAlertLog.forEach((alert) => {
        if (!dataOfCid.objectIDs.includes(alert.objectID)) {
          objectsValid = false;
          return;
        }
      });

      if (!objectsValid) {
        message.warn(t('cidView.alertSiteMismatch'));
        return;
      }

      recommend = recordOfAlertLog.filter((f) => f.recommend);
    }
    if (recommend.length > 0) {
      message.warn(t('cidView.pendingRecommendedAlerts'));
      return;
    }
    // 檢核clientDeviceSeq
    if (deviceSeqOptions.length > 0 && dataOfCid.clientDeviceSeq) {
      const existOption = deviceSeqOptions.find((option) => option.value === dataOfCid.clientDeviceSeq);
      if (!existOption) {
        message.warn(t('cidView.invNotInList'));
        return;
      }
    }

    // save data prepare
    const saveData = {};
    saveData.cidFormID = dataOfCid.cidFormID;
    saveData.encrypt = dataOfCid.encrypt || false;
    saveData.recordOnlyFlag = dataOfCid.recordOnlyFlag || false;
    saveData.startDate = DateTimeFormat(dataOfCid.occurDate);
    saveData.level = dataOfCid.level;
    saveData.source = dataOfCid.source;
    saveData.userInCharge = dataOfCid.userInCharge;
    saveData.problemCategory = dataOfCid.problemCategory;
    saveData.errorCode = dataOfCid.errorCode;
    saveData.topic = dataOfCid.topic;
    saveData.currentProgress = dataOfCid.currentProgress;
    saveData.possibleRootCause = dataOfCid.possibleRootCause;
    saveData.rootCause = dataOfCid.rootCause;
    saveData.solution = dataOfCid.solution;
    saveData.logbook = dataOfCid.logbook;
    saveData.objects = dataOfCid.objectIDs;
    saveData.alertLogs = dataOfCid.alertLogs;
    saveData.ocaps = dataOfCid.ocapIDs;
    saveData.files = dataOfCid.files;
    saveData.timezone = appConfigs.timezone;
    saveData.issue = dataOfCid.issue;
    saveData.rootCauseLevel1 =
      dataOfCid.rootCauseLevel && dataOfCid.rootCauseLevel.length > 0 ? dataOfCid.rootCauseLevel[0] : null;
    saveData.rootCauseLevel2 =
      dataOfCid.rootCauseLevel && dataOfCid.rootCauseLevel.length > 1 ? dataOfCid.rootCauseLevel[1] : null;
    saveData.rootCauseLevel3 =
      dataOfCid.rootCauseLevel && dataOfCid.rootCauseLevel.length > 2 ? dataOfCid.rootCauseLevel[2] : null;
    saveData.solutionLevel1 = dataOfCid.solutionLevel1;
    saveData.solutionLevel2 = dataOfCid.solutionLevel2;
    saveData.alertComponent = dataOfCid.alertComponent;
    saveData.clientDeviceSeq = dataOfCid.clientDeviceSeq ? dataOfCid.clientDeviceSeq : null;
    saveData.exclusions = dataOfCid.exclusions;
    saveData.boosterCircuitRef = dataOfCid.boosterCircuitRef;

    setButtonWorking(true);
    axios
      .post(`${appConfigs.apiGatewayURL}/oms/cid/save`, saveData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('cidView.failedToSaveCidForm', { error: res?.data?.msg }));
          return;
        }

        message.success(t('cidView.saveCidFormSuccess'));
        if (cbFn) {
          cbFn(true);
        } else {
          handleCancelEdit(true);
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('cidView.saveCidFormError'));
          console.error('save cid form error: ', err);
        }
      })
      .then(() => {
        setButtonWorking(false);
      });
  };
  const handleSendMail = () => {
    // 寄信資料準備
    const data = {};
    data.cidFormID = dataOfCid.cidFormID;
    data.notificationReason = dataOfCid.notificationReason;

    // 寄信
    setButtonWorking(true);
    axios
      .post(`${appConfigs.apiGatewayURL}/oms/cid/emailToInvestor`, data, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('cidView.failedToSendEmail', { error: res?.data?.msg }));
          return;
        }

        message.success(t('cidView.sendEmailSuccess'));
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('cidView.sendEmailError'));
          console.error('send mail form error: ', err);
        }
      })
      .then(() => {
        setButtonWorking(false);
      });
  };
  const handlePreSendMail = () => {
    // 檢查通知理由是否填寫
    if (!dataOfCid.notificationReason) {
      const temp = { ...dataOfCid };
      temp.notificationReason_msg = t('cidView.enterNotificationReason');
      setDataOfCid(temp);
      return;
    }

    // 如果表單尚未結案，則需要先儲存再寄信，否則就直接寄信
    if (![CidStatusConstant.CLOSED].includes(dataOfCid.status) && isEditing) {
      handleDataOfCidSave(handleSendMail)();
    } else {
      handleSendMail();
    }
  };
  const handleDataOfCidSummarySave = () => {
    // save data prepare
    const saveData = {};
    saveData.cidFormID = dataOfCid.cidFormID;
    saveData.alertStartTime = DateTimeFormat(dataOfCid.alertStartTime);
    saveData.alertEndTime = DateTimeFormat(dataOfCid.alertEndTime);
    saveData.totalLossEnergy = dataOfCid.totalLossEnergy;
    saveData.respTypeLevel1 =
      dataOfCid.respTypeLevel && dataOfCid.respTypeLevel.length > 0 ? dataOfCid.respTypeLevel[0] : null;
    saveData.respTypeLevel2 =
      dataOfCid.respTypeLevel && dataOfCid.respTypeLevel.length > 1 ? dataOfCid.respTypeLevel[1] : null;
    saveData.timezone = appConfigs.timezone;

    setButtonWorking(true);
    axios
      .post(`${appConfigs.apiGatewayURL}/oms/cid/summary/save`, saveData, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('cidView.failedToSaveCidSummary', { error: res?.data?.msg }));
          return;
        }

        message.success(t('cidView.saveCidSummarySuccess'));
        handleCancelEdit(true);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('cidView.saveCidSummaryError'));
          console.error('save cid summary error: ', err);
        }
      })
      .then(() => {
        setButtonWorking(false);
      });
  };

  const handleDataOfCidCancel = () => {
    let modalClose = false;
    if (
      dataChangedOfCid ||
      dataChangedOfAddAlertLogID ||
      dataChangedOfAskReason ||
      dataChangedOfWithdrawReason ||
      dataChangedOfSummary
    ) {
      if (window.confirm(t('cidView.confirmClose'))) {
        modalClose = true;
      }
    } else {
      modalClose = true;
    }

    if (modalClose) {
      if (isNew) {
        setRedirectFlag(true);
      } else {
        if (dataChangedOfCid) {
          setDataOfCid((prev) => ({ ...prev, ...dataOfCidOrigin }));
          setRecordOfAlertLog(dataOfCidOrigin.alertLogList || []);
        }
        handleCancelEdit(false);
      }
    }
  };

  // 結案計算發電損失
  const handleLossEnergyCalculate = () => {
    let alertOwnerID = [];
    if (Array.isArray(dataOfCid.alertLogs)) {
      alertOwnerID = [...new Set(dataOfCid.alertLogs.map((m) => m.alertOwnerID))];
    }
    let alertDatas = [];
    if (Array.isArray(recordOfAlertLog)) {
      alertDatas = recordOfAlertLog.map((log) => {
        return {
          alertLogID: log.alertLogID,
          alertOwnerID: log.alertOwnerID,
          alertName: log.alertName,
          startTimestamp: new Date(log.startTime).toISOString(), // 轉換為 UTC 時間
          endTimestamp: log.endTime ? new Date(log.endTime).toISOString() : null, // 轉換為 UTC 時間，null 保持原狀
          expired: log.alertStatusName,
          lossEnergy: log.lossEnergy,
          ownerType: log.ownerType,
          alertComponent: log.alertComponent,
        };
      });
    }

    if (alertOwnerID.length === 0) return;
    let params = {
      begin: dataOfCid.alertStartTime,
      end: dataOfCid.alertEndTime,
      alertOwnerID: alertOwnerID,
      alertDatas: alertDatas,
    };

    axios
      .post(`${appConfigs.apiGatewayURL}/oms/cid/lossenergy`, params, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        dataOfCid.totalLossEnergy = res.data['data'];
        let current = { ...dataOfCid };
        setDataOfCid(current);
        setDataChangedOfSummary(true);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('cidView.calculateLossError'));
          console.error('caculate loss energy fail: ', err);
        }
      });
  };

  // ========== CID 表單流程操作相關 ==========
  //
  const handleSendRequestModelClose = () => {
    let modalClose = false;
    if (dataChangedOfAskReason) {
      if (window.confirm(t('cidView.confirmClose'))) {
        modalClose = true;
      }
    } else {
      modalClose = true;
    }

    if (modalClose) {
      setSendRequestModelOpen(false);
      setDataChangedOfAskReason(false);
      setDataOfAskReason({ value: '', msg: '' });
    }
  };
  const handleDataOfAskReasonChange = (e) => {
    setDataOfAskReason({ value: e.target.value, msg: '' });
    setDataChangedOfAskReason(true);
  };
  const handleSendRequest = () => {
    if (!dataOfAskReason.value) {
      setDataOfAskReason({ value: '', msg: t('cidView.enterRequestReason') });
      return;
    }

    const saveData = {
      cidFormID: dataOfCid.cidFormID,
      reason: dataOfAskReason.value,
    };

    //
    setButtonWorking(true);
    axios
      .post(`${appConfigs.apiGatewayURL}/oms/cid/sendRequest`, saveData, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('cidView.failedToSendRequest', { error: res?.data?.msg }));
          return;
        }

        message.success(t('cidView.sendRequestSuccess'));
        setSendRequestModelOpen(false);
        setDataOfAskReason({ value: '', msg: '' });
        handleCancelEdit(true);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('cidView.sendRequestError'));
          console.error('send cid request error: ', err);
        }
      })
      .then(() => {
        setButtonWorking(false);
      });
  };
  const handleCidClose = () => {
    if (!dataOfCid.cidFormID) return;

    setButtonWorking(true);
    axios
      .post(
        `${appConfigs.apiGatewayURL}/oms/cid/close`,
        { cidFormID: dataOfCid.cidFormID, timezone: appConfigs.timezone },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('cidView.failedToCloseCidForm', { error: res?.data?.msg }));
          return;
        }

        message.success(t('cidView.closeCidFormSuccess'));
        handleCancelEdit(true);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('cidView.closeCidFormError'));
          console.error('save cid form error: ', err);
        }
      })
      .then(() => {
        setButtonWorking(false);
      });
  };
  const handleCidVoid = () => {
    if (!dataOfCid.cidFormID) return;

    setButtonWorking(true);
    axios
      .post(
        `${appConfigs.apiGatewayURL}/oms/cid/void`,
        { cidFormID: dataOfCid.cidFormID, timezone: appConfigs.timezone },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('cidView.failedToVoidCidForm', { error: res?.data?.msg }));
          return;
        }

        message.success(t('cidView.voidCidFormSuccess'));
        handleCancelEdit(true);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('cidView.voidCidFormError'));
          console.error('save cid form error: ', err);
        }
      })
      .then(() => {
        setButtonWorking(false);
      });
  };

  // ========== 抽回委託 ==========
  //
  const handleWithdrawModalOpen = useCallback(
    (cidId) => () => {
      setWithdrawModalProps({ open: true, cidFormID: cidId });
    },
    []
  );
  const handleWithdrawModalClose = () => {
    let modalClose = false;
    if (dataChangedOfWithdrawReason) {
      if (window.confirm(t('cidView.confirmClose'))) {
        modalClose = true;
      }
    } else {
      modalClose = true;
    }

    if (modalClose) {
      setWithdrawModalProps({ open: false, cidFormID: '' });
      setDataChangedOfWithdrawReason(false);
      setWithdrawReason({ value: '', msg: '' });
    }
  };
  const handleWithdrawReasonChange = (e) => {
    setWithdrawReason({ value: e.target.value, msg: '' });
    setDataChangedOfWithdrawReason(true);
  };
  const handleCidWithdraw = () => {
    if (!dataOfCid.cidFormID) return;
    if (!withdrawReason.value) {
      setWithdrawReason({ value: '', msg: t('cidView.enterWithdrawReason') });
      return;
    }

    const saveData = {
      cidFormID: dataOfCid.cidFormID,
      reason: withdrawReason.value,
      timezone: appConfigs.timezone,
    };

    setButtonWorking(true);
    axios
      .post(`${appConfigs.apiGatewayURL}/oms/cid/withdrawRequest`, saveData, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('cidView.failedToWithdrawRequest', { error: res?.data?.msg }));
          return;
        }

        message.success(t('cidView.withdrawRequestSuccess'));
        setWithdrawModalProps({ open: false, cidFormID: '' });
        setWithdrawReason({ value: '', msg: '' });
        handleCancelEdit(true);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('cidView.withdrawRequestError'));
          console.error('withdraw cid request error: ', err);
        }
      })
      .then(() => {
        setButtonWorking(false);
      });
  };

  // ========== CID 警報相關 ==========
  //
  const handleAddAlertLogIDChange = (e) => {
    setDataOfAddAlertLogID(e.target.value.trim());
    setDataChangedOfAddAlertLogID(true);
  };
  const handleAlertLogAdd = useCallback(async () => {
    if (!dataOfAddAlertLogID) return;
    const check = recordOfAlertLog.find((r) => r.alertLogID === parseInt(dataOfAddAlertLogID));
    if (check) {
      message.error(t('cidView.alertLogExists'));
      return;
    }

    setAddingAlert(true);
    await axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `
            query OmQuery($alertLogID: Int!, $timezone: String!, $cidFormID: String) { 
              om_getAlertLogContentByAlertLogID(alertLogID: $alertLogID, timezone: $timezone, cidFormID: $cidFormID) {
                detail {
                  alertLogID ownerName alertDegree avgLossEnergy lossEnergy days 
                  alertID alertDisplayName alertComponent clientDeviceSeq objectID objectName alertStatusName startTime endTime
                  misjudge alertName alertOwnerID startTimestamp cidID
                }
                group {
                  list {
                    alertLogID ownerName alertDegree avgLossEnergy lossEnergy days 
                    alertID alertDisplayName alertComponent clientDeviceSeq objectID objectName alertStatusName startTime endTime
                    misjudge alertName alertOwnerID startTimestamp cidID
                  }
                }
              }
            }`
          ),
          variables: {
            alertLogID: dataOfAddAlertLogID,
            timezone: appConfigs.timezone,
            cidFormID: dataOfCid.cidFormID,
          },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(
            t('cidView.failedToQueryAlertLog', {
              alertLogID: dataOfAddAlertLogID,
              error: GetAppSyncRspsErrorMessage(res),
            })
          );
          return;
        }
        const list =
          res.data.data.om_getAlertLogContentByAlertLogID?.detail ||
          res.data.data.om_getAlertLogContentByAlertLogID?.group?.list;
        if (!list || list.length === 0) {
          message.warn(t('cidView.alertLogNotFound', { alertLogID: dataOfAddAlertLogID }));
          return;
        }

        if (list[0].misjudge === 'Y') {
          message.warn(t('cidView.alertLogMisjudged', { alertLogID: dataOfAddAlertLogID }));
          return;
        }
        if (list[0].cidID && list[0].cidID !== dataOfCid.cidFormID) {
          message.warn(
            t('cidView.alertLogHasCid', { alertLogID: dataOfAddAlertLogID, cidID: list[0].cidID })
          );
          return;
        }

        let text = `${list[0].alertID}${list[0].alertDisplayName}`;
        const issueNew = new Set([
          ...recordOfAlertLog.map((alert) => `${alert.alertID}${alert.alertDisplayName}`),
          text,
        ]);
        // 移除已存在的alert
        const tmplist = recordOfAlertLog.filter(
          ({ alertName, alertOwnerID, startTimestamp }) =>
            !list.some(
              (e) =>
                e.alertName === alertName &&
                e.alertOwnerID === alertOwnerID &&
                e.startTimestamp === startTimestamp
            )
        );
        const combinedList = [...tmplist, ...list].sort((a, b) =>
          moment(a.startTimestamp).diff(moment(b.startTimestamp))
        );

        setDataOfCid((prev) => ({
          ...prev,
          alertLogs: combinedList.map(({ alertName, alertOwnerID, startTimestamp }) => ({
            alertName,
            alertOwnerID,
            startTimestamp,
          })),
          issue: issueNew ? Array.from(issueNew).join(',') : prev.issue,
          alertComponent: combinedList[0].alertComponent,
          clientDeviceSeq: combinedList[0].clientDeviceSeq ? parseInt(combinedList[0].clientDeviceSeq) : null,
        }));
        setDataChangedOfCid(true);
        setRecordOfAlertLog(combinedList.map(SetKeyToArray));
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.warn(t('cidView.failedToQueryAlertLog', { alertLogID: dataOfAddAlertLogID }));
          console.error('AlertDetail query alert log content error', err);
        }
      })
      .then(() => {
        setDataOfAddAlertLogID(null);
        setDataChangedOfAddAlertLogID(false);
        setAddingAlert(false);
      });
  }, [dataOfAddAlertLogID, recordOfAlertLog, dataOfCid.cidFormID, user.token, t]);
  const handleAlertLogDelete = useCallback(
    (alertOwnerID, alertName, startTimestamp) => () => {
      if (!alertOwnerID || !alertName || !startTimestamp) return;

      const alerts = recordOfAlertLog
        .filter(
          (f) =>
            f.alertOwnerID !== alertOwnerID ||
            f.alertName !== alertName ||
            f.startTimestamp !== startTimestamp
        )
        .sort((a, b) => moment(a.startTimestamp).diff(moment(b.startTimestamp)));

      if (alerts.length === 0) {
        setDataOfCid((prev) => ({
          ...prev,
          alertLogs: null,
          alertComponent: undefined,
          clientDeviceSeq: null,
        }));
        setDataChangedOfCid(true);
        setRecordOfAlertLog([]);
        return;
      }

      const alertLogsNew = alerts.map(({ alertOwnerID, alertName, startTimestamp }) => ({
        alertOwnerID,
        alertName,
        startTimestamp,
      }));
      const alertComponentNew = alerts[0].alertComponent;
      const clientDeviceSeqNew = alerts[0].clientDeviceSeq;
      const issueNew = new Set(alerts.map((alert) => `${alert.alertID}${alert.alertDisplayName}`));
      setDataOfCid((prev) => ({
        ...prev,
        alertLogs: alertLogsNew,
        issue: issueNew.size > 0 ? Array.from(issueNew).join(',') : prev.issue,
        alertComponent: alertComponentNew,
        clientDeviceSeq: clientDeviceSeqNew ? parseInt(clientDeviceSeqNew) : null,
      }));
      setDataChangedOfCid(true);
      setRecordOfAlertLog(alerts.map(SetKeyToArray));
    },
    [recordOfAlertLog]
  );
  const handleAlertRecommendAdd = useCallback(
    (alertOwnerID, alertName, startTimestamp) => () => {
      const tmpList = [...recordOfAlertLog];
      const index = tmpList.findIndex(
        (item) =>
          item.alertOwnerID === alertOwnerID &&
          item.alertName === alertName &&
          item.startTimestamp === startTimestamp
      );
      const item = tmpList[index];
      delete item.recommend;
      tmpList.splice(index, 1, item);

      setDataOfCid((prev) => ({
        ...prev,
        alertLogs: tmpList
          .filter((item) => !item.recommend)
          .map(({ alertName, alertOwnerID, startTimestamp }) => ({
            alertName,
            alertOwnerID,
            startTimestamp,
          })),
      }));
      setDataChangedOfCid(true);
      setRecordOfAlertLog(tmpList);
    },
    [recordOfAlertLog]
  );
  const handleAlertRecommendDelete = useCallback(
    (alertOwnerID, alertName, startTimestamp) => () => {
      const tmpList = recordOfAlertLog.filter(
        (item) =>
          item.alertOwnerID !== alertOwnerID ||
          item.alertName !== alertName ||
          item.startTimestamp !== startTimestamp
      );

      setDataOfCid((prev) => ({
        ...prev,
        exclusions: [
          ...(prev.exclusions || []),
          {
            alertOwnerID: alertOwnerID,
            alertName: alertName,
            startTimestamp: startTimestamp,
          },
        ],
      }));
      setDataChangedOfCid(true);
      setRecordOfAlertLog(tmpList.map(SetKeyToArray));
    },
    [recordOfAlertLog]
  );

  // =====================
  if (loading) {
    return (
      <>
        <PageHeader
          className="app-page-header"
          title={`${
            isNew ? t('cidView.newCidForm') : isEditing ? t('cidView.editCidForm') : t('cidView.viewCidForm')
          } ${t('cidView.cidForm')}`}
        />
        <div className="app-page-content">
          <div style={{ display: 'flex', minHeight: 300, justifyContent: 'center', alignItems: 'center' }}>
            <Spin spinning={loading} tip={t('cidView.loading')} />
          </div>
        </div>
      </>
    );
  }

  if (hasPermissionToShowMaintenanceInfo) {
    // 維運版
    return (
      <>
        <PageHeader
          className="app-page-header"
          title={`${
            isNew ? t('cidView.newCidForm') : isEditing ? t('cidView.editCidForm') : t('cidView.viewCidForm')
          } ${t('cidView.cidForm')}`}
          extra={
            formProps.canEdit && !isEditing
              ? [
                  <Button key="1" type="primary" onClick={() => setIsEditing(true)}>
                    {t('cidView.edit')}
                  </Button>,
                ]
              : formProps.canEdit && isEditing
              ? []
              : null
          }
        />
        <div className="app-page-content">
          {!isEditing && listOfTimeStep.items.length > 0 && isAfter2024 && (
            <section className="app-page-section">
              <Card title={t('cidView.processingTimeline')}>
                <div style={{ overflow: 'auto', padding: '4px 0px' }}>
                  <Steps
                    size={'small'}
                    progressDot
                    current={listOfTimeStep.current}
                    items={listOfTimeStep.items}
                  />
                </div>
              </Card>
            </section>
          )}
          <section className="app-page-section">
            <Card title={t('cidView.cidBasicInfo')}>
              <Row gutter={[8, 24]}>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <RfdmeDisplay label={t('cidView.cidNumber')}>
                    {dataOfCid.cidFormID || t('cidView.autoGenerate')}
                  </RfdmeDisplay>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  {isEditing ? (
                    <RfdmeDatePicker
                      required
                      label={t('cidView.occurrenceDate')}
                      showTime={{ format: 'HH:mm' }}
                      format="YYYY-MM-DD HH:mm"
                      value={dataOfCid.occurDate}
                      msg={dataOfCid.occurDate_msg}
                      popupClassName="app-datetimepicker"
                      onSelect={handleDataOfCidChange('occurDate')}
                      onChange={handleDataOfCidChange('occurDate')}
                    />
                  ) : (
                    <RfdmeDisplay label={t('cidView.occurrenceDate')}>
                      {dataOfCid.occurDate && DateTimeFormat(dataOfCid.occurDate)}
                    </RfdmeDisplay>
                  )}
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  {isEditing ? (
                    <RfdmeSelect
                      required
                      label={t('cidView.importance')}
                      options={listOfCidLevel}
                      value={dataOfCid.level}
                      msg={dataOfCid.level_msg}
                      onChange={handleDataOfCidChange('level')}
                    />
                  ) : (
                    <RfdmeDisplay label={t('cidView.importance')}>{dataOfCid.levelName}</RfdmeDisplay>
                  )}
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  {isEditing ? (
                    <RfdmeCheckbox
                      disabled={formProps.disabledReadOnly}
                      label={t('cidView.recordOnly')}
                      checked={dataOfCid.recordOnlyFlag}
                      onChange={handleDataOfCidChange('recordOnlyFlag')}
                    />
                  ) : (
                    <RfdmeDisplay label={t('cidView.recordOnly')}>
                      {dataOfCid.recordOnlyFlag ? t('cidView.yes') : t('cidView.no')}
                    </RfdmeDisplay>
                  )}
                </Col>
                {isEditing && (
                  <Col span={18}>
                    <RfdmeSelect
                      required
                      label={t('cidView.relatedSites')}
                      mode="multiple"
                      options={listOfSolarPlant}
                      value={dataOfCid.objectIDs}
                      msg={dataOfCid.objectIDs_msg}
                      onChange={handleDataOfCidChange('objectIDs')}
                      style={{ marginBottom: 12 }}
                    />
                  </Col>
                )}
                {isEditing && (
                  <Col span={6}>
                    <RfdmeSelect
                      label={t('cidView.circuit')}
                      options={listOfBoosterCircuit}
                      value={dataOfCid.boosterCircuitRef}
                      onChange={handleDataOfCidChange('boosterCircuitRef')}
                    />
                  </Col>
                )}
                <Col span={24}>
                  {!isEditing && <div className="app-form-label">{t('cidView.relatedSites')}</div>}
                  <Table
                    bordered
                    size="small"
                    pagination={false}
                    columns={ColumnsOfSolarPlantTable(isEditing)}
                    dataSource={listOfSelectedSolarPlant}
                  />
                </Col>
                <Col span={24}>
                  {isEditing ? (
                    <Row gutter={[16, 8]} style={{ marginBottom: 12 }}>
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <RfdmeInput
                          label={t('cidView.alertList')}
                          placeholder={t('cidView.enterAlertLogId')}
                          value={dataOfAddAlertLogID}
                          onChange={handleAddAlertLogIDChange}
                        />
                      </Col>
                      <Col xs={12} sm={12} md={3} lg={3} xl={3} style={{ paddingTop: '25px' }}>
                        <Button type="primary" loading={addingAlert} onClick={handleAlertLogAdd}>
                          {t('cidView.addAlert')}
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    <div className="app-form-label">{t('cidView.alertList')}</div>
                  )}
                  <Table
                    className="cid-alertlist-table"
                    bordered
                    size="small"
                    pagination={false}
                    columns={ColumnsOfAlertLogTable(
                      isEditing,
                      handleAlertLogDelete,
                      handleAlertRecommendAdd,
                      handleAlertRecommendDelete
                    )}
                    dataSource={recordOfAlertLog}
                    loading={recommendLoading}
                    rowClassName={(record, index) => {
                      return record.recommend ? 'ant-table-row-highlight' : '';
                    }}
                  />
                </Col>
                <Col span={8}>
                  <RfdmeDisplay label={t('cidView.status')}>
                    {dataOfCid.statusName || t('cidView.autoGenerate')}
                  </RfdmeDisplay>
                </Col>
                <Col span={8}>
                  {isEditing ? (
                    <RfdmeSelect
                      required
                      label={t('cidView.source')}
                      options={listOfCidSource}
                      value={dataOfCid.source}
                      msg={dataOfCid.source_msg}
                      onChange={handleDataOfCidChange('source')}
                    />
                  ) : (
                    <RfdmeDisplay label={t('cidView.source')}>{dataOfCid.sourceName}</RfdmeDisplay>
                  )}
                </Col>
                <Col span={8}>
                  {isEditing ? (
                    <RfdmeSelect
                      required
                      label={t('cidView.personInCharge')}
                      options={listOfCidUserInCharge}
                      value={dataOfCid.userInCharge}
                      msg={dataOfCid.userInCharge_msg}
                      onChange={handleDataOfCidChange('userInCharge')}
                    />
                  ) : (
                    <RfdmeDisplay label={t('cidView.personInCharge')}>
                      {dataOfCid.userInChargeName}
                    </RfdmeDisplay>
                  )}
                </Col>
                <Col span={8}>
                  {isEditing ? (
                    <RfdmeSelect
                      required
                      label={t('cidView.problemCategory')}
                      options={listOfCidProblemCategory}
                      value={dataOfCid.problemCategory}
                      msg={dataOfCid.problemCategory_msg}
                      onChange={handleDataOfCidChange('problemCategory')}
                    />
                  ) : (
                    <RfdmeDisplay label={t('cidView.problemCategory')}>
                      {dataOfCid.problemCategoryName}
                    </RfdmeDisplay>
                  )}
                </Col>
                <Col span={16}>
                  {isEditing ? (
                    <>
                      <div className="app-form-label" required>
                        {t('cidView.problemPhenomenon')}
                      </div>
                      <AutoComplete
                        allowClear
                        options={alertOptions}
                        value={dataOfCid.issue}
                        onChange={handleDataOfCidChange('issue')}
                        filterOption={(inputValue, option) =>
                          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                      />
                      <div className="app-form-msg">{dataOfCid.issue_msg}</div>
                    </>
                  ) : (
                    <RfdmeDisplay label="問題現象">{dataOfCid.issue}</RfdmeDisplay>
                  )}
                </Col>
                <Col span={24}>
                  {isEditing ? (
                    <RfdmeInput
                      label={t('cidView.currentProgressAnalysis')}
                      value={dataOfCid.currentProgress}
                      onChange={handleDataOfCidChange('currentProgress')}
                    />
                  ) : (
                    <RfdmeDisplay label={t('cidView.currentProgressAnalysis')}>
                      {dataOfCid.currentProgress}
                    </RfdmeDisplay>
                  )}
                </Col>
                <Col span={24}>
                  {isEditing ? (
                    <RfdmeSelect
                      mode="multiple"
                      label={t('cidView.ocapSop')}
                      options={listOfEnabledOcap}
                      value={dataOfCid.ocapIDs}
                      onChange={handleDataOfCidChange('ocapIDs')}
                    />
                  ) : (
                    <>
                      <div className="app-form-label">{t('cidView.ocapSop')}</div>
                      <Table
                        bordered
                        size="small"
                        pagination={false}
                        columns={ColumnsOfOcapTable}
                        dataSource={dataOfCid.ocapList}
                      />
                    </>
                  )}
                </Col>
                <Col span={24}>
                  {isEditing ? (
                    <>
                      <div className="app-form-label">{t('cidView.actualRootCause')}</div>
                      <Cascader
                        allowClear
                        showSearch={{ limit: 10 }}
                        style={{ width: '100%' }}
                        placeholder={t('cidView.selectActualRootCause')}
                        options={listOfLevelRootCause}
                        value={dataOfCid.rootCauseLevel}
                        onChange={handleDataOfCidChange('rootCauseLevel')}
                      />
                    </>
                  ) : (
                    <>
                      {dataOfCid.rootCause && !dataOfCid.rootCauseLevelName ? (
                        <RfdmeDisplay label={t('cidView.actualRootCause')}>
                          {dataOfCid.rootCause}
                        </RfdmeDisplay>
                      ) : (
                        <RfdmeDisplay label={t('cidView.actualRootCause')}>
                          {dataOfCid.rootCauseLevelName}
                        </RfdmeDisplay>
                      )}
                    </>
                  )}
                </Col>
                <Col span={24}>
                  {isEditing ? (
                    <Row gutter={[8, 0]}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <RfdmeSelect
                          label={t('cidView.implementationStrategy')}
                          placeholder={t('cidView.selectStrategyCategory')}
                          options={listOfLevel1Solution}
                          value={dataOfCid.solutionLevel1}
                          onChange={handleDataOfCidChange('solutionLevel1')}
                        />
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <RfdmeSelect
                          label="　"
                          placeholder={t('cidView.selectImplementationStrategy')}
                          options={listOfLevel2Solution}
                          value={dataOfCid.solutionLevel2}
                          onChange={handleDataOfCidChange('solutionLevel2')}
                        />
                      </Col>
                    </Row>
                  ) : (
                    <>
                      {dataOfCid.solution && !dataOfCid.solutionLevel1 && !dataOfCid.solutionLevel2 ? (
                        <RfdmeDisplay label={t('cidView.implementationStrategy')}>
                          {dataOfCid.solution}
                        </RfdmeDisplay>
                      ) : (
                        <Row gutter={[8, 0]}>
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <RfdmeDisplay label={t('cidView.implementationStrategy')}>
                              {dataOfCid.solutionLevel1Name}
                            </RfdmeDisplay>
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <RfdmeDisplay label="　">{dataOfCid.solutionLevel2Name}</RfdmeDisplay>
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                </Col>
                <Col span={24}>
                  {isEditing ? (
                    <RfdmeTextArea
                      label={t('cidView.logbook')}
                      rows={5}
                      value={dataOfCid.logbook}
                      onChange={handleDataOfCidChange('logbook')}
                    />
                  ) : (
                    <RfdmeDisplay label={t('cidView.logbook')}>{dataOfCid.logbook}</RfdmeDisplay>
                  )}
                </Col>
                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  {isEditing ? (
                    <RfdmeSelect
                      disabled={recordOfAlertLog.length > 0}
                      label={t('cidView.deviceLevel')}
                      options={listOfAlertComponent}
                      value={dataOfCid.alertComponent}
                      onChange={handleDataOfCidChange('alertComponent')}
                    />
                  ) : (
                    <RfdmeDisplay label={t('cidView.deviceLevel')}>{dataOfCid.alertComponent}</RfdmeDisplay>
                  )}
                </Col>
                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  {isEditing ? (
                    <>
                      {dataOfCid.alertComponent === 'INV' ? (
                        <RfdmeSelect
                          required
                          showSearch
                          disabled={isComponentSeqDisabled}
                          label={t('cidView.invNumber')}
                          options={deviceSeqOptions}
                          value={dataOfCid.clientDeviceSeq}
                          msg={dataOfCid.clientDeviceSeq_msg}
                          onChange={handleDataOfCidChange('clientDeviceSeq')}
                        />
                      ) : (
                        <RfdmeInputNumber
                          disabled={recordOfAlertLog.length > 0}
                          label={t('cidView.invNumber')}
                          value={dataOfCid.clientDeviceSeq}
                          onChange={handleDataOfCidChange('clientDeviceSeq')}
                        />
                      )}
                    </>
                  ) : (
                    <RfdmeDisplay label={t('cidView.invNumber')}>
                      {deviceSeqOptions.find((opt) => opt.value === dataOfCid.clientDeviceSeq)?.label ||
                        dataOfCid.clientDeviceSeq}
                    </RfdmeDisplay>
                  )}
                </Col>
                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  <RfdmeDisplay label={t('cidView.abnormalDeviceCapacity')}>
                    {dataOfCid.alertComponentCapacity || '-'} kWp
                  </RfdmeDisplay>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  {isEditing ? (
                    <RfdmeUpload
                      label={t('cidView.attachments')}
                      maxCount={(((systemConfigs || {}).fileUpload || {}).cidForm || {}).number}
                      maxSize={(((systemConfigs || {}).fileUpload || {}).cidForm || {}).capacity}
                      value={dataOfCid.files}
                      onChange={handleDataOfCidChange('files')}
                    />
                  ) : (
                    <>
                      <div className="app-form-label">{t('cidView.attachments')}</div>
                      <RfdmeUpload downloadOnly value={dataOfCid.fileList} />
                    </>
                  )}
                </Col>
              </Row>
            </Card>
          </section>
          {formProps.showNotificationReason && (
            <section className="app-page-section">
              <Card title={t('cidView.notificationTrigger')}>
                <Row gutter={[8, 24]}>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                    {formProps.canTriggerNotification ? (
                      <RfdmeSelect
                        label={t('cidView.notificationReason')}
                        options={listOfCidNotificationReason}
                        value={dataOfCid.notificationReason}
                        msg={dataOfCid.notificationReason_msg}
                        onChange={handleDataOfCidChange('notificationReason')}
                      />
                    ) : (
                      <RfdmeDisplay label={t('cidView.notificationReason')}>
                        {dataOfCid.notificationReasonName || t('cidView.none')}
                      </RfdmeDisplay>
                    )}
                  </Col>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                    <RfdmeDisplay label={t('cidView.isNotified')}>
                      {dataOfCid.isSendEmail ? t('cidView.notified') : t('cidView.notNotified')}
                    </RfdmeDisplay>
                  </Col>
                  {formProps.canTriggerNotification && (
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Button
                        type="primary"
                        loading={buttonWorking}
                        onClick={handlePreSendMail}
                        style={{ marginTop: '25px' }}
                      >
                        {t('cidView.notifyInvestorNow')}
                      </Button>
                    </Col>
                  )}
                </Row>
              </Card>
            </section>
          )}
          <section className="app-page-section">
            <Card title={t('cidView.omMaintenanceHistory')}>
              <Table
                bordered
                size="small"
                pagination={false}
                columns={ColumnsOfAskOmLogTable}
                dataSource={dataOfCid.askOmLogList}
              />
            </Card>
          </section>
          <section className="app-page-section">
            <Card title={t('cidView.omRelatedInfo')}>
              <Table
                bordered
                size="small"
                pagination={false}
                columns={ColumnsOfOmFormTable}
                dataSource={dataOfOmWorkHistory}
              />
            </Card>
          </section>
          {formProps.showSummaryButton && (
            <section className="app-page-section" ref={summaryRef}>
              <Card title={t('cidView.cidSummary')}>
                <Row gutter={[8, 24]}>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <RfdmeDatePicker
                      label={t('cidView.powerAnomalyStartTime')}
                      showTime={{ format: 'HH:mm', minuteStep: 15 }}
                      format="YYYY-MM-DD HH:mm"
                      value={dataOfCid.alertStartTime}
                      msg={dataOfCid.alertStartTime_msg}
                      popupClassName="app-datetimepicker"
                      onSelect={handleDataOfCidChange('alertStartTime')}
                      onChange={handleDataOfCidChange('alertStartTime')}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <RfdmeDatePicker
                      label={t('cidView.powerAnomalyEndTime')}
                      showTime={{ format: 'HH:mm', minuteStep: 15 }}
                      format="YYYY-MM-DD HH:mm"
                      value={dataOfCid.alertEndTime}
                      msg={dataOfCid.alertEndTime_msg}
                      popupClassName="app-datetimepicker"
                      onSelect={handleDataOfCidChange('alertEndTime')}
                      onChange={handleDataOfCidChange('alertEndTime')}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className="app-form-item">
                      <div className="app-form-label">
                        {t('cidView.cidTotalPowerLoss')}
                        <Tooltip title={t('cidView.recalculatePowerLoss')}>
                          <Button
                            onClick={handleLossEnergyCalculate}
                            type="text"
                            size="small"
                            icon={<ReloadOutlined />}
                          />
                        </Tooltip>
                      </div>
                      <div className="app-form-input-wrap">
                        <InputNumber
                          style={{ width: '100%' }}
                          placeholder={t('cidView.enterCidTotalPowerLoss')}
                          min={0}
                          max={9999999}
                          value={dataOfCid.totalLossEnergy}
                          onChange={handleDataOfCidChange('totalLossEnergy')}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className="app-form-label">{t('cidView.responsibility')}</div>
                    <Cascader
                      allowClear
                      showSearch={{ limit: 10 }}
                      style={{ width: '100%' }}
                      placeholder={t('cidView.selectResponsibility')}
                      options={listOfLevelRespType}
                      value={dataOfCid.respTypeLevel}
                      onChange={handleDataOfCidChange('respTypeLevel')}
                    />
                  </Col>
                </Row>
              </Card>
            </section>
          )}

          {(isEditing || formProps.showSummaryButton) && (
            <div className="app-form-footer">
              <Row gutter={[8, 8]} justify="end">
                {!formProps.showSummaryButton && (
                  <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Button onClick={handleDataOfCidCancel} block>
                      {t('cidView.common.close')}
                    </Button>
                  </Col>
                )}
                {formProps.showCreateButton && (
                  <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Button
                      type="primary"
                      loading={buttonWorking}
                      onClick={handleDataOfCidSave(setRedirectFlag)}
                      block
                    >
                      {t('cidView.common.create')}
                    </Button>
                  </Col>
                )}
                {formProps.showUpdateButton && (
                  <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Button type="primary" loading={buttonWorking} onClick={handleDataOfCidSave()} block>
                      {t('cidView.common.save')}
                    </Button>
                  </Col>
                )}
                {formProps.showSubmitButton && (
                  <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Button type="primary" onClick={handleDataOfCidSave(setSendRequestModelOpen)} block>
                      {t('cidView.submitRequest')}
                    </Button>
                  </Col>
                )}
                {formProps.showReSubmitButton && (
                  <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Button type="primary" onClick={handleDataOfCidSave(setSendRequestModelOpen)} block>
                      {t('cidView.resubmitRequest')}
                    </Button>
                  </Col>
                )}
                {formProps.showCloseButton && (
                  <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Button
                      type="primary"
                      loading={buttonWorking}
                      onClick={handleDataOfCidSave(setShowClosePopconfirm)}
                      block
                    >
                      {t('cidView.closeCase')}
                    </Button>
                    <Popconfirm
                      title={t('cidView.confirmCloseCase')}
                      okText={t('cidView.common.confirm')}
                      cancelText={t('cidView.common.cancel')}
                      onConfirm={handleCidClose}
                      placement="topRight"
                      open={showClosePopconfirm}
                      onCancel={() => setShowClosePopconfirm(false)}
                    ></Popconfirm>
                  </Col>
                )}
                {formProps.showSummaryButton && (
                  <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Button type="primary" loading={buttonWorking} onClick={handleDataOfCidSummarySave} block>
                      {t('cidView.saveSummary')}
                    </Button>
                  </Col>
                )}
                {formProps.showWithdrawButton && (
                  <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Button
                      danger
                      type="primary"
                      onClick={handleWithdrawModalOpen(dataOfCid.cidFormID)}
                      block
                    >
                      {t('cidView.withdraw')}
                    </Button>
                  </Col>
                )}
                {formProps.showVoidButton && (
                  <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Popconfirm
                      title={t('cidView.confirmVoidCase')}
                      okText={t('cidView.common.confirm')}
                      cancelText={t('cidView.common.cancel')}
                      onConfirm={handleCidVoid}
                      placement="topRight"
                    >
                      <Button danger type="primary" loading={buttonWorking} block>
                        {t('cidView.void')}
                      </Button>
                    </Popconfirm>
                  </Col>
                )}
              </Row>
            </div>
          )}
        </div>

        {/* 跳出視窗-提出委託 */}
        <RfdmeModal width="600px" open={sendRequestModelOpen}>
          <Row>
            <Col span={24}>
              <RfdmeTextArea
                label={
                  <>
                    <span>{t('cidView.requestReason')}</span>
                    {(dataOfCid.alertComponent || dataOfCid.clientDeviceSeq) && (
                      <span style={{ marginLeft: '5px' }}>
                        <b>
                          {t('cidView.deviceLevel')}：{dataOfCid.alertComponent}；
                        </b>
                        <b>
                          {t('cidView.invNumber')}：#
                          {deviceSeqOptions.find((opt) => opt.value === dataOfCid.clientDeviceSeq)?.label ||
                            dataOfCid.clientDeviceSeq}
                        </b>
                      </span>
                    )}
                  </>
                }
                placeholder={t('cidView.enterRequestReason')}
                rows={5}
                value={dataOfAskReason.value}
                msg={dataOfAskReason.msg}
                onChange={handleDataOfAskReasonChange}
              />
            </Col>
          </Row>
          <Divider />
          <Row justify="end" gutter={[8, 0]}>
            <Col>
              <Button onClick={handleSendRequestModelClose}>{t('cidView.common.close')}</Button>
            </Col>
            <Col>
              <Button type="primary" loading={buttonWorking} onClick={handleSendRequest}>
                {t('cidView.submitRequest')}
              </Button>
            </Col>
          </Row>
        </RfdmeModal>

        {/* 跳出視窗-抽回委託 */}
        <RfdmeModal open={withdrawModalProps.open}>
          <Row>
            <Col span={24}>
              <RfdmeTextArea
                label={t('cidView.withdrawRequestReason')}
                rows={5}
                value={withdrawReason.value}
                msg={withdrawReason.msg}
                onChange={handleWithdrawReasonChange}
              />
            </Col>
          </Row>
          <Divider />
          <Row justify="end" gutter={[8, 0]}>
            <Col>
              <Button onClick={handleWithdrawModalClose}>{t('cidView.common.close')}</Button>
            </Col>
            <Col>
              <Button type="primary" loading={buttonWorking} onClick={handleCidWithdraw}>
                {t('cidView.confirmWithdrawRequest')}
              </Button>
            </Col>
          </Row>
        </RfdmeModal>
      </>
    );
  } else {
    // 投資人版
    return (
      <>
        <PageHeader className="app-page-header" title={dataOfCid.cidFormID} />
        <div className="app-page-content">
          {listOfTimeStep.items.length > 0 && isAfter2024 && (
            <section className="app-page-section">
              <Card title={t('cidView.processingTimeline')}>
                <Steps
                  size={'small'}
                  progressDot
                  current={listOfTimeStep.current}
                  items={listOfTimeStep.items}
                />
              </Card>
            </section>
          )}
          <section className="app-page-section">
            <Card title={t('cidView.cidBasicInfo')}>
              <Row gutter={[8, 24]}>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <RfdmeDisplay label={t('cidView.cidNumber')}>{dataOfCid.cidFormID}</RfdmeDisplay>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <RfdmeDisplay label={t('cidView.powerAnomalyDate')}>
                    {dataOfCid.alertStartTime && DateFormat(dataOfCid.alertStartTime)}
                  </RfdmeDisplay>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <RfdmeDisplay label={t('cidView.importance')}>{dataOfCid.levelName}</RfdmeDisplay>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <RfdmeDisplay label={t('cidView.problemPhenomenon')}>{dataOfCid.issue}</RfdmeDisplay>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <RfdmeDisplay label={t('cidView.responsibility')}>
                    {dataOfCid.respTypeLevelName}
                  </RfdmeDisplay>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <RfdmeDisplay label={t('cidView.abnormalDeviceCapacity')}>
                    {dataOfCid.alertComponentCapacity || '-'} kWp
                  </RfdmeDisplay>
                </Col>
                <Col span={24}>
                  <div className="app-form-label">{t('cidView.relatedSites')}</div>
                  <Table
                    bordered
                    size="small"
                    pagination={false}
                    columns={ColumnsOfSolarPlantTable(false)}
                    dataSource={listOfSelectedSolarPlant}
                  />
                </Col>
                <Col span={24}>
                  <div className="app-form-label">{t('cidView.attachments')}</div>
                  <RfdmeUpload downloadOnly value={dataOfCid.fileList} />
                </Col>
              </Row>
            </Card>
          </section>
        </div>
      </>
    );
  }
};

export default CidView;
