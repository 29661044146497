import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { message } from 'antd';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
//
import App from './layout/app';
import { AuthContextProvider, SessionClear } from './contexts/authProvider';
import SettingProvider from './contexts/settingsProvider';
//import reportWebVitals from './reportWebVitals';
//
import 'antd/dist/antd.min.css';
import './css/ant-override.css';
import './css/main.css';
import './css/rwd.css';
import './css/dark.css';
//
let abortControllers = new Map();
let isLoggedOut = false;

const getRequestIdentifier = (config) => {
  let data = config.data;
  if (config.params && Object.keys(config.params).length) {
    data = { ...data, ...config.params };
  }
  // Create a stringified key using both the URL and the serialized data
  return `${config.url}_${JSON.stringify(data)}`;
};

const removePendingRequest = (config, abort = false) => {
  // Generate the request identifier
  const requestIdentifier = getRequestIdentifier(config);
  if (abortControllers.get(requestIdentifier)) {
    if (abort) {
      // Abort the request if needed
      abortControllers.get(requestIdentifier).abort();
    }
    // Remove the request identifier from the map
    abortControllers.delete(requestIdentifier);
  }
};

const logoutUser = () => {
  if (isLoggedOut) return;

  isLoggedOut = true;
  message.warn({
    content: (
      <div>
        <strong>登入逾時</strong>
        <div>系統將於 3 秒後自動登出，請再次登入</div>
      </div>
    ),
  });

  // 使用AbortController取消所有進行中的request
  abortControllers.forEach((controller, url) => {
    controller.abort();
    abortControllers.delete(url);
  });

  // 重置 AbortController Map
  abortControllers.clear();

  //
  SessionClear();
  setTimeout(() => {
    window.location.href = '/login';
  }, 1000 * 3);
};

axios.interceptors.request.use(
  async (config) => {
    try {
      // 處理重複請求
      removePendingRequest(config, true);
      const controller = new AbortController();
      config.signal = controller.signal;
      abortControllers.set(getRequestIdentifier(config), controller);

      // 設置語言 Header
      config.headers['Accept-Language'] = localStorage.getItem('i18nextLng') || 'zh-TW';

      // 驗證 token
      const authHeader = config.headers?.Authorization;
      if (!authHeader) return config;

      const token = authHeader.replace('Bearer', '').trim();
      if (!token) return config;

      const storedToken = window.localStorage.getItem('token');
      if (!storedToken) {
        throw new Error('token was removed');
      }

      // 檢查 token 是否過期
      const decodedToken = jwtDecode(token);
      const expirationTime = (decodedToken?.exp || 1) * 1000;

      if (Date.now() >= expirationTime) {
        logoutUser();
        throw new axios.Cancel('token has expired');
      }

      return config;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => {
    abortControllers.delete(response.config.url);
    return response;
  },
  (error) => {
    abortControllers.delete(error.config.url);
    if (error.response) {
      switch (error.response.status) {
        case 401:
          console.warn('未經授權');
          message.warn({
            content: (
              <div>
                <strong>未經授權</strong>
                <div>無權限使用此動作，如有疑問請通知系統管理員確認權限</div>
              </div>
            ),
          });
          break;
        case 403:
          console.warn('沒有存取權限');
          message.warn({
            content: (
              <div>
                <strong>沒有存取權限</strong>
                <div>無權限使用此動作，如有疑問請通知系統管理員確認權限</div>
              </div>
            ),
          });
          break;
        case 404:
          console.warn('找不到該頁面');
          break;
        case 500:
          console.warn('伺服器出錯');
          break;
        case 503:
          console.warn('服務失效');
          break;
        default:
          console.warn(`連接錯誤: ${error.response.status}`);
      }
    }
    return Promise.reject(error);
  }
);

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <SettingProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </SettingProvider>
        </AuthContextProvider>
      </QueryClientProvider>
    </I18nextProvider>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
