import React, { useCallback, useEffect, useState, useMemo, useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import {
  Button,
  Space,
  Spin,
  Tooltip,
  Popconfirm,
  message,
  Table,
  PageHeader,
  Tabs,
  Card,
  Divider,
  Row,
  Col,
  Collapse,
} from 'antd';
import {
  AuditOutlined,
  StopOutlined,
  CheckOutlined,
  FileAddOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
//
import RfdmeModal from '../../components/Modal';
import RfdmeTextArea from '../../components/TextArea';
import RfdmeSelect from '../../components/Select';
import RfdmeInput from '../../components/Input';
import RfdmeDatePicker from '../../components/DatePicker';
//
import useListOfOmSource from '../../hooks/useListOfOmSource';
import useListOfOmUser from '../../hooks/useListOfOmUser';
import useListOfOmAction from '../../hooks/useListOfOmAction';
import useListOfOmExtraPv from '../../hooks/useListOfOmExtraPv';
import useListOfCidLevel from '../../hooks/useListOfCidLevel';
import useListOfIntegratedDateType from '../../hooks/useListOfIntegratedDateType';
import useListOfPvOwner from '../../hooks/useListOfPvOwner';
import useLocalStorage from '../../hooks/useLocalStorage';
import useListOfCidProblemCategory from '../../hooks/useListOfCidProblemCategory';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import {
  AppSyncQueryTextFormat,
  JsonStringToFormObject,
  FormObjectToJsonString,
  SetKeyToArray,
} from '../../utils/format';
import { IsValidAppSyncRsps, IsValidApiGatewayRsps } from '../../utils/valid';
import { GetAppSyncRspsErrorMessage } from '../../utils/parse';
import { CidStatusConstant, OmStatusConstant } from '../../utils/constant';
import { QueryOmContractLevelList } from '../../utils/dataHelpers';
import { useTranslation } from 'react-i18next';

// 本頁使用元件
const { Panel } = Collapse;

// 本頁固定變數
const FILTER_DATA_MOMENT_COLUMN_NAMES = ['startDate', 'endDate'];
const INITIAL_FILTER_DATA = { timezone: appConfigs.timezone };
const FILTER_DATA_IN_SESSION_NAME = 'om-todo-filterData';

// 本頁自訂函式
const GenerateQueryConditions = (tableInfo) => {
  const conditions = GetDataOfFilterFromSession() || {};

  //加入 tableInfo
  if (tableInfo) {
    conditions.page = tableInfo.page;
    conditions.pageSize = tableInfo.pageSize;
    conditions.sortColumnName = tableInfo.sortColumn;
    conditions.sortDirection = tableInfo.sortDirection === 'ascend' ? 'asc' : 'desc';
  }

  return conditions;
};
const ShouldDisplayEditOmButton = (omStatus) => {
  return [
    OmStatusConstant.REQUEST_PREPARATION,
    OmStatusConstant.PLANNED,
    OmStatusConstant.PROCESSING,
    OmStatusConstant.ISSUE_OBSERVE,
  ].includes(omStatus);
};
const ShouldDisplayFollowUpOmButton = (omStatus) => {
  return omStatus === OmStatusConstant.ISSUE_ONGOING;
};
const GetDataOfFilterFromSession = () => {
  return JsonStringToFormObject(
    window.sessionStorage.getItem(FILTER_DATA_IN_SESSION_NAME),
    FILTER_DATA_MOMENT_COLUMN_NAMES
  );
};
const SetDefaultDataOfFilterToSession = () => {
  window.sessionStorage.setItem(
    FILTER_DATA_IN_SESSION_NAME,
    FormObjectToJsonString(INITIAL_FILTER_DATA, FILTER_DATA_MOMENT_COLUMN_NAMES)
  );
};
const SetDataOfFilterToSession = (value) => {
  window.sessionStorage.setItem(
    FILTER_DATA_IN_SESSION_NAME,
    FormObjectToJsonString(value, FILTER_DATA_MOMENT_COLUMN_NAMES)
  );
};

//主要元件
const OmTodoList = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  // 資料類
  const { data: listOfOmSource } = useListOfOmSource();
  const { data: listOfOmExtraPv } = useListOfOmExtraPv();
  const { data: listOfOmUser } = useListOfOmUser();
  const { data: listOfOmAction } = useListOfOmAction();
  const { data: listOfDefaultCidLevel } = useListOfCidLevel();
  const { data: listOfDateType } = useListOfIntegratedDateType();
  const { data: listOfPvOwner } = useListOfPvOwner();
  const { data: listOfCidProblemCategory } = useListOfCidProblemCategory();
  const [dataOfFilter, setDataOfFilter] = useState({ ...INITIAL_FILTER_DATA });
  const [rejectReason, setRejectReason] = useState({ value: '', msg: '' });
  const [recordOfOmWait, setRecordOfOmWait] = useState([]); // 委託待收清單
  const [recordOfOmTodo, setRecordOfOmTodo] = useState([]); // 委託待處理清單
  const [recordOfOmList, setRecordOfOmList] = useState([]); // 無委託待處理清單
  const [listOfCidLevel, setListOfCidLevel] = useState([]);
  // 畫面控制類
  const [loading, setLoading] = useState(false);
  const [collaps, setCollaps] = useState(true);
  const [tabkey, setTabkey] = useState('wait');
  const [rejectModalProps, setRejectModalProps] = useState({ open: false, cidFormID: '' });
  // 瀏覽器暫存類 (for search)
  const [tableTodoSortInfo, setTableTodoSortInfo] = useLocalStorage('om-todo-table-todo-sort-info', {
    columnKey: '',
    order: '',
  });
  const [tableOmListInfo, setTableOmListInfo] = useLocalStorage('om-todo-table-omlist-table-info', {
    sortColumn: null,
    sortDirection: 'ascend',
    page: 1,
    pageSize: 20,
  });
  // 瀏覽器暫存類 (for last review highlight)
  const [tableTodoExpandCidFormID, setTableTodoExpandCidFormID] = useLocalStorage(
    'om-todo-table-todo-expand-cid-form-id',
    ''
  );
  const [tableOmListHighlightOmFormIDs, setTableOmListHighlightOmFormIDs] = useLocalStorage(
    'om-todo-table-omlist-highlight-omformids',
    []
  );
  const rowRefsForTodo = useRef({});
  const rowRefsForOmList = useRef({});

  //
  const listOfPvByOwner = useMemo(() => {
    if (!Array.isArray(listOfOmExtraPv) || listOfOmExtraPv.length === 0) return [];
    if (
      Array.isArray(listOfOmExtraPv) &&
      (!Array.isArray(dataOfFilter.ownerIDs) || dataOfFilter.ownerIDs.length === 0)
    ) {
      return listOfOmExtraPv;
    }

    const items = listOfOmExtraPv.filter((f) => dataOfFilter.ownerIDs.includes(f.ownerID));
    if (items.length === 0) return [];

    return items.map(SetKeyToArray);
  }, [dataOfFilter.ownerIDs, listOfOmExtraPv]);

  // column
  const BASIC_CID_TABLE_COLUMNS = useMemo(
    () => [
      {
        title: t('omTodoList.columns.importance'),
        dataIndex: 'cidLevelName',
        key: 'cidLevelName',
        width: 80,
        sorter: (a, b) => a.cidLevel - b.cidLevel,
        showSorterTooltip: false,
      },
      {
        title: t('omTodoList.columns.cidNumber'),
        dataIndex: 'cidFormID',
        key: 'cidFormID',
        width: 100,
        render: (value) => (
          <Link to={`/cids/${value}`} target="_blank">
            {value}
          </Link>
        ),
        sorter: (a, b) => a.cidFormID.localeCompare(b.cidFormID),
        showSorterTooltip: false,
      },
      {
        title: t('omTodoList.columns.createDate'),
        dataIndex: 'cidCreateDate',
        key: 'cidCreateDate',
        width: 100,
        sorter: (a, b) => a.cidCreateDate.localeCompare(b.cidCreateDate),
        showSorterTooltip: false,
      },
      {
        title: t('omTodoList.columns.status'),
        dataIndex: 'cidStatusName',
        key: 'cidStatusName',
        width: 100,
        sorter: (a, b) => a.cidStatus - b.cidStatus,
        showSorterTooltip: false,
      },
      {
        title: t('omTodoList.columns.relatedSite'),
        dataIndex: 'cidObjects',
        key: 'cidObjects',
        render: (value) => <>{Array.isArray(value) && value.map((v, vIdx) => <div key={vIdx}>{v}</div>)}</>,
        sorter: (a, b) => {
          const o1 = Array.isArray(a.cidObjects) ? a.cidObjects[0].split('_')[0] : '';
          const o2 = Array.isArray(b.cidObjects) ? b.cidObjects[0].split('_')[0] : '';
          return o1.localeCompare(o2);
        },
        showSorterTooltip: false,
      },
      {
        title: t('omTodoList.columns.requestTime'),
        dataIndex: 'askTime',
        key: 'askTime',
        width: 150,
        sorter: (a, b) => a.askTime.localeCompare(b.askTime),
        showSorterTooltip: false,
      },
      {
        title: t('omTodoList.columns.acceptTime'),
        dataIndex: 'takeTime',
        key: 'takeTime',
        width: 150,
        sorter: (a, b) => a.takeTime.localeCompare(b.takeTime),
        showSorterTooltip: false,
      },
      {
        title: t('omTodoList.columns.requestContent'),
        dataIndex: 'askReason',
        key: 'askReason',
        sorter: (a, b) => a.askReason.localeCompare(b.askReason),
        showSorterTooltip: false,
        render: (value, record) => (
          <>
            {(record.cidAlertComponent || record.cidClientDeviceSeq) && (
              <>
                <b>
                  {t('omTodoList.columns.equipmentLevel')}
                  {record.cidAlertComponent}；
                </b>
                <b>
                  {t('omTodoList.columns.invNumber')}#
                  {record.cidClientDeviceOriginSeq &&
                  record.cidClientDeviceSeq !== record.cidClientDeviceOriginSeq
                    ? `${record.cidClientDeviceSeq} [${record.cidClientDeviceOriginSeq}]`
                    : record.cidClientDeviceSeq}
                </b>
              </>
            )}
            <div>{value}</div>
          </>
        ),
      },
      {
        title: t('omTodoList.columns.requestNumber'),
        dataIndex: 'askSeq',
        key: 'askSeq',
        width: 90,
        sorter: (a, b) => a.askSeq - b.askSeq,
        showSorterTooltip: false,
      },
      {
        title: t('omTodoList.columns.requestor'),
        dataIndex: 'askUserName',
        key: 'askUserName',
        width: 100,
        sorter: (a, b) => a.askUserName.localeCompare(b.askUserName),
        showSorterTooltip: false,
      },
    ],
    [t]
  );

  // 隨 SPV 與 案場選擇來變更重要性
  useEffect(() => {
    async function fetchData() {
      let items = [];
      const queryCompanyIDs =
        !Array.isArray(dataOfFilter?.ownerIDs) || dataOfFilter.ownerIDs.length < 1
          ? []
          : dataOfFilter.ownerIDs;
      const queryObjectIDs =
        !Array.isArray(dataOfFilter?.objectIDs) || dataOfFilter.objectIDs.length < 1
          ? []
          : dataOfFilter.objectIDs;

      const getOmContractLevelList = await QueryOmContractLevelList(user, queryCompanyIDs, queryObjectIDs);

      if (queryCompanyIDs.length > 0 || queryObjectIDs.length > 0) {
        if (getOmContractLevelList.length > 0) {
          items = [...getOmContractLevelList.sort((a, b) => a.key - b.key)];
        } else {
          items = [...listOfDefaultCidLevel];
        }
      } else {
        items = [...getOmContractLevelList.sort((a, b) => a.key - b.key)];
        listOfDefaultCidLevel.forEach((defaultItem) => {
          const exists = getOmContractLevelList.some((item) => item.label === defaultItem.label);

          if (!exists) {
            // 如果不存在，則將 defaultItem 加入到 getOmContractLevelList
            items.push(defaultItem);
          }
        });
      }

      items = items.map((m) => ({
        label: m.label,
        value: m.label,
      }));

      setListOfCidLevel(items.map(SetKeyToArray));
      setDataOfFilter((currentData) => {
        return { ...currentData, cidLevelNames: [] };
      });
    }
    fetchData();
  }, [user, listOfDefaultCidLevel, dataOfFilter.ownerIDs, dataOfFilter.objectIDs]);

  // =================================================================
  // 初始化暫存的篩選條件
  useEffect(() => {
    const data = GetDataOfFilterFromSession();
    if (data) {
      setDataOfFilter(data);
    } else {
      SetDefaultDataOfFilterToSession();
    }
  }, []);

  // 讀取資料
  const handleCidRequestQuery = useCallback(() => {
    const queryConditions = GenerateQueryConditions(null);

    setLoading(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery(
                $timezone: String!
                $ownerIDs: [String]
                $objectIDs: [String]
                $dateType: Int
                $startDate: String
                $endDate: String
                $omFormID: String
                $omSourceIDs: [Int]
                $omActionIDs: [Int]
                $omUserInChargeIDs: [String]
                $cidFormID: String
                $cidLevelNames: [String],
                $cidProblemCategoryIDs: [Int]
             ) { 
                om_getOmPendingList(
                  timezone: $timezone
                  ownerIDs: $ownerIDs
                  objectIDs: $objectIDs
                  dateType: $dateType
                  startDate: $startDate
                  endDate: $endDate
                  omFormID: $omFormID
                  omSourceIDs: $omSourceIDs
                  omActionIDs: $omActionIDs
                  omUserInChargeIDs: $omUserInChargeIDs
                  cidFormID: $cidFormID
                  cidLevelNames: $cidLevelNames
                  cidProblemCategoryIDs: $cidProblemCategoryIDs
                ) {
                  cidFormID
                  cidStatus
                  cidStatusName
                  cidLevel
                  cidLevelName
                  cidObjects
                  cidCreateDate
                  cidAlertComponent
                  cidClientDeviceSeq
                  cidClientDeviceOriginSeq
                  askSeq
                  askTime
                  askReason
                  askUserName
                  takeTime
                  omFormList
                }
              }`
          ),
          variables: { timezone: appConfigs.timezone, ...queryConditions },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.error(t('omTodoList.failedToQueryPendingList', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        //
        const resData = res.data.data.om_getOmPendingList || [];
        const waitData = resData
          .filter((f) => f.cidStatus === CidStatusConstant.REQUEST_SEND)
          .map((m, mIdx) => {
            m.key = mIdx;

            if (Array.isArray(m.omFormList)) {
              m.omFormList = m.omFormList.map((item) => JsonStringToFormObject(item));
            }

            return m;
          });
        const todoData = resData
          .filter((f) => f.cidStatus !== CidStatusConstant.REQUEST_SEND)
          .map((m) => ({
            ...m,
            omFormList: _.chain(m.omFormList)
              .map((fm, fmIdx) => {
                fm = JsonStringToFormObject(fm);
                fm.key = fmIdx;
                return fm;
              })
              .sortBy(['cidAskSeq', 'omFormID'])
              .value(),
          }));

        //
        setRecordOfOmWait(waitData);
        setRecordOfOmTodo(todoData);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omTodoList.queryPendingListError'));
          console.error('load om todo list error: ', err);
        }
      })
      .then(() => {
        setLoading(false);
      });

    //
  }, [user, t]);
  const handleNonRequestOmQuery = useCallback(() => {
    if (!Array.isArray(listOfOmSource) || listOfOmSource.length === 0) {
      return;
    }

    //
    const queryConditions = GenerateQueryConditions(tableOmListInfo);

    setLoading(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery(
                $timezone: String!
                $page: Int!
                $pageSize: Int!
                $sortColumnName: String
                $sortDirection: String
                $omStatusIDs: [Int]
                $ownerIDs: [String]
                $objectIDs: [String]
                $dateType: Int
                $startDate: String
                $endDate: String
                $omFormID: String
                $omSourceIDs: [Int]
                $omActionIDs: [Int]
                $omUserInChargeIDs: [String]
                $cidProblemCategoryIDs: [Int]
              ) { 
                om_getOmFormList(
                  timezone: $timezone
                  page: $page
                  pageSize: $pageSize
                  sortColumnName: $sortColumnName
                  sortDirection: $sortDirection
                  omStatusIDs: $omStatusIDs
                  ownerIDs: $ownerIDs
                  dateType: $dateType
                  startDate: $startDate
                  endDate: $endDate
                  objectIDs: $objectIDs
                  omFormID: $omFormID
                  omSourceIDs: $omSourceIDs
                  omActionIDs: $omActionIDs
                  omUserInChargeIDs: $omUserInChargeIDs
                  nonCidFlag: true
                  cidProblemCategoryIDs: $cidProblemCategoryIDs
                ) {
                  total
                  list {
                    omFormID
                    omStatus
                    omStatusName
                    omStartTime
                    omEndTime
                    omSourceName
                    omObjectName
                    omObjectRegion
                    omUserInChargeName
                    omActionName 
                    omProcessingItem
                    omWorkContent
                    omRecommend
                    omComponents { componentName amount }
                    omNextFormID  
                    omOwnerName                     
                  }
                }
              }
            `
          ),
          variables: {
            omStatusIDs: [
              OmStatusConstant.REQUEST_PREPARATION,
              OmStatusConstant.PLANNED,
              OmStatusConstant.PROCESSING,
              OmStatusConstant.ISSUE_OBSERVE,
              OmStatusConstant.ISSUE_ONGOING,
            ],
            timezone: appConfigs.timezone,
            ...queryConditions,
          },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.error(
            t('omTodoList.failedToQueryNonRequestList', { error: GetAppSyncRspsErrorMessage(res) })
          );
          return;
        }

        const tmp = res.data.data.om_getOmFormList;

        for (let i = 0; i < tmp.list.length; i++) {
          tmp.list[i].key = i;
          tmp.list[i].nextHighlightOmID = tmp.list[i + 1]?.omFormID;
        }

        setRecordOfOmList(tmp);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omTodoList.queryNonRequestListError'));
          console.error('load om form list error: ', err);
        }
      })
      .then(() => {
        setLoading(false);
      });
  }, [user, listOfOmSource, tableOmListInfo, t]);

  // 開啟畫面時，系統預設載入資料
  useEffect(() => {
    const sessionTabKey = sessionStorage.getItem('om-todo-list-tabKey');
    if (sessionTabKey) {
      setTabkey(sessionTabKey);
      if (sessionTabKey === 'omlist') {
        handleNonRequestOmQuery();
        return;
      }
    }
    handleCidRequestQuery();
  }, [handleCidRequestQuery, handleNonRequestOmQuery]);

  // 設定畫面效果 scroll to record, highlight record.
  useEffect(() => {
    if (tabkey === 'todo') {
      if (rowRefsForTodo.current[tableTodoExpandCidFormID]) {
        rowRefsForTodo.current[tableTodoExpandCidFormID].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }

    if (tabkey === 'omlist') {
      const rowKey = tableOmListHighlightOmFormIDs[1] || tableOmListHighlightOmFormIDs[0];
      if (rowRefsForOmList.current[rowKey]) {
        rowRefsForOmList.current[rowKey].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [tabkey, tableTodoExpandCidFormID, tableOmListHighlightOmFormIDs, recordOfOmList]);
  useEffect(() => {
    const trElements = document.querySelectorAll('.om-omlist-table tr');
    if (trElements.length > 0) {
      trElements.forEach((t) => {
        if (t.style.backgroundColor) t.style.backgroundColor = '';
      });
    }

    const elements = document.querySelectorAll('.ant-table-row-highlight');
    if (elements.length > 0) {
      elements[0].style.backgroundColor = '#fff9df';
    }
  }, [tabkey, recordOfOmList]);

  // =================================================================
  // filter control.
  const handleDataOfFilterChange = (name) => (e) => {
    const modifyValue = e && e.target ? e.target.value : e;
    const tmp = { ...dataOfFilter };
    tmp[name] = modifyValue;

    if (name === 'dateType' && !modifyValue) {
      tmp.startDate = null;
      tmp.endDate = null;
    }

    setDataOfFilter(tmp);
  };
  const handleSetAsDefault = () => {
    SetDefaultDataOfFilterToSession();
    setDataOfFilter({ ...INITIAL_FILTER_DATA });
  };
  const handleSearch = () => {
    if ((dataOfFilter.startDate || dataOfFilter.endDate) && !dataOfFilter.dateType) {
      message.warn(t('omTodoList.selectDateTypeFirst'));
      return;
    }
    SetDataOfFilterToSession(dataOfFilter);

    // 判斷 tabKey 來決定如何更新資料
    if (tabkey === 'omlist') {
      setTableOmListInfo((prev) => ({ ...prev, page: 1 })); // 回到第一頁查詢
    } else {
      handleCidRequestQuery();
    }
  };

  // tab control.
  const handleTabChange = (value) => {
    setTabkey(value);
    window.sessionStorage.setItem('om-todo-list-tabKey', value);

    if (value === 'omlist' && recordOfOmList.length === 0) {
      handleNonRequestOmQuery();
    }
    if (value !== 'omlist' && recordOfOmWait.length === 0 && recordOfOmTodo.length === 0) {
      handleCidRequestQuery();
    }
  };

  // Todo table control.
  const handleExpandForTableTodo = useCallback(
    (expanded, record) => {
      setTableTodoExpandCidFormID(expanded ? record.cidFormID : '');
    },
    [setTableTodoExpandCidFormID]
  );
  const handleChangeForTableTodo = (pagination, filters, sorter) => {
    const item = { columnKey: sorter.field || '', order: sorter.order || '' };
    setTableTodoSortInfo(item);
  };

  // OmList table control.
  const handleTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'paginate') {
      setTableOmListInfo((prev) => ({
        ...prev,
        page: pagination.current,
      }));
    }

    if (extra.action === 'sort') {
      if (sorter.field === 'omObjectRegion') {
        sorter.field = 'omObjectRegionSeq';
      }
      setTableOmListInfo((prev) => ({
        ...prev,
        sortColumn: sorter.field,
        sortDirection: sorter.order,
      }));
    }
  };

  // =================================================================
  const handleOpenOmForm = useCallback(
    (_cidFormId, _omFormId, _originOmFormId, _keepOmFormIDInOmList) => () => {
      if (_keepOmFormIDInOmList) {
        setTableOmListHighlightOmFormIDs(_keepOmFormIDInOmList.split(','));
      }

      navigate(`/oms/${_omFormId || t('omTodoList.autoNumber')}`, {
        state: {
          isNew: _omFormId ? false : true,
          originOmFormId: _originOmFormId,
          cidFormId: _cidFormId,
          redirectUrl: '/omtodo',
        },
      });
    },
    [navigate, setTableOmListHighlightOmFormIDs, t]
  );
  const handleRequestReceive = useCallback(
    (cidId) => () => {
      if (!cidId) return;

      //
      axios
        .post(
          `${appConfigs.apiGatewayURL}/oms/om/receivedRequest`,
          { cidFormID: cidId },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (!IsValidApiGatewayRsps(res)) {
            message.warn(t('omTodoList.failedToReceiveRequest', { error: res?.data?.msg }));
            return;
          }
          handleCidRequestQuery();
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            message.error('接收委託異常');
            console.error('接收委託異常', err);
          }
        });
    },
    [user, handleCidRequestQuery, t]
  );
  const handleRequestComplete = useCallback(
    (cidId, nextExpandCidFormID) => () => {
      if (!cidId) return;

      //
      axios
        .post(
          `${appConfigs.apiGatewayURL}/oms/om/completeRequest`,
          { cidFormID: cidId },
          { headers: { Authorization: user.token } }
        )
        .then((res) => {
          if (!IsValidApiGatewayRsps(res)) {
            message.warn(t('omTodoList.failedToCompleteRequest', { error: res?.data?.msg }));
            return;
          }
          handleCidRequestQuery();
          setTableTodoExpandCidFormID(nextExpandCidFormID || '');
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            message.error(t('omTodoList.completeRequestError'));
            console.error('完成委託異常', err);
          }
        });
    },
    [user, handleCidRequestQuery, setTableTodoExpandCidFormID, t]
  );
  const handleRejectModalOpen = useCallback(
    (cidId) => () => {
      setRejectModalProps({ open: true, cidFormID: cidId });
    },
    []
  );
  const handleRejectModalClose = () => {
    setRejectModalProps({ open: false, cidFormID: '' });
    setRejectReason({ value: '', msg: '' });
  };
  const handleRejectReasonChange = (e) => {
    setRejectReason({ value: e.target.value, msg: '' });
  };
  const handleRequestReject = () => {
    if (!rejectModalProps.cidFormID) return;
    if (!rejectReason.value) {
      setRejectReason({ value: '', msg: t('omTodoList.pleaseFillRejectReason') });
      return;
    }

    //
    axios
      .post(
        `${appConfigs.apiGatewayURL}/oms/om/rejectRequest`,
        { cidFormID: rejectModalProps.cidFormID, reason: rejectReason.value },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('omTodoList.failedToRejectRequest', { error: res?.data?.msg }));
          return;
        }
        handleCidRequestQuery();
        handleRejectModalClose();
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omTodoList.rejectRequestError'));
          console.error('拒絕委託異常', err);
        }
      });
  };

  // =================================================================
  // 委託待收列表-欄位
  const columns_wait = useMemo(() => {
    return [
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: 80,
        render: (value, record) => (
          <Space>
            <Tooltip title={t('omTodoList.receiveRequest')}>
              <Popconfirm
                placement="topLeft"
                title={t('omTodoList.confirmReceiveRequest')}
                okText={t('omTodoList.confirmReceiveRequestText')}
                cancelText={t('omTodoList.cancel')}
                onConfirm={handleRequestReceive(record.cidFormID)}
              >
                <Button type="text" size="small" icon={<AuditOutlined />} />
              </Popconfirm>
            </Tooltip>
            <Tooltip title={t('omTodoList.rejectRequest')}>
              <Button
                type="text"
                size="small"
                icon={<StopOutlined />}
                onClick={handleRejectModalOpen(record.cidFormID)}
              />
            </Tooltip>
          </Space>
        ),
      },
      ..._.cloneDeep(BASIC_CID_TABLE_COLUMNS.filter((c) => c.key !== 'takeTime')),
    ];
  }, [handleRequestReceive, handleRejectModalOpen, BASIC_CID_TABLE_COLUMNS, t]);

  // 委託處理列表-欄位
  const columns_todo = useMemo(() => {
    return [
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: 80,
        render: (value, record) => (
          <Space>
            <Tooltip title={t('omTodoList.addRequestOm')}>
              <Button
                size="small"
                icon={<FileAddOutlined />}
                onClick={handleOpenOmForm(record.cidFormID, null, null)}
              />
            </Tooltip>
            <Tooltip title={t('omTodoList.completeRequest')}>
              <Popconfirm
                placement="topLeft"
                title={t('omTodoList.confirmCompleteRequest')}
                okText={t('omTodoList.confirmCompleteRequestText')}
                cancelText={t('omTodoList.cancel')}
                onConfirm={handleRequestComplete(record.cidFormID, record.nextExpandCidFormID)}
              >
                <Button size="small" icon={<CheckOutlined />} />
              </Popconfirm>
            </Tooltip>
          </Space>
        ),
      },
      Table.EXPAND_COLUMN,
      ..._.cloneDeep(BASIC_CID_TABLE_COLUMNS).map((m, mIdx) => {
        if (m.key === tableTodoSortInfo.columnKey) {
          m.sortOrder = tableTodoSortInfo.order;
        }
        return m;
      }),
    ];
  }, [handleOpenOmForm, handleRequestComplete, tableTodoSortInfo, BASIC_CID_TABLE_COLUMNS, t]);

  // 處理列表-欄位
  const columns_omlist = useMemo(() => {
    return [
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: 40,
        render: (value, omRecord) => (
          <Space>
            {user.operations.includes('OMS_edit-om-form') && ShouldDisplayEditOmButton(omRecord.omStatus) && (
              <Tooltip title={t('omTodoList.editOmForm')}>
                <Button
                  size="small"
                  icon={<EditOutlined />}
                  onClick={handleOpenOmForm(
                    null,
                    omRecord.omFormID,
                    null,
                    `${omRecord.omFormID},${omRecord.nextHighlightOmID || ''}`
                  )}
                />
              </Tooltip>
            )}
            {user.operations.includes('OMS_edit-om-form') &&
              ShouldDisplayFollowUpOmButton(omRecord.omStatus) && (
                <Tooltip title={t('omTodoList.followUpOmForm')}>
                  <Button
                    size="small"
                    icon={<FileAddOutlined />}
                    onClick={handleOpenOmForm(
                      null,
                      null,
                      omRecord.omFormID,
                      `${omRecord.omFormID},${omRecord.nextHighlightOmID || ''}`
                    )}
                  />
                </Tooltip>
              )}
          </Space>
        ),
      },
      {
        title: t('omTodoList.omFormID'),
        dataIndex: 'omFormID',
        key: 'omFormID',
        width: 90,
        sorter: true,
        showSorterTooltip: false,
        render: (value, record) => (
          <>
            <Link to={`/oms/${value}`} target="_blank">
              {value}
            </Link>
            <span className="text-light text-small">{record.omNextFormID}</span>
          </>
        ),
      },
      {
        title: t('omTodoList.omStatus'),
        dataIndex: 'omStatusName',
        key: 'omStatusName',
        width: 130,
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('omTodoList.omStartTime'),
        dataIndex: 'omStartTime',
        key: 'omStartTime',
        width: 150,
        sorter: true,
        showSorterTooltip: false,
        render: (value, record) => (
          <div>
            <div>{record.omStartTime}</div>
            <div>{record.omEndTime}</div>
          </div>
        ),
      },
      {
        title: 'SPV',
        dataIndex: 'omOwnerName',
        key: 'omOwnerName',
        width: 85,
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('omTodoList.source'),
        dataIndex: 'omSourceName',
        key: 'omSourceName',
        width: 85,
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('omTodoList.relatedSite'),
        dataIndex: 'omObjectName',
        key: 'omObjectName',
        width: 180,
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('omTodoList.region'),
        dataIndex: 'omObjectRegion',
        key: 'omObjectRegion',
        width: 60,
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('omTodoList.userInCharge'),
        dataIndex: 'omUserInChargeName',
        key: 'omUserInChargeName',
        width: 105,
        render: (value) => (Array.isArray(value) ? value.join(', ') : null),
      },
      {
        title: t('omTodoList.action'),
        dataIndex: 'omActionName',
        key: 'omActionName',

        width: 100,
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('omTodoList.processingItem'),
        dataIndex: 'omProcessingItem',
        key: 'omProcessingItem',
        width: 300,
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('omTodoList.workContent'),
        dataIndex: 'omWorkContent',
        key: 'omWorkContent',
        sorter: true,
        showSorterTooltip: false,
        render: (value, record) => (
          <>
            <b>{record.omWorkContent}</b>
            <div>
              <span className="text-light text-small">{t('omTodoList.recommend')}: </span>
              <span>{record.omRecommend}</span>
            </div>
            <div>
              <span className="text-light text-small">{t('omTodoList.componentChange')}: </span>
              <span>
                {!Array.isArray(record.omComponents) || record.omComponents.length === 0
                  ? t('omTodoList.no')
                  : record.omComponents.map((m) => `${m.componentName} ${m.amount}pcs`).join(', ')}
              </span>
            </div>
          </>
        ),
      },
    ].map((m) => {
      if (m.key === tableOmListInfo.sortColumn) {
        m.sortOrder = tableOmListInfo.sortDirection;
      }
      return m;
    });
  }, [user, tableOmListInfo, handleOpenOmForm, t]);

  // 待處理列表-展開欄位
  const expandedRowRender = (record) => {
    const columns = [
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: 40,
        render: (value, omRecord) => (
          <Space>
            {user.operations.includes('OMS_edit-om-form') && ShouldDisplayEditOmButton(omRecord.status) && (
              <Tooltip title={t('omTodoList.editOmForm')}>
                <Button
                  size="small"
                  icon={<EditOutlined />}
                  onClick={handleOpenOmForm(record.cidFormID, omRecord.omFormID, null)}
                />
              </Tooltip>
            )}
            {user.operations.includes('OMS_edit-om-form') &&
              ShouldDisplayFollowUpOmButton(omRecord.status) && (
                <Tooltip title={t('omTodoList.followUpOmForm')}>
                  <Button
                    size="small"
                    icon={<FileAddOutlined />}
                    onClick={handleOpenOmForm(record.cidFormID, null, omRecord.omFormID)}
                  />
                </Tooltip>
              )}
          </Space>
        ),
      },
      {
        title: t('omTodoList.cidAskSeq'),
        dataIndex: 'cidAskSeq',
        key: 'cidAskSeq',
        width: 75,
      },
      {
        title: t('omTodoList.omFormID'),
        dataIndex: 'omFormID',
        key: 'omFormID',
        width: 80,
        render: (value, item) => (
          <>
            <Link to={`/oms/${value}`} target="_blank">
              {value}
            </Link>
            <span className="text-light text-small">{item.nextOmFormID}</span>
          </>
        ),
      },
      {
        title: t('omTodoList.status'),
        dataIndex: 'statusName',
        key: 'statusName',
        width: 130,
      },
      {
        title: t('omTodoList.omStartTime'),
        dataIndex: 'startTime',
        key: 'startTime',
        width: 150,
        render: (value, record) => (
          <>
            <div>{record.startTime}</div>
            <div>{record.endTime}</div>
          </>
        ),
      },
      {
        title: t('omTodoList.userInCharge'),
        dataIndex: 'userInChargeName',
        key: 'userInChargeName',
        width: 100,
        render: (value) => value && value.split(',').map((m, mIdx) => <div key={mIdx}>{m}</div>),
      },
      {
        title: t('omTodoList.site'),
        dataIndex: 'objectName',
        key: 'objectName',
        width: 150,
      },
      {
        title: t('omTodoList.processingItem'),
        dataIndex: 'processingItem',
        key: 'processingItem',
      },
      {
        title: t('omTodoList.workContent'),
        dataIndex: 'workContent',
        key: 'workContent',
      },
      {
        title: t('omTodoList.recommend'),
        dataIndex: 'recommend',
        key: 'recommend',
      },
    ];

    return <Table columns={columns} dataSource={record.omFormList} pagination={false} />;
  };

  // -----------------------------------------------------------------

  const recordOfOmTodoAfterSort = useMemo(() => {
    // 整理出資料清單 (排序)
    let list = [];
    if (tableTodoSortInfo.columnKey) {
      list = _.chain(recordOfOmTodo)
        .cloneDeep()
        .sort(columns_todo.find((f) => f.key === tableTodoSortInfo.columnKey).sorter)
        .value();

      if (tableTodoSortInfo.order === 'descend') {
        list = _.reverse(list);
      }
    } else {
      list = _.cloneDeep(recordOfOmTodo);
    }

    // 增加所需的特定資料
    for (let i = 0; i < list.length; i++) {
      list[i].key = i;
      list[i].nextExpandCidFormID = list[i + 1]?.cidFormID;
    }

    return list;
  }, [recordOfOmTodo, columns_todo, tableTodoSortInfo]);
  const recordOfOmTodoExpandRowKey = useMemo(() => {
    const item = recordOfOmTodoAfterSort.find((f) => f.cidFormID === tableTodoExpandCidFormID);
    if (item) return item.key;
    return -1;
  }, [recordOfOmTodoAfterSort, tableTodoExpandCidFormID]);

  // =================================================================
  return (
    <>
      {/* page header */}
      <PageHeader
        className="app-page-header"
        title={t('omTodoList.title')}
        footer={
          <Tabs
            activeKey={tabkey}
            onChange={handleTabChange}
            items={[
              { label: t('omTodoList.pendingRequestList'), key: 'wait' },
              { label: t('omTodoList.processingRequestList'), key: 'todo' },
              { label: t('omTodoList.nonRequestList'), key: 'omlist' },
            ]}
          />
        }
      />
      {/* 搜尋面板 */}
      <div className="app-page-searchpanel">
        <Collapse onChange={() => setCollaps((val) => !val)}>
          <Panel header={t('omTodoList.filterConditions')} key="1">
            <Row gutter={[8, 8]}>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('omTodoList.spv')}
                  options={listOfPvOwner}
                  value={dataOfFilter.ownerIDs}
                  onChange={handleDataOfFilterChange('ownerIDs')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  placeholder={t('omTodoList.dateType')}
                  options={listOfDateType}
                  value={dataOfFilter.dateType}
                  onChange={handleDataOfFilterChange('dateType')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeDatePicker
                  placeholder={t('omTodoList.startDate')}
                  value={dataOfFilter.startDate}
                  onChange={handleDataOfFilterChange('startDate')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeDatePicker
                  placeholder={t('omTodoList.endDate')}
                  value={dataOfFilter.endDate}
                  onChange={handleDataOfFilterChange('endDate')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('omTodoList.relatedSite')}
                  options={listOfPvByOwner}
                  value={dataOfFilter.objectIDs}
                  onChange={handleDataOfFilterChange('objectIDs')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeInput
                  placeholder={t('omTodoList.omFormID')}
                  value={dataOfFilter.omFormID}
                  onChange={handleDataOfFilterChange('omFormID')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('omTodoList.omSource')}
                  options={listOfOmSource}
                  value={dataOfFilter.omSourceIDs}
                  onChange={handleDataOfFilterChange('omSourceIDs')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('omTodoList.omAction')}
                  options={listOfOmAction}
                  value={dataOfFilter.omActionIDs}
                  onChange={handleDataOfFilterChange('omActionIDs')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('omTodoList.omUserInCharge')}
                  options={listOfOmUser}
                  value={dataOfFilter.omUserInChargeIDs}
                  onChange={handleDataOfFilterChange('omUserInChargeIDs')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeInput
                  placeholder={t('omTodoList.cidFormID')}
                  value={dataOfFilter.cidFormID}
                  onChange={handleDataOfFilterChange('cidFormID')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('omTodoList.cidLevel')}
                  options={listOfCidLevel}
                  value={dataOfFilter.cidLevelNames}
                  onChange={handleDataOfFilterChange('cidLevelNames')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('omTodoList.cidProblemCategory')}
                  options={listOfCidProblemCategory}
                  value={dataOfFilter.cidProblemCategoryIDs}
                  onChange={handleDataOfFilterChange('cidProblemCategoryIDs')}
                />
              </Col>
              <Col xs={12} sm={12} md={6} lg={{ span: 3, offset: 18 }} xl={{ span: 3, offset: 18 }}>
                <Button block loading={loading} onClick={handleSetAsDefault}>
                  {t('omTodoList.reset')}
                </Button>
              </Col>
              <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                <Button type="primary" block loading={loading} onClick={handleSearch}>
                  {t('omTodoList.search')}
                </Button>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </div>
      <div className="app-page-content">
        {/* 委託待收列表 */}
        {tabkey === 'wait' && (
          <Card size="small">
            <Spin spinning={loading} tip={t('omTodoList.searching')}>
              <Table
                size="small"
                pagination={false}
                columns={columns_wait}
                dataSource={recordOfOmWait}
                scroll={{ x: 1500, y: collaps ? `calc(100vh - 420px)` : `calc(100vh - 585px)` }}
              />
            </Spin>
          </Card>
        )}

        {/* 委託處理列表 */}
        {tabkey === 'todo' && (
          <Card size="small">
            <Spin spinning={loading} tip={t('omTodoList.searching')}>
              <Table
                size="small"
                pagination={false}
                columns={columns_todo}
                dataSource={recordOfOmTodoAfterSort}
                expandable={{
                  expandedRowRender,
                  expandedRowKeys: [recordOfOmTodoExpandRowKey],
                  onExpand: handleExpandForTableTodo,
                }}
                scroll={{ x: 1500, y: collaps ? `calc(100vh - 380px)` : `calc(100vh - 565px)` }}
                onRow={(record) => ({ ref: (el) => (rowRefsForTodo.current[record.cidFormID] = el) })}
                onChange={handleChangeForTableTodo}
              />
            </Spin>
          </Card>
        )}

        {/* 無委託處理列表 */}
        {tabkey === 'omlist' && (
          <Card size="small">
            {user.operations.includes('OMS_insert-om-form') && (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleOpenOmForm(null, null, null)}
                style={{ marginBottom: '8px' }}
              >
                {t('omTodoList.addNonRequestOm')}
              </Button>
            )}
            <Spin spinning={loading} tip={t('omTodoList.searching')}>
              <Table
                className="om-omlist-table"
                size="small"
                columns={columns_omlist}
                dataSource={recordOfOmList.list}
                pagination={{
                  total: recordOfOmList.total,
                  pageSize: tableOmListInfo.pageSize,
                  showSizeChanger: false,
                  current: tableOmListInfo.page,
                }}
                rowClassName={(record, index) => {
                  return tableOmListHighlightOmFormIDs.includes(record.omFormID)
                    ? 'ant-table-row-highlight'
                    : '';
                }}
                onChange={handleTableChange}
                onRow={(record) => ({ ref: (el) => (rowRefsForOmList.current[record.omFormID] = el) })}
                scroll={{ x: 1500, y: collaps ? `calc(100vh - 480px)` : `calc(100vh - 665px)` }}
              />
            </Spin>
          </Card>
        )}
      </div>
      {/* 跳出視窗-拒絕委託 */}
      <RfdmeModal open={rejectModalProps.open}>
        <Row>
          <Col span={24}>
            <RfdmeTextArea
              label={t('omTodoList.rejectReason')}
              rows={5}
              value={rejectReason.value}
              msg={rejectReason.msg}
              onChange={handleRejectReasonChange}
            />
          </Col>
        </Row>
        <Divider />
        <Row justify="end" gutter={[8, 0]}>
          <Col>
            <Button onClick={handleRejectModalClose}>{t('omTodoList.close')}</Button>
          </Col>
          <Col>
            <Button type="primary" onClick={handleRequestReject}>
              {t('omTodoList.confirmReject')}
            </Button>
          </Col>
        </Row>
      </RfdmeModal>
    </>
  );
};

export default OmTodoList;
