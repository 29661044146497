import React from 'react';
import { InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import ComponentLayout from '../../layout/componentLayout';

const RfdmeInputNumber = ({ required, label, msg, ...props }) => {
  const { t } = useTranslation();

  return (
    <ComponentLayout label={label} required={required} msg={msg}>
      <InputNumber
        style={{ width: '100%' }}
        placeholder={label && `${t('pleaseEnter')} ${label}`}
        {...props}
      />
    </ComponentLayout>
  );
};

export default RfdmeInputNumber;
