import React from 'react';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';

const PageUnauthorized = () => {
  const { t } = useTranslation();
  
  return <Result status="403" title={t('pageUnauthorized.title')} subTitle={t('pageUnauthorized.subTitle')} />;
};

export default PageUnauthorized;
