import React, { useState, useMemo, useEffect, useCallback, useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import bn from 'bignumber.js';
import styled from 'styled-components';
//
import RfdmeModal from '../../components/Modal';
import RfdmeDisplay from '../../components/Display';
import RfdmeInput from '../../components/Input';
import RfdmeColorPicker from '../../components/ColorPicker';
import RfdmeUpload from '../../components/Upload';
import {
  Row,
  Col,
  Button,
  Tooltip,
  Tabs,
  Divider,
  message,
  Typography,
  Space,
  Table,
  PageHeader,
  Card,
  Collapse,
  Switch,
  Spin,
  Popconfirm,
} from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, UndoOutlined } from '@ant-design/icons';
import RfdmeSelect from '../../components/Select';
import RfdmeCheckbox from '../../components/Checkbox';
//
import appConfigs from '../../configs';
import useListOfOmSource from '../../hooks/useListOfOmSource';
import useListOfOmTraffic from '../../hooks/useListOfOmTraffic';
import useListOfOmComponent from '../../hooks/useListOfOmComponent';
import useListOfOmAction from '../../hooks/useListOfOmAction';
import useListOfOmObject from '../../hooks/useListOfOmObject';
import useListOfOmGroup from '../../hooks/useListOfOmGroup';
import useListOfPv from '../../hooks/useListOfPv';
import useListOfPvOwner from '../../hooks/useListOfPvOwner';
import useConfigOfSystem from '../../hooks/useConfigOfSystem';
import useListOfNotifyKind from '../../hooks/useListOfNotifyKind';
//
import AuthContext from '../../contexts/authProvider';
import {
  ValidFormData,
  IsValidApiGatewayRsps,
  IsValidAppSyncRsps,
  IsEmail,
  IsPhoneNumber,
} from '../../utils/valid';
import { AppSyncQueryTextFormat, SetKeyToArray } from '../../utils/format';
import { GetAppSyncRspsErrorMessage } from '../../utils/parse';
import { useTranslation } from 'react-i18next';

//
const { Title } = Typography;
const { Panel } = Collapse;
const ResultTitle = styled.div`
  font-weight: bold;
`;

//設定
const INITIAL_OBJECT_FILTER_DATA = {};
const INIT_TABLE_INFO = { sortColumn: null, sortDirection: 'ascend', page: 1, pageSize: 10 };

const GenerateQueryObjectConditions = (tableInfo) => {
  //從 session storage 取出查詢條件
  const conditions = JSON.parse(window.sessionStorage.getItem('object-group-list-filterData'));

  //重組查詢參數
  let queryConditions = {};
  queryConditions.region = conditions.region;
  queryConditions.ownerID = conditions.ownerID;
  if (tableInfo) {
    queryConditions.page = tableInfo.page;
    queryConditions.pageSize = tableInfo.pageSize;
    queryConditions.sortColumnName = tableInfo.sortColumn;
    queryConditions.sortDirection = tableInfo.sortDirection === 'ascend' ? 'asc' : 'desc';
  }

  return queryConditions;
};

//
const OmConfig = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const [dataOfConfig, setDataOfConfig] = useState({});
  const [dataOfTabType, setDataOfTabType] = useState({ type: 'source', typeName: '來源項目' });
  const [tableInfoOfDataList, setTableInfoOfDataList] = useState({ type: 'source', status: true });
  const { data: listOfSource, refetch: loadSource } = useListOfOmSource();
  const { data: listOfTraffic, refetch: loadTraffic } = useListOfOmTraffic();
  const { data: listOfComponent, refetch: loadComponent } = useListOfOmComponent();
  const { data: listOfAction, refetch: loadAction } = useListOfOmAction();
  const { data: listOfObject, refetch: loadObject } = useListOfOmObject();
  const { data: listOfGroup, refetch: loadGropup } = useListOfOmGroup();
  const { data: systemConfigs } = useConfigOfSystem();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [objectFilterData, setObjectFilterData] = useState(INITIAL_OBJECT_FILTER_DATA);
  const [objectTableInfo, setObjectTableInfo] = useState(INIT_TABLE_INFO);
  const { data: pvList } = useListOfPv();
  const { data: pvOwnerList } = useListOfPvOwner();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [dataOfUser, setDataOfUser] = useState({});
  const [tableInfoOfUserList, setTableInfoOfUserList] = useState({ ...INIT_TABLE_INFO, status: true });
  const [tableObjectID, setTableObjectID] = useState(null);
  const [objectDataList, setObjectDataList] = useState({ total: 0, list: [] });
  const { data: listOfNotifyKind } = useListOfNotifyKind();
  const [filteredDataList, setFilteredDataList] = useState(listOfSource);
  //
  const [modalOpen, setModalOpen] = useState(false);

  const pvRegionList = useMemo(() => {
    if (!Array.isArray(pvList)) return [];

    return _.chain(pvList)
      .uniqBy('region')
      .filter((f) => f.region?.trim())
      .sortBy('region')
      .map((m, mIdx) => ({ key: mIdx, label: m.region, value: m.region }))
      .value();
  }, [pvList]);

  useEffect(() => {
    if (isFirstLoad) {
      const filterDataStringInSession = window.sessionStorage.getItem('object-group-list-filterData');
      if (filterDataStringInSession) {
        const conditions = JSON.parse(filterDataStringInSession);
        setObjectFilterData(conditions);
      } else {
        window.sessionStorage.setItem(
          'object-group-list-filterData',
          JSON.stringify(INITIAL_OBJECT_FILTER_DATA)
        );
      }

      const tableInfoStringInSession = window.sessionStorage.getItem('object-list-tableInfo');
      if (tableInfoStringInSession) {
        const info = JSON.parse(tableInfoStringInSession);
        setObjectTableInfo(info);
      } else {
        window.sessionStorage.setItem('object-list-tableInfo', JSON.stringify(INIT_TABLE_INFO));
      }

      setIsFirstLoad(false);
    }
  }, [isFirstLoad]);

  //=================================================================
  const handleModalOpen = useCallback(
    (record) => () => {
      setDataOfConfig(record);
      setModalOpen(true);
    },
    []
  );
  const handleModalClose = () => {
    setDataOfConfig({});
    setModalOpen(false);
  };

  //=================================================================
  const handleDataOfTabTypeChange = (activeKey) => {
    if (!activeKey) return;

    let t = '';
    let tName = '';

    switch (activeKey) {
      case 'source':
        t = 'source';
        tName = '來源項目';
        break;
      case 'traffic':
        t = 'traffic';
        tName = '交通方式';
        break;
      case 'component':
        t = 'component';
        tName = '組件更換';
        break;
      case 'action':
        t = 'action';
        tName = '行動';
        break;
      case 'object':
        t = 'object';
        tName = '對象';
        break;
      case 'group':
        t = 'group';
        tName = '維運組別';
        break;
      case 'user':
        t = 'user';
        tName = '人員';
        break;
      case 'objectGroup':
        t = 'objectGroup';
        tName = '案場分區定義';
        break;
      default:
        break;
    }

    setDataOfTabType({ type: t, typeName: tName });
    setTableInfoOfDataList({ type: t, status: true });
  };

  const handleDataOfConfigChange = (name) => (e) => {
    setDataOfConfig((prev) => ({ ...prev, [name]: e && e.target ? e.target.value : e, [`${name}_msg`]: '' }));
  };

  useEffect(() => {
    if (!tableInfoOfDataList.type) {
      return;
    }

    switch (tableInfoOfDataList.type) {
      case 'source':
        if (listOfSource) {
          setFilteredDataList(listOfSource.filter((item) => item.enable === tableInfoOfDataList.status));
        }
        break;
      case 'traffic':
        if (listOfTraffic) {
          setFilteredDataList(listOfTraffic.filter((item) => item.enable === tableInfoOfDataList.status));
        }
        break;
      case 'component':
        if (listOfComponent) {
          setFilteredDataList(listOfComponent.filter((item) => item.enable === tableInfoOfDataList.status));
        }
        break;
      case 'action':
        if (listOfAction) {
          setFilteredDataList(listOfAction.filter((item) => item.enable === tableInfoOfDataList.status));
        }
        break;
      case 'object':
        if (listOfObject) {
          setFilteredDataList(listOfObject.filter((item) => item.enable === tableInfoOfDataList.status));
        }
        break;
      default:
        break;
    }
  }, [tableInfoOfDataList, listOfSource, listOfTraffic, listOfComponent, listOfAction, listOfObject]);

  const handleDataOfConfigBlur = (name) => (e) => {
    const modifyValue = e.target.value;
    switch (name) {
      case 'email':
        if (modifyValue && !IsEmail(modifyValue)) {
          setDataOfConfig((prev) => ({ ...prev, [name + '_msg']: t('omConfig.emailFormatError') }));
        }
        break;
      case 'phone':
        if (modifyValue && !IsPhoneNumber(modifyValue)) {
          setDataOfConfig((prev) => ({ ...prev, [name + '_msg']: t('omConfig.phoneFormatError') }));
        }
        break;
      default:
        break;
    }
  };

  const handleSave = () => {
    // valid
    const validResult = ValidFormData(dataOfConfig, [
      {
        name: 'seq',
        type: 'number',
        required: true,
      },
      { name: 'label', type: 'input', required: true },
    ]);
    if (!validResult.status) {
      setDataOfConfig(validResult.data);
      return;
    }
    // 確認資料是否重複
    let origins = [];
    if (dataOfTabType.type === 'source') origins = listOfSource;
    else if (dataOfTabType.type === 'traffic') origins = listOfTraffic;
    else if (dataOfTabType.type === 'component') origins = listOfComponent;
    else if (dataOfTabType.type === 'action') origins = listOfAction;
    else if (dataOfTabType.type === 'object') origins = listOfObject;
    else if (dataOfTabType.type === 'group') origins = listOfGroup;
    let duplicate = origins.filter(
      (o) => o.value !== dataOfConfig.value && o.label === dataOfConfig.label && o.enable === true
    );
    if (duplicate && duplicate.length) {
      setDataOfConfig((prev) => ({
        ...prev,
        label_msg: t('omConfig.duplicateData'),
      }));
      return;
    }

    //
    const saveData = {};
    saveData.key = dataOfConfig.value;
    saveData.value = dataOfConfig.label;
    if (
      dataOfTabType.type === 'source' ||
      dataOfTabType.type === 'traffic' ||
      dataOfTabType.type === 'component' ||
      dataOfTabType.type === 'action' ||
      dataOfTabType.type === 'object'
    ) {
      saveData.enable = true;
    }
    if (dataOfTabType.type === 'group') {
      saveData.seq = dataOfConfig.seq;
    }
    if (saveData.seq) {
      saveData.seq = bn(dataOfConfig.seq).toNumber();
    }
    if (dataOfConfig.colorCode) {
      saveData.colorCode = dataOfConfig.colorCode;
    }

    //
    let apiUrl = '';
    if (dataOfTabType.type === 'source') apiUrl = '/oms/om/config/source/save';
    if (dataOfTabType.type === 'traffic') apiUrl = '/oms/om/config/traffic/save';
    if (dataOfTabType.type === 'component') apiUrl = '/oms/om/config/component/save';
    if (dataOfTabType.type === 'action') apiUrl = '/oms/om/config/action/save';
    if (dataOfTabType.type === 'object') apiUrl = '/oms/om/config/object/save';
    if (dataOfTabType.type === 'group') apiUrl = '/oms/om/config/group/save';

    //
    axios
      .post(`${appConfigs.apiGatewayURL}${apiUrl}`, saveData, { headers: { Authorization: user.token } })
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('omConfig.failedToSaveConfig', { error: res?.data?.msg }));
          return;
        }

        //reload
        if (dataOfTabType.type === 'source') loadSource();
        if (dataOfTabType.type === 'traffic') loadTraffic();
        if (dataOfTabType.type === 'component') loadComponent();
        if (dataOfTabType.type === 'action') loadAction();
        if (dataOfTabType.type === 'object') loadObject();
        if (dataOfTabType.type === 'group') loadGropup();

        setDataOfConfig({});
        setModalOpen(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omConfig.saveConfigError'));
          console.error('save config error: ', err);
        }
      });
  };

  const handleDataChangeStatus = useCallback(
    (type, record, status) => () => {
      // 確認資料是否重複
      let origins = [];
      if (type === 'source') origins = listOfSource;
      else if (type === 'traffic') origins = listOfTraffic;
      else if (type === 'component') origins = listOfComponent;
      else if (type === 'action') origins = listOfAction;
      else if (type === 'object') origins = listOfObject;

      let duplicate = origins.filter(
        (o) => o.value !== record.value && o.label === record.label && o.enable === true
      );
      if (duplicate && duplicate.length) {
        message.error(t('omConfig.duplicateDataInEnabled', { label: record.label }));
        return;
      }

      //
      const saveData = {};
      saveData.key = record.value;
      saveData.value = record.label;
      saveData.seq = bn(record.seq).toNumber();
      saveData.enable = status;

      //
      let apiUrl = '';
      if (type === 'source') apiUrl = '/oms/om/config/source/save';
      if (type === 'traffic') apiUrl = '/oms/om/config/traffic/save';
      if (type === 'component') apiUrl = '/oms/om/config/component/save';
      if (type === 'action') apiUrl = '/oms/om/config/action/save';
      if (type === 'object') apiUrl = '/oms/om/config/object/save';

      //
      axios
        .post(`${appConfigs.apiGatewayURL}${apiUrl}`, saveData, { headers: { Authorization: user.token } })
        .then((res) => {
          if (!IsValidApiGatewayRsps(res)) {
            message.warn(t('omConfig.failedToSaveConfig', { error: res?.data?.msg }));
            return;
          }

          //reload
          if (type === 'source') loadSource();
          if (type === 'traffic') loadTraffic();
          if (type === 'component') loadComponent();
          if (type === 'action') loadAction();
          if (type === 'object') loadObject();
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            message.error(t('omConfig.saveConfigError'));
            console.error('save config error: ', err);
          }
        });
    },
    [
      user,
      listOfSource,
      listOfTraffic,
      listOfComponent,
      listOfAction,
      listOfObject,
      loadSource,
      loadTraffic,
      loadComponent,
      loadAction,
      loadObject,
      t,
    ]
  );

  const handleColorChange = (color) => {
    setDataOfConfig((prev) => ({ ...prev, colorCode: color }));
  };

  //=================================================================
  const handleObjectFilterDataChange = (name) => (value) => {
    const modifyValue = value && value.target ? value.target.value : value;
    setObjectFilterData((prev) => {
      let newState = {
        ...prev,
        [name]: modifyValue,
      };

      console.info(newState);
      return newState;
    });
  };

  const handleObjectReset = () => {
    setObjectFilterData({ ...INITIAL_OBJECT_FILTER_DATA });
    window.sessionStorage.setItem('object-group-list-filterData', JSON.stringify(INITIAL_OBJECT_FILTER_DATA));
    setObjectTableInfo((tbi) => ({ ...tbi, page: 1 }));
  };

  const handleObjectSearch = () => {
    window.sessionStorage.setItem('object-group-list-filterData', JSON.stringify(objectFilterData));
    setObjectTableInfo((tbi) => ({ ...tbi, page: 1 }));
  };

  const handleQueryObjectGroup = useCallback(() => {
    //
    const queryConditions = GenerateQueryObjectConditions(objectTableInfo);

    //查詢
    setLoading(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery (
               $region: String, 
               $ownerID: String, 
               $page: Int!
               $pageSize: Int!
               $sortColumnName: String
               $sortDirection: String!
             ) { 
               om_getOmObjectGroupList (
                 region: $region,
                 ownerID: $ownerID,
                 page: $page,
                 pageSize: $pageSize,
                 sortColumnName: $sortColumnName,
                 sortDirection: $sortDirection
               ) {
                 total
                 list {
                   objectID
                   objectName
                   companyName
                   region
                   groupID
                   groupName
                   groupColorCode
                 }
               }
             }        
            `
          ),
          variables: queryConditions,
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(
            t('omConfig.failedToQueryObjectGroupList', { error: GetAppSyncRspsErrorMessage(res) })
          );
          return;
        }

        const tmp = res.data.data.om_getOmObjectGroupList;
        tmp.list = tmp.list.map(SetKeyToArray);
        setObjectDataList(tmp);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error('load om object group list error', err);
          message.error(t('omConfig.queryObjectGroupListError'));
        }
      })
      .then(() => {
        setLoading(false);
      });

    //
  }, [user, objectTableInfo, t]);

  useEffect(() => {
    if (!isFirstLoad) handleQueryObjectGroup();
  }, [isFirstLoad, handleQueryObjectGroup]);

  const handleObjectTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'paginate') {
      window.sessionStorage.setItem(
        'object-list-tableInfo',
        JSON.stringify({ ...objectTableInfo, page: pagination.current })
      );
      setObjectTableInfo((tbi) => ({
        ...tbi,
        page: pagination.current,
      }));
    }

    if (extra.action === 'sort') {
      if (sorter.field === 'region') {
        sorter.field = 'regionSeq';
      }
      window.sessionStorage.setItem(
        'object-list-tableInfo',
        JSON.stringify({ ...objectTableInfo, sortColumn: sorter.field, sortDirection: sorter.order })
      );
      setObjectTableInfo((tbi) => ({
        ...tbi,
        sortColumn: sorter.field,
        sortDirection: sorter.order, //"ascend" or "descend"
      }));
    }
  };

  const handleObjectGroupSave = useCallback(
    (objectID, newGroupID) => {
      if (!user || !objectID || !newGroupID) {
        return;
      }

      const saveData = {
        objectID: objectID,
        groupID: newGroupID,
      };

      setTableObjectID(objectID);
      axios
        .post(`${appConfigs.apiGatewayURL}/oms/om/config/objectGroup/save`, saveData, {
          headers: { Authorization: user.token },
        })
        .then((res) => {
          if (!IsValidApiGatewayRsps(res)) {
            message.warn(t('omConfig.failedToUpdateObjectGroup', { objectID, error: res?.data?.msg }));
            return;
          }

          message.success(t('omConfig.updateObjectGroupSuccess', { objectID }));
          handleQueryObjectGroup();
          queryClient.refetchQueries(['query-om-extra-pv-list']);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            message.error(t('omConfig.updateObjectGroupError', { objectID }));
            console.error('change object groupID error: ', err);
          }
        })
        .finally(() => {
          setTableObjectID(null);
        });
    },
    [user, handleQueryObjectGroup, queryClient, t]
  );

  //=================================================================
  const handleOmUserQuery = useCallback(() => {
    if (!user) {
      return;
    }

    const queryConditions = {
      status: tableInfoOfUserList.status,
      page: tableInfoOfUserList.page,
      pageSize: tableInfoOfUserList.pageSize,
      sortColumnName: tableInfoOfUserList.sortDirection && tableInfoOfUserList.sortColumn,
      sortDirection:
        tableInfoOfUserList.sortDirection && tableInfoOfUserList.sortDirection === 'ascend' ? 'asc' : 'desc',
    };
    setLoading(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery(
                $status: Boolean,
                $page: Int,
                $pageSize: Int,
                $sortColumnName: String,
                $sortDirection: String
              ) { 
                om_getOmUserExtensionList(
                  status: $status,
                  page: $page,
                  pageSize: $pageSize,
                  sortColumnName: $sortColumnName,
                  sortDirection: $sortDirection
                ) {
                  total
                  list {
                    userID
                    email
                    userName
                    status
                    phone
                    omGroupID
                    omGroupName
                    certifications {
                      fileID
                      fileName
                    }
                    notifyKind
                  }          
                }
              }
            `
          ),
          variables: queryConditions,
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('omConfig.failedToQueryUserList', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        const tmp = res.data.data.om_getOmUserExtensionList;
        tmp.list = tmp.list.map((m, mIdx) => {
          m.key = m.userID;
          return m;
        });
        setDataOfUser(tmp);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omConfig.queryUserListError'));
          console.error('load om user extension list error: ', err);
        }
      })
      .then(() => {
        setLoading(false);
      });
  }, [user, tableInfoOfUserList, t]);

  useEffect(() => {
    if (dataOfTabType.type !== 'user') {
      return;
    }

    handleOmUserQuery();
  }, [dataOfTabType.type, handleOmUserQuery]);

  const handleTableUserChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'paginate') {
      setTableInfoOfUserList((tbi) => ({
        ...tbi,
        page: pagination.current,
      }));
    }

    if (extra.action === 'sort') {
      console.log(sorter);
      setTableInfoOfUserList((tbi) => ({
        ...tbi,
        sortColumn: sorter.field,
        sortDirection: sorter.order, //"ascend" or "descend"
      }));
    }
  };

  const handleCheckBoxGroupChange = (checkedValues) => {
    setDataOfConfig((prev) => ({ ...prev, notifyKind: checkedValues }));
  };

  const handleUserSave = () => {
    const validResult = ValidFormData(dataOfConfig, [
      { name: 'userName', type: 'input', required: true },
      { name: 'email', type: 'input', required: true },
      { name: 'omGroupID', type: 'select', required: true },
    ]);
    if (!validResult.status) {
      setDataOfConfig(validResult.data);
      return;
    }
    //檢核信箱及電話
    if (dataOfConfig.email && !IsEmail(dataOfConfig.email)) {
      setDataOfConfig((prev) => ({ ...prev, email_msg: t('omConfig.emailFormatError') }));
      return;
    }
    if (dataOfConfig.phone && !IsPhoneNumber(dataOfConfig.phone)) {
      setDataOfConfig((prev) => ({ ...prev, phone_msg: t('omConfig.phoneFormatError') }));
      return;
    }
    //
    const saveData = {
      userID: dataOfConfig.userID,
      email: dataOfConfig.email,
      userName: dataOfConfig.userName,
      phone: dataOfConfig.phone,
      omGroupID: dataOfConfig.omGroupID,
      certifications: dataOfConfig.certifications,
      notifyKind: dataOfConfig.notifyKind,
    };

    setSaving(true);
    axios
      .post(`${appConfigs.apiGatewayURL}/oms/om/user/save`, saveData, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(t('omConfig.failedToSaveUser', { error: res?.data?.msg }));
          return;
        }

        message.success(t('omConfig.saveUserSuccess'));
        setDataOfConfig({});
        setModalOpen(false);
        handleOmUserQuery();
        queryClient.refetchQueries(['query-om-user-list']);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('omConfig.saveUserError'));
          console.error('save om user error: ', err);
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleUserChangeStatus = useCallback(
    (record, status) => () => {
      if (!user || !record.userID || !record.email) {
        return;
      }
      const statusName = status === true ? t('omConfig.enabled') : t('omConfig.disable');

      const saveData = {
        userID: record.userID,
        email: record.email,
        status: status,
      };

      setSaving(true);
      axios
        .post(`${appConfigs.apiGatewayURL}/oms/om/user/changeStatus`, saveData, {
          headers: { Authorization: user.token },
        })
        .then((res) => {
          if (!IsValidApiGatewayRsps(res)) {
            message.warn(
              t('omConfig.failedToChangeUserStatus', { status: statusName, error: res?.data?.msg })
            );
            return;
          }

          message.success(t('omConfig.changeUserStatusSuccess', { status: statusName }));
          handleOmUserQuery();
          queryClient.refetchQueries(['query-om-user-list']);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            message.warn(t('omConfig.changeUserStatusError', { status: statusName }));
            console.error('change om user status error: ', err);
          }
        })
        .finally(() => {
          setSaving(false);
        });
    },
    [user, handleOmUserQuery, queryClient, t]
  );

  //=================================================================
  const columns = useMemo(() => {
    switch (dataOfTabType.type) {
      case 'group':
        return [
          {
            title: '',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: 60,
            render: (value, record) => (
              <Space>
                <Tooltip title={t('omConfig.edit')}>
                  <Button icon={<EditOutlined />} onClick={handleModalOpen(record)} />
                </Tooltip>
              </Space>
            ),
          },
          {
            title: t('omConfig.groupColor'),
            dataIndex: 'colorCode',
            key: 'colorCode',
            align: 'center',
            width: 150,
            render: (value) => <RfdmeColorPicker value={value} size={25} disabled={true} />,
          },
          {
            title: t('omConfig.sequence'),
            dataIndex: 'seq',
            key: 'seq',
            align: 'center',
            width: 85,
          },
          {
            title: t('omConfig.groupName'),
            dataIndex: 'label',
            key: 'label',
          },
        ];
      case 'user':
        return [
          {
            title: '',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: 60,
            render: (value, record) => (
              <Space>
                {record.status === true ? (
                  <Tooltip title={t('omConfig.disable')}>
                    <Popconfirm
                      placement="topLeft"
                      title={t('omConfig.confirmDisableUser')}
                      okText={t('omConfig.confirmDisable')}
                      cancelText={t('omConfig.cancel')}
                      onConfirm={handleUserChangeStatus(record, false)}
                    >
                      <Button type="text" icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                ) : (
                  <Tooltip title={t('omConfig.enable')}>
                    <Popconfirm
                      placement="topLeft"
                      title={t('omConfig.confirmEnableUser')}
                      okText={t('omConfig.confirmEnable')}
                      cancelText={t('omConfig.cancel')}
                      onConfirm={handleUserChangeStatus(record, true)}
                    >
                      <Button type="text" icon={<UndoOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                )}
              </Space>
            ),
          },
          {
            title: t('omConfig.name'),
            dataIndex: 'userName',
            key: 'userName',
            width: 200,
            sorter: true,
            showSorterTooltip: false,
            render: (value, record) => (
              <Button type="link" onClick={handleModalOpen({ ...record, canEdit: record.status })}>
                {value}
              </Button>
            ),
          },
          {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
            width: 350,
            sorter: true,
            showSorterTooltip: false,
          },
          {
            title: t('omConfig.phone'),
            dataIndex: 'phone',
            key: 'phone',
            width: 200,
            sorter: true,
            showSorterTooltip: false,
          },
          {
            title: t('omConfig.status'),
            dataIndex: 'status',
            key: 'status',
            width: 150,
            render: (value) => (value === true ? t('omConfig.enabled') : t('omConfig.disabled')),
          },
          {
            title: t('omConfig.maintenanceGroupName'),
            dataIndex: 'omGroupName',
            key: 'omGroupName',
            sorter: true,
            showSorterTooltip: false,
          },
        ];
      case 'objectGroup':
        return [
          {
            title: t('omConfig.siteId'),
            dataIndex: 'objectID',
            key: 'objectID',
            sorter: true,
            showSorterTooltip: false,
          },
          {
            title: 'SPV',
            dataIndex: 'companyName',
            key: 'companyName',
            sorter: true,
            showSorterTooltip: false,
          },
          {
            title: t('omConfig.site'),
            dataIndex: 'objectName',
            key: 'objectName',
            sorter: true,
            showSorterTooltip: false,
          },
          {
            title: t('omConfig.region'),
            dataIndex: 'region',
            key: 'region',
            sorter: true,
            showSorterTooltip: false,
          },
          {
            title: t('omConfig.groupName'),
            dataIndex: 'groupID',
            key: 'groupID',
            sorter: true,
            showSorterTooltip: false,
            render: (value, record) => (
              <RfdmeSelect
                showSearch
                placeholder={t('omConfig.group')}
                isColor={true}
                options={listOfGroup}
                value={value}
                loading={record.objectID === tableObjectID}
                disabled={record.objectID === tableObjectID}
                onChange={(newGroupID) => handleObjectGroupSave(record.objectID, newGroupID)}
              />
            ),
          },
        ];
      default:
        return [
          {
            title: '',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: 90,
            render: (value, record) => (
              <Space>
                {record.enable === true ? (
                  <>
                    <Tooltip title={t('omConfig.disable')}>
                      <Popconfirm
                        placement="topLeft"
                        title={t('omConfig.confirmDisableItem', { type: dataOfTabType.typeName })}
                        okText={t('omConfig.confirmDisable')}
                        cancelText={t('omConfig.cancel')}
                        onConfirm={handleDataChangeStatus(dataOfTabType.type, record, false)}
                      >
                        <Button type="text" icon={<DeleteOutlined />} />
                      </Popconfirm>
                    </Tooltip>
                    <Tooltip title={t('omConfig.edit')}>
                      <Button icon={<EditOutlined />} onClick={handleModalOpen(record)} />
                    </Tooltip>
                  </>
                ) : (
                  <Tooltip title={t('omConfig.enable')}>
                    <Popconfirm
                      placement="topLeft"
                      title={t('omConfig.confirmEnableItem', { type: dataOfTabType.typeName })}
                      okText={t('omConfig.confirmEnable')}
                      cancelText={t('omConfig.cancel')}
                      onConfirm={handleDataChangeStatus(dataOfTabType.type, record, true)}
                    >
                      <Button type="text" icon={<UndoOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                )}
              </Space>
            ),
          },
          {
            title: t('omConfig.sequence'),
            dataIndex: 'seq',
            key: 'seq',
            align: 'center',
            width: 85,
          },
          {
            title: t('omConfig.value'),
            dataIndex: 'label',
            key: 'label',
          },
        ];
    }
  }, [
    handleModalOpen,
    dataOfTabType.type,
    dataOfTabType.typeName,
    handleUserChangeStatus,
    listOfGroup,
    tableObjectID,
    handleObjectGroupSave,
    handleDataChangeStatus,
    t,
  ]);

  return (
    <>
      {/* page header */}
      <PageHeader
        className="app-page-header"
        title={t('omConfig.title')}
        footer={
          <Tabs
            defaultActiveKey="source"
            onChange={handleDataOfTabTypeChange}
            items={[
              { label: t('omConfig.sourceItem'), key: 'source' },
              { label: t('omConfig.transportationMethod'), key: 'traffic' },
              { label: t('omConfig.componentReplacement'), key: 'component' },
              { label: t('omConfig.action'), key: 'action' },
              { label: t('omConfig.object'), key: 'object' },
              { label: t('omConfig.maintenanceGroup'), key: 'group' },
              { label: t('omConfig.personnel'), key: 'user' },
              { label: t('omConfig.siteAreaDefinition'), key: 'objectGroup' },
            ]}
          />
        }
      />
      <div className="app-page-content">
        {/* 來源項目 */}
        {dataOfTabType.type === 'source' && (
          <Card size="small">
            <Row justify="end" align="middle">
              <Switch
                style={{ marginBottom: '8px', marginRight: '8px' }}
                checkedChildren={t('omConfig.valueStatusEnabled')}
                unCheckedChildren={t('omConfig.valueStatusDisabled')}
                checked={tableInfoOfDataList.status}
                onChange={(value) => {
                  setTableInfoOfDataList((prev) => ({ type: 'source', status: value }));
                }}
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleModalOpen({})}
                style={{ marginBottom: '8px' }}
              >
                {t('omConfig.createOption')}
              </Button>
            </Row>
            <Table
              bordered
              size="small"
              pagination={false}
              columns={columns}
              dataSource={filteredDataList}
              scroll={{ y: 'calc(100vh - 400px)' }}
            />
          </Card>
        )}

        {/* 交通方式 */}
        {dataOfTabType.type === 'traffic' && (
          <Card size="small">
            <Row justify="end" align="middle">
              <Switch
                style={{ marginBottom: '8px', marginRight: '8px' }}
                checkedChildren={t('omConfig.valueStatusEnabled')}
                unCheckedChildren={t('omConfig.valueStatusDisabled')}
                checked={tableInfoOfDataList.status}
                onChange={(value) => {
                  setTableInfoOfDataList((prev) => ({ type: 'traffic', status: value }));
                }}
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleModalOpen({})}
                style={{ marginBottom: '8px' }}
              >
                {t('omConfig.createOption')}
              </Button>
            </Row>
            <Table
              bordered
              size="small"
              pagination={false}
              columns={columns}
              dataSource={filteredDataList}
              scroll={{ y: 'calc(100vh - 400px)' }}
            />
          </Card>
        )}

        {/* 組件更換 */}
        {dataOfTabType.type === 'component' && (
          <Card size="small">
            <Row justify="end" align="middle">
              <Switch
                style={{ marginBottom: '8px', marginRight: '8px' }}
                checkedChildren={t('omConfig.valueStatusEnabled')}
                unCheckedChildren={t('omConfig.valueStatusDisabled')}
                checked={tableInfoOfDataList.status}
                onChange={(value) => {
                  setTableInfoOfDataList((prev) => ({ type: 'component', status: value }));
                }}
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleModalOpen({})}
                style={{ marginBottom: '8px' }}
              >
                {t('omConfig.createOption')}
              </Button>
            </Row>
            <Table
              bordered
              size="small"
              pagination={false}
              columns={columns}
              dataSource={filteredDataList}
              scroll={{ y: 'calc(100vh - 400px)' }}
            />
          </Card>
        )}

        {/* 行動 */}
        {dataOfTabType.type === 'action' && (
          <Card size="small">
            <Row justify="end" align="middle">
              <Switch
                style={{ marginBottom: '8px', marginRight: '8px' }}
                checkedChildren={t('omConfig.valueStatusEnabled')}
                unCheckedChildren={t('omConfig.valueStatusDisabled')}
                checked={tableInfoOfDataList.status}
                onChange={(value) => {
                  setTableInfoOfDataList((prev) => ({ type: 'action', status: value }));
                }}
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleModalOpen({})}
                style={{ marginBottom: '8px' }}
              >
                {t('omConfig.createOption')}
              </Button>
            </Row>
            <Table
              bordered
              size="small"
              pagination={false}
              columns={columns}
              dataSource={filteredDataList}
              scroll={{ y: 'calc(100vh - 400px)' }}
            />
          </Card>
        )}

        {/* 對象 */}
        {dataOfTabType.type === 'object' && (
          <Card size="small">
            <Row justify="end" align="middle">
              <Switch
                style={{ marginBottom: '8px', marginRight: '8px' }}
                checkedChildren={t('omConfig.valueStatusEnabled')}
                unCheckedChildren={t('omConfig.valueStatusDisabled')}
                checked={tableInfoOfDataList.status}
                onChange={(value) => {
                  setTableInfoOfDataList((prev) => ({ type: 'object', status: value }));
                }}
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleModalOpen({})}
                style={{ marginBottom: '8px' }}
              >
                {t('omConfig.createOption')}
              </Button>
            </Row>
            <Table
              bordered
              size="small"
              pagination={false}
              columns={columns}
              dataSource={filteredDataList}
              scroll={{ y: 'calc(100vh - 400px)' }}
            />
          </Card>
        )}

        {/* 維運組別 */}
        {dataOfTabType.type === 'group' && (
          <Card size="small">
            <Row justify="end" align="middle">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleModalOpen({})}
                style={{ marginBottom: '8px' }}
              >
                {t('omConfig.createGroup')}
              </Button>
            </Row>
            <Table
              bordered
              size="small"
              pagination={false}
              columns={columns}
              dataSource={listOfGroup}
              scroll={{ y: 'calc(100vh - 400px)' }}
            />
          </Card>
        )}

        {/* 人員 */}
        {dataOfTabType.type === 'user' && (
          <Spin spinning={saving} tip={t('omConfig.processing')}>
            <Card size="small">
              <Row justify="space-between" align="middle">
                <Col>
                  <ResultTitle>{t('omConfig.userList')}</ResultTitle>
                </Col>
                <Col>
                  <Row justify="end">
                    <Space>
                      <Switch
                        style={{ marginBottom: '8px' }}
                        checkedChildren={t('omConfig.valueStatusEnabled')}
                        unCheckedChildren={t('omConfig.valueStatusDisabled')}
                        checked={tableInfoOfUserList.status}
                        onChange={(value) => {
                          setTableInfoOfUserList((prev) => ({ ...prev, status: value }));
                        }}
                      />
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={handleModalOpen({ canEdit: true, status: true })}
                        style={{ marginBottom: '8px' }}
                      >
                        {t('omConfig.addUser')}
                      </Button>
                    </Space>
                  </Row>
                </Col>
              </Row>
              <Spin spinning={loading} tip={t('omConfig.searching')}>
                <Table
                  bordered
                  size="small"
                  pagination={{
                    total: dataOfUser.total,
                    pageSize: tableInfoOfUserList.pageSize,
                    showSizeChanger: false,
                    current: tableInfoOfUserList.page,
                  }}
                  onChange={handleTableUserChange}
                  columns={columns}
                  dataSource={dataOfUser.list || []}
                  scroll={{ y: 'calc(100vh - 400px)' }}
                />
              </Spin>
            </Card>
          </Spin>
        )}

        {/* 案場分區定義 */}
        {dataOfTabType.type === 'objectGroup' && (
          <>
            <section className="app-page-searchpanel">
              <Collapse defaultActiveKey={['1']}>
                <Panel header={t('omConfig.filterConditions')} key="1">
                  <Row gutter={[16, 16]}>
                    <Col span={8}>
                      <RfdmeSelect
                        showSearch
                        placeholder={t('omConfig.region')}
                        options={pvRegionList}
                        value={objectFilterData.region}
                        onChange={handleObjectFilterDataChange('region')}
                      />
                    </Col>
                    <Col span={8}>
                      <RfdmeSelect
                        showSearch
                        placeholder="SPV"
                        options={pvOwnerList}
                        value={objectFilterData.ownerID}
                        onChange={handleObjectFilterDataChange('ownerID')}
                      />
                    </Col>
                    <Col span={8}>
                      <Row gutter={[16, 0]} justify="end">
                        <Col>
                          <Button onClick={handleObjectReset}>{t('omConfig.reset')}</Button>
                        </Col>
                        <Col>
                          <Button type="primary" onClick={handleObjectSearch}>
                            {t('omConfig.search')}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </section>

            <section className="app-page-section">
              <Card title={t('omConfig.objectList')}>
                <Spin spinning={loading} tip={t('omConfig.searching')}>
                  <Table
                    size="small"
                    columns={columns}
                    dataSource={objectDataList.list}
                    scroll={{ y: 'calc(100vh - 400px)' }}
                    pagination={{
                      total: objectDataList.total,
                      pageSize: objectTableInfo.pageSize,
                      showSizeChanger: false,
                      current: objectTableInfo.page,
                    }}
                    onChange={handleObjectTableChange}
                  />
                </Spin>
              </Card>
            </section>
          </>
        )}
      </div>
      <RfdmeModal width="600px" open={modalOpen}>
        <Title level={4}>
          {dataOfConfig.value || dataOfConfig.userID
            ? t('omConfig.editUser')
            : t('omConfig.addItem', { type: dataOfTabType.typeName })}
        </Title>
        {['source', 'traffic', 'component', 'action', 'object'].includes(dataOfTabType.type) && (
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <RfdmeInput
                required
                label={t('omConfig.sequence')}
                value={dataOfConfig.seq}
                msg={dataOfConfig.seq_msg}
                onChange={handleDataOfConfigChange('seq')}
              />
            </Col>
            <Col span={18} />

            <Col span={24}>
              <RfdmeInput
                required
                label={t('omConfig.value')}
                value={dataOfConfig.label}
                msg={dataOfConfig.label_msg}
                onChange={handleDataOfConfigChange('label')}
              />
            </Col>
          </Row>
        )}
        {dataOfTabType.type === 'group' && (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <RfdmeColorPicker
                required
                label={t('omConfig.groupColor')}
                value={dataOfConfig.colorCode}
                onColorChange={handleColorChange}
              />
            </Col>
            <Col span={6}>
              <RfdmeInput
                required
                label={t('omConfig.sequence')}
                value={dataOfConfig.seq}
                msg={dataOfConfig.seq_msg}
                onChange={handleDataOfConfigChange('seq')}
              />
            </Col>
            <Col span={24}>
              <RfdmeInput
                required
                label={t('omConfig.groupName')}
                value={dataOfConfig.label}
                msg={dataOfConfig.label_msg}
                onChange={handleDataOfConfigChange('label')}
              />
            </Col>
          </Row>
        )}
        {dataOfTabType.type === 'user' && (
          <Row gutter={[16, 16]}>
            <Col span={12}>
              {dataOfConfig.canEdit ? (
                <RfdmeInput
                  required
                  label={t('omConfig.name')}
                  value={dataOfConfig.userName}
                  msg={dataOfConfig.userName_msg}
                  onChange={handleDataOfConfigChange('userName')}
                />
              ) : (
                <RfdmeDisplay label={t('omConfig.name')}>{dataOfConfig.userName}</RfdmeDisplay>
              )}
            </Col>
            <Col span={12}>
              {dataOfConfig.canEdit && !dataOfConfig.userID ? (
                <RfdmeInput
                  required
                  label="email"
                  value={dataOfConfig.email}
                  msg={dataOfConfig.email_msg}
                  onChange={handleDataOfConfigChange('email')}
                  onBlur={handleDataOfConfigBlur('email')}
                />
              ) : (
                <RfdmeDisplay label="email">{dataOfConfig.email}</RfdmeDisplay>
              )}
            </Col>
            <Col span={12}>
              {dataOfConfig.canEdit ? (
                <RfdmeInput
                  label={t('omConfig.phone')}
                  value={dataOfConfig.phone}
                  msg={dataOfConfig.phone_msg}
                  onChange={handleDataOfConfigChange('phone')}
                  onBlur={handleDataOfConfigBlur('phone')}
                />
              ) : (
                <RfdmeDisplay label={t('omConfig.phone')}>{dataOfConfig.phone}</RfdmeDisplay>
              )}
            </Col>
            <Col span={12}>
              {dataOfConfig.canEdit ? (
                <RfdmeSelect
                  required
                  label={t('omConfig.maintenanceGroup')}
                  options={listOfGroup}
                  value={dataOfConfig.omGroupID}
                  msg={dataOfConfig.omGroupID_msg}
                  onChange={handleDataOfConfigChange('omGroupID')}
                />
              ) : (
                <RfdmeDisplay label={t('omConfig.maintenanceGroup')}>{dataOfConfig.omGroupName}</RfdmeDisplay>
              )}
            </Col>
            <Col span={12}>
              <RfdmeDisplay label={t('omConfig.status')}>
                {dataOfConfig.status ? t('omConfig.enable') : t('omConfig.disabled')}
              </RfdmeDisplay>
            </Col>
            <Col span={24}>
              <RfdmeCheckbox
                label={t('omConfig.notificationMessageType')}
                isGroup={true}
                options={listOfNotifyKind}
                value={dataOfConfig.notifyKind}
                onChange={handleCheckBoxGroupChange}
              />
            </Col>
            <Col span={24}>
              {!dataOfConfig.canEdit && (
                <div className="app-form-label">{t('omConfig.relatedCertificates')}</div>
              )}
              <RfdmeUpload
                label={t('omConfig.relatedCertificates')}
                maxCount={systemConfigs?.fileUpload?.omUser?.number}
                maxSize={systemConfigs?.fileUpload?.omUser?.capacity}
                value={dataOfConfig.certifications}
                onChange={handleDataOfConfigChange('certifications')}
                downloadOnly={!dataOfConfig.canEdit}
              />
            </Col>
          </Row>
        )}
        <Divider />
        <Row gutter={[16, 0]} justify="end">
          <Col>
            <Button onClick={handleModalClose}>{t('omConfig.close')}</Button>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={dataOfTabType.type === 'user' ? handleUserSave : handleSave}
              loading={saving}
            >
              {t('omConfig.save')}
            </Button>
          </Col>
        </Row>
      </RfdmeModal>
    </>
  );
};

export default OmConfig;
