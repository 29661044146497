import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { message, Row, Col, Card } from 'antd';
import { Tree, TreeNode } from 'react-organizational-chart';
import { useTranslation } from 'react-i18next';

//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { IsValidAppSyncRsps } from '../../utils/valid';
import { AppSyncQueryTextFormat } from '../../utils/format';
import { GetAppSyncRspsErrorMessage } from '../../utils/parse';
import pattern from '../../images/pattern.svg';

const GetBoosterStationID = () => {
  return (window.location.pathname || '').replace('/boosterStations/', '') || '';
};

const BoosterStationInfo = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [boosterStationName, setBoosterStationName] = useState('');

  useEffect(() => {
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery($boosterStationID: String!) { 
                om_getBoosterStationStruct(boosterStationID: $boosterStationID) {
                  boosterStationID
                  boosterStationName
                  circuitID
                  objectIDs
                  objectNames
                  objectWarnings
                }
              }
            `
          ),
          variables: { boosterStationID: GetBoosterStationID() },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(
            t('boosterStation.info.failedToGetStructureInfo', { error: GetAppSyncRspsErrorMessage(res) })
          );
          return;
        }

        // 取出資料
        const base = res.data.data.om_getBoosterStationStruct;
        if (!Array.isArray(base) || base.length === 0) {
          message.warn(t('boosterStation.info.failedToGetStructureInfo'));
          return;
        }

        // booster
        const boosterName = base[0].boosterStationName || '';

        //
        for (let i = 0; i < base.length; i++) {
          const circuitItem = base[i];
          circuitItem.objects = [];

          for (let j = 0; j < circuitItem.objectIDs.length; j++) {
            let color = '';
            const warnings = circuitItem.objectWarnings[j] || [];
            if (warnings.includes('controllerWarning') || warnings.includes('inverterWarning')) {
              color = 'red';
            } else if (warnings.includes('healthWarning') || warnings.includes('moduleWashWarning')) {
              color = 'yellow';
            } else {
              color = 'white';
            }

            circuitItem.objects.push({
              id: circuitItem.objectIDs[j],
              name: circuitItem.objectNames[j],
              color: color,
            });
          }

          const redAmount = circuitItem.objects.filter((f) => f.color === 'red').length;
          const totalAmount = circuitItem.objectIDs.length;
          if (redAmount / totalAmount > 1 / 3) {
            circuitItem.color = 'red';
          } else {
            circuitItem.color = 'white';
          }

          delete circuitItem.objectIDs;
          delete circuitItem.objectNames;
          delete circuitItem.objectWarnings;
        }

        setBoosterStationName(boosterName);
        setData(base);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('boosterStation.info.errorGettingStructureInfo'));
          console.error('load booster station struct error', err);
        }
      });
  }, [user, t]);

  return (
    <Card>
      <div className="booster-station-tree" style={{ backgroundImage: `url(${pattern})` }}>
        <Tree className="test" label={<div className="root">{boosterStationName}</div>}>
          {data.map((m, mIdx) => (
            <TreeNode
              key={`lv1-${mIdx}`}
              label={
                <div className={`branch ${m.color}`}>
                  {t('boosterStation.info.circuit', { id: m.circuitID })}
                </div>
              }
            >
              <TreeNode
                label={
                  <Row className="leaf-row" gutter={[0, 0]} justify="start">
                    {Array.isArray(m.objects) &&
                      m.objects.length > 0 &&
                      m.objects.map((n, nIdx) => (
                        <Col key={`lv2-${nIdx}`} className={`leaf-col ${n.color}`}>
                          <Link to={`/pvs/${n.id}`}>
                            {n.name} ({n.id})
                          </Link>
                        </Col>
                      ))}
                  </Row>
                }
              />
            </TreeNode>
          ))}
        </Tree>
        <br />
        <br />
      </div>
    </Card>
  );
};

export default BoosterStationInfo;
