import React, { useContext } from 'react';
import { Select } from 'antd';
import ComponentLayout from '../../layout/componentLayout';
import { handleAntSelectSearch } from '../../utils/antAction';
import { SettingContext } from '../../contexts/settingsProvider';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const RfdmeSelect = ({ required, label, msg, isColor, ...props }) => {
  const { dark } = useContext(SettingContext);
  const mode = props.mode || 'single';
  const showSearch = mode === 'multiple' || props.showSearch || false;
  const filterOption = showSearch ? handleAntSelectSearch : null;
  let options = null;
  if (isColor) {
    options = props.options;
    delete props.options;
  }

  const { t } = useTranslation();

  return (
    <ComponentLayout label={label} required={required} msg={msg}>
      <Select
        popupClassName={dark ? 'dark' : ''}
        allowClear
        style={{ width: '100%' }}
        placeholder={label && `${t('pleaseSelect')}${label}`}
        mode={mode}
        showSearch={showSearch}
        filterOption={filterOption}
        {...props}
      >
        {isColor &&
          options.map((option) => (
            <Option key={option.value} value={option.value} label={option.label}>
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <span
                  style={{
                    display: 'inline-block',
                    width: '20px',
                    height: '20px',
                    backgroundColor: option.colorCode,
                    marginRight: '5px',
                  }}
                />
                {option.label}
              </div>
            </Option>
          ))}
      </Select>
    </ComponentLayout>
  );
};

export default RfdmeSelect;
