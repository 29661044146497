import React from 'react';
import { Card } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';

const InfoCard = ({ icon, title, value, unit, additionalInfo }) => {
  return (
    <Card className="app-dashboard-info-card">
      <div className="app-dashboard-info-card-inner">
        <div className="icon">{icon}</div>
        <div className="text">
          <p>{title}</p>
          <p className="value">
            {value} <span style={{ fontSize: '16px', fontWeight: 'normal' }}>{unit}</span>
          </p>
          {additionalInfo && (
            <p>
              <ClockCircleOutlined /> {additionalInfo}
            </p>
          )}
        </div>
      </div>
    </Card>
  );
};

export default InfoCard;
