/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState, useContext, useMemo } from 'react';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import {
  PageHeader,
  Row,
  Col,
  List,
  Card,
  Typography,
  Badge,
  Button,
  message,
  Spin,
  Tag,
  Space,
  Tooltip,
} from 'antd';
import {
  DeleteOutlined,
  CloseOutlined,
  HolderOutlined,
  FireOutlined,
  EnvironmentOutlined,
  ShareAltOutlined,
  FileTextOutlined,
  ClockCircleOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import FullCalendar from '@fullcalendar/react';
import zhTwLocale from '@fullcalendar/core/locales/zh-tw';
import enLocale from '@fullcalendar/core/locales/en-gb';
import viLocale from '@fullcalendar/core/locales/vi';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import { SettingContext } from '../../contexts/settingsProvider';
import * as XLSX from 'xlsx';
//
import RfdmeModal from '../../components/Modal';
import OmMorningReportTable from './report';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { AppSyncQueryTextFormat, SetKeyToArray, CidLevelColorFormat } from '../../utils/format';
import { IsValidAppSyncRsps, IsValidApiGatewayRsps } from '../../utils/valid';
import { OmStatusConstant } from '../../utils/constant';
import { GetAppSyncRspsErrorMessage } from '../../utils/parse';
//
import useListOfOmExecutionResult from '../../hooks/useListOfOmExecutionResult';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/* 共用函式 */
// 該 OM 單是否已經是結案(結束)的狀態 (即不可以再被異動)
const IsCloseStatus = (status) => {
  return [
    OmStatusConstant.ISSUE_RESOLVED,
    OmStatusConstant.ISSUE_ONGOING,
    OmStatusConstant.ISSUE_ONGOING_BUT_FOLLOW_UP,
  ].includes(status);
};

/* 主要頁面元件  */
const OmCalendar = () => {
  const { t, i18n } = useTranslation();
  const { user } = useContext(AuthContext);
  const { dark } = useContext(SettingContext);
  const [listOfCalendarEvent, setListOfCalendarEvent] = useState([]);
  const [listOfOmForm, setListOfOmForm] = useState([]);
  const [modalProps, setModalProps] = useState({ open: false, date: null });
  const [loadingCalender, setLoadingCalendar] = useState(false);
  const [loadingOmForm, setLoadingOmForm] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [recordOfReport, setRecordOfReport] = useState([]);
  const [exporting, setExporting] = useState(false);
  //
  const { data: omExecutionResultList } = useListOfOmExecutionResult();
  //
  const omExecutionResultFullList = useMemo(() => {
    return [{ label: t('omCalendar.pleaseSelect'), value: '' }, ...(omExecutionResultList || [])];
  }, [omExecutionResultList, t]);
  const canEdit = useMemo(() => {
    return (user.operations || []).includes('OMS_manage-om-calendar');
  }, [user.operations]);

  // ===================================================================================
  // 當元件掛載後，啟用拖拉功能
  useEffect(() => {
    if (!canEdit) return;

    new Draggable(document.getElementById('todo-list'), {
      itemSelector: '.todo-item',
      eventData: (eventEl) => JSON.parse(eventEl.getAttribute('data-event')),
    });
  }, [canEdit]);

  // 讀取 OM 委託準備中的清單
  useEffect(() => {
    if (!canEdit) return;

    setLoadingOmForm(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery(
              $omStatusIDs: [Int],               
              $timezone: String!,
              $page: Int!,
              $pageSize: Int!
            ) { 
              om_getOmFormList(
                omStatusIDs: $omStatusIDs,
                timezone: $timezone,
                page: $page,
                pageSize: $pageSize,
              ) {
                total
                list {
                  omFormID
                  omObjectName
                  omObjectRegion
                  omProcessingItem
                  omGroupName
                  omGroupColor
                  omReportSeq
                  cidLevelName
                  cidLevelSeq
                  cidOccurTime
                  cidAvgLossEnergy
                  cidLatestArrivalTime
                  cidArrivalDays
                }
              }
            }
          `
          ),
          variables: {
            omStatusIDs: [OmStatusConstant.REQUEST_PREPARATION],
            timezone: appConfigs.timezone,
            page: 1,
            pageSize: 1000,
          },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('omCalendar.failedToGetDispatchList', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        //
        let resultItems = res.data.data.om_getOmFormList.list.map((m) => ({
          id: m.omFormID,
          start: '',
          end: null,
          extendedProps: {
            status: null,
            statusName: null,
            objectName: m.omObjectName,
            region: m.omObjectRegion,
            processItem: m.omProcessingItem,
            teamName: m.omGroupName,
            omReportSeq: m.omReportSeq,
            levelName: m.cidLevelName,
            occurTime: m.cidOccurTime,
            cidLevelSeq: m.cidLevelSeq === null ? 999 : m.cidLevelSeq,
            cidAvgLossEnergy: m.cidAvgLossEnergy === null ? '' : m.cidAvgLossEnergy.toFixed(2),
            cidArrivalDays: m.cidLatestArrivalTime === null ? ' - ' : m.cidArrivalDays,
          },
          backgroundColor: m.omGroupColor,
        }));
        resultItems = _.orderBy(
          resultItems,
          [
            'extendedProps.cidLevelSeq',
            'extendedProps.cidArrivalDays',
            function (item) {
              return parseFloat(item.extendedProps.cidAvgLossEnergy) || 0;
            },
          ],
          ['asc', 'asc', 'desc']
        );
        setListOfOmForm(resultItems);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.warn(t('omCalendar.getDispatchListError'));
          console.error('load om form list error: ', err);
        }
      })
      .then(() => {
        setLoadingOmForm(false);
      });
  }, [user, canEdit, t]);

  // ===================================================================================
  const loadReportRecord = (date) => {
    if (!date) return;

    setLoadingReport(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `
            query OmQuery(
              $omStatusIDs: [Int], 
              $dateOverlap: Boolean,
              $startDate: String,
              $endDate: String,
              $timezone: String!,
              $page: Int!,
              $pageSize: Int!,
            ) { 
              om_getOmFormList(
                omStatusIDs: $omStatusIDs,
                dateOverlap: $dateOverlap,
                startDate: $startDate,
                endDate: $endDate,
                timezone: $timezone,
                page: $page,
                pageSize: $pageSize,
              ) {
                total
                list {
                  omFormID
                  omStatus
                  omStatusName
                  omStartTime
                  omEndTime
                  cidFormID
                  omSourceName
                  omObjectName
                  omObjectRegion
                  omUserInChargeName
                  omProcessingItem
                  omWorkContent
                  omRecommend
                  omComponents { componentName amount }
                  omReportSeq
                }
              }
            }
            `
          ),
          variables: {
            omStatusIDs: [
              OmStatusConstant.REQUEST_PREPARATION,
              OmStatusConstant.PLANNED,
              OmStatusConstant.PROCESSING,
              OmStatusConstant.ISSUE_OBSERVE,
              OmStatusConstant.ISSUE_ONGOING,
              OmStatusConstant.ISSUE_ONGOING_BUT_FOLLOW_UP,
              OmStatusConstant.ISSUE_RESOLVED,
            ],
            dateOverlap: true,
            timezone: appConfigs.timezone,
            startDate: moment(date).format('YYYY-MM-DD'),
            endDate: moment(date).format('YYYY-MM-DD'),
            page: 1,
            pageSize: 1000,
          },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('omCalendar.failedToGetReportList', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }
        const filterList = res.data.data.om_getOmFormList.list.filter(function (item) {
          var startTime = moment(item.omStartTime, 'YYYY-MM-DD');
          var endTime = moment(item.omEndTime, 'YYYY-MM-DD');
          var dateToCheck = moment(date, 'YYYY-MM-DD');

          return dateToCheck.isBetween(startTime, endTime, undefined, '[]');
        });
        setRecordOfReport(
          _.chain(filterList || [])
            .sortBy('omReportSeq')
            .map(SetKeyToArray)
            .value()
        );
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.warn(t('omCalendar.getReportListError'));
          console.error('load report (om form) list error: ', err);
        }
      })
      .then(() => {
        setLoadingReport(false);
      });
  };

  const handleModalOpen = (selectedDate) => {
    if (selectedDate) {
      setModalProps({ open: true, date: selectedDate });
      loadReportRecord(selectedDate);
    }
  };

  const handleModalClose = () => {
    setModalProps({ open: false, date: null });
  };

  const handleDragAndDrop = async (eventItem, isRemove) => {
    if (!eventItem) return false;

    // check status for changing.
    if (IsCloseStatus(eventItem.extendedProps?.status)) return false;
    let end = eventItem.end;
    if (end) {
      end = moment(end).subtract(1, 'days').format('YYYY-MM-DD');
    } else {
      end = eventItem.start;
    }
    //
    try {
      const res = await axios.post(
        `${appConfigs.apiGatewayURL}/oms/om/calendar/changeDate`,
        {
          omFormID: eventItem.id,
          startDate: eventItem.start,
          endDate: end,
          clear: isRemove,
        },
        { headers: { Authorization: user.token } }
      );

      if (!IsValidApiGatewayRsps(res)) {
        message.warn(res.data.msg);
        return false;
      }

      // calendar part.
      if (!isRemove) {
        const calendarItem = listOfCalendarEvent.find((f) => f.id === eventItem.id);
        if (calendarItem) {
          if (calendarItem.start !== eventItem.start) {
            setListOfCalendarEvent((oldEvents) => [
              ...oldEvents.filter((f) => f.id !== eventItem.id),
              eventItem,
            ]);
          }
        } else {
          setListOfCalendarEvent((oldEvents) => [...oldEvents, eventItem]);
        }
      } else {
        setListOfCalendarEvent((oldEvents) => oldEvents.filter((event) => event.id !== eventItem.id));
      }

      // list part.
      if (!isRemove) {
        const listItem = listOfOmForm.find((f) => f.id === eventItem.id);
        if (listItem) {
          setListOfOmForm((oldTodos) => oldTodos.filter((f) => f.id !== eventItem.id));
        }
      } else {
        // setListOfOmForm((oldTodos) =>
        //   _.chain([...oldTodos, eventItem])
        //     .sortBy(['extendedProps.cidLevelSeq', 'id'])
        //     .value()
        // );
        setListOfOmForm((oldTodos) =>
          _.orderBy(
            [...oldTodos, eventItem],
            [
              'extendedProps.cidLevelSeq',
              'extendedProps.cidArrivalDays',
              function (item) {
                return parseFloat(item.extendedProps.cidAvgLossEnergy) || 0;
              },
            ],
            ['asc', 'asc', 'desc']
          )
        );
      }

      return true;
    } catch (err) {
      message.error(t('omCalendar.updateDateError'));
      console.error('更新日期異常', err);
      return false;
    }
  };

  const handleExport = () => {
    setExporting(true);

    const exportData = recordOfReport.map((item) => {
      let omDate = '';
      if (item.omStartTime && item.omEndTime) {
        omDate = `${item.omStartTime.split(' ')[0]} ~ ${item.omEndTime.split(' ')[0]}`;
      } else if (item.omStartTime) {
        omDate = item.omStartTime.split(' ')[0];
      } else if (item.omEndTime) {
        omDate = item.omEndTime.split(' ')[0];
      }

      return {
        [t('omCalendar.dispatchDate')]: omDate,
        [t('omCalendar.relatedSite')]: item.omObjectName,
        [t('omCalendar.region')]: item.omObjectRegion,
        [t('omCalendar.source')]: item.cidFormID ? item.cidFormID : item.omSourceName,
        [t('omCalendar.omNumber')]: item.omFormID,
        [t('omCalendar.mainHandler')]: Array.isArray(item.omUserInChargeName)
          ? item.omUserInChargeName.join(', ')
          : item.omUserInChargeName,
        [t('omCalendar.processingItem')]: item.omProcessingItem,
        [t('omCalendar.workSummary')]: item.omWorkContent,
        [t('omCalendar.nextSuggestion')]: item.omRecommend,
        [t('omCalendar.componentReplacement')]: item.omComponents,
        [t('omCalendar.closeStatus')]: [OmStatusConstant.PROCESSING, OmStatusConstant.ISSUE_OBSERVE].includes(
          item.omStatus
        )
          ? ''
          : item.omStatusName,
      };
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wscols = [
      { wch: 25 }, // 派工日期
      { wch: 30 }, // 相關案場
      { wch: 10 }, // 地區
      { wch: 10 }, // 來源
      { wch: 10 }, // OM單號
      { wch: 15 }, // 主要處理人員
      { wch: 30 }, // 處理項目
      { wch: 30 }, // 工作簡述
      { wch: 30 }, // 下次建議
      { wch: 30 }, // 組件更換
      { wch: 30 }, // 狀態
    ];
    ws['!cols'] = wscols;

    exportData.forEach((_, rowIndex) => {
      const cellAddress = XLSX.utils.encode_cell({ c: 0, r: rowIndex + 1 });
      if (!ws[cellAddress]) return;
      ws[cellAddress].s = { alignment: { wrapText: true } };
    });

    XLSX.utils.book_append_sheet(wb, ws, 'Report');
    XLSX.writeFile(wb, `${t('omCalendar.dispatchScheduleList')}_${moment().format('YYYYMMDD_HHmmss')}.xlsx`);
    setExporting(false);
  };

  // ===================================================================================
  // 接收從外部而來的事件時觸發 (如: drog, other calendar)
  const handleEventReceive = (info) => {
    handleDragAndDrop(JSON.parse(JSON.stringify(info.event)), false);
  };

  // 日曆內拖拉行為
  const handleEventChange = async (info) => {
    const result = await handleDragAndDrop(JSON.parse(JSON.stringify(info.event)), false);
    if (!result) info.revert();
  };

  // 移除日曆中的項目
  const handleEventRemove = (info) => () => {
    const removedEvent = { ...JSON.parse(JSON.stringify(info.event)), start: '', end: '' };
    handleDragAndDrop(removedEvent, true);
  };

  // 點擊日期 (開啟晨會彈框)
  const handleDateClick = (date) => {
    handleModalOpen(moment(date).format('YYYY-MM-DD'));
    return false;
  };

  // 初始&切換日曆月份時的資料載入
  const handleDatesSet = (dateInfo) => {
    setLoadingCalendar(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `
            query OmQuery(
              $omStatusIDs: [Int],
              $dateOverlap: Boolean,
              $startDate: String,
              $endDate: String,             
              $timezone: String!,
              $page: Int!,
              $pageSize: Int!
            ) { 
              om_getOmFormList (
                omStatusIDs: $omStatusIDs,
                dateOverlap: $dateOverlap,
                startDate: $startDate,
                endDate: $endDate,              
                timezone: $timezone,
                page: $page,
                pageSize: $pageSize,
              ) {
                total
                list {
                  omFormID
                  omStatus
                  omStatusName
                  omStartTime
                  omEndTime
                  omObjectName
                  omObjectRegion
                  omProcessingItem
                  omGroupName
                  omGroupColor
                  omReportSeq
                  cidLevelName
                  cidLevelSeq
                  cidOccurTime
                  cidAvgLossEnergy
                  cidLatestArrivalTime
                  cidArrivalDays                  
                }
              }
            }
            `
          ),
          variables: {
            omStatusIDs: [
              OmStatusConstant.PLANNED,
              OmStatusConstant.PROCESSING,
              OmStatusConstant.ISSUE_OBSERVE,
              OmStatusConstant.ISSUE_RESOLVED,
              OmStatusConstant.ISSUE_ONGOING,
              OmStatusConstant.ISSUE_ONGOING_BUT_FOLLOW_UP,
            ],
            dateOverlap: true,
            startDate: moment(dateInfo.start).format('YYYY-MM-DD'),
            endDate: moment(dateInfo.end).format('YYYY-MM-DD'),
            timezone: appConfigs.timezone,
            page: 1,
            pageSize: 1000,
          },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('omCalendar.failedToGetCalendarData', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        //
        setListOfCalendarEvent(
          res.data.data.om_getOmFormList.list.map((m) => ({
            id: m.omFormID,
            start: moment(m.omStartTime).format('YYYY-MM-DD'),
            end: moment(m.omEndTime).add(1, 'days').format('YYYY-MM-DD'),
            extendedProps: {
              status: m.omStatus,
              statusName: m.omStatusName,
              objectName: m.omObjectName,
              region: m.omObjectRegion,
              processItem: m.omProcessingItem,
              teamName: m.omGroupName,
              omReportSeq: m.omReportSeq,
              levelName: m.cidLevelName,
              occurTime: m.cidOccurTime,
              cidLevelSeq: m.cidLevelSeq === null ? 999 : m.cidLevelSeq,
              cidAvgLossEnergy: m.cidAvgLossEnergy === null ? '' : m.cidAvgLossEnergy.toFixed(2),
              cidArrivalDays: m.cidLatestArrivalTime === null ? ' - ' : m.cidArrivalDays,
            },
            backgroundColor: m.omGroupColor,
          }))
        );
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.warn(t('omCalendar.getCalendarDataError'));
          console.error('load calendar list error: ', err);
        }
      })
      .then(() => {
        setLoadingCalendar(false);
      });
  };

  //排序event順序
  const eventOrderFunc = (a, b) => {
    if (a.omReportSeq === null && b.omReportSeq !== null) {
      return 1;
    }
    if (b.omReportSeq === null && a.omReportSeq !== null) {
      return -1;
    }
    return (a.omReportSeq || 0) - (b.omReportSeq || 0);
  };

  // ===================================================================================
  // 根據當前語言選擇對應的 locale
  const calendarLocale = useMemo(() => {
    switch (i18n.language) {
      case 'zh':
        return zhTwLocale;
      case 'en':
        return enLocale;
      case 'vi':
        return viLocale;
      default:
        return zhTwLocale;
    }
  }, [i18n.language]);

  return (
    <>
      {/* page header */}
      <PageHeader className="app-page-header" title={t('omCalendar.dispatchSchedule')} />

      <div className="app-fullCalendar-wrap">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={canEdit ? 20 : 24}
          xl={canEdit ? 20 : 24}
          className="app-fullCalendar"
          id="calendar-events"
        >
          {loadingCalender && (
            <div
              style={{
                position: 'absolute',
                inset: 0,
                backgroundColor: dark ? 'rgba(39,46,54,0.6)' : 'rgba(255,255,255,0.5)',
                zIndex: 999,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  borderRadius: '6px',
                  display: 'inline-block',
                  padding: '16px 20px',
                  backgroundColor: '#fff',
                  color: 'rgba(0,0,0,0.85)',
                  boxShadow: '0px 0px 12px rgba(0,0,0,0.05)',
                }}
              >
                <Row align="middle" gutter={[16, 0]}>
                  <Col>
                    <Spin />
                  </Col>
                  <Col>{t('omCalendar.loadingData')}</Col>
                </Row>
              </div>
            </div>
          )}
          <FullCalendar
            editable={canEdit}
            droppable={canEdit}
            navLinks
            height="100%"
            initialView="dayGridMonth"
            eventBorderColor="transparent"
            locale={calendarLocale}
            plugins={[dayGridPlugin, interactionPlugin]}
            events={listOfCalendarEvent}
            eventContent={(eventInfo) => (
              <div className="app-fullCalendar-event-wrap">
                <Row justify="space-between" align="middle">
                  <Col>
                    <a
                      className="app-fullCalendar-event-link"
                      href={`/oms/${eventInfo.event.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>
                        {eventInfo.event.id} ({eventInfo.event.extendedProps.region})
                      </div>
                      <span>{eventInfo.event.extendedProps.objectName}</span>
                    </a>
                  </Col>
                  {canEdit && !IsCloseStatus(eventInfo?.event?.extendedProps?.status) && (
                    <Col>
                      <Button
                        type="text"
                        size="small"
                        icon={<DeleteOutlined />}
                        onClick={handleEventRemove(eventInfo)}
                      />
                    </Col>
                  )}
                </Row>
              </div>
            )}
            navLinkDayClick={handleDateClick}
            eventReceive={handleEventReceive}
            eventChange={handleEventChange}
            datesSet={handleDatesSet}
            eventOrder={eventOrderFunc}
            // eventResize={handleEventResize}
          />
        </Col>
        {canEdit && (
          <Col xs={24} sm={24} md={24} lg={4} xl={4}>
            <div className="app-fullCalendar-list">
              <Typography.Title level={4}>{t('omCalendar.pendingDispatchList')}</Typography.Title>

              <Spin spinning={loadingOmForm} tip={t('omCalendar.searching')}>
                <List
                  id="todo-list"
                  grid={{ gutter: 16, column: 1 }}
                  style={{ overflow: 'auto', paddingRight: '15px' }}
                  dataSource={listOfOmForm}
                  renderItem={(item) => (
                    <List.Item
                      className={`todo-item ${dark ? 'dark' : ''}`}
                      data-event={JSON.stringify(item)}
                    >
                      <Badge.Ribbon text={item.extendedProps.teamName} color={item.backgroundColor}>
                        <Card
                          title={
                            <>
                              <HolderOutlined style={{ marginRight: 4 }} />
                              <Link to={`/oms/${item.id}`} target="_blank" style={{ color: '#2ab2df' }}>
                                {item.id}
                              </Link>
                            </>
                          }
                        >
                          <div>
                            <Space>
                              <Tooltip title={t('omCalendar.averagePowerLoss')}>
                                <FileTextOutlined />
                              </Tooltip>
                              {item.extendedProps.cidAvgLossEnergy || '-'} kWh
                            </Space>
                          </div>
                          <div>
                            <Space>
                              <Tooltip title={t('omCalendar.severity')}>
                                <FireOutlined />
                              </Tooltip>
                              <Tag color={CidLevelColorFormat(item.extendedProps.cidLevelSeq)}>
                                {item.extendedProps.levelName || '-'}
                              </Tag>
                            </Space>
                          </div>
                          <div>
                            <Space>
                              <Tooltip title={t('omCalendar.region')}>
                                <EnvironmentOutlined />
                              </Tooltip>
                              {item.extendedProps.region}
                            </Space>
                          </div>
                          <div>
                            <Space>
                              <Tooltip title={t('omCalendar.site')}>
                                <ShareAltOutlined />
                              </Tooltip>
                              {item.extendedProps.objectName}
                            </Space>
                          </div>
                          <div>
                            <Space>
                              <Tooltip title={t('omCalendar.issueContent')}>
                                <FileTextOutlined />
                              </Tooltip>
                              {item.extendedProps.processItem}
                            </Space>
                          </div>
                          <div>
                            <Space>
                              <Tooltip title={t('omCalendar.countdownDays')}>
                                <ClockCircleOutlined />
                              </Tooltip>
                              {item.extendedProps.cidArrivalDays} {t('omCalendar.days')}
                            </Space>
                          </div>
                        </Card>
                      </Badge.Ribbon>
                    </List.Item>
                  )}
                />
              </Spin>
            </div>
          </Col>
        )}
      </div>

      {modalProps.open && (
        <RfdmeModal width="100%" height="100%" open={true}>
          <div className="app-modal-header">
            <div className="ant-modal-title">
              {t('omCalendar.reportDate')}：{modalProps.date}
            </div>
            <div className="app-modal-close" onClick={handleModalClose}>
              <CloseOutlined />
            </div>
          </div>
          <div className="app-modal-content">
            <Row gutter={[8, 8]}>
              <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                <Button type="primary" icon={<ExportOutlined />} loading={exporting} onClick={handleExport}>
                  {t('omCalendar.export')}
                </Button>
              </Col>
            </Row>
            <Row gutter={[8, 8]} style={{ marginTop: 16 }}>
              <Spin spinning={loadingReport} tip={t('omCalendar.searching')}>
                <OmMorningReportTable
                  data={recordOfReport}
                  executionResultList={omExecutionResultFullList}
                  readonly={!canEdit}
                />
              </Spin>
            </Row>
          </div>
          <div className="app-modal-footer">
            <Row gutter={[8, 8]} justify="end">
              <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                <Button onClick={handleModalClose} block>
                  {t('omCalendar.close')}
                </Button>
              </Col>
            </Row>
          </div>
        </RfdmeModal>
      )}
    </>
  );
};

export default OmCalendar;
