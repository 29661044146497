import React, { useContext } from 'react';
import { Result } from 'antd';
import AuthContext from '../../contexts/authProvider';
import { SettingContext } from '../../contexts/settingsProvider';
import { useTranslation } from 'react-i18next';

const GuaranteedPowerAnalysis = ({ url, objectID }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { dark } = useContext(SettingContext);
  const theme = dark ? 'dark' : 'light';
  const token = user.token?.replace?.(/^Bearer /, '');

  if (!url) {
    return <Result status="404" title="404" subTitle={t('guaranteedPowerAnalysis.pageNotFound')} />;
  }

  return (
    <div style={{ margin: '-24px', height: 'calc(100vh - 197px)', overflow: 'hidden' }}>
      <iframe
        id="frame"
        width={'100%'}
        title={t('guaranteedPowerAnalysis.siteInfo')}
        style={{ border: 0, height: '100%' }}
        src={`${url}&kiosk=tv&var-plant=${objectID}&auth_token=${token}&theme=${theme}`}
      ></iframe>
    </div>
  );
};

export default GuaranteedPowerAnalysis;
