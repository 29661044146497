import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import styled from 'styled-components';
import { Col, Row, Card, Button, Tooltip, Typography } from 'antd';
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from 'recharts';
import { useTranslation } from 'react-i18next';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { DigitToChineseFormat } from '../../utils/format';

const { Title } = Typography;

const queryObjFN = ({ item, alertList, key }) => {
  const obj = {
    objectID: item.objectID,
    warning: item[key],
  };

  const foundObj = alertList.find((obj) => obj.key === key);
  if (foundObj && foundObj.alertIDs) {
    obj.alertList = foundObj.alertIDs;
  }

  obj.alertLogCidStatus = null;
  return obj;
};

const WarningButton = ({ warning, href, children, state, processing, alertLink }) => {
  const className = warning === 0 ? 'btn-gray' : warning === 1 ? 'btn-alert' : null;
  const icon = warning === 0 ? <CheckCircleOutlined /> : warning === 1 ? <CloseCircleOutlined /> : null;
  const { t } = useTranslation();

  return (
    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
      <div style={{ width: '100%', position: 'relative' }} className="app-alert-button">
        {warning === 1 && processing === 0 ? (
          <Tooltip title={t('spvDashboard.card.hasOpenedCid')}>
            <FileTextOutlined
              style={{
                border: '1px solid #252525',
                background: '#fff',
                borderRadius: '50%',
                color: '#252525',
                width: 22,
                height: 22,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 12,
                position: 'absolute',
                right: -3,
                top: -3,
                zIndex: 2,
                boxShadow: '0px 3px 0px rgba(0,0,0,0.1)',
              }}
            />
          </Tooltip>
        ) : null}
        {alertLink === true ? (
          <Link to="/alerts" disabled={warning === null} target="_blank">
            <Button
              block
              size="small"
              className={`${className} ant-btn-md`}
              disabled={warning === null}
              onClick={() =>
                window.localStorage.setItem('alert-list-object-filterData', JSON.stringify(state))
              }
              icon={icon}
            >
              {children}
            </Button>
          </Link>
        ) : (
          <Button
            block
            size="small"
            className={`${className} ant-btn-md`}
            disabled={warning === null}
            icon={icon}
          >
            {children}
          </Button>
        )}
      </div>
    </Col>
  );
};

const CircleLabel = ({ viewBox, value }) => {
  const { cx, cy } = viewBox;
  const { t } = useTranslation();
  
  return (
    <foreignObject x={cx - 30} y={cy - 25} width="60" height="50">
      <Tooltip
        title={t('spvDashboard.card.prValueTooltip')}
        placement="bottom"
      >
        <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <TitleDiv style={{ fontSize: 12 }}>
            <InfoCircleOutlined />
            {t('spvDashboard.card.annual')}
          </TitleDiv>
          <TitleDiv style={{ fontSize: 12 }}>{t('spvDashboard.card.cumulativePR')}</TitleDiv>
          <ValueDiv style={{ fontSize: 14 }}>{value ? `${_.round(value, 2)}%` : '--%'}</ValueDiv>
        </div>
      </Tooltip>
    </foreignObject>
  );
};

const CircleProgress = ({ value = 25, fillColor }) => {
  const data = [{ value: value || 0 }, { value: 100 - (value || 0) }];

  return (
    <ResponsiveContainer width="100%" height={120}>
      <PieChart>
        <Pie
          dataKey="value"
          startAngle={90}
          endAngle={-270}
          data={data}
          outerRadius={50}
          innerRadius={40}
          fill="#8884d8"
        >
          <Cell key="filled" fill={fillColor} strokeWidth={0} />
          <Cell key="unfilled" fill="rgba(155,155,155,0.12)" strokeWidth={0} />
          <Label position="center" content={<CircleLabel value={value} />} />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

const CardItem = ({ item, alertList, alertLink, investorView = false }) => {
  const { t } = useTranslation();

  if (investorView) {
    const periodName = DigitToChineseFormat(item.electricityPeriod);
    const spvName = item.majorFlag
      ? `${item.companyName} ${periodName ? t('spvDashboard.card.phase', { phase: periodName }) : ''}`
      : item.companyName;

    return (
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
        <Card
          size="small"
          className="card-alert"
          title={<Title level={5}>{item.ownerObjectID}</Title>}
          extra={
            <Button href={`/pvs/${item.ownerObjectID}`} type="primary" ghost className="btn-medium">
              {t('spvDashboard.card.details')}
            </Button>
          }
        >
          <Row gutter={[24, 0]} style={{ padding: '0 16px' }}>
            <Col span={10}>
              <CircleProgress value={item.cumulativePR} fillColor="#1EA4D0" />
            </Col>
            <Col span={14}>
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Col span={24}>
                    <TitleDiv>SPV</TitleDiv>
                  </Col>
                  <Col span={24}>
                    <ValueDiv>{`${spvName}`}</ValueDiv>
                  </Col>
                </Col>
                <Col span={24}>
                  <Col span={24}>
                    <TitleDiv>{t('spvDashboard.card.siteName')}</TitleDiv>
                  </Col>
                  <Col span={24}>
                    <ValueDiv>{item.objectName}</ValueDiv>
                  </Col>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    );
  }

  const title = item.objectName ? item.objectName + ` (${item.ownerObjectID})` : null;
  return (
    <Card
      size="small"
      className="card-alert app-dashboard-info-card custom"
      title={title}
      extra={
        <Button href={`/pvs/${item.ownerObjectID}`} type="primary" ghost className="btn-medium">
          {t('spvDashboard.card.details')}
        </Button>
      }
    >
      <Row gutter={[6, 6]} align="middle">
        <WarningButton
          warning={item.controllerWarning}
          state={queryObjFN({ item, alertList, key: 'controllerWarning' })}
          alertLink={alertLink}
        >
          {t('spvDashboard.card.monitoringSystem')}
        </WarningButton>
        <WarningButton
          warning={item.inverterWarning}
          state={queryObjFN({ item, alertList, key: 'inverterWarning' })}
          alertLink={alertLink}
        >
          {t('spvDashboard.card.inverter')}
        </WarningButton>
        <WarningButton
          warning={item.healthWarning}
          state={queryObjFN({ item, alertList, key: 'healthWarning' })}
          alertLink={alertLink}
        >
          {t('spvDashboard.card.health')}
        </WarningButton>
        <WarningButton
          warning={item.moduleWashWarning}
          state={queryObjFN({ item, alertList, key: 'moduleWashWarning' })}
          processing={item.moduleWashNoCidFlag}
          alertLink={alertLink}
        >
          {t('spvDashboard.card.moduleNeedsCleaning')}
        </WarningButton>
      </Row>
    </Card>
  );
};

const TitleDiv = styled.div`
  color: #a9a9a9;
  line-height: 1.3;
`;
const ValueDiv = styled.div`
  font-weight: 600;
  line-height: 1.3;
`;

export default CardItem;
