import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { message, PageHeader, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import PowerPerformance from './powerPerformance';
import CidStatistics from './cidStatistics';
import ModuleCleanAndInspection from './moduleCleanInspection';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { IsValidAppSyncRsps } from '../../utils/valid';
import { AppSyncQueryTextFormat } from '../../utils/format';
import { GetAppSyncRspsErrorMessage } from '../../utils/parse';

const GetTitleFromPath = (urlPath) => {
  const decodedURL = decodeURI(urlPath);
  const title = (decodedURL || '').replace('/spvlist/', '');
  return title || '';
};

const Spv = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const [tabkey, setTabkey] = useState('powerPerformance');
  const [loadingCompleted, setLoadingCompleted] = useState(false);
  const [grafanaConfig, setGrafanaConfig] = useState([]);
  const navigate = useNavigate();
  const { companyID = '', electricityPeriod = '1' } = location?.state;
  const title = GetTitleFromPath(location.pathname);

  const handleTabChange = (value) => {
    setTabkey(value);
  };

  // grafanaConfig 與 tab item 的對應
  const GRAFANA_CONFIG_MAPPING = useMemo(() => [
    {
      code: 'spv-power-performance',
      item: { seq: 1, label: t('spv.powerPerformance'), key: 'powerPerformance' },
    },
    {
      code: 'spv-cid-statistics',
      item: { seq: 2, label: t('spv.cidStatistics'), key: 'cidStatistics' },
    },
    {
      code: 'spv-module-clean-and-inspection',
      item: { seq: 3, label: t('spv.moduleCleanAndInspection'), key: 'moduleCleanAndInspection' },
    },
  ],[t]);

  // get embedding-grafana URL
  useEffect(() => {
    if (!companyID) return;

    setLoadingCompleted(true);
    axios
      .post(
        appConfigs.sharedAppSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery($system: String, $kind: String) {
              shared_getGrafanaEmbeddingList(system: $system, kind: $kind) {
                code
                url
               }
             }
            `
          ),
          variables: {
            system: appConfigs.systemID,
            kind: 'spvInfo',
          },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('spv.failedToGetGrafanaEmbeddingInfo', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }
        setGrafanaConfig(res.data.data.shared_getGrafanaEmbeddingList);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error('load grafanaUrlApi error', err);
          message.error(t('spv.getGrafanaEmbeddingInfoError'));
        }
      })
      .then(() => {
        setLoadingCompleted(false);
      });
  }, [user, companyID, t]);

  const formProps = useMemo(() => {
    const result = { tabItems: [] };

    if (Array.isArray(grafanaConfig) && grafanaConfig.length > 0) {
      const isOwnerView = user.operations.includes('SPVINFO_investor-view');

      for (let i = 0; i < grafanaConfig.length; i++) {
        const gc = grafanaConfig[i];

        let matchItem = GRAFANA_CONFIG_MAPPING.find((f) => f.code === gc.code);
        if (!matchItem && isOwnerView) {
          matchItem = GRAFANA_CONFIG_MAPPING.find((f) => f.code === gc.code.replace('-investor', ''));
        }

        if (matchItem) {
          result[matchItem.item.key] = gc.url;
          result.tabItems.push(matchItem.item);
        }
      }
    }

    return result;
  }, [user, grafanaConfig, GRAFANA_CONFIG_MAPPING]);

  return (
    <>
      {/* page header */}
      <PageHeader
        className="app-page-header rwd-btn-wrap"
        onBack={() => navigate('/spvlist')}
        title={title}
        footer={<Tabs activeKey={tabkey} onChange={handleTabChange} items={formProps?.tabItems || []} />}
      />

      {/* page content */}
      {loadingCompleted && (
        <>
          <h1>{t('spv.loadingSettings')}</h1>
        </>
      )}
      {!loadingCompleted && (
        <>
          {tabkey === 'powerPerformance' && (
            <PowerPerformance
              url={formProps[tabkey]}
              companyID={companyID}
              electricityPeriod={electricityPeriod}
            />
          )}
          {tabkey === 'cidStatistics' && (
            <CidStatistics
              grafanaUrl={formProps[tabkey]}
              companyID={companyID}
              electricityPeriod={electricityPeriod}
            />
          )}
          {tabkey === 'moduleCleanAndInspection' && (
            <ModuleCleanAndInspection
              url={formProps[tabkey]}
              companyID={companyID}
              electricityPeriod={electricityPeriod}
            />
          )}
        </>
      )}
    </>
  );
};

export default Spv;
