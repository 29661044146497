import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
//
import { Input, Button, message, Form, Checkbox, Select } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import useLocalStorage from '../../hooks/useLocalStorage';
//
import appConfigs from '../../configs';
import { IsValidApiGatewayRsps } from '../../utils/valid';
import AuthContext from '../../contexts/authProvider';
import { GetEncryptedString, GetDecryptedString } from '../../utils/encryption';
//
import useRWD from '../../hooks/useRWD';
import bg from '../../images/loginbg.jpg';
import bg_m from '../../images/loginbg_m.jpg';
import logo from '../../images/logo_s.svg';
import logo_ch from '../../images/logo_ch.svg';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const Login = ({ homePagePath }) => {
  const { t, i18n } = useTranslation();
  const { AuthUser } = useContext(AuthContext);
  const [name, setName] = useLocalStorage('username', '');
  const [pass, setPass] = useLocalStorage('password', '');
  const [loading, setLoading] = useState(false);
  const [rememberAccout, setRememberAccout] = useLocalStorage('remember', 'false');
  const navigate = useNavigate();
  const device = useRWD();

  const handleLogin = (values) => {
    const username = values.username.trim();
    const password = values.password.trim();
    if (!username || !password) {
      message.warning(t('login.enterAccountPassword'));
      return;
    }

    if (values.remember) {
      setName(GetEncryptedString(username));
      setPass(GetEncryptedString(password));
      setRememberAccout(values.remember);
    } else {
      setName('');
      setPass('');
      setRememberAccout('');
    }

    setLoading(true);
    axios
      .post(`${appConfigs.apiGatewayURL}/auth/login`, {
        systemID: appConfigs.systemID,
        account: username,
        password: password,
      })
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.error(t('login.loginFailed'));
          console.warn('login fail', res);
          return;
        }

        if (!res.data.data.token) {
          message.error(t('login.loginFailedNoToken'));
          console.warn('login fail', res);
          return;
        }

        AuthUser({
          userName: res.data.data.userName,
          menus: res.data.data.menuList || [],
          operations: res.data.data.operationList || [],
          token: res.data.data.token,
        });

        navigate(homePagePath);
      })
      .catch((err) => {
        message.error(t('login.loginError'));
        console.error('login error', err);
      })
      .then(() => {
        setLoading(false);
      });
  };

  const handleLanguageChange = (value) => {
    i18n.changeLanguage(value);
  };

  return (
    <div
      className="app-login"
      style={
        device !== 'hisesPC' && device !== 'PC'
          ? { backgroundImage: `url(${bg_m})` }
          : { backgroundImage: `url(${bg})` }
      }
    >
      <div className="app-login-header">
        <div className="container">
          <div className="app-login-header-menu">
            <div className="logo">
              <img
                src={i18n.language === 'zh-TW' ? logo_ch : logo}
                alt={t('login.microElectricityOmSystem')}
                style={{ width: 170 }}
              />
            </div>
            <div className="btn">
              <Select
                defaultValue={i18n.language}
                value={i18n.language}
                style={{ width: 140 }}
                onChange={handleLanguageChange}
                popupClassName="dark"
              >
                <Option value="en">{t('settings.english')}</Option>
                <Option value="zh-TW">{t('settings.chinese')}</Option>
                <Option value="vi">{t('settings.vietnamese')}</Option>
              </Select>
            </div>
          </div>
        </div>
      </div>
      <div className="app-login-content">
        <div className="container">
          <div className="app-login-form">
            <h1>{t('login.microElectricityOmSystem')}</h1>
            {appConfigs.stageName && <div className="stage-name">{appConfigs.stageName}</div>}
            <Form
              name="login"
              layout="vertical"
              onFinish={handleLogin}
              initialValues={{
                remember: rememberAccout,
                username: GetDecryptedString(name),
                password: GetDecryptedString(pass),
              }}
              size="large"
              autoComplete="off"
              onFieldsChange={(changedFields) => {
                if (changedFields[0].name[0] === 'remember') setRememberAccout((value) => !value);
              }}
            >
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: t('login.pleaseInputUsername'),
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={t('login.usernamePlaceholder')}
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: t('login.pleaseInputPassword'),
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder={t('login.passwordPlaceholder')}
                />
              </Form.Item>

              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>{t('login.rememberMe')}</Checkbox>
                </Form.Item>
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading} block>
                  {t('login.login')}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <div className="app-login-footer">
        <div className="container">
          <p>©{moment().year()} Micro Electricity Co., Ltd. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
