import React from 'react';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
} from 'recharts';

const legendFormatter = (value, t) => {
  if (value === 'invPowerPerUT') return t('spvDashboard.chartCard.inverter');
  if (value === 'dmPowerPerUT') return t('spvDashboard.chartCard.digitalMeter');
  return value;
};

const ChartCard = (data) => {
  const { t } = useTranslation();

  const tooltipFormatter = (value, name) => {
    const formattedName = legendFormatter(name, t);
    return [value, formattedName];
  };

  return (
    <Card title={t('spvDashboard.chartCard.realtimeSitePowerData')} className="app-dashboard-card">
      <ResponsiveContainer width="100%" height={'100%'}>
        <ComposedChart data={data.data}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#009CCD" stopOpacity={0.9} />
              <stop offset="95%" stopColor="#009CCD" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <CartesianGrid stroke="rgba(144,144,144,0.2)" />
          <XAxis dataKey="time" />
          <YAxis />
          <Tooltip formatter={tooltipFormatter} />
          <Legend formatter={(value) => legendFormatter(value, t)} />
          <Line
            dot={false}
            type="monotone"
            dataKey="invPowerPerUT"
            stroke="#FF9900"
            style={{ strokeDasharray: '3 3' }}
            animationDuration={200}
            strokeWidth={1}
          />
          <Area
            type="monotone"
            dataKey="dmPowerPerUT"
            fill="url(#colorUv)"
            stroke="#009CCD"
            strokeOpacity={1}
            strokeWidth={1}
            animationDuration={200}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default ChartCard;
