import { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import styled from 'styled-components';
import moment from 'moment';
import { Button, Row, Col, Spin, message, Table, PageHeader, Collapse, Card } from 'antd';
import { AlertOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons';
import RfdmeModal from '../../components/Modal';
import RfdmeTextArea from '../../components/TextArea';
import RfdmeSelect from '../../components/Select';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { IsValidAppSyncRsps, IsValidApiGatewayRsps } from '../../utils/valid';
import {
  AppSyncQueryTextFormat,
  SetKeyToArray,
  JsonStringToFormObject,
  FormObjectToJsonString,
} from '../../utils/format';
import { GetAppSyncRspsErrorMessage } from '../../utils/parse';
import useListOfPv from '../../hooks/useListOfPv';
import useListOfCidProblemCategory from '../../hooks/useListOfCidProblemCategory';
//
import { useTranslation } from 'react-i18next';
//
const { Panel } = Collapse;
//
const WarningDiv = styled.div`
  color: #fa541c;
  text-align: center;
`;
const TitleDiv = styled.div`
  text-align: center;
`;
const HintTextLabel = styled.span`
  font-size: 12px;
  color: #ccc;
`;

//
const INITIAL_FILTER_DATA = { timezone: appConfigs.timezone };
const FILTER_DATA_IN_SESSION_NAME = 'cid-time-monitor-filterData';

//主要元件
const CidTimeMonitor = () => {
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [collapse, setCollapse] = useState(true);
  //
  const [modalOpen, setModalOpen] = useState(false);
  const [filterData, setFilterData] = useState(INITIAL_FILTER_DATA);
  const [cidList, setCidList] = useState([]);
  const [cidData, setCidData] = useState({
    cidFormID: '',
    arrivalOvertimeDesc: '',
    repairOvertimeDesc: '',
  });
  const { data: listOfCidProblemCategory } = useListOfCidProblemCategory();
  const { data: listOfPv } = useListOfPv();
  const { t } = useTranslation();
  const listOfPvOwner = useMemo(() => {
    if (!Array.isArray(listOfPv) || listOfPv.length === 0) return [];

    return _.chain(listOfPv)
      .map((m) => ({ label: m.ownerName, value: m.ownerID }))
      .unionBy('value')
      .sortBy(['label'])
      .value();
  }, [listOfPv]);

  //column
  const GenerateColumns = useCallback(
    (modalFn) => {
      return [
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          width: '3%',
          align: 'center',
          fixed: 'left',
          render: (_, record) => {
            return (
              <Row justify="center" gutter={[8, 0]}>
                <Col>
                  <Button size="small" icon={<EditOutlined />} onClick={modalFn(record)} />
                </Col>
              </Row>
            );
          },
        },
        {
          title: () => <TitleDiv>{t('reportCidTimeMonitor.cidFormID')}</TitleDiv>,
          dataIndex: 'cidFormID',
          key: 'cidFormID',
          width: '6%',
          fixed: 'left',
          sorter: (a, b) => a.cidFormID.localeCompare(b.cidFormID),
          showSorterTooltip: false,
          render: (value) => (
            <Link to={`/cids/${value}`} target="_blank">
              {value}
            </Link>
          ),
        },
        {
          title: () => <TitleDiv>{t('reportCidTimeMonitor.objectList')}</TitleDiv>,
          dataIndex: 'objectList',
          key: 'objectList',
          width: '10%',
          fixed: 'left',
          render: (value) => {
            if (Array.isArray(value) && value.length > 0) {
              return value.map((v, vIdx) => (
                <div key={vIdx}>
                  <div>
                    <HintTextLabel>{v.ownerName}</HintTextLabel>
                  </div>
                  <div>{`${v.objectID}_${v.objectName}`}</div>
                </div>
              ));
            } else {
              return null;
            }
          },
        },
        {
          title: () => <TitleDiv>{t('reportCidTimeMonitor.problemCategoryName')}</TitleDiv>,
          dataIndex: 'problemCategoryName',
          key: 'problemCategoryName',
          width: '7%',
          sorter: (a, b) => a.problemCategoryName.localeCompare(b.problemCategoryName),
          showSorterTooltip: false,
        },
        {
          title: () => <TitleDiv>{t('reportCidTimeMonitor.levelName')}</TitleDiv>,
          dataIndex: 'levelName',
          key: 'levelName',
          width: '7%',
          sorter: (a, b) => {
            if (a.levelSeq !== b.levelSeq) {
              return a.levelSeq - b.levelSeq;
            }
            return a.levelName.localeCompare(b.levelName);
          },
          showSorterTooltip: false,
        },
        {
          title: () => <TitleDiv>{t('reportCidTimeMonitor.contractDays')}</TitleDiv>,
          children: [
            {
              title: () => <TitleDiv>{t('reportCidTimeMonitor.arrival')}</TitleDiv>,
              dataIndex: 'arrivalDays',
              key: 'arrivalDays',
              width: '4%',
              sorter: (a, b) => (a.arrivalDays || 99) - (b.arrivalDays || 99),
              showSorterTooltip: false,
            },
            {
              title: () => <TitleDiv>{t('reportCidTimeMonitor.repair')}</TitleDiv>,
              dataIndex: 'repairDays',
              key: 'repairDays',
              width: '4%',
              sorter: (a, b) => (a.repairDays || 99) - (b.repairDays || 99),
              showSorterTooltip: false,
            },
          ],
        },
        {
          title: () => (
            <TitleDiv>
              <div>{t('reportCidTimeMonitor.problem')}</div>
              <div>{t('reportCidTimeMonitor.startTime')}</div>
            </TitleDiv>
          ),
          dataIndex: 'occurTime',
          key: 'occurTime',
          width: '6%',
          sorter: (a, b) => a.occurTime.localeCompare(b.occurTime),
          showSorterTooltip: false,
        },
        {
          title: () => <TitleDiv>Deadline</TitleDiv>,
          children: [
            {
              title: () => <TitleDiv>{t('reportCidTimeMonitor.arrival')}</TitleDiv>,
              dataIndex: 'latestArrivalTime',
              key: 'latestArrivalTime',
              width: '6%',
              sorter: (a, b) =>
                (a.latestArrivalTime || moment().format('YYYY-MM-DD HH:mm')).localeCompare(
                  b.latestArrivalTime || moment().format('YYYY-MM-DD HH:mm')
                ),
              showSorterTooltip: false,
            },
            {
              title: () => <TitleDiv>{t('reportCidTimeMonitor.repair')}</TitleDiv>,
              dataIndex: 'latestRepairTime',
              key: 'latestRepairTime',
              width: '6%',
              sorter: (a, b) =>
                (a.latestRepairTime || moment().format('YYYY-MM-DD HH:mm')).localeCompare(
                  b.latestRepairTime || moment().format('YYYY-MM-DD HH:mm')
                ),
              showSorterTooltip: false,
            },
          ],
        },
        {
          title: () => <TitleDiv>{t('reportCidTimeMonitor.timeoutWarning')}</TitleDiv>,
          children: [
            {
              title: () => <TitleDiv>{t('reportCidTimeMonitor.arrival')}</TitleDiv>,
              dataIndex: 'arrivalTimeoutWarning',
              key: 'arrivalTimeoutWarning',
              width: '4%',
              render: (value) =>
                value && (
                  <WarningDiv>
                    <AlertOutlined />
                  </WarningDiv>
                ),
              sorter: (a, b) => a.arrivalTimeoutWarning - b.arrivalTimeoutWarning,
              showSorterTooltip: false,
            },
            {
              title: () => <TitleDiv>{t('reportCidTimeMonitor.repair')}</TitleDiv>,
              dataIndex: 'repairTimeoutWarning',
              key: 'repairTimeoutWarning',
              width: '4%',
              render: (value) =>
                value && (
                  <WarningDiv>
                    <AlertOutlined />
                  </WarningDiv>
                ),
              sorter: (a, b) => a.repairTimeoutWarning - b.repairTimeoutWarning,
              showSorterTooltip: false,
            },
          ],
        },
        {
          title: () => <TitleDiv>{t('reportCidTimeMonitor.overtimeWarning')}</TitleDiv>,
          children: [
            {
              title: () => <TitleDiv>{t('reportCidTimeMonitor.arrival')}</TitleDiv>,
              dataIndex: 'arrivalOvertimeWarning',
              key: 'arrivalOvertimeWarning',
              width: '4%',
              render: (value) =>
                value && (
                  <WarningDiv>
                    <AlertOutlined />
                  </WarningDiv>
                ),
              sorter: (a, b) => a.arrivalOvertimeWarning - b.arrivalOvertimeWarning,
              showSorterTooltip: false,
            },
            {
              title: () => <TitleDiv>{t('reportCidTimeMonitor.repair')}</TitleDiv>,
              dataIndex: 'repairOvertimeWarning',
              key: 'repairOvertimeWarning',
              width: '4%',
              render: (value) =>
                value && (
                  <WarningDiv>
                    <AlertOutlined />
                  </WarningDiv>
                ),
              sorter: (a, b) => a.repairOvertimeWarning - b.repairOvertimeWarning,
              showSorterTooltip: false,
            },
          ],
        },
        {
          title: () => <TitleDiv>{t('reportCidTimeMonitor.scheduledOmStartTime')}</TitleDiv>,
          children: [
            {
              title: () => <TitleDiv>{t('reportCidTimeMonitor.plan')}</TitleDiv>,
              dataIndex: 'scheduledOmStartTime',
              key: 'scheduledOmStartTime',
              width: '6%',
              sorter: (a, b) =>
                (a.scheduledOmStartTime || moment().format('YYYY-MM-DD HH:mm')).localeCompare(
                  b.scheduledOmStartTime || moment().format('YYYY-MM-DD HH:mm')
                ),
              showSorterTooltip: false,
            },
            {
              title: () => <TitleDiv>{t('reportCidTimeMonitor.actual')}</TitleDiv>,
              dataIndex: 'actualOmStartTime',
              key: 'actualOmStartTime',
              width: '6%',
              sorter: (a, b) =>
                (a.actualOmStartTime || moment().format('YYYY-MM-DD HH:mm')).localeCompare(
                  b.actualOmStartTime || moment().format('YYYY-MM-DD HH:mm')
                ),
              showSorterTooltip: false,
            },
          ],
        },
        {
          title: () => (
            <TitleDiv>
              <div>{t('reportCidTimeMonitor.repair')}</div>
              <div>{t('reportCidTimeMonitor.repairCompleteTime')}</div>
            </TitleDiv>
          ),
          dataIndex: 'repairCompleteTime',
          key: 'repairCompleteTime',
          width: '6%',
          sorter: (a, b) =>
            (a.repairCompleteTime || moment().format('YYYY-MM-DD HH:mm')).localeCompare(
              b.repairCompleteTime || moment().format('YYYY-MM-DD HH:mm')
            ),
          showSorterTooltip: false,
        },
        {
          title: () => <TitleDiv>{t('reportCidTimeMonitor.arrivalOvertimeDesc')}</TitleDiv>,
          dataIndex: 'arrivalOvertimeDesc',
          key: 'arrivalOvertimeDesc',
          width: '10%',
        },
        {
          title: () => <TitleDiv>{t('reportCidTimeMonitor.repairOvertimeDesc')}</TitleDiv>,
          dataIndex: 'repairOvertimeDesc',
          key: 'repairOvertimeDesc',
          width: '10%',
        },
      ];
    },
    [t]
  );

  const WarningOptions = (label) => {
    return [
      {
        label: `${label}${t('reportCidTimeMonitor.arrival')}`,
        value: 'arrival',
      },
      {
        label: `${label}${t('reportCidTimeMonitor.repair')}`,
        value: 'repair',
      },
    ];
  };

  const listOfPvByOwner = useMemo(() => {
    if (!Array.isArray(listOfPv) || listOfPv.length === 0) return [];
    if (
      Array.isArray(listOfPv) &&
      (!Array.isArray(filterData.ownerIDs) || filterData.ownerIDs.length === 0)
    ) {
      return listOfPv;
    }

    const items = listOfPv.filter((f) => filterData.ownerIDs.includes(f.ownerID));
    if (items.length === 0) return [];

    return items.map(SetKeyToArray);
  }, [filterData.ownerIDs, listOfPv]);

  //
  useEffect(() => {
    const data = JsonStringToFormObject(window.sessionStorage.getItem(FILTER_DATA_IN_SESSION_NAME));
    if (data) {
      setFilterData(data);
    } else {
      window.sessionStorage.setItem(FILTER_DATA_IN_SESSION_NAME, FormObjectToJsonString(INITIAL_FILTER_DATA));
    }
  }, []);
  const handleCidRequestQuery = useCallback(() => {
    const queryConditions = JsonStringToFormObject(
      window.sessionStorage.getItem(FILTER_DATA_IN_SESSION_NAME)
    );

    setLoading(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery(
                $timezone: String!, 
                $ownerIDs: [String], 
                $objectIDs: [String], 
                $problemCategoryIDs: [String],
                $timeoutWarning: String,
                $overtimeWarning: String
              ) {
              om_getCidTimeMonitorList(
                timezone: $timezone,
                ownerIDs: $ownerIDs,
                objectIDs: $objectIDs,
                problemCategoryIDs: $problemCategoryIDs,
                timeoutWarning: $timeoutWarning,
                overtimeWarning: $overtimeWarning
              ) {
                cidFormID
                objectList
                levelSeq
                levelName
                problemCategoryName
                arrivalDays
                repairDays
                occurTime
                latestArrivalTime
                latestRepairTime
                scheduledOmStartTime
                actualOmStartTime
                repairCompleteTime
                arrivalTimeoutDays
                repairTimeoutDays
                arrivalTimeoutWarning
                repairTimeoutWarning
                arrivalOvertimeWarning
                repairOvertimeWarning
                arrivalOvertimeDesc
                repairOvertimeDesc
              }
            }
            `
          ),
          variables: queryConditions,
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.error(`載入CID控管列表資料失敗: ${GetAppSyncRspsErrorMessage(res)}`);
          return;
        }
        //
        const data = (res.data.data.om_getCidTimeMonitorList || []).map((m, idx) => ({
          key: idx,
          ...m,
          objectList: Array.isArray(m.objectList)
            ? m.objectList.filter((f) => f).map((m) => JsonStringToFormObject(m))
            : [],
        }));
        setCidList(data);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error('load cid time monitor list error', err);
          message.error(`${t('reportCidTimeMonitor.loadCidTimeMonitorListError')}`);
        }
      })
      .then(() => {
        setLoading(false);
      });
  }, [user, t]);
  useEffect(() => {
    handleCidRequestQuery();
  }, [handleCidRequestQuery]);

  //
  const handleFilterDataChange = (name) => (e) => {
    const modifyValue = e && e.target ? e.target.value : e;
    const tmp = { ...filterData };
    tmp[name] = modifyValue;
    delete tmp[`${name}_msg`];

    if (name === 'ownerIDs' && Array.isArray(modifyValue) && modifyValue.length > 0 && tmp.objectIDs) {
      const items = listOfPv.filter(
        (f) => tmp.objectIDs.includes(f.value) && modifyValue.includes(f.ownerID)
      );
      tmp.objectIDs = items && items.length > 0 ? items.map((i) => i.value) : [];
    }

    setFilterData(tmp);
  };
  const handleFilterDataReset = () => {
    window.sessionStorage.setItem(FILTER_DATA_IN_SESSION_NAME, FormObjectToJsonString(INITIAL_FILTER_DATA));
    setFilterData(INITIAL_FILTER_DATA);
  };
  const handleSearch = () => {
    window.sessionStorage.setItem(FILTER_DATA_IN_SESSION_NAME, FormObjectToJsonString(filterData));
    handleCidRequestQuery();
  };

  //
  const handleModalOpen = (record) => () => {
    setCidData({
      cidFormID: record.cidFormID,
      arrivalOvertimeDesc: record.arrivalOvertimeDesc,
      repairOvertimeDesc: record.repairOvertimeDesc,
    });
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setCidData({});
    setModalOpen(false);
  };

  const handleCidDataChange = (name) => (e) => {
    setCidData((prev) => ({
      ...prev,
      [name]: e?.target?.value,
    }));
  };

  const handleOvertimeDescSave = () => {
    if (!cidData.cidFormID) return;

    setSaving(true);
    axios
      .post(`${appConfigs.apiGatewayURL}/oms/cid/overtimeDesc/save`, cidData, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.warn(`${t('reportCidTimeMonitor.saveCidOvertimeDescFailed')}: ${res?.data?.msg}`);
          return;
        }

        message.success(t('reportCidTimeMonitor.saveCidOvertimeDescSuccess'));
        setCidList((prevList) =>
          prevList.map((item) =>
            item.cidFormID === cidData.cidFormID
              ? {
                  ...item,
                  arrivalOvertimeDesc: cidData.arrivalOvertimeDesc,
                  repairOvertimeDesc: cidData.repairOvertimeDesc,
                }
              : item
          )
        );
        handleModalClose();
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('reportCidTimeMonitor.saveCidOvertimeDescAbnormal'));
          console.error('save cid overtime desc error: ', err);
        }
      })
      .then(() => {
        setSaving(false);
      });
  };

  return (
    <>
      <PageHeader className="app-page-header" title={t('reportCidTimeMonitor.title')} />

      <div className="app-page-searchpanel">
        <Collapse onChange={() => setCollapse((val) => !val)}>
          <Panel header={t('reportCidTimeMonitor.filterConditions')} key="1">
            <Row gutter={[8, 8]}>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder="SPV"
                  options={listOfPvOwner}
                  value={filterData.ownerIDs}
                  onChange={handleFilterDataChange('ownerIDs')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('reportCidTimeMonitor.objectList')}
                  options={listOfPvByOwner}
                  value={filterData.objectIDs}
                  onChange={handleFilterDataChange('objectIDs')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <RfdmeSelect
                  mode="multiple"
                  placeholder={t('reportCidTimeMonitor.problemCategoryName')}
                  options={listOfCidProblemCategory}
                  value={filterData.problemCategoryIDs}
                  onChange={handleFilterDataChange('problemCategoryIDs')}
                />
              </Col>
              <Col xs={24} sm={24} md={6} lg={8} xl={8}>
                <RfdmeSelect
                  placeholder={t('reportCidTimeMonitor.timeoutWarning')}
                  options={WarningOptions(t('reportCidTimeMonitor.timeout'))}
                  value={filterData.timeoutWarning}
                  onChange={handleFilterDataChange('timeoutWarning')}
                />
              </Col>
              <Col xs={24} sm={24} md={6} lg={8} xl={8}>
                <RfdmeSelect
                  placeholder={t('reportCidTimeMonitor.overtimeWarning')}
                  options={WarningOptions(t('reportCidTimeMonitor.overtime'))}
                  value={filterData.overtimeWarning}
                  onChange={handleFilterDataChange('overtimeWarning')}
                />
              </Col>
              <Col xs={12} sm={12} md={6} lg={{ span: 3, offset: 2 }} xl={{ span: 3, offset: 2 }}>
                <Button block loading={loading} onClick={handleFilterDataReset}>
                  {t('reportCidTimeMonitor.reset')}
                </Button>
              </Col>
              <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                <Button type="primary" block loading={loading} onClick={handleSearch}>
                  {t('reportCidTimeMonitor.search')}
                </Button>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </div>

      {/* 列表 */}
      <div className="app-page-content">
        <Card size="small">
          <Spin spinning={loading} tip={t('reportCidTimeMonitor.searching')}>
            <Table
              bordered
              size="small"
              pagination={false}
              columns={GenerateColumns(handleModalOpen)}
              dataSource={cidList}
              scroll={{ x: '105vw', y: collapse ? 'calc(100vh - 385px)' : `calc(100vh - 490px)` }}
            />
          </Spin>
        </Card>
      </div>

      {/* 跳出視窗-表單結案 */}
      <RfdmeModal width="600px" open={modalOpen}>
        <div className="app-modal-header">
          <div className="ant-modal-title">{t('reportCidTimeMonitor.cidOvertimeDesc')}</div>
        </div>
        <Button className="app-modal-close" icon={<CloseOutlined />} type="text" onClick={handleModalClose} />
        <div className="app-modal-content">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <RfdmeTextArea
                label={t('reportCidTimeMonitor.arrivalOvertimeDesc')}
                rows={5}
                value={cidData.arrivalOvertimeDesc}
                onChange={handleCidDataChange('arrivalOvertimeDesc')}
                showCount
                maxLength={200}
              />
            </Col>
            <Col span={24}>
              <RfdmeTextArea
                label={t('reportCidTimeMonitor.repairOvertimeDesc')}
                rows={5}
                value={cidData.repairOvertimeDesc}
                onChange={handleCidDataChange('repairOvertimeDesc')}
                showCount
                maxLength={200}
              />
            </Col>
          </Row>
        </div>
        <div className="app-modal-footer">
          <Row gutter={[8, 8]}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button block onClick={handleModalClose} disabled={saving}>
                {t('reportCidTimeMonitor.close')}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button block type="primary" loading={saving} onClick={handleOvertimeDescSave}>
                {t('reportCidTimeMonitor.save')}
              </Button>
            </Col>
          </Row>
        </div>
      </RfdmeModal>
    </>
  );
};

export default CidTimeMonitor;
